import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Register/Login.vue';
import Register from '../views/Register/Register.vue';
import Index from '../views/Index/Index.vue';
import SelectReg from '../views/Register/SelectReg.vue';
import ResetPassword from '../views/Register/ResetPassword.vue';
import HomePage from './../components/HomePage/HomePage.vue';
import WeChat from './../components/WeChat/WeChat.vue';
import UserManage from './../components/UserManage/UserManage.vue';
import EditUser from './../components/UserManage/EditUser.vue';
import UserDetail from './../components/UserManage/UserDetail.vue';
import system from './../components/SystemSetting/System.vue';
import AccessManage from './../views/Access/AccessManage.vue';
import AccesScale from './../views/Access/AccesScale.vue';
import AccessGroupManage from './../views/Access/AccessGroupManage';
import AccessConfiger from './../views/Access/AccessConfiger';
import QuestAndAnswer from './../views/FAQ/QuestAndAnswer';
import QuestDetail from './../views/FAQ/QuestDetail';
import FollowUp from './../views/FollowUp/FollowReportList';
import FollowPlan from './../views/FollowUp/FollowPlan.vue';
import AddFollow from './../views/FollowUp/AddFollow.vue';
import QuestionFrom from './../views/FollowUp/QuestionFrom.vue';
import AccessDetail from './../views/Access/AccessDetail.vue';
import AccessAudit from './../views/Access/AccessAudit.vue';
import QuestionFromList from './../views/FollowUp/QuestionFromList.vue';
import PlanConfiger from './../views/FollowUp/PlanConfiger';

import Print from '../views/Register/Print';


import test from '../views/Index/test.vue';


Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/print',
      component: Print,
    },
    {
      path: '/register/:type/:isModify?',
      component: Register,
    },
    {
      path: '/seletReg',
      component: SelectReg,
    },
    {
      path: '/resetPassword',
      component: ResetPassword,
    },
    {
      path: '/test',
      component: test,
    },    
    {
      path: '/index',
      component: Index,
      children: [
        {
          path: '/homePage',
          component: HomePage,
          meta: { title: '首页', requireAuth: true }
        },
        {
          path: '/weChat',
          component: WeChat,
          meta: { title: '聊天', requireAuth: true }
        },
        {
          path: '/userManage',
          component: UserManage,
          meta: { title: '用户管理', requireAuth: true }
        },
        {
          path: '/EditUser/:patientId?',
          component: EditUser,
          meta: { title: '用户编辑/新增', requireAuth: true }
        },
        {
          path: '/UserDetail/:patientId',
          component: UserDetail,
          meta: { title: '用户详情', requireAuth: true }
        },
        {
          path: '/system/:sysType',
          component: system,
          meta: { title: '系统设置', requireAuth: true }
        },
        {
          path: '/accessManage',
          component: AccessManage,
          meta: { title: '测评报告', requireAuth: true }
        },
        {
          path: '/accesScale',
          component: AccesScale,
          meta: { title: '量表管理', requireAuth: true }
        },
        {
          path: '/accessGroupManage',
          component: AccessGroupManage,
          meta: { title: '量表管理', requireAuth: true }
        },
        {
          path: '/accessConfiger',
          component: AccessConfiger,
          meta: { title: '应用配制', requireAuth: true }
        },
        {
          path: '/accessDetail/:accessId',
          component: AccessDetail,
          meta: { title: '应用详情', requireAuth: true }
        },
        {
          path: '/accessAudit/:accessId',
          component: AccessAudit,
          meta: { title: '审核报告', requireAuth: true }
        },
        {
          path: '/questAndAnswer',
          component: QuestAndAnswer,
          meta: { title: '问答', requireAuth: true }
        },
        {
          path: '/questDetail/:qaId?',
          component: QuestDetail,
          meta: { title: '问答详情', requireAuth: true }
        },
        {
          path: '/followUp',
          component: FollowUp,
          meta: { title: '随访', requireAuth: true }
        },
        {
          path: '/followPlan',
          component: FollowPlan,
          meta: { title: '随访计划', requireAuth: true }
        },
        {
          path: '/planConfiger',
          component: PlanConfiger,
          meta: { title: '应用配制', requireAuth: true }
        },
        {
          path: '/addFollow/:planId?',
          component: AddFollow,
          meta: { title: '新增计划', requireAuth: true }
        },
        ,
        {
          path: '/questionFromList',
          component: QuestionFromList,
          meta: { title: '问卷列表', requireAuth: true }
        },
        {
          path: '/questionFrom/:questionId?',
          component: QuestionFrom,
          meta: { title: '问卷表单', requireAuth: true }
        }
      ]
    },
  ]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
