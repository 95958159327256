<template>
  <div class="assess">
    <follow-nav routeLink="followUp"></follow-nav>
    <div class="manage-search">
      <div class="search-left">
        <div class="search-input-out">
          <el-input class="search-input" v-model="userName" placeholder="搜索患者姓名" />
          <img src="../../assets/images/search.png" class="search-icon" @click="getPlanLists" />
        </div>
        <el-date-picker
          v-model="startDate"
          type="date"
          class="search-date"
          placeholder="开始日期">
        </el-date-picker>
        <el-date-picker
          v-model="endDate"
          type="date"
          class="search-date"
          placeholder="结束日期">
        </el-date-picker>
        <el-select class="search-select" placeholder="分组" v-model="groupId" >
          <el-option
            v-for="item in followGroupDatas"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="access-con"> 
      <el-table
        v-has="'mgr.visitReport.query'"
        :data="ReportListData"
        class="table-div"
        height="calc(100vh -320px)"
        style="width: 100%">
        <el-table-column
          prop="personName"
          label="用户姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="name"
          label="随访计划"
          width="280">
        </el-table-column>
        <el-table-column
          prop="groupName"
          label="分组">
        </el-table-column>
        没有分组信息？？？
        <el-table-column
          prop="visitStartDate"
          label="随访开始时间">
        </el-table-column>
        <el-table-column
          prop="visitEndDate"
          label="随访结束时间">
        </el-table-column>
        <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button class="group-link " v-has="'mgr.visitReport.query'" @click="toDetail(scope.row,0)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">人工随访</el-button> 
              <el-button class="group-link" v-has="'mgr.visitReport.query'" @click="toDetail(scope.row,1)" style="color:#4a4a4a" type="text" size="small">查看</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="pager" v-has="'mgr.visitReport.query'">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
        <el-pagination
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
        </div>
      <!-- 分组 -->
    </div>
    <el-drawer
    title="随访详情"
    class="exam-drawer"
    size="614"
    @close="closeExport"
    :visible="exportDrawerVisable">
      <follow-detail
      v-if="recordDetail && planDetail"
      :recordDetail="recordDetail"
      :planDetail="planDetail"
      :seeType="seeType"
      @toDoExam="toDoExam"
      ></follow-detail>
    </el-drawer>
    <!--做量表-->
    <el-drawer
    title="随访详情(量表)"
    class="exam-drawer"
    @close="closeToAccess"
    :visible="exportToAccVisable">
      <to-access 
        v-if="startAccessDetail"
        :replyId="replyId"
        :startAccessDetail="startAccessDetail"
        @returnFoll="closeToAccess"
        @accessSuccess="accessSuccess"
      ></to-access>
    </el-drawer>
    <!--做问卷-->
    <el-drawer
    title="随访详情(问卷)"
    class="exam-drawer"
    @close="closeToQuestion"
    :visible="exportToVisable">
      <to-question 
        v-if="startReplyDetail"
        :doExamId="doExamId"
        :questPlanId="questPlanId"
        :startReplyDetail="startReplyDetail"
        @returnFoll="closeToQuestion"
      ></to-question>
    </el-drawer>
  </div>
</template>
<script>
import FollowNav from '@/components/FollowUp/FollowNav'
import FollowDetail from '@/components/FollowUp/FollowDetail'
import ToQuestion from '@/components/FollowUp/ToQuestion'
import ToAccess from '@/components/FollowUp/ToAccess'
import {
  //reqVisitPlanList,
  reqVisitReport,
  reqPageGroup,
  reqVisitRecordDetail,
  reqVisitPlanDetail,
  regStartReply,
  reqQuestionDetail
} from '../../api/index'
import { powerRight } from '../../utils/index'
export default {
  components: {FollowNav, FollowDetail, ToQuestion, ToAccess},
  data () {
    return {
      startDate: null,
      endDate: null,
      userName: '',
      followGroupDatas: [],
      ReportListData: [],
      exportDrawerVisable: false,
      pageNo: 1,
      pageSize:  20,
      total: 0,
      groupId: '',
      detailId: null,
      listData: null, // 当前选中的随访报告
      seeType: 0, // 人工随访/查看
      recordDetail: null, // 随访记录
      planDetail: null, // 随访计划
      exportToAccVisable: false, // 做题
      startAccessDetail: null, // 量表详情
      exportToVisable: false, // 做题
      startReplyDetail: null, // 问卷详情
      replyId: null, // 做量表的replyId
      doExamId: null,  // 做量表的id
      contentId: null, //問卷的id
      questPlanId: null, // 问卷planId
    }
  },
   mounted(){
    if(powerRight('mgr.visitGroup.query')){
      this.getGroupList()
    }
    if(powerRight('mgr.visitReport.query')){
      this.getPlanLists()
    }
    // this.getWjDetail()
  },
  methods:{
      
    // 分组列表
    getGroupList(){
      reqPageGroup({
        pageNo: 0,
        pageSize: 1000
      }).then(res => {
        if(res && res.code === 0){
          this.followGroupDatas = res.data.list
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getPlanLists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getPlanLists()
    },
    // 列表
    getPlanLists(){
      const params = {
        endTime: this.endDate,
        groupId: this.groupId,
        pageModel: {
          pageNo: this.pageNo,
          pageSize: this.pageSize
        },
        startTime: this.startDate,
        userName: this.userName
      }
      reqVisitReport(params).then(res => {
        if(res && res.code === 0){
          this.total = res.data.totalCount    
          this.ReportListData = res.data.list     
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      // 要去掉
      //this.ReportListData = [
      //   {
      //     personName: '张三三',
      //     id: 1
      //   }
      // ]
      // this.total = 12
    },
    toDetail(data,type){
      this.listData = data
      console.log('toDetail',data,type)
      this.detailId = data.id
      this.seeType = type
      reqVisitRecordDetail(data.id).then(res => {
        if(res && res.code === 0){
          this.recordDetail = res.data
          console.log('recordDetail --',this.recordDetail)
          reqVisitPlanDetail(data.planId).then(planRes => {
            console.log('reqVisitPlanDetail',planRes)
            if(planRes && planRes.code === 0){
              this.planDetail = planRes.data
              this.exportDrawerVisable = true
            }else{
              this.$message({
                message: '获取随访计划失败,'+planRes.msg,
                type: 'error'
              }); 
            }
          })
        }else{
          this.$message({
            message: '获取随访记录失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    closeExport(){
      this.exportDrawerVisable = false
    },
    // ----------个人做题
    // 详情 做题
    closeToQuestion(){
      this.exportToVisable = false
    },
    // 问卷
    getWjDetail(){
      reqQuestionDetail(this.contentId).then(res => {
        if(res && res.code === 0){
          this.startReplyDetail = res.data
          this.replyId = res.data.replyId
          this.exportToVisable = true
        }else{
          this.$message({
            message: '获取问卷详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 医生给做题(量表)
    toDoExam(data,item){
      console.log('toDoExam',data,item)
      
      if(data.contentType ==='scale'){
        this.doExamId = data.id
        this.getLbDetail()
      }else if(data.contentType ==='questionnaire'){
        this.questPlanId = item.planId
        this.doExamId = data.id
        this.contentId = data.contentId
        this.getWjDetail()
      }
      
    },
    // -- 做量表
    closeToAccess(){
      this.exportToAccVisable = false
    },
    // 做了量表了
    accessSuccess(){
      this.exportToAccVisable = false
      this.toDetail(this.listData,0)
    },
    // 评测量表
    getLbDetail(){
      regStartReply({
        id: this.doExamId//'003646b791f6874ecd76a9b6e3c9c654'
      }).then(res => {
        if(res && res.code === 0){
          this.startAccessDetail = res.data
          this.replyId = res.data.replyId
          this.exportToAccVisable = true
        }else{
          this.$message({
            message: '获取问卷详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-date
      width 136px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .access-con
    .table-div
      width 100%
      font-size 14px
      overflow auto
      height calc(100vh - 230px)
      overflow auto
      >>>tr
        line-height 18px
        margin 4px 0
        >>>.el-button--text
          color #4a4a4a!important
        &:hover>td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
          .table-green-btn
            color #00A389!important
            font-weight 700!important
      >>>.table-handles
        white-space nowrap
      .table-green-btn
        font-size 14px
        color #4a4a4a!important
        font-weight bold
      .popover-more
        margin-left 10px!important
        position relative
        top -4px
  .exam-drawer
    /deep/.el-drawer__header 
      color #009375
      font-size 16px
      font-weight bold
      padding 20px 0 20px 20px
      position relative
      margin-bottom 5px
      &:after
        content ""
        position absolute
        display inline-block
        width 100%
        height 1px
        bottom 0
        right 0
        background #d8d8d8
      .el-icon-close
        color #4a4a4a
        font-size 16px
</style>