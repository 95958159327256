<template>
  <div class="assess">
    <access-links :routeLink="'accessConfiger'"></access-links>
    <div class="configer">
      <div class="configer-title">应用配置</div>
      <!-- <rule-setting  configModule="exam" ></rule-setting> -->
      <div class="configer-main">
        <!--<div class="configer-item">
           <div class="configer-item-con">
            <span class="item-name">知情同意书</span>
            <div class="item-con">
              <div class="item-radio">
                <span class="radio-circle radio-circle-active"></span>
                开启
              </div>
              <div class="item-radio">
                <span class="radio-circle"></span>
                关闭
              </div>
            </div>
          </div>
          <textarea class="configer-area"></textarea>
        </div> -->
        <div class="configer-item" v-for="(item,index) in moduleList" :key="index">
          <template v-if="item.configCode === 'exam.agree.book'">
            <div class="configer-item-con" >
              <span class="item-name">{{item.configName}}</span>
              <div class="item-con">
                <el-radio-group class="configer-radios" :value="item.configValue" @change="changeConfig($event,item.configCode)">
                  <el-radio  label="Y">开启</el-radio>
                  <el-radio label="N">关闭</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="configer-more" >
              <textarea  v-model="item.configDesc" class="configer-area"  @focus="showDesBtns = true"></textarea>
              <div class="batch-btns" v-if="showDesBtns">
                <el-button class="batch-btn" size="small" type="" @click="item.configDesc = configDesc">取消</el-button>
                <el-button class="batch-btn success-btn" size="small" @click="confirmBook(item)"  v-if="'mgr.moduleConfig.saveConfig'" type="">确定</el-button>
              </div>
            </div>
          </template>
          <!-- 报告样式 -->
          <div class="configer-item-con"  v-else-if="item.configCode === 'exam.report.style'">
            <span class="item-name">{{item.configName}}</span>
            <div class="item-con">
              <el-radio-group class="configer-radios" :value="item.configValue" @change="changeConfig($event,item.configCode)">
                <el-radio  label="Y">简易</el-radio>
                <el-radio label="N">指导</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- 报告导出 -->
          <template v-else-if="item.configCode === 'exam.report.export'">
            <div class="configer-item-con" >
              <span class="item-name">{{item.configName}}</span>
              <div class="item-con">
                <el-radio-group class="configer-radios" :value="item.configValue" @change="changeConfig($event,item.configCode)">
                  <el-radio  label="Y">允许</el-radio>
                  <el-radio label="N">关闭</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="configer-other" style="padding-left:180px;margin-bottom:20px;" v-if="item.configValue === 'Y'">
              <el-radio-group class="configer-radios" v-model="item.configDesc"  @change="changeConfigValue">
                <el-radio label="Word">Word</el-radio>
                <el-radio label="PDF">PDF</el-radio>
              </el-radio-group>
            </div>
          </template>

          <div class="configer-item-con" v-else>
            <span class="item-name">{{item.configName}}</span>
            <div class="item-con">
              <el-radio-group class="configer-radios" :value="item.configValue" @change="changeConfig($event,item.configCode)">
                <el-radio  label="Y">开启</el-radio>
                <el-radio label="N">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">是否允许重做</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">是否允许查看报告</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">是否开启价格</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">是否开启报告审核</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">报告样式</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">打印报告选项</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">报告导出</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="item-con has-left">
            <el-radio-group class="configer-radios">
              <el-radio :label="3">Word</el-radio>
              <el-radio :label="6">Excel</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="configer-item">
          <div class="configer-item-con">
            <span class="item-name">报告签名开启</span>
            <div class="item-con">
              <el-radio-group class="configer-radios">
                <el-radio :label="3">开启</el-radio>
                <el-radio :label="6">关闭</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  regListByModule,
  regSaveConfig
} from '../../api/index'
import AccessLinks from '@/components/Access/AccessLinks'
import RuleSetting from '@/components/SystemSetting/RuleSetting'
export default {
  components:{ AccessLinks, RuleSetting },
  data () {
    return {
      moduleList: [],
      configDesc: '',
      showDesBtns: false
    }
    
  },
  mounted(){
    this.getAllModule()
  },
  methods:{
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
    getAllModule(){
      regListByModule({configModule:'exam'}).then(res => {
        if(res.code === 0){
         this.moduleList = res.data
         for(let i=0; i<this.moduleList.length; i++){
           if(this.moduleList[i].configCode === 'exam.agree.book'){
             this.configDesc = this.moduleList[i].configDesc
           }
         }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    changeConfig(val,code){
      console.log('val,code',val,code)
      regSaveConfig({
        configCode: code,
        configValue: val==='Y'?'N':'Y'
      }).then(res => {
        if(res.code === 0){
         this.getAllModule()
         this.$message({
            message: '操作成功',
            type: 'success'
          }); 
        }else{
          this.$notify.error({
            title: '错误',
            message: '修改失败！' + res.msg
          });
          
        }
      })
    },
    changeConfigValue(val){
      regSaveConfig({
        configCode: 'exam.report.export',
        configDesc: val,
        configValue: 'Y'
      }).then(res => {
        if(res.code === 0){
         this.getAllModule()
         this.$message({
            message: '操作成功',
            type: 'success'
          }); 
        }else{
          this.$notify.error({
            title: '错误',
            message: '修改失败！' + res.msg
          });
          
        }
      })
    },
    confirmBook(data){
      regSaveConfig({
        configCode: data.configCode,
        configValue: data.configValue,
        configDesc: data.configDesc
      }).then(res => {
        if(res.code === 0){
         this.getAllModule()
         this.showDesBtns = false
         this.$message({
            message: '操作成功',
            type: 'success'
          }); 
        }else{
          this.$notify.error({
            title: '错误',
            message: '修改失败！' + res.msg
          });
          
        }
      })
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .assess-manages
    width 367px
    height 46px
    line-height 46px
    box-sizing border-box
    border 1px solid #E8E8E8
    display flex
    justify-content space-between
    border-radius 25px
    margin 0 auto 25px
    .assess-item
      display inline-block
      width 100px
      height 40px
      line-height 40px
      text-align center
      margin  3px
      font-size 14px
      cursor pointer
    .assess-item-active
      border-radius 25px
      background #E0F2EE
      color #009375
      font-weight  bold
  .configer
    padding 30px 90px
    .configer-title
      font-weight bold
      font-size 18px
      margin-bottom 40px
    .configer-main
      .configer-item
        border-bottom 1px dashed #9E9E9E
        .configer-item-con
          display flex
          padding 18px 0
          line-height 20px
          flex-wrap wrap
          .item-name
            width 130px
            text-align right
            margin-right 50px
          .item-con
            display flex
            .item-radio
              display flex
              margin-right 75px
              .radio-circle
                display inline-block
                width 16px
                height 16px
                border-radius 50%
                border 2px solid #cacaca
                position relative
                top 3px
                margin-right 8px
              .radio-circle-active
                width 14px
                height 14px
                border 5px solid #009375
            .configer-radios
              /deep/.is-checked
                .el-radio__label
                  color #009375
                .el-radio__inner
                  border-color #009375
                  background #009375
        .configer-other
          .configer-radios
            /deep/.is-checked
              .el-radio__label
                color #009375
              .el-radio__inner
                border-color #009375
                background #009375
        .has-left
          margin-left 180px!important
          margin-bottom 15px
        .configer-more
          display flex
          .configer-area
            width 469px
            height 100px
            border-radius 10px
            border 1px solid #9E9E9E
            margin 0 0 18px 180px
            resize none
            padding 8px
          .batch-btns
            width 70px
            padding-left 20px
            .batch-btn
              width:70px
              height 34px
              border-radius 5px
              background #F0F3F6
              border none
            .success-btn
              background #009375
              color #fff
              margin 10px 10px 0 0
</style>