<template>
  <div class="assess">
    <access-links :routeLink="'accessAudit'"></access-links>
    <div class="qadetail-return" @click="returnList" >
      <img src="../../assets/images/return_arrow.png" class="return-icon"/>
      <span >返回报告列表</span>
    </div>
    <div class="access-detail">
      <div class="access-set">
        <p class="set-title-tabs">
          <span class="set-title-tab" :class="{ 'set-title-tab-active': replyStatus === 0}" @click="changeStatus(0)">待审核</span>
          <span class="set-title-tab" :class="{ 'set-title-tab-active': replyStatus === 1}" @click="changeStatus(1)">已审核</span>
        </p>
        <!-- <template v-if="replyStatus === 0"> -->
        <div class="audit-con">
          <input type="text" class="audit-search" v-model="keyword" placeholder="搜索电话、姓名、量表名" />
          <img src="../../assets/images/search.png"  class="search-icon" @click="getReplylist" />
        </div>
        <div class="audit-items">
          <div class="audit-item" :class="{'audit-item-active':accessId == item.replyId}" v-for="(item,i) in replyList" :key="i" @click="changeAccess(item.replyId)">
            <div class="audit-item-con">
              <p class="audit-title">{{item.examName}}</p>
              <p class="audit-item-bot">
                <span>{{item.name}}</span> 
                <span>{{item.examTime}}</span>
              </p>
            </div>
          </div>
          <!-- <div class="audit-item">
            <p class="audit-title">SAS焦虑测评量表</p>
            <p class="audit-item-bot">
              <span>王强</span> 
              <span>2022-12-12</span>
            </p>
          </div> -->
        </div>
      </div>
      <div class="access-main">
        <div class="report">
          <div class="report-top">
            <p class="report-main-title" v-if="orgName">{{orgName}}</p>
            <p class="report-sentit">{{reportName}}</p>
          </div>
          <div class="report-titles">
            <span class="report-title">一、基础信息</span>
            <span class="report-tit-right">测评日期：{{createTime}}</span>
          </div>
          <div class="report-base">
            <span class="base-item">
              <span class="base-item-name">姓        名：</span>
              <span class="base-item-val">{{name}}</span>
            </span>
            <span class="base-item">
              <span class="base-item-name">性       别：</span>
              <span class="base-item-val">{{sex}}</span>
            </span>
            <span class="base-item">
              <span class="base-item-name">出生日期：</span>
              <span class="base-item-val">{{birthday}}岁</span>
            </span>
            <span class="base-item">
              <span class="base-item-name">学        历：</span>
              <span class="base-item-val">{{education}}</span>
            </span>
            <span class="base-item">
              <span class="base-item-name">手机号码：</span>
              <span class="base-item-val">{{mobilePhone}}</span>
            </span>
            <span class="base-item">
              <span class="base-item-name">科        室：</span>
              <span class="base-item-val">{{deptName}}</span>
            </span>
          </div>
          <div class="report-titles">
            <span class="report-title">二、测评分析</span>
          </div>
          <div class="report-froms">
            <div class="report-from-top">
              <span class="from-item textindnt">名称</span>
              <span class="from-item">得分</span>
              <span class="from-item">参考范围</span>
              <span class="from-item">提示</span>
            </div>
            <div class="from-sans" v-for="(item,index) in reportItemList" :key="index">
               <div class="from-san textindnt">{{item.title}}</div>
                <div class="from-san">{{item.scoreFact}}</div>
                <div class="from-san from-san-left">
                  <p class="from-san-item">{{item.reference}}</p>
                  <!-- <p class="from-san-item">0-4：无</p>
                  <p class="from-san-item">5-7：轻度</p>
                  <p class="from-san-item">8-15：中度</p>
                  <p class="from-san-item">16-39：中度</p> -->
                </div>
                <div class="from-san">-</div>
            </div>
          </div>
          <div class="report-titles">
            <span class="report-title">三、指导建议</span>
          </div>
          <div class="report-jy">
            <div class="docter-con">{{advice}}</div>
          </div>
          <div class="report-titles">
            <span class="report-title">四、测评详情</span>
          </div>
          <div class="replay-out">
            <div class="replay-item" v-for="(qu,quidx) in replyJson"  :key="quidx">
              <div class="replay-title">{{qu.examName}} </div>
              <template v-if="qu.isSelect">
                <div  class="replay-ans" v-for="(opt,optidx) in qu.replyAnswer" :key="optidx">{{opt.content}} </div>
              </template>
              <div v-else class="replay-ans">{{qu.replyAnswer}} </div>
            </div>
          </div>
          <div v-for="(item, index) in customArr" :key="index">
            <div class="report-titles" >
              <span class="report-title">{{item.title}}</span>
            </div>
            <div class="report-jy">
              <div class="docter-con">{{item.content}}</div>
            </div>
          </div>
          <template v-if="auditInfo">
            <div class="report-titles" >
              <span class="report-title">审核结果 {{auditInfo.auditStatusDesc}}</span>
            </div>
            <div class="report-jy"> 
              <div class="docter-con" >状态 ：{{auditInfo.auditStatusDesc}}</div>
              <div class="docter-con-des"> 描述 ：{{auditInfo.auditContent}}</div>
            </div>
          </template>
          <!-- <div class="report-bottom">
            <div class="report-qimin">
              <span>评估人员签字：</span>
              <img src="../../assets/images/singo.png" class="sigo-img"/>
            </div>
          </div>
          <div class="report-bot">
            <span class="report-time">打印时间：2022-11-18 09:35:24</span>
            <span>* 本报告数据均来源于本次测评结果，不能代替医生诊断，仅供参考。</span>
          </div> -->
        </div>
        <div class="from-item-btns" >
          <span class="from-item-btn" @click="handlAudit(2)">未通过</span>
          <span class="from-item-btn from-item-btn-blue" @click="handlAudit(1)" >通过审核</span>
        </div>
      </div>
    </div>
    <audit-dialog 
      :isAudit="isAudit" 
      :accessId="accessId"
      @closeAudit="closeAudit"
      @successAudit="successAudit"
      v-if="auditDialog"
    ></audit-dialog>
  </div>
</template>

<script>
import {
  regExamlist,
  regReplyList,
  regExamReport
} from '../../api/index'
import AccessLinks from '@/components/Access/AccessLinks'
import AuditDialog from '@/components/Access/AuditDialog'
export default {
  components: {AccessLinks, AuditDialog},
  data () {
    return {
      checkedSets: ['上海', '北京'],
      setings: ['基本信息', '因子', '指导建议', '测试答案', '自定义项（1）', '医生签字'],
      isAudit: false, // 是否通过审核
      accessId: null,
      reportName: '',
      createTime: '',
      name: '',
      sex: '',
      birthday: '',
      education: '',
      mobilePhone: '',
      deptName: '',
      eleSign: '',
      advice: '',
      reportItemList: [],
      auditDialog: false,
      replyList: [],
      replyJson: [],
      customArr: [],
      //aleryReplyList: [],
      replyStatus: 0, // 1 已审核 / 0 未审核 / 2 审核不通过
      keyword: '',
      auditInfo: null,
      orgName: '',
    }
    
  },
  mounted(){
    this.getReplylist()
    if(this.$route.params.accessId === undefined){
      this.$message({
        message: '非法路径',
        type: 'error'
      }); 
      this.$router.push({ path: '/accessManage'})
    }
    this.accessId  = Number(this.$route.params.accessId)
    this.toAccessDetail()
  },
  methods:{
    // 审核列表
    getReplylist(){
      const params = {
        pageNo: 1,
        pageSize: 1000,
        auditStatus: this.replyStatus, // 1 已审核 / 0 未审核 / 2 审核不通过
        keyword: this.keyword,
        status: 2
      }
      regReplyList(params).then(res => {
        if(res && res.code === 0){
          this.replyList= res.data.list
        }else{
          this.$message({
            message: '获取审核列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
     // 查看详情
    toAccessDetail(){
      regExamReport({replyId:this.accessId}).then(res => {
        if(res && res.code === 0){
          this.detailObj= res.data
          this.reportName = this.detailObj.reportName
          this.createTime = this.detailObj.createTime
          this.advice = this.detailObj.advice
          this.orgName = this.detailObj.orgName || ''
          this.reportItemList = this.detailObj.reportItemList
          this.auditInfo = this.detailObj.auditInfo
          this.name = this.detailObj.userInfo.name
          this.sex = this.detailObj.userInfo.sex
          this.birthday = this.detailObj.userInfo.birthday
          this.education = this.detailObj.userInfo.education
          this.mobilePhone = this.detailObj.userInfo.mobilePhone
          this.deptName = this.detailObj.userInfo.deptName
          this.eleSign = this.detailObj.userInfo.eleSign
          if(this.eleSign){
            this.setings = ['基本信息', '测评分析', '指导建议', '测评详情','自定义项', '医生签字']
          }else {
            this.setings = ['基本信息', '测评分析', '指导建议', '测评详情','自定义项']
          }
          this.customArr = this.detailObj.extendList
          let reply = this.detailObj.reply.replyDataJson
          //let ree = [{"examType":"单选题","examName":"您是否觉得心情低落?","replyAnswer":"偶尔有"}]
          let geyJson = JSON.parse(reply)
          console.log('geyJson',geyJson)
          for(let i=0;i<geyJson.length; i++){
            if(geyJson[i].examType === "choice_single"){
              geyJson[i].isSelect = true
              geyJson[i].replyAnswer = JSON.parse(geyJson[i].replyAnswer)
            }
          }
          this.replyJson = geyJson
          console.log('this.replyJson',this.replyJson)
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
          this.returnList()
        }
      })
    },
    changeStatus(status){
      this.replyStatus = status
      this.getReplylist()
    },
    returnList(){
      this.$router.push({ path: `/accessManage`})
    },
    handleSetChange(value) {
      let checkedCount = value.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 审核
    handlAudit(val){
      this.auditDialog = true
      this.isAudit = val
    },
    successAudit(){
      this.closeAudit()
    },
    closeAudit(){
      this.toAccessDetail()
      this.auditDialog = false
    },
    changeAccess(id){
      console.log('changeAccess')
      this.accessId  = id
      this.toAccessDetail()
      this.$router.push({ path: `/accessAudit/${id}`})
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .qadetail-return
    width 100%
    display block
    height 70px
    display flex
    align-items center
    line-height 70px
    font-size 14px
    cursor pointer
    .return-icon
      width 30px
      margin 0 13px 0 40px
  .access-detail
    padding 0 0 0 18px
    display  flex
    align-items stretch
    .access-set
      width 315px
      border-radius 10px
      border 1px solid #9E9E9E
      overflow-y scroll
      .set-title-tabs
        height 48px
        text-align center
        .set-title-tab
          display inline-block
          height 32px
          line-height 32px
          margin-top 7px
          width 50%
          font-size 16px
          font-weight bold
          border-right 1px solid #d8d8d8
          text-align center
          cursor pointer
        .set-title-tab-active
          color #009375
      .audit-con
        position relative
        border-top 1px solid #d8d8d8
        border-bottom 1px solid #d8d8d8
        .audit-search
          width 100%
          height 40px
          line-height 40px
          padding 0 30px 0 20px
        .search-icon
          position absolute
          right 15px
          top 13px
          cursor pointer
      .audit-items
        padding-bottom 20px
        .audit-item
          line-height 22px
          margin 5px 10px 5px
          padding-bottom 1px
          overflow hidden
          cursor pointer
          border-bottom 1px dashed  #d8d8d8
          .audit-item-con
            padding 5px 5px 2px
            .audit-title
              font-size 16px
            .audit-item-bot
              display flex
              justify-content space-between
              font-size 12px
              color #9e9e9e
              min-height 14px
        .audit-item-active
          .audit-item-con
            border-radius 5px
            background #E0F2EE
            margin-bottom 2px
            .audit-title
              color #009375
    .access-main
      flex 1
      border 1px solid #9e9e9e
      border-radius 10px
      margin 0 35px
      padding 0 50px
      .report
        .report-top
          padding 36px 0 50px
          text-align center
          font-weight bold
          .report-main-title
            font-size 20px
            line-height 28px
            margin-bottom 10px
          .report-sentit
            font-size 18px
        .report-titles
          height 36px
          line-height 36px
          border-bottom 2px solid #000
          font-size 14px
          font-weight bold
          display flex
          justify-content space-between
          .report-title
            font-weight bold
            .title-input
              flex 1
              min-width 300px
              height 30px
              border 1px solid #CED4DA
              border-radius 5px
          .report-tit-right
            font-size 12px
            font-weight normal
        .report-base
          padding 15px 25px
          display flex
          flex-wrap wrap
          .base-item
            display inline-block
            width 33.3%
            margin-bottom 8px
        .report-froms
          border-bottom 1px solid #000
          margin-bottom 25px
          .textindnt
            text-indent 25px
          .report-from-top
            height 24px
            line-height 24px
            border-bottom 1px solid  #000
            font-size 12px
            display flex
            margin 5px 0
            .from-item
              flex 1
              text-align left
          .from-sans
            display flex
            padding 5px 0
            .from-san
              flex 1
              text-align left
            .from-san-left
              text-align left
            .from-san-item
              margin-bottom 5px
              line-height 20px
        .report-jy
          min-height 80px
          padding 25px
          .docter-text
            width 100%
            height 80px
            padding 5px 10px
            border-radius 5px
            border-color #9e9e9e
          .docter-text
            width 100%
            padding 5px 10px
          .docter-con-des
            margin 10px 0
        .replay-out
          margin-bottom 20px
          padding 15px 15px
          .replay-item
            margin-bottom 5px
            .replay-title
              margin 10px 0 15px
            .replay-ans
              margin-left 5px
              padding 5px 10px 5px 20px
              border-radius 3px
              background #E0F2EE
              color #009375
        .report-bottom
          padding-bottom 8px
          border-bottom 1px solid #000
          text-align right
          .sigo-img
            width 136px
        .report-bot
          display flex
          font-size 10px
          padding 10px 0 50px
          justify-content space-between
        .report-btn
          display inline-block
          width 104px
          margin 20px auto
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          cursor pointer
      .from-item-btns
        display flex 
        padding 0 80px 40px
        padding 0 40px
        justify-content space-around
        .from-item-btn
          padding 0 9px 0 17px
          height 34px
          line-height 34px
          background #F0F0F5
          border-radius 5px
          letter-spacing 8px
          margin 15px 0 10px
        .from-item-btn-blue
          background #009375
          color #fff
</style>