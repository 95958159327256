<template>
  <el-dialog
    title="邀请用户"
    class="dialog-main"
    :visible=true
    @close="closeInviteDialog"
    width="480px">
    <div class="dialog-con">
      <div class="invite-con">
        <div class="invite-code-outer">
          <img v-if="codeUrl" :src="codeUrl" class="invite-code" />
          <img v-else src="../../assets/images/code.png" class="invite-code" />
          <div class="invite-right">
            <p class="invite-par">归属分组</p>
            <el-select  class="invite-input" placeholder="请选择"  v-model="groupId" >
              <el-option v-for="(item,i) in chartGroup"  :key="i" :value="item.id" :label="item.groupName"></el-option>
            </el-select>
            <p class="invite-par">有效时间</p>
            <el-date-picker
              v-model="dateVal"
              type="daterange"
              class="invite-date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleChangeDate"
              >
            </el-date-picker>
            
            <!-- <span class="set-btn" @click="getExamlQrcode"><img src="../../assets/images/get.png" class="invite-code" />生成二维码</span> -->
          </div>
        </div>
        
        <!-- <p class="invite-bottom"><img src="../../assets/images/dowen.png" class="note-icon"/>   下载邀请二维码</p> -->
        <p class="invite-bottom"  v-if="codeUrl" @click="downCode"> <img src="../../assets/images/dowen.png" class="invite-code-img" />二维码下载</p>
        <p class="invite-bottom" v-else @click="getChartQrcode"> <img src="../../assets/images/get.png" class="invite-code-img" />生成二维码</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  regAddChartQrcode,
  regChatGroupList
} from '../../api/index'
export default {
  props:{
    allCategory: Array,
    chartGroupId: Number
  },
  data () {
    return {
      chartGroup: [], // 分组
      dateVal: ['',''],
      id:'',	
      endTime	:'',	
      startTime:'',	
      group: null,
      groupId: null,
      base64url: '',
      codeUrl: ''
    }
  },
  mounted(){
    console.log('chartGroupId',this.chartGroupId)
    this.getGroupList()
  },
  methods:{
    // 获取分组
    getGroupList(){
      regChatGroupList().then(res=>{
        if(res.code === 0){
          this.chartGroup = res.data
          if(this.chartGroupId){
            this.groupId = this.chartGroupId
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取分组列表失败！' + res.msg
          });
        }
      })
    },
    // 生成二维码
    getChartQrcode(){
      regAddChartQrcode({
        id: this.groupId,	
        startTime: this.dateVal[0],	
        endTime	: this.dateVal[1],	
      }).then(res => {
        if(res && res.code === 0){
          // 转成图片文件
          this.codeUrl =  res.data.qrcodeImgUrl
        }else{
          this.$message({
            message: '生成失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 下载
    downCode(){
      let aLink = document.createElement("a"); // 生成一个a元素
      //let event = new MouseEvent("click"); // 创建一个单击事件
      let event = document.createEvent("HTMLEvents")
      event.initEvent('click',true,true)
      aLink.download =  "二维码.jpg"; // 设置图片名称
      aLink.href = this.codeUrl
      aLink.click()
      // a.href = this.codeUrl; // 将生成的URL设置为a.href属性
      // a.dispatchEvent(event); // 触发a的单击事件
    },
    // 修改日期
    handleChangeDate(data){
      console.log('data',data,this.dateVal)
    },
    closeInviteDialog(){
      this.$emit('closeInviteDialog')
    },
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  >>>.el-dialog__header
    border-bottom 1px solid #D8D8D8
  .dialog-con
    
    .invite-code-outer
      display flex
      margin 15px 0 30px
      .invite-code
        width 135px
        height 135px
        margin-right 25px
        position relative
      .invite-par
        font-size 14px
        font-weight bold
        color #4b4b4b
        margin-top 5px
      .invite-input
        margin 8px 0 10px
        .el-input
          
          border 1px solid #ececec
          border-radius 3px
          padding 0 10px
          width 100%
          input 
            height 30px
            line-height 30px
      .invite-date
        width 250px
        height 30px
        line-height 30px
        color #009375
        margin 12px 0 24px
        >>>.el-range-separator
          width 10%
          line-height 22px
        >>>.el-date-editor .el-range__icon
          line-height 22px
        >>>.el-range-input
          color #009375
      .set-btn
        display flex
        width 116px
        height 36px
        line-height 36px
        border-radius 3px
        padding 0 9px
        font-size 12px
        color #009375
        border 1px solid #CED4DA
        align-items center
        cursor pointer
        .invite-code
          width 17px
          height 17px
          margin-right 10px
          top 4px
          margin-right 4px
    .invite-bottom
      color #009375
      text-align center
      cursor pointer
      .invite-code-img
        width 17px
        height 17px
        margin-right 10px
        top 4px
        margin-right 4px
      .note-icon
        position relative
        top 3px
        margin-right 7px
</style>