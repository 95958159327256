<template>
  <div class="struct">
    <div class="struct-title">成员与部门</div>
    <div class="struct-con">
      <div class="struct-left">
        <!-- <div class="struct-search">
          <input type="text" class="search-input" placeholder="搜索" />
          <img src="../../assets/images/search.png" class="search-icon" />
        </div> -->
        <div class="struct-company">
          <div class="company-name" @click="handleClickDept(orgId)">
            <span >{{orgName}}</span>
            <el-popover
              class="company-popover"
              placement="bottom"
              width="87"
              trigger="hover">
              <div class="company-navs">
                <p class="company-nav" @click="handleAddOrgDia" v-if="'mgr.org.addOrg'">新增机构</p>
                <!-- <p class="company-nav" @click="editOrg()">编辑</p> -->
              </div>
              <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
            </el-popover>
          </div>
        </div>
        <div class="inner-tree">
          <div class="inner-con">
            <!-- <div class="depart-item">
              <div class="depart-item-left"><img src="../../assets/images/organ.png" class="organ-icon" />组织部门</div>
            </div> -->
            <el-tree 
              v-has="'mgr.dept.query'"
              :data="firstOrgDeptArr" 
              :props="defaultProps" 
              class="org-tree"
              :default-expand-all=true
              :check-strictly="true"
              :check-on-click-node="true"
              :expand-on-click-node="false"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }" @click="handleClickDept(node)">
                <span class="org-tree-name" >{{ node.label }}</span>
                <el-popover
                  class="company-popover"
                  placement="bottom"
                  width="87"
                  trigger="hover">
                  <div class="company-navs">
                    <p class="company-nav" @click="editDept(node)"  v-has="'mgr.dept.editDept'">编辑</p>
                    <p class="company-nav"  @click="deleteDept(node)" v-has="'mgr.dept.removeDept'">删除</p>
                  </div>
                  <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
                </el-popover>
              </span>
            </el-tree>
          </div>
          <!-- 子公司 -->
          <div class="inner-con" v-for="(item,index) in childOrg" :key="index" v-has="'mgr.org.query'" >
            <div 
              class="secend-tit" 
              :class="{'secend-tit-active': item.id === currentOrgId}"
              @click="setCurrentOrg(item)"
            >
              <span class="org-name" >{{item.orgName}}</span>
              <el-popover
                class="company-popover"
                placement="bottom"
                width="87"
                trigger="hover">
                <div class="company-navs">
                  <p class="company-nav" v-has="'mgr.org.editOrg'">编辑</p>
                  <p class="company-nav" >删除</p>
                </div>
                <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
              </el-popover>
              <!-- <img src="../../assets/images/organ.png" class="organ-tree-icon" /> -->
            </div>
            <el-tree 
              v-has="'mgr.dept.query'"
              :data="childOrg[index].children" 
              :props="defaultProps" 
              class="org-tree"
              :default-expand-all=true
              :check-strictly="true"
              :check-on-click-node="true"
              :expand-on-click-node="false"
              @node-click="FnHandleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="org-tree-name" @click="handleClickDept(node.id)">{{ node.label }}</span>
                <el-popover
                  class="company-popover"
                  placement="bottom"
                  width="87"
                  trigger="hover">
                  <div class="company-navs">
                    <p class="company-nav" @click="editDept(node)" v-has="'mgr.dept.editDept'">编辑</p>
                    <p class="company-nav"  @click="deleteDept(node)" v-has="'mgr.dept.removeDept'">删除</p>
                  </div>
                  <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
                </el-popover>
              </span>
            </el-tree>
            <!-- <div class="depart-item">
              <img src="../../assets/images/organ.png" class="organ-icon" />组织部门
            </div> -->
          </div>
        </div>
        <!-- <div class="add-org" v-if="addOrgVisiable">
          机构名：<input type="text" class="add-org-text" placeholder="请填写新的机构名" />
          <span class="add-org-btn" @click="handleAddOrg">确定</span>
        </div> -->
        <div class="add-company" @click="handleAddDeptDia"  v-has="'mgr.dept.addDept'">
          + 新建部门
        </div>
      </div>
      <div class="struct-right">
        <div class="right-top">
          <div class="right-top-left">
            <span class="struct-top-title">{{listOrgName}}</span>
            <span  class="struct-total">总人数（{{total}}）</span>
          </div>
          <div class="right-top-right">
            <span class="search-btn" @click="getImoprtTemplate" v-has="'mgr.member.import'"><img src="../../assets/images/import.png"  class="search-btn-icon"> 获取导入模板</span>
            <span class="search-btn"  @click="handleImportant"  v-has="'mgr.member.import'">
              <img src="../../assets/images/import.png" class="search-btn-icon"> 导入数据
            </span>
            <span class="search-btn" @click="handleAddMemberDia"  v-has="'mgr.member.add'">
              <img src="../../assets/images/add.png" class="search-btn-icon"> 添加成员
            </span>
          </div>
        </div>
        <div class="struct-tables">
          <el-table
          v-has="'mgr.member.query'"
          :data="userDatas"
          height="calc(100vh - 380px)"
          :header-cell-style="{ background:'#F0F3F6',lineHeight:'18px',padding:'8px 0',color:'#000'}"
          class="struct-table">
            <el-table-column
              prop="name"
              label="姓名"
              width="180">
            </el-table-column>
            <el-table-column
              prop="status"
              label="账号状态">
              <template slot-scope="scope" >
                <template v-if="scope.row.status == 'Y'">
                  <span class="error-text">启用</span>
                </template>
                <template v-else>
                  <span class="error-text">停用</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="mobilePhone"
              label="联系手机号">
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="部门">
            </el-table-column>
            <el-table-column
              width="160"
              class="table-handles"
              style="white-space: nowrap;width:140px;"
              label="操作">
              <template slot-scope="scope"> <!-- slot-scope="scope" -->
                <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
                <el-button
                  type="text"
                  v-has="'mgr.member.query'"
                  @click="handleSeeMemberId(scope.row)"
                  class="table-green-btn"
                  size="small">
                  查看
                </el-button>
                <el-popover
                  popper-class="table-popover"
                  placement="bottom"
                  width="90"
                  style="padding:0;min-width:90px!important;"
                  trigger="click">
                  <div class="company-navs">
                    <div class="company-nav" @click="changeDepart(scope.row)" v-has="'mgr.member.edit'" >变更部门</div>
                    <template  v-has="'mgr.member.edit'">
                      <div class="company-nav" v-if="scope.row.status === 'Y'" @click="changeMemberState(scope.row)"  >暂停账号</div>
                      <div class="company-nav" v-else @click="changeMemberState(scope.row)" >启用账号</div>
                    </template>
                    <div class="company-nav" @click="editMember(scope.row)" v-has="'mgr.member.edit'" >编辑</div>
                  </div>
                  <el-button
                    type="text"
                    style="font-size:8px;letter-spacing:inherit;margin-left:5px;"
                    class="popover-more table-green-btn" 
                    slot="reference"
                    size="small">
                    ● ● ●
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pager">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
          <el-pagination
            v-has="'mgr.member.query'"
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="total, sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 详情抽屉 -->
    <el-drawer
      title=""
      :visible.sync="detailDrawer"
      :with-header="false">
        <struct-member-drawer
          :memberId="memberId"
          v-if="detailDrawer"
          @closeDrawer="closeDrawer"
          @toEditMember="toEditMember"
          @toEditSYMember="toEditSYMember"
        ></struct-member-drawer>
    </el-drawer>
    <!-- 新建机构 -->
    <struct-add-org-dialog
      v-if="addOrgVisiable"
      :userDatas="userDatas"
      :DiaOrgId="DiaOrgId"
      @closeAddOrg="closeAddOrg"
      @addOrgSuccess="addOrgSuccess"
    ></struct-add-org-dialog>
    <!-- 新建部门 -->
    <struct-adddept-dialog
      v-if="addDeptVisiable"
      :userDatas="userDatas"
      :currentOrgId="currentOrgId"
      :childOrg="childOrg"
      :orgName="orgName"
      :mainOrgDeptTree="mainOrgDeptTree"
      :currentDeptId="currentDeptId"
      @closeAddDept="closeAddDept"
      @addDeptSuccess="addDeptSuccess"
    ></struct-adddept-dialog>
    <!-- 添加成员 -->
    <struct-add-member-dialog
      :orgName="orgName"
      v-if="addMemberVisiable"
      @closeAddMember="closeAddMember"
      @addMemberSuccess="addMemberSuccess"
      :memberId="memberId"
      :memberTitle="memberTitle"
    ></struct-add-member-dialog>
    <!-- 添加成员 end -->
    <!-- 变更部门 -->
    <change-dept-dialog-vue
     v-if="changeDeptVisible"
     ref="changeDia"
     :memberId="memberId"
     :firstOrgDeptArr="firstOrgDeptArr"
     :childOrg="childOrg"
     :orgName="orgName"
     :orgId="orgId"
     @closeChangeDept="closeChangeDept"
     @changeDeptSucc="changeDeptSucc"
    ></change-dept-dialog-vue>
    <!-- 暂停账号 -->
    <suspend-account v-if="suspendVisible" :suspendName="suspendName" @confirmSuspend="handleChangeState" @cancleSuspend="cancleSuspend"></suspend-account>
    <!-- 导入文件 -->
    <el-dialog
      title="批量导入"
      class="batch-upload"
      :visible.sync="uploadDialogVisible"
      width="680px">
      <el-upload
        class="upload-demo"
        drag
        ref="upload" 
        accept=".xls,.xlsx"
        action="https://localhost:8080/posts/"
        :on-change="uploadExcel"
        :show-file-list="false"
        :auto-upload="false"
        multiple>
        <div class="loading" v-if="loadingExcel"><i class="el-icon-loading"></i></div>
        <template v-else>
          <i class="el-icon-upload" ></i>
          <div class="el-upload__text">拖拽文件或选择上传你文件，只能上传excel文档</div>
        </template>
      </el-upload>
      
    </el-dialog>
    <!-- 导入文件end -->
    <!-- 导入文件end -->
    <batch-add-member
      v-if="uploadTableVisible"
      :menuData="menuData"
      :excelName="excelName"
      @closeAddUser="closeAddUser"
      @batchAddSuccess="batchAddSuccess"
      @closeExcel="closeExcel"
    ></batch-add-member>
    <purview-dialog
    v-if="PurviewVisiable"
    :memberId="memberId"
    :memberName="memberName"
    @closePurview="closePurview"
    @authSucccess="authSucccess"
    ></purview-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import {
  regOrgTree,
  regFromUpload,
  reglistMember,
  regDeptTree,
  regRemoveDept,
  regEditMemberStatus,
  reqMeUploadTemplate
} from '../../api/index'
import { powerRight } from '../../utils/index'
const xlsx = require('xlsx')
import StructMemberDrawer from '@/components/SystemSetting/SystemCompontents/StructMemberDrawer'
import StructAdddeptDialog from '@/components/SystemSetting/SystemCompontents/StructAdddeptDialog'
import StructAddMemberDialog from '@/components/SystemSetting/SystemCompontents/StructAddMemberDialog'
import StructAddOrgDialog from '@/components/SystemSetting/SystemCompontents/StructAddOrgDialog'
import ChangeDeptDialogVue from './SystemCompontents/ChangeDeptDialog.vue';
import SuspendAccount from '@/components/SystemSetting/SystemCompontents/SuspendAccount'
import BatchAddMember from '@/components/SystemSetting/StructComponents/BatchAddMember'
import PurviewDialog from '@/components/SystemSetting/RoleComonents/PurviewDialog'
export default {
  name: 'Structure',
  data() {
    return {
      orgName: '', // 一级组织树名
      listOrgName: '',
      changeDeptVisible: false, // 变更组织
      orgId: null, // 一级组织ID
      detailDrawer: false, // 是否显示详情抽屉
      userDatas:[],
      pageNo: 1,
      pageSize:  20,
      total: 0, // 成员数
      addOrgVisiable: false, // 新增机构
      addDeptVisiable: false, // 新建部门
      addMemberVisiable: false, // 添加成员
      categoryName: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },      
      memberId: null, // 查看用户详情ID
      isEditMember: false, // 查看成员是否为编辑状态
      childOrg: [], // 二级机构
      firstOrgDeptArr: [], //  一组机构下的部门树
      mainOrgDeptTree: null, // 包含一级机构级下的部门数
      currentOrgId: null, // 当前机构ID
      currentOrgName: '', // 当前机构名字
      currentDeptId: null, // 编辑部门，部门id
      memberTitle: '添加成员',
      suspendVisible: false, // 暂停账号
      suspendName: '',
      uploadDialogVisible: false,
      loadingExcel:false,
      menuData: [],
      excelName: '',
      uploadTableVisible: false,
      PurviewVisiable: false, // 权限分配
      memberName: '',
    }
  },
  components:{
    StructMemberDrawer,
    StructAdddeptDialog,
    StructAddMemberDialog,
    StructAddOrgDialog,
    ChangeDeptDialogVue,
    SuspendAccount,
    BatchAddMember,
    PurviewDialog
  },
  mounted(){
    if(powerRight('mgr.org.query')){
      this.getOrgTree()
    }
    if(powerRight('mgr.member.query')){
      this.getMemberList()
    }
  },
  methods:{
    
    // 获取组织树
    getOrgTree(){
      regOrgTree({}).then(res => {
        if(res.code === 0){
          console.log('组织树树',res.data)
          this.orgName = res.data.orgName
          this.listOrgName = this.orgName
          this.orgId = res.data.id
          let childData = res.data.children
          if(powerRight('mgr.dept.query')){
            this.getOneOrgDept(res.data.id)
          }
          console.log('childData',childData)
          this.childOrg = childData
          if(powerRight('mgr.dept.query')){
            for(let i=0; i<childData.length; i++){
              this.getDeptTree(childData[i],i)
            }
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取组织树失败！'+ res.msg
          });
          
        }
      })
    },
    // 获取一级组织下的部门树
    getOneOrgDept(id){
      regDeptTree({
        orgId: id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.firstOrgDeptArr = res.data
          this.mainOrgDeptTree = {
            id: this.orgId,
            orgName: this.orgName,
            name: this.orgName,
            children: res.data
          }
          console.log('组织下部门树',res.data,this.mainOrgDeptTree)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    // 获取组织下的部门树
    getDeptTree(data,i){
      regDeptTree({
        orgId: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.childOrg[i].children = res.data
          //this.$refs.changeDia.setTree()
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getMemberList()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getMemberList()
    },
    // 获取成员列表
    getMemberList(){
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        deptId: this.currentDeptId
      }
      reglistMember(params).then(res => {
        if(res.code === 0){
          console.log('成员列表',res.data)
          this.userDatas = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取成员列表失败！' + res.msg
          });
          
        }
      })
    },
    // 选择机构
    setCurrentOrg(val){
      this.currentOrgId = val.id
      this.currentOrgName = val.orgName
      this.handleClickDept(val.id)
    },
    // 新增机构
    handleAddOrg(){

    },
    // 新建部门
    handleAddDeptDia(){
      // regAddDept
      // if(this.currentOrgId === null){
      //   this.$notify.warning({
      //     title: '提示',
      //     message: '请先点选机构或上级部门'
      //   });
      //   return false
      // }
      console.log('新建部门2')
      this.addDeptVisiable = true
      this.currentDeptId = null
    },
    // 新建部门成功
    addDeptSuccess(){
      this.addDeptVisiable = false
      this.getOrgTree()
    },
    // 编辑部门
    editDept(node = null){
      console.log('node',node)
      if(node){
        this.currentDeptId = node.data.id
      }else{
        this.currentDeptId = this.orgId
      }
      this.addDeptVisiable = true
    },
    FnHandleNodeClick(node){
      console.log('FnHandleNodeClick',node)
      this.currentDeptId = node.id
      this.getMemberList()
    },
    handleClickDept(id){
      console.log('handleClickDept',id,typeof id)
      if(typeof id == 'number'){
        this.currentDeptId = ''
        this.listOrgName = this.orgName
      }else{
        this.currentDeptId = id.data.id
        this.listOrgName = id.data.name
      }
      this.getMemberList()
    },
    // 删除部门
    deleteDept(node){
      console.log('node',node)
      regRemoveDept({
        id: node.data.id
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: '删除部门成功！',
            type: 'success'
          });
         this.getOrgTree()
        }else{
          this.$notify.error({
            title: '错误',
            message: '删除部门失败！'+ res.msg
          });
        }
      })
    },
    // 新增成员
    handleAddMemberDia(){
      this.addMemberVisiable = true
      this.memberId = null
      this.memberTitle = "添加成员"
    },
    // 成员详情
    handleSeeMemberId(data){
      this.memberId = data.id
      this.detailDrawer = true
      this.isEditMember = false
    },
    // 编辑成员
    editMember(data){
      this.addMemberVisiable = true
      this.memberId = data.id
      this.memberTitle="编辑成员"
    },
    // 暂停开启成员
    changeMemberState(data){
      if(data.status === 'Y'){
        this.suspendName = data
        this.suspendVisible = true
        console.log('暂停开启成员',data)
        return false
      }
      this.handleChangeState(data)
    },
    cancleSuspend(){
      this.suspendVisible = false
    },
    handleChangeState(data){
      regEditMemberStatus({
        id: data.id,
        status: data.status === 'Y' ? 'N': 'Y'
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.suspendVisible = false
          this.getMemberList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '操作失败！'+ res.msg
          });
        }
      })
    },
    
    // 获取批量导入模板
    getImoprtTemplate(){
      reqMeUploadTemplate().then(res => {
        if(res && res.code === 0){
          if(res.data && res.data.url){
            window.open(res.data.url)
          }
        }else{
          this.$message({
            message: '获取批量导入模板失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 批量导入
    handleImportant(){
      this.uploadDialogVisible = true
    },
    uploadExcel (file, fileList) {
      console.log('fileList',fileList)
      const files = { 0: file.raw }// 取到File
      this.loadingExcel = true
      console.log('true')
      this.readExcel(files)
    },
    readExcel (files) { // 表格导入
      if (files.length <= 0) { // 如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      console.log('files',files)
      this.excelName = files[0].name
      const fileReader = new FileReader()
      fileReader.onload = (files) => {
        try {
          const data = files.target.result
          
          const workbook = xlsx.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0]// 取第一张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
          this.menuData = _.map(ws, obj => ({  
            name: obj.姓名, 
            mobilePhone: obj.手机号,
            accountId: obj.工号,
            roleName: obj.角色职务,
            status: obj.状态 ,            
          }));
          for(let i=0; i<this.menuData.length; i++){
            if(this.menuData[i].status == '启用'){
              this.menuData[i].status = 'Y'
            }else{
              this.menuData[i].status = 'N'
            }
          }
          if(this.menuData.length){
            this.uploadTableVisible = true
          }
          console.log( 'ws是表格里的数据，且是json格式',ws,this.menuData)
          // 重写数据
          this.loadingExcel = false
          this.$refs.upload.value = ''
          // return ws
        } 
        catch (e) {
          console.log(e)
          this.loadingExcel = false
          return false
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
    // 批量新增
    closeAddUser(){
      this.uploadTableVisible = false
    },
    // 删除整个批量上传的excel
    closeExcel(){
      this.menuData = []
      this.uploadTableVisible = false
      this.uploadDialogVisible = true
    },
    // 批量新增成功
    batchAddSuccess(){
      this.uploadTableVisible = false
      this.uploadDialogVisible = false
      this.getMemberList()
    },
    handleNodeClick(data){
      console.log('handleNodeClick',data)
    },
    closeAddDept(){
      this.addDeptVisiable = false
    },
    changeDeptSucc(){
      this.changeDeptVisible = false
    },
    // 新增成员关闭
    closeAddMember(){
      this.addMemberVisiable = false
    },
    // 新增成员成功
    addMemberSuccess(){
      this.addMemberVisiable = false
      this.getMemberList()
    },
    closeAddOrg(){
      this.addOrgVisiable = false
    },
    addOrgSuccess(){
      this.getOrgTree()
    },
    // 新增机构
    handleAddOrgDia(){
     this.addOrgVisiable = true 
     this.DiaOrgId = null
    },
    editOrg(){
      this.addOrgVisiable = true 
      this.DiaOrgId = this.orgId
    },
    // 关闭详情抽屉
    closeDrawer(){
      this.detailDrawer = false
    },
    toEditMember(id){
      this.detailDrawer = false
      this.addMemberVisiable = true
      this.memberId = id
    },
    toEditSYMember(id,name){
      this.detailDrawer = false
      this.PurviewVisiable = true
      this.memberId = id
      this.memberName = name
    },
    // 关闭权限
    closePurview(){
      this.PurviewVisiable = false
    },
    // 授权成功
    authSucccess(){
      this.PurviewVisiable = false
      //this.editRole(this.roleId)
    },

    // 变更部门
    changeDepart(data){
      this.memberId = data.id
      this.changeDeptVisible = true
    },
    // 关闭变更部门
    closeChangeDept(){
      this.changeDeptVisible = false
    },
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.struct
  .struct-title
    color #009375
    font-size 16px
    font-weight bold
    padding 10px 29px 25px
    border-bottom 1px solid #d8d8d8
  .struct-con
    display flex
    height calc(100vh - 205px)
    .struct-left
      display inline-block
      width 295px
      padding 15px 10px 15px 25px
      border-right 1px solid #d8d8d8
      .struct-search
        display inline-block
        width 260px
        height 40px
        border-radius 5px
        background #F0F3F6
        position relative
        display flex
        align-items center
        margin-bottom 25px
        .search-input
          width 210px
          height 40px
          line-height 40px
          margin 0 10px
          background transparent
          &:focus
            outline none
      .struct-company
        .company-name
          display flex
          height 22px
          line-height 22px
          font-size 16px
          justify-content space-between
          >>>.more-icon
            cursor pointer
          .company-popover
            display none
          &:hover
            .company-popover
              display inline-block
      .inner-tree
        .inner-con
          .secend-tit
            padding-left 10px
            display flex
            width 100%
            justify-content space-between
            height 20px
            line-height 20px
            font-size 16px
            margin 20px 0 10px
            position  relative
            .organ-tree-icon
              position absolute
              left 14px 
              top 30px
              width 14px
              z-index 1
            .company-popover
              display none
            &:hover
              .company-popover
                display inline-block
          .secend-tit-active
            background #fcfcfc
          .org-tree
            .custom-tree-node
              display flex
              width 100%
              justify-content space-between
              height 18px
              line-height 18px
              font-size 14px
            /deep/.el-tree-node__content
              padding-left 10px!important
              margin 4px 0 0
              .el-tree-node__children
                padding-left 10px
            /deep/.el-tree-node__content
              .company-popover
                display none
              &:hover
                .company-popover
                  display inline-block
      .add-company
        display inline-block
        width 260px
        height 38px
        line-height 38px
        border-radius 5px
        border 1px solid #DADADA
        text-align center
        margin-top 40px
        cursor pointer
      .add-org
        margin 20px 0
        display flex
        align-items center
        font-size 12px
        .add-org-text
          height 28px
          line-height 28px
          outline 1px solid #ccc
          padding 0 10px
          border-radius 2px
          width 140px
          &:placeholder
            color #ccc
        .add-org-btn
          display inline-block
          width 50px
          height 28px
          line-height 28px
          text-align center
          border-radius 5px
          background #009375
          color #fff
          cursor pointer
          margin-left 10px
    .struct-right
      flex 1
      padding 20px 40px
      .right-top
        display flex
        justify-content space-between
        align-content cneter
        line-height 40px
        margin-bottom 20px
        .right-top-left
          font-size 18px
          font-weight bold
          .struct-total
            font-size 14px
            margin-left 25px
            font-weight normal
        .right-top-right
          .search-btn
            display inline-block
            height 40px
            padding 0 10px
            border 1px solid #d8d8d8
            border-radius 5px
            margin-left 25px
            align-items center
            cursor pointer
            .search-btn-icon
              margin-right 10px
              position relative
              top 3px
      .struct-table
        width 100%
        font-size 14px
        overflow auto
        >>>tr
          line-height 18px
          margin 4px 0
          >>>.el-button--text
            color #4a4a4a!important
          &:hover>td 
            background  #E0F2EE
            color #00A389
            font-weight  bolder
            .table-green-btn
              color #00A389!important
              font-weight 700!important
        >>>.table-handles
          white-space nowrap
        .table-green-btn
          font-size 14px
          color #4a4a4a!important
          font-weight bold
        .popover-more
          margin-left 10px!important
          position relative
          top -4px
    .pagers
      margin-top 20px
  .batch-upload
    >>>.el-icon-upload
      margin 22px 0 22px
      &::before
        content ""
        display inline-block
        width 40px
        height 30px
        background url(../../assets/images/upload.png) no-repeat
    >>>.el-dialog__header
      border-bottom 1px solid #d8d8d8
      font-size 16px
      font-weight bolder
    >>>.el-upload__text
      color #009375
    >>>.el-upload-dragger
      width 642px
      height 137px
      border-color #009375 
    >>>.loading
      margin-top 40px
      i
        font-size 30px
.company-navs
  padding 6px 4px 0
  .company-nav
    display inline-block
    width 77px
    height 20px
    line-height 20px 
    margin-bottom 6px
    padding 0 5px
    cursor pointer
    font-size 12px
    &:hover
      background #D0E6E6
      border-radius 3px
      color #009375
      
</style>