<template>
  <div class="struct">
    <div class="struct-title">角色管理</div>
    <div class="struct-con">
      <div class="struct-left">
        <!-- <div class="struct-search">
          <input type="text" class="search-input" placeholder="搜索" />
          <img src="../../assets/images/search.png" class="search-icon" />
        </div> -->
        <div class="struct-company">
          <div class="company-name">
            <span class="company-name">{{orgName}}</span>
          </div>
        </div>
        <div class="inner-tree">
          <div class="inner-con">
            <!-- <div class="depart-item">
              <div class="depart-item-left"><img src="../../assets/images/organ.png" class="organ-icon" />组织部门</div>
            </div> -->
            <el-tree 
              v-has="'mgr.role.query'"
              :data="firstOrgDeptRoleArr" 
              :props="defaultProps" 
              class="org-tree"
              :default-expand-all=true
              @node-click="nodeClickHandle"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }" :class="{'custom-tree-node-bg': !data.deptId}">
                <img v-if="data.deptId" src="../../assets/images/role_icon.png" class="role-icon" />
                <span class="org-tree-name">{{ node.label }} </span>  
                <el-popover
                  v-if="data.type === 'role'"
                  class="company-popover"
                  placement="bottom"
                  width="87"
                  trigger="click">
                  <div class="company-navs">
                    <p class="company-nav" @click="editRole(data.id)" v-has="'mgr.role.edit'">编辑</p>
                    <p class="company-nav"  @click="deleteRole(data)" v-has="'mgr.role.remove'">删除</p>
                  </div>
                  <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
                </el-popover>
              </span>
              <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="org-tree-name">{{ node.label }}</span>
                <el-popover
                  class="company-popover"
                  placement="bottom"
                  width="87"
                  trigger="click">
                  <div class="company-navs">
                    <p class="company-nav" @click="editDept(node)">编辑</p>
                    <p class="company-nav"  @click="deleteDept(node)">删除</p>
                  </div>
                  <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
                </el-popover>
              </span> -->
            </el-tree>
          </div>
          <!-- 子公司 -->
          <div class="inner-con" v-for="(item,index) in childOrgRole" :key="index">
            <div 
              class="secend-tit" 
              :class="{'secend-tit-active': item.id === currentOrgId}"
              
            >
            <!-- @click="setCurrentOrg(item)" -->
              <span class="org-name">{{item.orgName}}</span>
              <!-- <el-popover
                class="company-popover"
                placement="bottom"
                width="87"
                trigger="click">
                <div class="company-navs">
                  <p class="company-nav">编辑</p>
                  <p class="company-nav" >删除</p>
                </div>
                <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
              </el-popover> -->
              <!-- <img src="../../assets/images/organ.png" class="organ-tree-icon" /> -->
            </div>
            <el-tree 
              :data="childOrgRole[index].children" 
              :props="defaultProps" 
              class="org-tree"
              :default-expand-all=true
              @node-click="nodeClickHandle"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }" :class="{'custom-tree-node-bg': !data.deptId}">
                <img v-if="data.deptId" src="../../assets/images/role_icon.png" class="role-icon" />
                <span class="org-tree-name">{{ node.label }}</span>  
                <el-popover
                  v-if="data.type === 'role'"
                  class="company-popover"
                  placement="bottom"
                  width="87"
                  trigger="click">
                  <div class="company-navs">
                    <p class="company-nav" @click="editRole(data.id)" v-has="'mgr.role.edit'">编辑</p>
                    <p class="company-nav"  @click="deleteRole(data)" v-has="'mgr.role.remove'">删除</p>
                  </div>
                  <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
                </el-popover>
              </span>
            </el-tree>
            <!-- <div class="depart-item">
              <img src="../../assets/images/organ.png" class="organ-icon" />组织部门
            </div> -->
          </div>
        </div>
        <div class="add-company" @click="handleAddRoleDia" v-has="'mgr.role.add'">
          + 新增角色
        </div>
      </div>
      <div class="struct-right">
        <div class="right-top">
          <div class="right-top-left">
            <span class="struct-top-title">{{currentDeprtName}}</span>
          </div>
          <div class="right-top-right">
            <span class="search-btn" @click="handleAddMemberDia" v-has="'mgr.member.add'">
              <img src="../../assets/images/add.png" class="search-btn-icon"> 添加成员
            </span>
          </div>
        </div>
        <div class="struct-tables">
          <el-table
          v-has="'mgr.member.query'"
          :data="userDatas"
          height="calc(100vh - 380px)"
          :header-cell-style="{ background:'#F0F3F6',lineHeight:'18px',padding:'8px 0',color:'#000'}"
          class="struct-table">
            <el-table-column
              prop="name"
              label="姓名"
              width="180">
            </el-table-column>
            <el-table-column
              prop="status"
              label="账号状态">
              <template slot-scope="scope" >
                <template v-if="scope.row.status == 'Y'">
                  <span class="error-text">启用</span>
                </template>
                <template v-else>
                  <span class="error-text">停用</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="mobilePhone"
              label="联系手机号">
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="部门">
            </el-table-column>
            <el-table-column
              width="160"
              v-if="this.roleId"
              class="table-handles"
              style="white-space: nowrap;width:140px;"
              label="操作">
              <template slot-scope="scope"> <!-- slot-scope="scope" -->
                <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
                <el-button
                  type="text"
                  @click="handleDelteMember(scope.row)"
                  class="table-green-btn"
                  size="small">
                  移除
                </el-button>
                <!-- <el-popover
                  popper-class="table-popover"
                  placement="bottom"
                  width="90"
                  style="padding:0;min-width:90px!important;"
                  trigger="click">
                  <div class="company-navs">
                    <div class="company-nav">变更部门</div>
                    <div class="company-nav" >暂停账号</div>
                    <div class="company-nav" @click="editUser" >编辑</div>
                  </div>
                  <el-button
                    type="text"
                    style="font-size:8px;letter-spacing:inherit;margin-left:5px;"
                    class="popover-more table-green-btn" 
                    slot="reference"
                    size="small">
                    ● ● ●
                  </el-button>
                </el-popover> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pager">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
          <el-pagination
            v-has="'mgr.member.query'"
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="total, sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新建角色 -->
    <add-role-dialog
      ref="addRole"
      v-if="addRoleVisiable"
      :title="dailogTitle"
      :roleDetail="roleDetail"
      :childOrg="childOrg"
      :mainOrgDeptTree="mainOrgDeptTree"
      @closeAddRole="closeAddRole"
      @modifyRoleSucc="modifyRoleSucc"
      @addRoleSucc="addRoleSucc"
      @toAuto="toAuto"
    ></add-role-dialog>
    <!-- 新建部门 end -->
    <!-- 添加成员 -->
    <struct-add-member-dialog
      :orgName="orgName"
      v-if="addMemberVisiable && orgName"
      @closeAddMember="closeAddMember"
      @addMemberSuccess="addMemberSuccess"
      :memberTitle="memberTitle"
    ></struct-add-member-dialog>
    <!-- <el-dialog
      title="添加成员"
      class="dialog-main"
      :visible.sync="addMemberVisiable"
      width="800px">
      <div class="dialog-con">
        <div class="dialog-member">
          <div class="dialog-member-half">
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>姓名</p>
              <input type="text" v-model="name" class="dialog-dept-text" placeholder="请输入姓名" />
            </div>
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>手机号</p>
              <input type="text" v-model="mobilePhone" class="dialog-dept-text" placeholder="请输入手机号" />
            </div>
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>初始密码</p>
              <input type="password" v-model="password" class="dialog-dept-text" placeholder="默认手机号码后六位" />
            </div>
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text"></span>工号</p>
              <input type="text"  v-model="jobNo" class="dialog-dept-text" disabled placeholder="默认由系统生成唯一标识" />
            </div>
          </div>
          <div class="dialog-member-half">
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>部门</p>
              <input type="text" class="dialog-dept-text" />
            </div>
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>角色职务</p>
            </div>
            <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>成员状态</p>
              <el-select v-model="status" class="dialog-dept-select"  placeholder="请选择">
                <el-option
                  label="启用"
                  value="Y">
                </el-option>
                <el-option
                  label="停用"
                  value="N">
                </el-option>
              </el-select>
            </div>
            <div class="dialog-dept-item">
               <p class="dialog-dept-name"><span class="red-text">*</span>电子签名</p>
               <el-upload
                  v-if="!eleSign"
                  class="upload-demo"
                  drag
                  :http-request="handleUpload"
                  ref="upload" 
                  action="customize"
                  accept=".jpg,.png,.bmp,.gif"
                  :before-upload="uploadLicense"
                  :show-file-list="false"
                  >
                  <img src="../../assets/images/upload.png" class="upload-img "/>
                  <p class="link-color">拖拽文件或选择上传你文件</p>
                </el-upload>
                <div class="qualif-img" v-else>
                  <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteSign"/>
                  <img :src="eleSign" class="qualif-image" />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-btns">
        <span class="dialog-btn dialog-cancle-btn" @click="addMemberVisiable = false">取消</span>
        <span class="dialog-btn" @click="handleAddMember">确定</span>
      </div>
    </el-dialog> -->
    <!-- 添加成员 end -->
    <purview-dialog
    v-if="PurviewVisiable"
    :roleId="roleId"
    :currentRoleName="currentRoleName"
    :currentRoles = "currentRoles"
    @closePurview="closePurview"
    @authSucccess="authSucccess"
    ></purview-dialog>
  </div>
</template>

<script>
import {
  regOrgTree,
  regAddMember,
  regFromUpload,
  reglistMember,
  regDetailMember,
  regDeptTree,
  regRoleList,
  regDetailRole,
  regDeptAndRoleTree,
  regDeleteRole,
  regRemoveAuthByRole
} from '../../api/index'
import { maxFileSize } from '../../utils/variate'
import { powerRight } from '../../utils/index'
import AddRoleDialog from '@/components/SystemSetting/RoleComonents/AddRoleDialog'
import PurviewDialog from '@/components/SystemSetting/RoleComonents/PurviewDialog'
import StructAddMemberDialog from '@/components/SystemSetting/SystemCompontents/StructAddMemberDialog'
export default {
  name: 'Structure',
  data() {
    return {
      maxFileSize,
      orgName: '', // 组织树名
      currentDeprtName: '',
      dailogTitle: '新建角色' ,
      roleDetail: {},
      PurviewVisiable: false, // 权限分配
      userDatas:[],
      pageNo: 1,
      pageSize:  20,
      total: 0, // 成员数
      addRoleVisiable: false, // 新建角色
      deptName: '', // 部门名称
      deptParentId:'', // 新建部门,上级部门id
      leaderId: '', // 部门领导Id
      categoryName: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      classifyOption:[
        {
          name:'公司组织名',
          id: 1,
          children:[
          ]
        }        
      ],
      // 部门
      addMemberVisiable: false, // 添加成员
      // 成员信息
      jobNo: '', // 工号
      mobilePhone: '',
      name: '',
      password: '',
      status: 'Y', //  启用状态Y 启用/ N 停用
      roleIdList: [], //角色职务id列表
      roleId: null, // 当前角色id
      eleSign: '', // 电子签名
      deptIdList: [], // 部门id列表
      memberDetail: {}, // 用户详情
      orgId: null, // 当前机构
      childOrg: [], // 二级机构
      childOrgRole: [],
      childOrg1:[
        {
          "id": 3,
          "parentId": 1,
          "orgName": "子机构1",
          "children": [
            {
              "id": 1,
              "name": "外科",
              "parentId": 0,
              "children": [
                {
                  "id": 4,
                  "name": "新组织部门1",
                  "parentId": 1,
                  "children": [
                    {
                      "id": 1,
                      "orgId": 3,
                      "deptId": 4,
                      "parentId": 4,
                      "name": "外科组织部门1管理",
                      "numofUser": 0,
                      "memo": "外科组织部门1管理",
                      "flagAdmin": "N",
                      "createTime": "2024-01-23 17:32:47",
                      "updateTime": "2024-01-23 17:32:47"
                    },
                    {
                      "id": 1,
                      "orgId": 3,
                      "deptId": 4,
                      "parentId": 4,
                      "name": "外科组织部门1管理2",
                      "numofUser": 0,
                      "memo": "外科组织部门1管理2",
                      "flagAdmin": "N",
                      "createTime": "2024-01-23 17:32:47",
                      "updateTime": "2024-01-23 17:32:47"
                    },
                    {
                      "id": 7,
                      "name": "烧伤科",
                      "parentId": 0,
                      "children": [
                        {
                          "id": 1,
                          "orgId": 3,
                          "deptId": 4,
                          "parentId": 4,
                          "name": "烧伤科管理",
                          "numofUser": 0,
                          "memo": "烧伤科管理",
                          "flagAdmin": "N",
                          "createTime": "2024-01-23 17:32:47",
                          "updateTime": "2024-01-23 17:32:47"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "id": 2,
              "name": "内科",
              "parentId": 0
            }
          ]
        }
      ],
      firstOrgDeptArr1: [
        {
          "id": 4,
          "name": "新组织部门1",
          "parentId": 1,
          "children": [
            {
              "id": 1,
              "orgId": 3,
              "deptId": 4,
              "name": "外科组织部门1管理",
              "numofUser": 0,
              "memo": "外科组织部门1管理",
              "flagAdmin": "N",
              "createTime": "2024-01-23 17:32:47",
              "updateTime": "2024-01-23 17:32:47"
            },
            {
              "id": 5,
              "name": "新组织部门2",
              "parentId": 1,
            }
          ]
        }
      ],
      firstOrgDeptArr: [], //  一组机构下的部门树
      firstOrgDeptRoleArr: [], // 一组机构下的部门角色树
      currentOrgId: null, // 当前机构ID
      currentOrgName: '', // 当前机构名字
      isAlreadyRole: false, 
      roleList: [], // 所有角色列表
      alerdyGetRole: [],
      currentRoles: [], // 已有权限的个数
      memberTitle: '添加成员',
      currentRoleName: '',
      roleId: '', // 当前角色Id
    }
  },
  components:{
    AddRoleDialog,
    PurviewDialog,
    StructAddMemberDialog
  },
  mounted(){
    if(powerRight('mgr.org.query')){
      this.getOrgTree()
    }
    if(powerRight('mgr.member.query')){
      this.getMemberList()
    }
  },
  methods:{
    editUser(){
      this.detailDrawer = true
    },
    // 获取所有角色
    getAllRole(deptId = null){
      let params = {}
      if(deptId){
        params.deptId = deptId
      }
      regRoleList(params).then(res => {
        if(res.code === 0){
         this.roleList = res.data.list
         //this.handleRoleToTree()
         //this.isAlreadyRole = true
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取角色列表失败！' + res.msg
          });
          
        }
      })
    },
    // 获取组织树
    getOrgTree(){
      regOrgTree({}).then(res => {
        if(res.code === 0){
          console.log('组织树树',res.data)
          this.orgName = res.data.orgName
          this.currentDeprtName = this.orgName
          this.orgId = res.data.id
          let childData = res.data.children
          this.getOneOrgDept(res.data.id)
          this.getOneOrgDeptRole(res.data.id)
          console.log('childData',childData)
          this.childOrg = childData
          this.childOrgRole = childData
          for(let i=0; i<childData.length; i++){
            this.getDeptTree(childData[i],i)
            
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取组织树失败！' + res.msg
          });
          
        }
      })
    },
    // 获取一级组织下的部门角色树
    getOneOrgDeptRole(id){
      regDeptAndRoleTree({
        id: id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.firstOrgDeptRoleArr = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门角色树失败！'+ res.msg
          });
        }
      })
    },
    // 获取一级组织下的部门树
    getOneOrgDept(id){
      regDeptTree({
        orgId: id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.firstOrgDeptArr = res.data
          this.mainOrgDeptTree = {
            id: this.orgId,
            orgName: this.orgName,
            name: this.orgName,
            children: res.data
          }
          console.log('组织下部门树',res.data,this.mainOrgDeptTree)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
     // 获取组织下的部门角色树
    getDeptRoleTree(data,i){
      regDeptAndRoleTree({
        id: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.childOrgRole[i].children = res.data
        
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    // 获取组织下的部门树
    getDeptTree(data,i){
      regDeptTree({
        orgId: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.childOrg[i].children = res.data
          this.getDeptRoleTree(data,i)
          // if(!this.isAlreadyRole){
          //   this.getAllRole()
          // }else{
          //   this.handleRoleToTree()
          // }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    
    treeToListNodes(data,roleList) {
      console.log('treeToListNodes',data,roleList)
      for(var i =0;i<data.length;i++){
        if(data[i].children){
          this.treeToListNodes(data[i].children,roleList)
        }
        console.log('data[i].id',data[i].id)
        for(let j=0;j<roleList.length; j++){
          
          if(roleList[j].deptId == data[i].id){
            console.log('data[i].children',data[i].children,data[i].id)
            if(data[i].children){
              data[i].children = data[i].children.concat(roleList[j])
            }else{
              data[i].children =  [roleList[j]]
            }
            
          }
        }
        // if(folderName !== ''){
        //   folders[i].folderName =  folderName + "/" + folders[i].folderName
        // }
        // listData.push(folders[i])
        // if(folders[i].children && folders[i].children.length){
        //   treeToListNodes(folders[i].children,listData, folders[i].folderName)
        // }
      }
      console.log('last data',data)
      return data
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getMemberList()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getMemberList()
    },
    // 获取成员列表
    getMemberList(){
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        roleId: this.roleId
      }
      reglistMember(params).then(res => {
        if(res.code === 0){
          console.log('成员列表',res.data)
          this.userDatas = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取成员列表失败！'+ res.msg
          });
          
        }
      })
    },
    // 新建角色
    handleAddRoleDia(){
      this.dailogTitle = '新建角色'
      this.roleDetail = null
      console.log('角色部门')
      this.roleId = null
      this.addRoleVisiable = true
    },
    // 新增成员
    handleAddMemberDia(){
      this.addMemberVisiable = true
      this.memberTitle="添加成员"
    },
    // 新增成员
    // handleAddMember(){
    //   if(!this.mobilePhone || this.mobilePhone.length !== 11){
    //     this.$message({
    //       message: '手机号格式不对',
    //       type: 'warning'
    //     }); 
    //     return false; 
    //   }
    //   let params = {
    //     mobilePhone: this.mobilePhone,
    //     name: this.name,
    //     password: '',
    //     roleIdList: [], //角色职务id列表
    //     eleSign: this.eleSign, // 电子签名
    //     deptIdList: [],
    //     status: this.status
    //   }
    //   if(this.password.trim() !== ''){
    //     params.password = this.$md5(this.password).toUpperCase()
    //   }else{
    //     params.password = this.$md5(this.mobilePhone.substring(this.mobilePhone.length - 6)).toUpperCase()
    //   }
    //   console.log('params',params)
    //   regAddMember(params).then(res => {
    //     if(res.code === 0){
    //       this.$message({
    //         message: '新增成员成功',
    //         type: 'success'
    //       }); 
    //     }else{
    //       this.$notify.error({
    //         title: '错误',
    //         message: '新增成员失败！'
    //       });
          
    //     }
    //   })
    // },
    // 查看人员
    handleSeeMember(data){
      regDetailMember({
        id:data.id
      }).then(res => {
        if(res.code === 0){
          this.detailDrawer = true
          this.memberDetail = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '查看人员详情失败！'+ res.msg,
          });
          
        }
      })
    },
    // 移除
    handleDelteMember(data){
      regRemoveAuthByRole({
        id:data.id,
        roleId: this.roleId
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.getMemberList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '移除失败！'+ res.msg,
          });
          
        }
      })
    },
    // 部门负责人ID
    changeLeaderId(data){
      console.log('部六',data)
      this.leaderId = data.id
    }, 
    
    handleNodeClick(data){
      console.log('handleNodeClick',data)
    },
    handleUpload(){},
    // 用戶上傳簽名
    uploadLicense(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.eleSign = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 刪除用戶簽名
    handleDeleteSign(){
      this.eleSign = ''
    },
    // 关闭权限
    closePurview(){
      this.PurviewVisiable = false
    },
    // 授权成功
    authSucccess(){
      this.PurviewVisiable = false
      this.editRole(this.roleId)
    },
    // 关闭新增角色dialog
    closeAddRole(){
      this.addRoleVisiable = false
    },
    // 修改角色树成功
    modifyRoleSucc(){
      this.addRoleVisiable = false
      this.getOrgTree()
    },
    addRoleSucc(id){
      this.getOrgTree()
      this.addRoleVisiable = false
      this.editRole(id)
    },
    // 给角色授权
    toAuto(currentRoles,name){
      this.currentRoles = currentRoles
      this.currentRoleName = name
      this.PurviewVisiable = true
    },
    // 点击树
    nodeClickHandle(e,node){
      console.log('click',e,node)
      this.getAllRole(e.deptId)
      if(node.data.type === "role"){
        this.currentDeprtName = node.data.name
        this.roleId = node.data.id
        this.getMemberList()
      }
    },
    // 编辑角色
    editRole(id){
      console.log('角色',id)
      this.roleId = id
      regDetailRole({id:this.roleId}).then(res => {
        if(res.code === 0){
          this.roleDetail = res.data
          this.addRoleVisiable = true
          this.dailogTitle = '修改角色'
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取角色详情失败！'+ res.msg
          });
          
        }
      })
    },
    deleteRole(data){
      this.$confirm('确认删除该角色么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        regDeleteRole({id:data.id}).then(res => {
          if(res.code === 0){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getOrgTree()
          }else{
            if(res.code == 101){
              this.$confirm('删除角色前，请移除角色内所有成员', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning'
                }).then(() => {
                }).catch(() => {})
            }else{
              this.$notify.error({
                title: '错误',
                message: '删除角色失败！'+ res.msg
              });
            }
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    // 新增成员关闭
    closeAddMember(){
      this.addMemberVisiable = false
    },
    // 新增成员成功
    addMemberSuccess(){
      this.addMemberVisiable = false
      this.getMemberList()
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.struct
  .struct-title
    color #009375
    font-size 16px
    font-weight bold
    padding 10px 29px 25px
    border-bottom 1px solid #d8d8d8
  .struct-con
    display flex
    height calc(100vh - 205px)
    .struct-left
      display inline-block
      width 295px
      padding 15px 10px 15px 25px
      border-right 1px solid #d8d8d8
      overflow-y auto
      .struct-search
        display inline-block
        width 260px
        height 40px
        border-radius 5px
        background #F0F3F6
        position relative
        display flex
        align-items center
        margin-bottom 25px
        .search-input
          width 210px
          height 40px
          line-height 40px
          margin 0 10px
          background transparent
          &:focus
            outline none
      .struct-company
        .company-name
          display flex
          height 22px
          line-height 22px
          font-size 18px
          font-weight bolder
          justify-content space-between
          margin-bottom 10px
          >>>.more-icon
            cursor pointer
            display none
          .company-popover
            display none
          &:hover
            .company-popover
              display inline-block
      .inner-tree
        .inner-con
          .secend-tit
            padding-left 10px
            display flex
            width 100%
            justify-content space-between
            height 20px
            line-height 20px
            font-size 16px
            margin 10px 0
            position  relative
            .org-name
              font-size 18px
              font-weight bold
            .organ-tree-icon
              position absolute
              left 14px 
              top 30px
              width 14px
              z-index 1
          .secend-tit-active
            background #fcfcfc
          .org-tree
            .custom-tree-node
              display flex
              width 100%
              // justify-content space-between
              height 32px
              line-height 32px
              font-size 14px
              border-radius 5px
              padding-left 5px
              position relative
              .company-popover
                display none
                position absolute
                right 0 
                top 5px
              .role-icon
                margin 5px 10px 0 0
                width 20px
                height 20px
              &:hover
                .company-popover
                  display inline-block
            /deep/.el-tree-node__content
              margin-bottom 10px
              padding-left 10px!important
              &:hover
                background transparent
              .el-tree-node__children
                padding-left 10px
            .custom-tree-node-bg
              background #F0F3F6
            /deep/.el-tree-node__content:hover, .el-upload-list__item:hover
              background transparent
      .add-company
        display inline-block
        width 260px
        height 38px
        line-height 38px
        border-radius 5px
        border 1px solid #DADADA
        text-align center
        margin-top 40px
        cursor pointer
      .add-org
        margin 20px 0
        display flex
        align-items center
        font-size 12px
        .add-org-text
          height 28px
          line-height 28px
          outline 1px solid #ccc
          padding 0 10px
          border-radius 2px
          width 140px
          &:placeholder
            color #ccc
        .add-org-btn
          display inline-block
          width 50px
          height 28px
          line-height 28px
          text-align center
          border-radius 5px
          background #009375
          color #fff
          cursor pointer
          margin-left 10px
    .struct-right
      flex 1
      padding 20px 40px
      .right-top
        display flex
        justify-content space-between
        align-content cneter
        line-height 40px
        margin-bottom 20px
        .right-top-left
          font-size 18px
          font-weight bold
          .struct-total
            font-size 14px
            margin-left 25px
            font-weight normal
        .right-top-right
          .search-btn
            display inline-block
            height 40px
            padding 0 10px
            border 1px solid #d8d8d8
            border-radius 5px
            margin-left 25px
            align-items center
            cursor pointer
            .search-btn-icon
              margin-right 10px
              position relative
              top 3px
      .struct-table
        width 100%
        font-size 14px
        overflow auto
        >>>tr
          line-height 18px
          margin 4px 0
          >>>.el-button--text
            color #4a4a4a!important
          &:hover>td 
            background  #E0F2EE
            color #00A389
            font-weight  bolder
            .table-green-btn
              color #00A389!important
              font-weight 700!important
        >>>.table-handles
          white-space nowrap
        .table-green-btn
          font-size 14px
          color #4a4a4a!important
          font-weight bold
        .popover-more
          margin-left 10px!important
          position relative
          top -4px
      .pager
        margin-top 10px
  .dialog-main
    .dialog-con
      padding 30px
      .dialog-dept-item
        width 462px
        margin-bottom 22px
        .dialog-dept-name
          margin-bottom 11px
        .dialog-dept-text
          width 100%
          height 38px
          line-height 38px
          border 1px solid #DADADA
          border-radius 5px
          padding 0 10px
        .dialog-dept-select
          width 100%
          border none
        .upload-demo
          >>>.el-upload-dragger
            width 200px
            height 89px
            color #009375
            border 1px dashed #009375
            display flex
            flex-direction column
          .upload-img
            width 20px
            margin 15px auto 12px
        .qualif-img 
          position relative
          margin-right 10px
          .qualif-image
            max-width 200px
            max-height 100px
          .close-qualif
            position absolute 
            width 20px
            top -8px
            right -8px
            background #fcfcfc
      .modify-bars
        margin-bottom 50px
        display flex
        align-items center
        .modify-bar
          display inline-block
          width 20px
          height 20px
          line-height 20px
          border-radius 25px
          background #D8D8D8
          color #fff
          margin 0 20px
          text-align center
        .modify-bar-ac
          width 55px
          background #E0F2EE
          color #009375
        .bar-link
          flex 1
          height 1px 
          background #ccc
      .modify-success
        text-align center
        .modify-par
          color #9e9e9e
          margin 15px 0 22px
        .modify-sencend
          font-size 12px
          color #009375
          margin-bottom 60px
      .dialog-member
        display flex
        .dialog-member-half
          flex 1
          margin-right 18px
          .dialog-dept-item
            width 300px
    .dialog-btns
      text-align center
      .dialog-btn
        display inline-block
        width 100px
        height 34px
        line-height 34px
        border-radius 5px
        background #009375 
        color #fff
        text-align center
        cursor pointer
      .dialog-cancle-btn
        background #F0F3F6
        color #4A4A4A
        margin-right 54px
    .dialog-btns-right
      text-align right
    >>>.el-dialog__header
      border-bottom 1px solid #d8d8d8
      font-size 16px
      font-weight bolder
.company-navs
  padding 6px 4px 0
  .company-nav
    display inline-block
    width 77px
    height 20px
    line-height 20px 
    margin-bottom 6px
    padding 0 5px
    cursor pointer
    font-size 12px
    &:hover
      background #D0E6E6
      border-radius 3px
      color #009375
      
</style>