<template>
  <el-dialog
    :title="diaTitle"
    class="dialog-main"
    :visible=true
    @close="closeAddOrg"
    width="600px">
    <div class="dialog-con">
      <div class="dialog-dept-item">
        <p class="dialog-dept-name"><span class="red-text">*</span>机构名称</p>
        <input type="text" v-model="orgName" class="dialog-dept-text" />
      </div>
      <!-- <div class="dialog-dept-item">
        <p  class="dialog-dept-name">上级机构</p >
        <el-select v-model="deptParentId" placeholder="请选择" @click="handleDeptParentId">
          <el-option
            v-for="item in classifyOption"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div> -->
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name">机构负责人</p >
        <el-select v-model="leaderId"  placeholder="请选择" @click="changeLeaderId">
          <el-option
            v-for="item in userDatas"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddOrg">取消</span>
      <span class="dialog-btn" @click="handleAddOrg">确定</span>
    </div>
  </el-dialog>
  <!-- 新建机构 end --> 
</template>
<script>
import {
  regAddOrg,
  regEditOrg,
  reglistMember
} from '../../../api/index'
export default {
  data(){
    return{
      orgName: '', // 机构名称
      leaderId: '', // 机构领导Id
      userDatas: [],
      classifyOption:[
        {
          name:'公司组织名',
          id: 1,
          children:[
          ]
        }        
      ],
      diaTitle: '新建机构'
    }
  },
  props:{
    DiaOrgId: String
  },
  mounted(){
    console.log('新建部门3')
    if(this.DiaOrgId){
      this.diaTitle = '编辑机构'
    }
    this.getMemberList()
  },
  activated(){
    
  },
  methods:{
    getMemberList(){
      console.log('eeee')
      const params = {
        pageNo: 1,
        pageSize: 10000
      }
      reglistMember(params).then(res => {
        if(res.code === 0){
          this.userDatas = res.data.list
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取成员列表失败！' + res.msg
          });
          
        }
      })
    },
    // 更改上级部门id
    handleDeptParentId(data) {
      console.log('handleNodeClick',data)
      this.deptParentId = Number(data.id) 
    },
    // 部门负责人ID
    changeLeaderId(data){
      console.log('部六',data)
      this.leaderId = data.id
    }, 
    handleAddOrg(){
      // console.log('adddd',{
      //   leaderId: this.leaderId,
      //   orgName: this.orgName,
      //   parentId: this.deptParentId
      // })
      let params = {
        leaderId: this.leaderId,
        orgName: this.orgName
      }
      if(this.DiaOrgId){
        params.id = this.DiaOrgId
        regEditOrg(params).then(res => {
          if(res.code === 0){
            this.$message({
              message: '编辑机构成功',
              type: 'success'
            }); 
            this.$emit('addOrgSuccess')
            this.closeAddOrg()
          }else{
            this.$notify.error({
              title: '错误',
              message: '编辑机构失败！'+ res.msg
            });
            
          }
        })
      }else{
        regAddOrg(params).then(res => {
          if(res.code === 0){
            this.$message({
              message: '新增机构成功',
              type: 'success'
            }); 
            this.$emit('addOrgSuccess')
            this.closeAddOrg()
          }else{
            this.$notify.error({
              title: '错误',
              message: '新建机构失败！'+ res.msg
            });
            
          }
        })
      }
    },
    // 关闭
    closeAddOrg(){
      this.$emit('closeAddOrg')
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 30px
    .dialog-dept-item
      width 462px
      margin-bottom 22px
      .dialog-dept-name
        margin-bottom 11px
      .el-select
        width 460px
      .dialog-dept-text
        width 100%
        height 38px
        line-height 38px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
      .dialog-dept-select
        width 100%
        border none
      .upload-demo
        >>>.el-upload-dragger
          width 200px
          height 89px
          color #009375
          border 1px dashed #009375
          display flex
          flex-direction column
        .upload-img
          width 20px
          margin 15px auto 12px
      .qualif-img 
        position relative
        margin-right 10px
        .qualif-image
          max-width 300px
          max-height 300px
        .close-qualif
          position absolute 
          width 20px
          top -8px
          right -8px
          background #fcfcfc
    .modify-bars
      margin-bottom 50px
      display flex
      align-items center
      .modify-bar
        display inline-block
        width 20px
        height 20px
        line-height 20px
        border-radius 25px
        background #D8D8D8
        color #fff
        margin 0 20px
        text-align center
      .modify-bar-ac
        width 55px
        background #E0F2EE
        color #009375
      .bar-link
        flex 1
        height 1px 
        background #ccc
    .modify-success
      text-align center
      .modify-par
        color #9e9e9e
        margin 15px 0 22px
      .modify-sencend
        font-size 12px
        color #009375
        margin-bottom 60px
    .dialog-member
      display flex
      .dialog-member-half
        flex 1
        margin-right 18px
        .dialog-dept-item
          width 300px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .dialog-btns-right
    text-align right
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
</style>