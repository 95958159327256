<template>
  <div class="pring">
    <div class="batch-print" v-if="showBatchPrint" >
      <div class="patch-print-btns">
        <el-button size="small" @click="cancleBatchPrint">取消</el-button>
        <el-button
          size="small"
          icon="el-icon-printer"
          type="danger"
          @click="toBatchPrint"
          >打印</el-button
        >
      </div>
      <div class="batch-print-images" id="batch_print">
        <div class="batch-print-image" >
          <img
            v-for="(item, index) in imagePrintArr"
            :key="index"
            :src="item"
            class="batch-print-img"
           
          />
        </div>
      </div>
    </div>
    <!-- <div class="mage" id="ttt">
      122333
          <img
            v-for="(item, index) in imagePrintArr"
            :key="index"
            :src="item"
            class="batch--img"
            style="max-width:100%;"
          />
        </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      showBatchPrint: true,
      imagePrintArr:[
          "http://192.168.36.198:9001/TIMS-Server/showFile/file/showFileById/20244112282447020271505963775693?flag=0",
          "http://192.168.36.198:9001/TIMS-Server/showFile/file/showFileById/0189E85468AC4106AC7B37CF07DF0719?flag=0",
          "http://192.168.36.198:9001/TIMS-Server/showFile/file/showFileById/4463B3930EED4AA98552C195E8A44943?flag=0"
      ],

    }
    
  },
  methods:{
    // 取消批量打印
    cancleBatchPrint() {
      this.showBatchPrint = false;
    },
    // 批量打印
    toBatchPrint() {
      // const printSection = document.querySelector('#ttt'); // 假设这是你要打印的区域
      // window.print(printSection)
      const printDoc = document.getElementById("batch_print");
      this.$print(printDoc);
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.pring
  .batch-print
    position fixed
    width 100%
    height 100%
    left 0
    top 0
    background rgba(0,0,0,0.6)
    z-index 101
    .patch-print-btns
      padding 10px
      text-align right
    .batch-print-images
      position absolute
      width 1000px
      padding  50px
      top 0px
      left 50%
      margin-left -500px
      bottom 0px
      overflow auto
      text-align center
      background #fff
      .batch-print-img
        display block
        max-width 100%
        max-height 100%
        margin-bottom 10px
</style>
<style  type="text/css" >        
@media print{
  .batch-print-images{
    position: absolute;
    width: 1000px;
    padding:  50px;
    top: 0px;
    left: 50%;
    margin-left: -500px;
    bottom: 0px;
    overflow: auto;
    text-align: center;
    background: #fff;
  }
  .batch-print-img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px; 
  }
}
</style>