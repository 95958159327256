<template>
  <div class="qadetail">
    <div class="qadetail-return">
      <img src="../../assets/images/return_arrow.png" class="return-icon" @click="returnQues" />
      <span class="qaReturn" @click="returnQues">返回</span>
    </div>
    <div class="qadetail-main">
      <div class="qadetail-left">
        <div class="qadetail-title">首页</div>
        <el-collapse class="qa-collapse">
          <el-collapse-item :title="item.categoryName" :name="i" v-for="(item,i) in groupList" :key="i">
            <div 
              style="cursor:pointer;line-height:30px;" 
              :class="currentId===sen.id ? 'qa-active': ''" 
              
              @click="changeDetail(sen.id)" v-for="(sen,j) in item.faqList" 
              :key="j"
            >{{sen.title}}</div>
          </el-collapse-item>
      </el-collapse>
      </div>
      <div class="qadetail-right">
        <div class="qad-head">
          <p class="qad-title">{{title}}</p>
          <p class="qad-time">{{createTime}}</p>
        </div>
        <div class="qad-content" v-html="content">
          <!-- {{content}} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reqFaqDetail,
  reqGroupList
} from '../../api/index'
export default {
  data () {
    return {
      groupList: [],
      createTime: '',
      title:'',
      content: '',
      currentId: ''
    }
    
  },
  mounted(){
    if(this.$route.params.qaId !== undefined){
      this.getDetail(this.$route.params.qaId)
    }else{
      this.$router.push({ path: '/questAndAnswer'})
    }
    this.getGroup()
  },
  methods:{
    // 问答详情
    getDetail(id){
      reqFaqDetail({id}).then(res => {
        if(res.code === 0){
          this.createTime = res.data.createTime
          this.content = res.data.content
          this.title = res.data.title
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取详情失败！'+ res.msg
          });
        }
      })
    },
    getGroup(){
      reqGroupList().then(res => {
        if(res.code === 0){
          this.groupList = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取列表失败！'+ res.msg
          });
        }
      })
    },
    changeDetail(id){
      if(this.currentId == id){
        return false
      }
      this.currentId = id
      console.log('currentId',this.currentId)
      this.$router.replace({ params: { rqId:id } });
      this.getDetail(id)
    },
    returnQues(){
      this.$router.push({ path: `/questAndAnswer`})
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.qadetail
  width 100%
  display flex
  height 100%
  flex-direction column 
  .qadetail-return
    width 100%
    display block
    height 70px
    display flex
    align-items center
    line-height 70px
    font-size 14px
    cursor pointer
    border-bottom 1px solid #D8D8D8
    .return-icon
      width 30px
      margin 0 13px 0 40px
  .qadetail-main
    display flex
    width 100%
    flex 1
    .qadetail-left
      width 300px
      padding 40px
      height 100%
      overflow-y auto
      border-right 1px solid #D8D8D8
      .qadetail-title
        font-size 16px
        margin-bottom 35px
      .qa-collapse
        border none!important
        >>>.el-collapse-item__header
          border-bottom none
          height 30px
          line-height 30px
        >>>.el-collapse-item__content
          padding-bottom 5px
        >>>.el-collapse-item__wrap
          text-indent 15px
          border none
        .qa-active
          background #D0E6E6
          color #009375
    .qadetail-right
      padding 80px 100px 0 50px
      flex 1
      .qad-head
        padding-bottom 10px
        border-bottom 1px dashed #D8D8D8
        margin-bottom 28px
        .qad-title
          font-size 28px
          font-weight 500
          height 41px
          margin-bottom 5px
          line-height 41px
        .qad-time
          font-size 16px
          color #9E9E9E
      .qad-content
        font-size 16px
        color #4a4a4a
        line-height 24px
        img 
          max-width 100%
</style>