<template>
  <el-tree
    :data="treeData"
    show-checkbox
    node-key="id"
    ref="tree"
    :check-strictly="true"
    :props="defaultProps"
    @node-click="handleNodeClick"
    
  >
  </el-tree>
</template>
 
<script>
export default {
  props:{
    treeData:{
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      leafNodes: []
    };
  },
  methods: {
    handleNodeClick(data, node, self) {
      // 如果节点有子节点，则不允许选择
      if (!node.isLeaf) {
        this.$message.info('只能选择叶子节点');
        // 取消选中
        this.$refs.tree.setCheckedNodes([]);
      } else {
        // 处理叶子节点的选择逻辑
      }
    }
  }
};
</script>