<template>
  <el-dialog
    title="审核描述"
    class="dialog-main"
    :visible=true
    @close="closeAudit"
    width="600px">
    <div class="dialog-con">
      <div class="dialog-dept-item">
        <p class="dialog-dept-name">审核类型</p>
        <input type="text" :value="auditText" disabled class="dialog-dept-text" />
      </div>
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name">审核描述</p >
        <textarea class="dialog-textarea" v-model.trim="auditContent"></textarea>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAudit">取消</span>
      <span class="dialog-btn" @click="confirmAudit">确定</span>
    </div>
  </el-dialog>
  <!-- 新建部门 end --> 
</template>
<script>
import {
  regReportaAudit
} from '../../api/index'
import _ from "lodash";
export default {
  data(){
    return{
      auditText: this.isAudit ==1 ? '审核通过': '审核不通过',
      auditContent: ''
    }
  },
  props:{
    accessId: Number,
    isAudit: Number
  },
  mounted(){
    console.log('isAudit',this.isAudit)
  },
  methods:{
    // 取消
    closeAudit(){
      this.$emit('closeAudit')
    },
    confirmAudit(){
      if(this.isAudit !==1 && this.auditContent === ''){
        this.$message({
          message: '审核不通过必须填写原因！',
          type: 'wanring'
        }); 
        return false
      }
      regReportaAudit({
        replyId: this.accessId,
        auditStatus: this.isAudit,
        auditContent: this.auditContent
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '操作成功',
            type: 'success'
          }); 
          this.$emit('successAudit')
        }else{
          this.$message({
            message: '操作失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    }
  },
  watch:{
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main  
  .dialog-con
    padding 30px
    .dialog-dept-item
      width 462px
      margin-bottom 22px
      .dialog-dept-name
        margin-bottom 11px
      .el-select
        width 460px
      .dialog-textarea
        width 100%
        height 80px
        line-height 28px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
        resize  none
      .dialog-dept-text
        width 100%
        height 38px
        line-height 38px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
      margin-top 20px
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>