<template>
  <div>
    <el-container class="main">
      <!-- width的宽度跟collapse一样动态控制 -->
      <el-aside width="collapse" class="side">
        <!-- :collapse="isCollapse"  class="el-menu-vertical" 动态控制导航菜单的收起与展开  router：让index作为 path 进行路由跳转 -->
        <el-menu 
          :default-active="$route.path" 
          router 
          :collapse="isCollapse" 
          class="el-menu-vertical" 
          background-color="#fff"
          text-color="##4A4A4A" 
          active-text-color="#fff"
        >
          <div class="menu-con">
            <div class="menu-icon">
              <img src="../../assets/images/logo_small.png" v-if="!block" class="menu-icon-logo" />
              <img src="../../assets/images/logo_long.png" v-else class="menu-icon-logo menu-icon-logo-long" />
            </div>
            <div class="menu-main">
              <div class="menu-main-top">
                <div class="menu-item" :class="{'menu-item-active': currentPage === 'homePage'}" @click="toLink('homePage')">
                  <span class="menu-img">
                    <img src="../../assets/images/menu_con.png" class="menu-image" />
                    <img src="../../assets/images/menu_con_a.png" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">工作台</span>
                </div>
                <div class="menu-item"  v-has="'mgr.chat.chat'" :class="{'menu-item-active': currentPage === 'weChat'}" @click="toLink('weChat')">
                  <span class="menu-img">
                    <img src="../../assets/images/menu_msg.png" class="menu-image" />
                    <img src="../../assets/images/menu_msg_a.png" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">会话</span>
                </div>
                <div class="menu-item" :class="{'menu-item-active': (currentPage === 'UserManage' || currentPage === 'EditUser' || currentPage === 'UserDetail')}" @click="toLink('UserManage')">
                  <span class="menu-img"> 
                    <img src="../../assets/images/menu_user.png" class="menu-image" />
                    <img src="../../assets/images/menu_user_a.png" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">用户</span>
                </div>
              </div>
              <div class="menu-main-top menu-main-bottom">
                <!-- <div class="menu-item">
                  <span class="menu-img">
                    <img src="../../assets/images/menu_con.png" class="menu-image" />
                    <img src="../../assets/images/menu_con_a.png" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">专病</span>
                </div> -->
                <div class="menu-item" :class="{'menu-item-active': (currentPage === 'accessManage' || currentPage === 'accesScale' || currentPage === 'accessGroupManage' || currentPage === 'accessConfiger' || currentPage === 'accessDetail')}" @click="toLink('accessManage')">
                  <span class="menu-img">
                    <img  :src="iconExamGray" class="menu-image" /> 
                    <!-- src="../../assets/images/menu_con.png" src="../../assets/images/menu_msg_a.png" -->
                    <img :src="iconExamGreen" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">测评</span>
                </div>
                <div class="menu-item" :class="{'menu-item-active': (currentPage === 'followUp' || currentPage === 'followPlan' || currentPage === 'addFollow' || currentPage === 'questionFromList' || currentPage === 'QuestionFrom' || currentPage === 'questionFrom' || currentPage === 'planConfiger')}" @click="toLink('followUp')">
                  <span class="menu-img">
                    <img :src="iconVisitGray" v-if="icons" class="menu-image" />
                    <img :src="iconVisitGreen" class="menu-image menu-image-active" />
                  </span>
                  <span class="menu-text" :class="{'show-text':block}">随访</span>
                </div>
              </div>
            </div>
            <div class="menu-bottom">
              <div class="menu-bot">
                <img src="../../assets/images/menu_help_a.png" @click="toLink('questAndAnswer')" class="bot-icon" :class="{'bot-icon-active': (currentPage === 'questAndAnswer' || currentPage === 'questDetail')}" />
                <img src="../../assets/images/menu_message.png" class="bot-icon" />
                <span class="menu-bot-outer menu-bot-outer-ac" v-if="currentPage === 'system'">
                  <img src="../../assets/images/menu_set_a.png" class="bot-icon" />
                </span>
                <span class="menu-bot-outer" v-else  @click="toSystem">
                  <img src="../../assets/images/menu_set.png" class="bot-icon"  />
                </span>
                <img  class="bot-user" :src="headImg" />
                <img src="../../assets/images/exit.png" title="退出登录" @click="handleExitLogin" class="bot-icon" />                  
              </div>
            </div>
          </div>
        </el-menu>
      </el-aside>
      <el-container class="main-container">
        <!-- <el-header>
          <div class="box_bgd" @click="isC">
            <i class="el-icon-s-fold" v-show="block"></i>
            <i class="el-icon-s-unfold" v-show="toggle"></i>
          </div>
        </el-header> -->
        <el-main>
          <div class="box_bgd" @click="isC">
            <img src="../../assets/images/fold.png" class="fold-icon"  v-show="block" />
            <img src="../../assets/images/unfold.png" class="fold-icon" v-show="toggle" />
          </div>
          <router-view @changePage="changePage"></router-view>
        </el-main>
      </el-container>
    </el-container>
    <notificate
    :notifyObj="notifyObj"
    :isShow="isShow"
    ></notificate>
    <!-- 输入密码 -->
    <el-dialog
      class="pass-dialog"
      :visible.sync="passwordVisible"
      :show-close=false
      title="输入密码"
      :close-on-click-modal=false
      width="600px"
      center
    >
      <div class="dialog-con">
        <p class="pass-par">由于长时间未活动，需要确认您的身份。</p>
        <p class="pass-word">密码</p>
        <el-input class="add-group" show-password v-model="newPass"  placeholder="输入密码"></el-input>
      </div>
      <div class="dialog-btns">
        <span class="dialog-btn dialog-cancle-btn" size="small" type="" @click="canclePass">取消</span>
        <span class="dialog-btn" size="small" @click="handlePass" type="">确定</span>
      </div>
    </el-dialog>
    <!-- 输入密码 end -->
  </div>
</template>

<script>

import {
  regExitLogin,
  regUserInfo,
  reqIcon,
  regListByModule,
  regCheckPwd
} from '../../api/index'
import bus from '@/utils/bus'
import Notificate from '@/components/HomePage/Notificate'
export default {
  data() {
    return {
      lastUserActivity: new Date(), // 初始化为最后用户操作的时间
      isCollapse: false, //导航栏默认为展开
      toggle: false, //第二个图标默认隐藏
      block: true, //默认显示第一个图标
      currentPage: 'homePage',
      notifyObj: null,
      isShow: false,
      headImg: '',
      iconExamGray: '', // 	评测:图标(灰色)
      iconExamGreen: '', // 评测:图标(绿色)
      iconVisitGray: '', // 随访:图标(灰色)
      iconVisitGreen: '', // 随访:图标(绿色)
      icons: null,
      isRePass: false, // 是否开启指定时间后重输密码
      rePassTime: 15*60, // 多少时间后重新输密码，默认为15分钟
      passwordVisible:false, // 长时间未操作输入密码
      newPass: ''
    };
  },
  created() {
    
    
  },
  beforeDestroy() {
    // 清理事件监听器
    if(this.isRePass){
      window.removeEventListener('mousemove', this.resetUserActivityTimer);
      window.removeEventListener('keydown', this.resetUserActivityTimer);
    }
  },
  components: {Notificate},
  mounted(){
    this.getIcon()
    bus.$on('alarm', (data) => {
      console.log('alarm',data)
      this.setAlarm(data)
    })
    bus.$on('changeUserInfo', () => {
      console.log('我的头像换 ')
      this.getUserInfo()
    })
    bus.$on('changePage', (data) => {
      console.log('changePage',data)
      this.currentPage = data
    })
    bus.$on('changeConfig', () => {
      this.getAllModule(true)
    })
    
    this.getUserInfo()
    console.log('this.$route',this.$route.path)
    this.currentPage = this.$route.path.split('/')[1]
    this.getAllModule()
    if(sessionStorage.getItem('rePass') == true){
      this.passwordVisible = true
    }
  },
  methods: {
    // 获取是否指定时间后输密码
    getAllModule(isChange = false){
      regListByModule({configModule:'base'}).then(res => {
        if(res.code === 0){
         const thData = res.data
         for(let i=0; i<thData.length; i++){
           if(thData[i].configCode === 'base.leave.valid'){
             this.isRePass = thData[i].configValue === 'Y' ? true: false
             console.log('this.isRePass',this.isRePass)
             if(this.isRePass){
               
               // 监听事件，更新lastUserActivity的时间
               window.addEventListener('mousemove', this.resetUserActivityTimer);
               window.addEventListener('keydown', this.resetUserActivityTimer);
             }
             if(isChange === true &&  thData[i].configValue === 'N'){
              window.removeEventListener('mousemove', this.resetUserActivityTimer);
              window.removeEventListener('keydown', this.resetUserActivityTimer);
             }
           }
         }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    // 到时间了弹出密码输入框
    resetUserActivityTimer() {
      const inactivityThreshold = this.rePassTime * 1000;
      const currentTime = new Date();
      if (currentTime - this.lastUserActivity > inactivityThreshold) {
        //sessionStorage.setItem('token',null)
        sessionStorage.setItem('rightCodeList',null)
        this.passwordVisible = true
        sessionStorage.setItem('rePass',true)
        // 用户无操作后的逻辑处理
      }
      this.lastUserActivity = new Date(); // 更新最后用户操作时间
    },
    // 图标
    getIcon(){
      reqIcon().then(res => {
        if(res.code === 0){
          sessionStorage.setItem('icons',JSON.stringify(res.data))
          this.icons = JSON.stringify(res.data)
          this.iconBaseGreen  = res.data.iconBaseGreen
          this.iconExamGray = res.data.iconExamGray
          this.iconExamGreen = res.data.iconExamGreen
          this.iconVisitGray = res.data.iconVisitGray
          this.iconVisitGreen = res.data.iconVisitGreen
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取图标失败！'
          });
        }
      })
    },
    // 动态控制展开与收起和切换对应图标
    isC() {
      this.isCollapse = !this.isCollapse;
      this.toggle = !this.toggle;
      this.block = !this.block;
    },
    toLink(pageUrl){
      this.currentPage = pageUrl
      this.$router.push({ path: '/'+ pageUrl })
    },
    toSystem(){
      this.currentPage = 'system'
      this.$router.push({ path: '/system/1'})
    },
    changePage(path){
      this.currentPage = path
      console.log('currentPages',this.currentPage)
    },
    // 退出登录
    handleExitLogin(){
      regExitLogin().then(res => {
        if(res.code === 0){
          this.$message({
            message: '退出登录成功！',
            type: 'success'
          });
          sessionStorage.setItem('rightCodeList',null)
          this.$router.push({ path: `/Login`})
        }else{
          this.$notify.error({
            title: '错误',
            message: '退出登录失败！'
          });
        }
      })
    },
    setAlarm(data){
      let scheArr = data
      let _this = this
      let today = new Date().toISOString().slice(0, 10)
      for(let i=0; i < scheArr.length; i++){
        setInterval(() => {
          const now = new Date();
          const year = now.getFullYear();
          let month = now.getMonth() + 1;
          if(month<10) month = "0"+month
          let day = now.getDate();
          if(day<10) day = "0"+day
          const hour = now.getHours();
          const minute = now.getMinutes();
          let second = now.getSeconds();
          if(second<10) second = "0"+second
          let curentT = year + '-' + month + '-' + day +' ' + hour+':'+minute+':'+second
          //console.log('curentT',curentT,scheArr[i].scheduleTime)
          if (curentT === scheArr[i].scheduleTime){
            clearInterval()
            _this.notifyObj = scheArr[i]// 在指定时间触发提示音或其他操作
            _this.isShow = true
          }
        },1000);
      }
    },
    // 获取个人信息
    getUserInfo(){
      console.log('获取个人信息')
      regUserInfo({}).then(res => {
        if(res.code === 0){
          console.log('userInfo',res)
          sessionStorage.setItem('userType',res.data.userType)
          if(res.data.userType === 1){
            this.headImg =  res.data.headImgUrl
          }else{
            this.headImg = res.data.orgInfo.logo
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取基础信息失败！'
          });
          
        }
      })
    },
    // 输入密码
    handlePass(){
      regCheckPwd({
        password: this.$md5(this.newPass).toUpperCase()
      }).then(res => {
        if(res.code === 0){
          if(res.data.checkResult == 1){
            this.passwordVisible = false
            sessionStorage.setItem('rePass',false)
          }else{
            this.$notify.error({
              title: '错误',
              message: '输入密码错误！'
            });
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '请求错误！'
          });
        }
      })
    },
    canclePass(){
      this.passwordVisible = false
      this.$router.push({ path: `/Login`})
    }
  },
  // watch: {
  //   // 监听lastUserActivity的变化
  //   lastUserActivity(newValue) {
  //     // 设置一个阈值，例如30秒无操作
  //     const inactivityThreshold = 3 * 1000;
  //     const currentTime = new Date();
  //     if (currentTime - newValue > inactivityThreshold) {
  //       console.log('用户超过30秒无操作');
  //       // 用户无操作后的逻辑处理
  //     }
  //   }
  // }
};
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.main
  height 100vh 
  .side 
    background #fff
    border-right 1px solid #DCDFE6
    height 100vh 
    overflow-y hidden
    .el-menu--collapse
      width: 64px     
    .el-menu 
      border-right-width 0
      .menu-con
        height 100vh
        display flex
        flex-direction column
        justify-content space-between
        .menu-icon
          display block
          text-align center
          min-height 40px
          margin 21px 0 15px
          .menu-icon-logo-long
            margin-top 5px
        .menu-main
          .menu-main-top
            padding-bottom 60px
            border-bottom 1px solid #D8D8D8
            .menu-item
              font-size 16px
              display flex
              align-items  center
              padding-left 13px
              margin 10px 0
              height 34px
              overflow hidden
              cursor pointer
              .menu-img
                width 34px
                height 34px
                border-radius 8px
                text-align center
                margin-right 10px
                .menu-image
                  margin 7px
                  width 20px
                .menu-image-active
                  display none
              .menu-text
                display none
                width: 0
              .show-text
                display inline-block
                white-space: nowrap;
                animation menuWidth 1s forwards
                @keyframes menuWidth
                {
                  0%  {width: 0}
                  100% {width: 48px}
                }
            .menu-item-active
              color #009375
              border-right 3px solid #009375
              .menu-img
                background rgba(0, 147, 117, 0.3) 
                .menu-image
                  display none
                .menu-image-active
                  display inline-block
              .menu-text
                font-weight bold
          .menu-main-bottom
            padding-bottom 0
            border-bottom none
            padding-top 20px
        .menu-bottom
          text-align center
          white-space normal
          .bot-icon
            display block
            margin 0 auto 15px
            cursor pointer
          .bot-icon-active
            background rgba(0, 147, 117, 0.1)
            border-radius 5px
          .bot-user
            display block
            width 34px
            height 34px
            border-radius 50%
            margin 0 auto 20px
          .menu-bot-outer
            display inline-block
            width 34px
            height 34px
            margin 0 auto 15px
            cursor pointer
            .bot-icon
              margin 7px
          .menu-bot-outer-ac
            background rgba(0, 147, 117, 0.3)
            border-radius 8px
    .el-menu-vertical:not(.el-menu--collapse) 
      width: 130px
  .main-container
    position relative
    height calc(100vh)
    overflow-x auto
    .el-main 
      position relative
      padding 0
      height 100vh
      .box_bgd 
        position absolute
        display flex
        align-items center
        justify-content center
        cursor pointer
        top 50%
        left 0
        cursor pointer
        margin-top -150px
        z-index 3
        .fold-icon
          width: 15px;
.pass-dialog
  >>>.el-dialog
    border-radius 10px
    overflow hidden
  >>>.el-dialog__body
    padding 0
  >>>.el-dialog__title
    font-weight bold
  .dialog-con
    width 280px
    margin 0 auto
    .pass-par
      text-align center
      color #000
      margin 20px 0 60px
    .pass-word
      font-size 14px
      color #4a4a4a
      margin-bottom 5px
  .dialog-btns
    text-align center
    background #f7f8ff
    border-top 1px solid #E6E7F2
    margin-top 50px
    .dialog-btn
      display inline-block
      width 70px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
      margin 15px 0
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>
