<template>
  <div class="assess-manages">
    <span class="assess-item" :class="{ 'assess-item-active' : routeLink === 'followUp'}" @click="toLink('followUp')">随访报告</span>
    <span class="assess-item" :class="{ 'assess-item-active' : routeLink === 'followPlan'}" @click="toLink('followPlan')">随访计划</span>
    <span class="assess-item" :class="{ 'assess-item-active' : routeLink === 'questionFrom' || routeLink === 'questionFromList'}"  @click="toLink('questionFromList')">问卷表单</span>
    <span class="assess-item" v-has="'mgr.moduleConfig.query'" :class="{ 'assess-item-active' : routeLink === 'planConfiger'}" @click="toLink('planConfiger')">应用配置</span>
  </div>
</template>

<script>
export default {
  props:{
    routeLink: String
  },
  data () {
    return {
    }
    
  },
  methods:{
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess-manages
  width 367px
  height 46px
  line-height 46px
  box-sizing border-box
  border 1px solid #E8E8E8
  display flex
  justify-content space-between
  border-radius 25px
  margin 0 auto 25px
  .assess-item
    display inline-block
    width 100px
    height 40px
    line-height 40px
    text-align center
    margin  3px
    font-size 14px
    cursor pointer
  .assess-item-active
    border-radius 25px
    background #E0F2EE
    color #009375
    font-weight  bold
</style>