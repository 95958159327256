<template>
  <div class="qa">
    <!-- <img src="../../assets/images/qa_bg.png" class="qa-img" /> -->
    <div class="qa-title">FAQ</div>
    <div class="qa-main">
      <img src="../../assets/images/faq.png" class="qa-image" />
      <div class="qa-items">
        <div class="qa-items-con">
          <div class="qa-item" v-for="(item,index) in faqList" :key="index" @click="toDetail(item.id)">
            <p class="qa-par">{{item.title}}</p>
            <img src="../../assets/images/right-arrow.png" class="right-icon" />
          </div>
        </div>
        <el-pagination
          class="qa-page"
          layout="prev, pager, next"
          :current-page="pageNo"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :total="total">
        </el-pagination>
      </div>
      
    </div>
  </div>
  
</template>

<script>
import {
  reqFaqList
} from '../../api/index'
export default {
  data () {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      faqList: []
    }
    
  },
  mounted(){
    this.getList()
  },
  methods:{
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getList()
    },
    getList(){
      reqFaqList({
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        
      }).then(res => {
        if(res.code === 0){
          this.faqList = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取问答列表失败！'+ res.msg
          });
        }
      })
    },
    toDetail(id){
      //questDetail
      this.$router.push({ path: `/questDetail/${id}`})
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.qa
  position relative
  width 100%
  height 100%
  background url("../../assets/images/qa_bg.png") no-repeat bottom center;
  background-size 100% auto
  .qa-img 
    position absolute
    left 0
    bottom 0
  .qa-title
    font-family "微软雅黑"
    font-size 38px
    font-weight bold
    text-align center
    position relative
    margin 50px 0 30px
    &::after
      content ""
      display inline-block
      width 69px
      height 6px
      background #009375
      border-radius 6px
      position absolute
      bottom -10px
      left 50%
      margin-left -34px
  .qa-main
    display flex
    padding 0 80px 0 10px
    .qa-image
      margin-right 10px
      height 502px
    .qa-items
      margin-top 20px
      flex 1
      .qa-items-con
        height 500px
        overflow auto
        padding-right 10px
      .qa-item
        padding-bottom 11px
        line-height 24px
        border-bottom 1px dashed #4a4a4a
        margin-bottom 15px
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        position relative
        padding-right 20px
        font-size 18px
        cursor pointer
        .right-icon
          position absolute
          right 0
          top 10px
      .qa-page
        text-align right
        margin 30px 0 10px
        text-align center
        position relative
        >>>.el-pagination__total
          position absolute
          left 0
          top 0
        >>>.el-pagination__sizes
          position absolute
          left 80px
          top 0
        >>>.el-pager
          .number
            min-width 32px
            &:hover
              color 00A389
          .active
            background #00A389
            color #fff
            border-radius 3px
            width 32px
</style>