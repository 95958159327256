<template>
  <el-dialog
    title="二维码邀请"
    class="dialog-main"
    :visible=true
    @close="closeInviteDialog"
    width="480px">
    <div class="dialog-con">
      <div class="invite-con">
        <div class="invite-item">
          <div class="invite-title">用户归属分组</div>
          <div class="invite-item-con">
            <el-select v-model="patientGroupId" class="search-select" v-has="'mgr.group.query'" placeholder="分组列表">
              <el-option
                v-for="item in batchListDatas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="invite-item">
          <div class="invite-title">是否查看报告</div>
          <div class="invite-item-con">
            <span class="ivite-is" @click="changeFlag(true)" :class="{'ivite-is-active':flagShowReport}">可查看</span>
            <span class="ivite-is" @click="changeFlag(false)" :class="{'ivite-is-active':!flagShowReport}">不可查看</span>
          </div>
        </div>
        <div class="invite-code-outer">
          <img v-if="qrcodeImgUrl" :src="qrcodeImgUrl" class="invite-code" />
          <img v-else src="../../../assets/images/code.png" class="invite-code" />
          
          <div class="invite-right">
            <p class="invite-par">二维码有效期</p>
            <el-date-picker
              v-model="dateVal"
              type="daterange"
              class="invite-date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleChangeDate"
              >
            </el-date-picker>
            <span class="set-btn" v-if="qrcodeImgUrl" @click="downCode"><img src="../../../assets/images/dowen.png" class="invite-code" />二维码下载</span>
            <span class="set-btn"  v-else @click="getExamlQrcode"><img src="../../../assets/images/get.png" class="invite-code" />生成二维码</span>
          </div>
        </div>
        <p class="invite-bottom"><img src="../../../assets/images/note.png" class="note-icon"/>   用户扫描二维码自动归入选择分组</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  regExamlGroupQrcode,
  regExamlQrcode,
  regUserGroupList
} from '../../../api/index'
export default {
  props:{
    allCategory: Array,
    examDetailId: Object,
    selectIds: String,
    isGroup:String, // 是否是分组
  },
  data () {
    return {
      dateVal: ['',''],
      id:'',	
      endTime	:'',	
      flagShowReport: true,	
      patientGroupId: '',	
      startTime:'',	
      qrcodeImgUrl: '', // 二维码地址
      batchListDatas: ''
    }
  },
  mounted(){
    this.getGroupDatas()
  },
  methods:{
    // 用户分组
    getGroupDatas(){
      regUserGroupList({
        pageNo: 1,
        pageSize: 10000,
        name: ''
      }).then(res => {
        if(res && res.code === 0){
          this.batchListDatas = res.data.list
        }else{
          this.$message({
            message: '获取分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 修改日期
    handleChangeDate(data){
      console.log('data',data,this.dateVal)
    },
    // 是否可查看
    changeFlag(val){
      this.flagShowReport = val
    },
    closeInviteDialog(){
      this.$emit('closeInviteDialog')
    },
    getExamlQrcode(){
      let params = {}
      if(this.selectIds == ''){
        params = {
          ids: this.examDetailId.id,	
          flagShowReport: this.flagShowReport ? 'Y':'N',	
          patientGroupId: this.patientGroupId,	
          startTime: this.dateVal[0],	
          endTime	: this.dateVal[1],	
        }
      }else{
        params = {
          ids: this.selectIds,	
          flagShowReport: this.flagShowReport ? 'Y':'N',	
          patientGroupId: this.patientGroupId,	
          startTime: this.dateVal[0],	
          endTime	: this.dateVal[1],	
        }
      }
      if(this.isGroup == '1'){
        regExamlGroupQrcode(params).then(res => {
          if(res && res.code === 0){
            this.qrcodeImgUrl = res.data.qrcodeImgUrl
          }else{
            this.$message({
              message: '生成失败，' + res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        regExamlQrcode(params).then(res => {
          if(res && res.code === 0){
            this.qrcodeImgUrl = res.data.qrcodeImgUrl
          }else{
            this.$message({
              message: '生成失败，' + res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    // 下载
    downCode(){
     
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download =  "pic"; // 设置图片名称
      a.href = this.qrcodeImgUrl; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  >>>.el-dialog__header
    border-bottom 1px solid #D8D8D8
  .dialog-con
    .invite-item
      margin-bottom 18px
      .invite-title
        margin-bottom 11px
      .invite-item-con
        .search-select
          width 415px
          height 38px
        .ivite-is
          display inline-block
          width 114px
          height 30px
          line-height 30px
          border-radius 3px
          border 1px solid #CED4DA
          position relative
          text-indent 45px
          margin-right 40px
          cursor pointer
          &::before
            content ""
            display inline-block
            width 14px
            height 14px
            border-radius 50%
            background #d8d8d8
            position absolute
            left 10px
            top 8px
        .ivite-is-active
          border-color #009375
          color #009375
          background #E0F2EE
          cursor pointer
          &::before
            background #009375
    .invite-code-outer
      display flex
      margin 15px 0 30px
      .invite-code
        width 135px
        height 135px
        margin-right 25px
      .invite-par
        font-size 14px
        font-weight bold
        color #4b4b4b
        margin-top 5px
      .invite-date
        width 250px
        height 30px
        line-height 30px
        color #009375
        margin 12px 0 24px
        >>>.el-range-separator
          width 10%
          line-height 22px
        >>>.el-date-editor .el-range__icon
          line-height 22px
        >>>.el-range-input
          color #009375
      .set-btn
        display flex
        width 116px
        height 36px
        line-height 36px
        border-radius 3px
        padding 0 9px
        font-size 12px
        color #009375
        border 1px solid #CED4DA
        align-items center
        cursor pointer
        .invite-code
          width 17px
          height 17px
          margin-right 10px
    .invite-bottom
      color #009375
      text-align center
      .note-icon
        position relative
        top 3px
        margin-right 7px
</style>