<template>
  <div class="select-reg">
    <div class="select-logo">
      <img src="../../assets/images/logo.png" class="reg-logo" />
    </div>
    <div class="select-main">
      <p class="select-title">注册为：</p>
      <div class="select-con">
        <div class="select-item">
          <span class="select-img">
            <img src="../../assets/images/personal.png" class="select-image"/>
          </span>
          <div class="select-item-title">个人注册</div>
          <div class="select-item-par">拥有营养师、健康管理师、心理咨询师、护理师及相关健康管理从业资质人员。</div>
          <span class="select-item-confirm" @click="selectType(1)">选择</span>
        </div>
        <div class="select-item">
          <span class="select-img">
            <img src="../../assets/images/operation.png" class="select-image"/>
          </span>
          <div class="select-item-title">企业注册</div>
          <div class="select-item-par">拥有相关健康管理资质的企业用户</div>
          <span class="select-item-confirm" @click="selectType(2)">选择</span>
        </div>
        <div class="select-item">
          <span class="select-img">
            <img src="../../assets/images/agency.png" class="select-image"/>
          </span>
          <div class="select-item-title">机构注册</div>
          <div class="select-item-par">拥有相关从业资质的医疗机构用户</div>
          <span class="select-item-confirm" @click="selectType(3)">选择</span>
        </div>
      </div>
    </div>
    <div class="select-copy">版权所有 © 2023 legu Health  - 保留所有权利</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods:{
    selectType(type){
      this.$router.push({ path: `/register/${type}` })
    }
  }
  
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.select-reg
  background #EDF0F5
  height 100%
  display flex
  justify-content space-between
  flex-direction column
  .select-logo
    padding 20px 0 0 150px
    font-size 32px
    font-weight bold
    color #009375
  .select-main
    padding 20px 100px
    .select-title
      font-size 32px
      font-weight 400
      color #4A4A4A
      text-align center
    .select-con
      display flex
      padding-top 100px
      justify-content space-around
      .select-item 
        width 260px
        height 400px
        background #ffffff
        border-radius 10px
        position relative
        .select-img
          position absolute
          top -70px
          left 57px
        .select-item-title
          margin 102px 0 43px
          text-align center
          font-size 22px
          font-weight 400
        .select-item-par
          line-height 22px
          width 210px
          margin 0 auto
        .select-item-confirm
          position absolute
          left 80px
          bottom 43px
          width 100px
          height 34px
          line-height 34px
          border-radius 5px
          background #009375
          color #fff
          text-align center
          cursor pointer
  .select-copy
    text-align center
    padding-bottom 50px
</style>