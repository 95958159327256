<template>
  <el-dialog
    title="设置随访项目"
    class="dialog-main"
    :visible=true
    @close="closeContentDialog"
    width="700px">
    <div class="content">
      <div class="cont-left">
        <div class="assess-manages">
          <span class="assess-item" v-for="(item,index) in contentListArr" :key="index" @click="changeType(item.code)" :class="{'assess-item-active': assessVal === item.code}">{{item.name}}</span>
          <!-- <span class="assess-item" @click="changeType(1)" :class="{'assess-item-active': assessVal === 1}">问卷</span>
          <span class="assess-item" @click="changeType(2)" :class="{'assess-item-active': assessVal === 2}">提醒</span>-->
        </div> 
        <!-- questionnaire -->
        <div v-if="assessVal === 'scale'" class="left-ass" v-has="'mgr.exam.query'">
          <!-- <div class="search-con">
            <el-input type="text" class="con-search"></el-input>
            <img src="../../assets/images/search.png" class="search-icon" />
          </div> -->
          <el-collapse class="table-coll" @change="handleChange">
            <el-collapse-item v-for="(item,index) in examList" :key="index" :title="item.categoryName" :name="item.categoryId">
              <div class="tab-item" @click="selectExam(child)" v-for="(child,j) in  item.child" :key="j">{{child.name}}</div>
            </el-collapse-item>
            <!-- <el-collapse-item title="反馈 Feedback" name="2">
              <div class="tab-item">心里健康评估量表</div>
              <div class="tab-item">心里健康评估量表</div>
              <div class="tab-item">心里健康评估量表</div>
              <div class="tab-item">心里健康评估量表</div>
              <div class="tab-item">心里健康评估量表</div>
            </el-collapse-item> -->
          </el-collapse>
        </div>
        <div v-if="assessVal === 'questionnaire' && hasQuery" class="left-ass">  
          <div class="question-item" @click="selectQuest(item)"  v-for="(item,index) in allQuestionList" :key="index" >{{item.questionnaireName}}</div>
        </div>
        <div v-if="assessVal === 'remind'">
          <div class="con-title">类型</div>
          <div class="con-from">
            <el-select placeholder=""  class="con-select"  v-model="type" >
              <el-option v-for="(item,i) in remindTypes"  :key="i" :value="item.code" :label="item.name"></el-option>
            </el-select>
          </div>
          <div class="con-title">推送时间</div>
          <div class="con-from">
            <el-select placeholder=""  class="con-select"  v-model="pushWay" >
              <el-option v-for="(item,i) in remindPushWays"  :key="i" :value="item.code" :label="item.name"></el-option>
            </el-select>
          </div>
          <div class="con-title">提醒内容</div>
          <div class="con-from">
            <textarea class="con-text" v-model="content" ></textarea>
            <span class="text-note">0/120</span>
          </div>
          <el-button class="add-remind-btn" @click="addRemind" >添加</el-button>
        </div>
      </div>
      <div class="cont-right">
        <div class="cont-right-title">已选择</div>
        <el-collapse class="table-coll" @change="handleChange" v-model="activeNames2">
          <el-collapse-item title="量表" name="1">
            <div  v-for="(item,index) in scaleList" :key="index">
              <div v-if="!item.id || (item.id && item.delFlag !== 2)" class="tab-item">
              {{item.contentName}}
              <img src="../../assets/images/close.png" class="coll-close" @click="deleteContent(item.contentId)" />
              </div>
            </div>
            <!-- <div class="tab-item">心里健康评估量表</div>
            <div class="tab-item">心里健康评估量表</div><div class="tab-item">心里健康评估量表</div>
            <div class="tab-item">心里健康评估量表</div> -->
          </el-collapse-item>
          <el-collapse-item title="问卷" name="2" v-has="'mgr.visitGeneration.add'">
            <div class="tab-item" v-for="(item,index) in questionList" :key="index">
              {{item.contentName}}
              <img src="../../assets/images/close.png" class="coll-close" @click="deleteQuest(index)" />
            </div>
          </el-collapse-item>
          <el-collapse-item title="提醒" name="3">
            <div  v-for="(item,index) in remindList" :key="index">
              <template v-if="!item.id || (item.id && item.delFlag !== 2)">
                <div  class="tab-item"  style="margin-bottom:2px;"> 
                  <template v-for="rem in  remindTypes">
                    <span v-if="rem.code === item.planRemindDto.type" style="margin-right:10px;">{{rem.name}}</span>
                  </template>  
                  <template v-for="rem in  remindPushWays">
                    <span v-if="rem.code === item.planRemindDto.pushWay">{{rem.name}}</span>
                  </template>
                  <!-- {{item.type}} {{item.pushWay}} -->
                  <img src="../../assets/images/close.png" class="coll-close" @click="deleteRemind(index)" />
                </div>
                <div class="conte" >{{item.planRemindDto.content}}</div>
              </template>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="batch-btns">
      <el-button class="batch-btn" size="small" type="" @click="closeContentDialog">取消</el-button>
      <el-button class="batch-btn success-btn" size="small" @click="handleConfirm"  type="">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  regExamlist,
  reqConstants,
  reqAQuestionList
} from '../../api/index'
import { powerRight } from '../../utils/index'
export default {
  props:{
    contentList:Array,
    currentPlanContentDtoList: Array
  },
  data () {
    return {
      noteCon: '',
      assessVal: this.contentList[0].code, 
      contentListArr: this.contentList,
      examList: [],
      allQuestionList: [], // 所有的问卷列表
      scaleList: [], // 选择的量表列表
      remindList: [], // 添加的提醒列表
      questionList: [], // 问卷列表
      activeNames2: ['1','2','3'],
      remindPushWays: [],
      remindTypes: [],
      type: '', // 提醒方式
      pushWay: '',
      content: ''
    }
    
  },
  mounted(){
    this.getExamList()
    this.startParams()
    this.getQuestionLists()
    this.scaleList = this.currentPlanContentDtoList.filter(item => item.type === 'scale')
    this.remindList = this.currentPlanContentDtoList.filter(item => item.type === 'remind')
    this.questionList = this.currentPlanContentDtoList.filter(item => item.type === 'questionnaire')
  },
  computed:{
    hasQuery(){
      return powerRight('mgr.visitQuestionnaire.query')
    }
  },
  methods:{
    // 提醒方式
    startParams(){
      reqConstants('StageRemindPushEnum').then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          this.remindPushWays = res.data
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      reqConstants('RemindTypeEnum').then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          this.remindTypes = res.data
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    changeNote(val){
      console.log('val',val)
    },
    // 切换类型
    changeType(val){
      this.assessVal = val
      if(val === 1){
      }
    },
    getExamList(){
      const params = {
        pageNo: 1,
        pageSize: 1000
      }
      regExamlist(params).then(res => {
        if(res && res.code === 0){
          if(res.data.list.length){
            let arr = res.data.list
            console.log('this.examList',this.examList)
            var map = {};
            var dest = [];
            for (var i = 0; i < arr.length; i++) {
                var ai = arr[i];
                if (!map[ai.categoryId]) {
                    dest.push({
                        categoryId: ai.categoryId,
                        categoryName: ai.categoryName,
                        child: [ai]
                    });
                    map[ai.categoryId] = ai;
                } else {
                    for (var j = 0; j < dest.length; j++) {
                        var dj = dest[j];
                        if (dj.categoryId == ai.categoryId) {
                          console.log('ai',ai)
                            dj.child.push(ai);
                            break;
                        }
                    }
                }
            }
            this.examList = dest
            console.log('examList',this.examList);
          }
        }else{
          this.$message({
            message: '获取测评列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
     // 问卷列表
    getQuestionLists(){
      reqAQuestionList({
        pageModel:{
          pageNo: 1,
          pageSize: 1000
        }
      }).then(res => {
        if(res && res.code === 0){
          let data = res.data.list
          
          this.allQuestionList = data.filter(item => item.questionnaireStatus == '1')
          
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 选择量表
    selectExam(data){
      console.log('选择量表',data)
      if(this.scaleList.filter(item=> item.contentId === data.id).length){
        return false
      }
      const obj = {
        contentId: data.id,
        type:'scale',
        contentName: data.name,
        times: 1,
      }
      this.scaleList.push(obj)
      console.log('this.scaleList',this.scaleList)
    },
    // 删除量表
    deleteContent(id){
      const idx = this.scaleList.findIndex(item => item.contentId === id);
      if(this.scaleList[idx].id){
        this.$set(this.scaleList,idx,{id:this.scaleList[idx].id,delFlag:2})
      }else{
        this.scaleList.splice(idx,1)
      }
      //this.scaleList.splice(index,1)
    },
    // 选择问卷
    selectQuest(data){
      console.log('选择了问卷')
      if(this.questionList.filter(item=> item.contentId === data.id).length){
        return false
      }
      const obj = {
        contentId: data.id,
        type:'questionnaire',
        contentName: data.questionnaireName,
        times: 1,
      }
      this.questionList.push(obj)
      console.log('this.questionList',this.questionList)
    },
    // 添加提醒
    addRemind(){
      if(this.content === '' || this.pushWay === '' ||this.type === ''){
        this.$message({
          message: '请先填写完当前提醒',
          type: 'wanring'
        }); 
        return false
      }
      const param = {
        planRemindDto: {
          content: this.content,
          pushWay: this.pushWay,
          type: this.type,
        },
        times: 1,
        type: 'remind'
      }
      this.remindList.push(param)
      console.log('this.remindList',this.remindList)
      this.content = ''
      this.pushWay = ''
      this.type = ''
    },
    // 删除问卷
    deleteQuest(idx){
      this.questionList.splice(idx,1)
    },
    // 删除提醒
    deleteRemind(idx){
      if(this.remindList[idx].id){
        this.$set(this.remindList,idx,{id:this.remindList[idx].id,delFlag:2})
      }else{
        this.remindList.splice(idx,1)
      }
    },
    closeContentDialog(){
      this.$emit('closeContentDialog')
    },
    handleChange(){
      
    },
    // 确定
    handleConfirm(){
      const planContentDtoList = this.remindList.concat(this.scaleList).concat(this.questionList)
      console.log('planContentDtoList',planContentDtoList)
      this.$emit('addFollow',planContentDtoList)
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .content
    display flex
    border-bottom 1px solid #D8D8D8
    .cont-left
      width 345px
      padding 0 20px 20px 30px
      border-right 1px solid #D8D8D8
      .assess-manages
        width 228px
        height 34px
        line-height 34px
        margin-top 25px
        box-sizing border-box
        border 1px solid #E8E8E8
        display flex
        justify-content space-between
        border-radius 25px
        margin 25px auto
        .assess-item
          display inline-block
          width 78px
          height 26px
          line-height 26px
          text-align center
          margin  3px
          font-size 14px
          cursor pointer
        .assess-item-active
          border-radius 25px
          background #E0F2EE
          color #009375
      .left-ass
        height 300px
        overflow auto
      .question-item
        padding 10px
        border-radius 5px
        cursor pointer
        &:hover
          background #E0F2EE
      .con-title
        margin-bottom 6px
      .con-from
        position relative
        margin-bottom 17px
        .con-select 
          width 293px
          height 34px
          line-height 34px
          border-radius 5px
          >>>.el-input
            height 34px
            line-height 30px
          >>>input
            height 34px
            line-height 30px
        .con-text
          width 293px
          height 73px
          padding 8px
          border-radius 5px
          border 1px solid #D8D8D8
          resize none
        .text-note
          position absolute
          right 8px
          bottom 8px
          color #9E9E9E
          font-size 12px
      .search-con
        position relative
        margin-bottom 17px
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
      .add-remind-btn
        display inline-block
        width 70px
        height 34px
        border-radius 5px
        background #009375
        color #fff
        border none
        margin 0 120px
    .cont-right
      padding 27px
      flex 1
      .cont-right-title
        font-size 16px
        margin-bottom 12px
    
    .table-coll
      border none
      height 330px
      overflow auto
      >>>.el-collapse-item__header
        border none
        color #009375
        font-size 16px
        flex-direction row-reverse
        justify-content flex-end
        .el-collapse-item__arrow
          width 25px
          height 25px
          line-height  25px
          margin 0
          &:before
            font-weight bold
      >>>.el-collapse-item__wrap
        border-bottom none 
        .el-collapse-item__content
          padding-bottom 0
          .tab-item
            padding 0 40px 0 30px
            border-radius 3px
            position relative
            cursor pointer
            &:hover
              background #E0F2EE
              .coll-close
                display inline-block
              .conte
                background #E0F2EE
            .coll-close
              display none
              width 14px
              height 14px
              position absolute
              right 10px
              top 5px
              cursor pointer
            .conte
              padding 5px
              border-radius 4px
              background #EFF0F6
  >>>.el-dialog__body
    padding 0
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
  .batch-btns
    padding 20px 100px
    display flex
    justify-content space-around
    .batch-btn
      width:70px
      height 34px
      border-radius 5px
      background #F0F3F6
      border none
    .success-btn
      background #009375
      color #fff
</style>