<template>
  <div class="assess">
    <follow-nav routeLink="followPlan"></follow-nav> 
    <div class="afollow">
      <div class="detail-top">
        <div class="detail-top-left">
          <img src="../../assets/images/chart_arrow.png" class="return-icon" @click="handleReturn" />
          新增计划
        </div>
      </div>
      <div class="afollow-con">
        <div class="afollow-left">
          <div class="afoll-item">
            <div class="afoll-title">计划名称</div>
            <el-input placeholder="" v-model="name" type="text" class="afoll-input" />
          </div>
          <div class="afoll-item">
            <div class="afoll-title">开始时间</div>
            <el-select placeholder="" v-model="startWay"  class="afoll-input">
              <el-option  key="begin_now"
                label="分配即开始"
                value="begin_now" >分配即开始</el-option>
            </el-select>
          </div>
          <div class="afoll-item">
            <div class="afoll-title">随访类型</div>
            <el-select placeholder="" v-model="type" @change="handleType"  class="afoll-input">
              <el-option   
                key="stage_visit"
                label="阶段随访"
                value="stage_visit"
               >阶段随访</el-option>
              <el-option
                key="time_visit"
                label="时间线随访"
                value="time_visit" 
              >时间线随访</el-option>
            </el-select>
          </div>
           <div class="afoll-item">
            <div class="afoll-title">所属分组</div>
            <el-select placeholder="" @change="changeGroup" v-model="groupName"   class="afoll-input">
               <el-option
                    v-for="item in FollowGroupDatas"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
              </el-option>
            </el-select>
          </div>
          <div class="from-item-btns">
            <span class="from-item-btn" @click="handleReturn">取消</span>
            <span class="from-item-btn from-item-btn-blue" @click="handleAddFollow">确定</span>
          </div>
        </div>
        <div class="afollow-right">
          <!-- right-info -->
          <!-- <div class="right-info" v-for="(item,index) in planTypeDtoList" :key="index">
            <div class="afolr-top">
              <span class="afolr-top-left">第一阶段</span>
              <div class="afolr-top-right">
                <span>随访内容</span>
                <span>编辑</span>
              </div>
            </div>
            <div class="afolr-main">
              <div class="stage-left">
                <div class="stage-item">
                  <span class="stage-name">开始时间：</span>
                  <el-select placeholder=""  class="stage-input">
                    <el-option value="分配几开始" >分配几开始</el-option>
                    <el-option value="前阶段结束" >前阶段结束</el-option>
                    <el-option value="前阶段同时" >前阶段同时</el-option>
                  </el-select>
                </div>
                <div class="stage-item">
                  <span class="stage-name">阶段周期：</span>
                  <el-input  class="stage-input stage-input-week" oninput="value=value.replace(/[^\d]/g,'')"  v-model="stageCycle" /><span class="stage-item-t">/周</span>
                </div>
                <div class="stage-item">
                  <span class="stage-name">频   率：</span>
                  <el-select placeholder="" v-model="frequency"  class="stage-input">
                    <el-option value="阶段1次" >阶段1次</el-option>
                    <el-option value="每日1次" >每日1次</el-option>
                    <el-option value="每周2次" >每周2次</el-option>
                    <el-option value="每日3次" >每日3次</el-option>
                    <el-option value="每周4次" >每周4次</el-option>
                    <el-option value="每日5次" >每日5次</el-option>
                  </el-select>
                </div>
                <div class="stage-item">
                  <span class="stage-name">阶段描述：</span>
                  <textarea class="stage-text" :value="item.comment" ></textarea>
                </div>
              </div>
              <div class="stage-right">
                <div class="stage-right-con">
                  <div class="satge-right-table">
                    <div class="satge-table-item">
                      <span class="table-span">序号</span>
                      <span class="table-span">类型</span>
                      <span class="table-span">内容</span>
                      <span class="table-span">次数</span>
                    </div>
                    <div class="satge-table-item">
                      <span class="table-span">1</span>
                      <span class="table-span">量表</span>
                      <span class="table-span">SCL-90</span>
                      <span class="table-span">1</span>
                    </div>
                    <div class="satge-table-item">
                      <span class="table-span">1</span>
                      <span class="table-span">量表</span>
                      <span class="table-span">SCL-90</span>
                      <span class="table-span">1</span>
                    </div>
                  </div>
                  <span class="satge-add">
                    <img src="../../assets/images/add.png" @click="changeContent" class="add-img"/> 选择随访内容
                  </span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- right-info end -->
          <!-- right-info -->
          <div class="right-info" v-for="(plan,planIdx) in planTypeDtoList " :key="planIdx">
            <template v-if="!plan.id || (plan.id && plan.delFlag !== 2)">
              <div class="afolr-top">
                <span class="afolr-top-left">
                  <template v-if="type !== 'time_visit'">
                    第{{plan.sequence}}阶段
                  </template>
                </span>
                <div class="afolr-top-right">
                  <span>随访内容</span>
                  <span @click="deletePlan(planIdx)">删除</span>
                </div>
              </div>
              <div class="afolr-main">
                <!-- 时间线随访 -->
                <div class="stage-left" v-if="type === 'time_visit'">
                  <div class="stage-item">
                    <p class="stage-name">时间(第几天)</p>
                    <el-input  class="stage-input stage-input-week" @change="changePlanWhichDay($event,planIdx)" oninput="value=value.replace(/[^\d]/g,'')"  v-model="plan.whichDay" /><span class="stage-item-t"></span>
                    <!-- -->
                  </div>
                </div>
                <!-- 阶段随访 -->
                <div class="stage-left" v-else>
                  <div class="stage-item">
                    <span class="stage-name">开始时间：</span>
                    <el-select placeholder=""  class="stage-input" :value="plan.startWay" @change="changePlanStartway($event,planIdx)">
                      <el-option v-for="(item,i) in startTimes"  :key="i" :value="item.code" :label="item.name"></el-option>
                      <!-- <el-option value="前阶段结束" >前阶段结束</el-option>
                      <el-option value="前阶段同时" >前阶段同时</el-option> -->
                    </el-select>
                  </div>
                  <div class="stage-item">
                    <span class="stage-name">阶段周期：</span>
                    <el-input  class="stage-input stage-input-week" v-model="plan.stageCycle" @change="changePlanStageCycle($event,planIdx)"  oninput="value=value.replace(/[^\d]/g,'')"  /><span class="stage-item-t">/周</span>
                  </div>
                  <div class="stage-item">
                    <span class="stage-name">频   率：</span>
                    <el-select placeholder=""  class="stage-input" :value="plan.frequency" @change="changeFrequency($event,planIdx)">
                      <el-option v-for="item in frequencyList" :key="item.code" :label="item.name" :value="item.code" ></el-option>
                      <!-- <el-option value="阶段1次" >阶段1次</el-option>
                      <el-option value="每日1次" >每日1次</el-option>
                      <el-option value="每周2次" >每周2次</el-option>
                      <el-option value="每日3次" >每日3次</el-option>
                      <el-option value="每周4次" >每周4次</el-option>
                      <el-option value="每日5次" >每日5次</el-option> -->
                    </el-select>
                  </div>
                  <div class="stage-item">
                    <span class="stage-name">阶段描述：</span>
                    <textarea class="stage-text" :value="plan.comment" @change="changeComment($event,planIdx)"></textarea>
                  </div>
                </div>
                <div class="stage-right">
                  <div class="stage-right-con">
                    <div class="satge-right-table">
                      <div class="satge-table-item">
                        <span class="table-span table-span-small">序号</span>
                        <span class="table-span table-span-small">类型</span>
                        <span class="table-span table-span-flex">内容</span>
                        <span class="table-span  table-span-small" >次数</span>
                      </div>
                      <div class="satge-table-item" v-for="(content,conIdx) in plan.planContentDtoList" :key="conIdx">
                        <template v-if="!plan.id || (plan.id && plan.delFlag !== 2)">
                          <span class="table-span  table-span-small">{{conIdx+1}}</span>
                          <span class="table-span  table-span-small" v-if="content.type ==='remind'">提醒</span>
                          <span class="table-span  table-span-small" v-else-if="content.type ==='scale'">量表</span>
                          <span class="table-span table-span-small" v-else-if="content.type ==='questionnaire'">问卷</span>
                          <span class="table-span table-span-flex" v-if="content.type ==='remind'">{{content.planRemindDto.content}}</span>
                          <span class="table-span table-span-flex" v-else>{{content.contentName}}</span>
                          <!-- <span class="table-span">1</span> -->
                          <input type="text" class="table-span table-span-small" @change="changeConteTimes($event,planIdx,conIdx)" :value="content.times" />
                        </template>
                      </div>
                      <!-- <div class="satge-table-item">
                        <span class="table-span">1</span>
                        <span class="table-span">量表</span>
                        <span class="table-span">SCL-90</span>
                        <span class="table-span">1</span>
                      </div> -->
                    </div>
                    <span class="satge-add" @click="addContent(planIdx)">
                      <img src="../../assets/images/add.png" class="add-img"  /> 选择随访内容
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- right-info end -->
          <div class="right-add">
            <span class="right-satge-add"  @click="addStage">
              <img src="../../assets/images/add.png" class="add-img" /> 新增随访阶段
            </span>
          </div>
        </div>
      </div>
    </div>
    <follow-content
      v-if="contentDialogVisible"
      :contentList="contentList"
      @closeContentDialog="closeContentDialog"
      @addFollow="addFollow"
      :currentPlanContentDtoList="currentPlanContentDtoList"
    ></follow-content>
  </div>
</template>

<script>
import {
  reqPageGroup,
  reqConstants,
  reqCreatePlan,
  reqPlanDetail,
  reqUpdatePlan
} from '../../api/index'
import FollowNav from '@/components/FollowUp/FollowNav'
import FollowContent from '@/components/FollowUp/FollowContent'
export default {
  components: {FollowNav, FollowContent },
  data () {
    return {
      id: null,
      startTimes: [], // 开始时间列表
      frequencyList: [], // 随访阶段频率
      contentList: [], // 随访内容类型
      stageCycle: 0,
      whichDay: 0, // 第几天
      name: '',
      contentDialogVisible: false,
      FollowGroupDatas: [],
      groupId: null,
      groupName: '',
      startWay:'begin_now',
      type: '',
      planTypeDtoList: [], // 随访阶段list
      currentPlan: null, // 当前正在编辑的阶段
      sequenceNum: 0,
      currentPlanIdx: 0, // 当前正在编辑的阶段idx
      currentPlanContentDtoList: [], // i当前随访阶段内容，传给弹窗
      addPlan:{
        sequence: 1,
        startWay: '', // 开始时间/方式 
        comment: '',
        stageCycle: 0, // 阶段周期
        frequency: null, // 频率
        planContentDtoList: [], // 随访内容
        watchDay: 0
      },
    }
    
  },
  mounted(){
    this.startParams()
    this.getGroupList()
    if(this.$route.params.planId !== undefined){ //详情
      this.id = this.$route.params.planId
      this.getDetail()
    }
  },
  methods:{
    // 初始化下拉列表
    startParams(){
      console.log('startParams')
      //this.setData()
      reqConstants('StageStartWayEnum').then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          this.startTimes = res.data
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      // 阶段频率
      reqConstants('StageFrequencyEnum').then(res => {
        console.log('StageFrequencyEnum',res)
        if(res && res.code === 0){
          this.frequencyList = res.data
        }else{
          this.$message({
            message: '获取随访阶段频率失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      // 阶段频率
      reqConstants('ContentTypeEnum').then(res => {
        console.log('ContentTypeEnum',res)
        if(res && res.code === 0){
          this.contentList = res.data
        }else{
          this.$message({
            message: '获取随访内容类型失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 获取详情
    getDetail(){
      reqPlanDetail(this.id).then(res => {
        if(res && res.code === 0){
          const detail = res.data
          this.type = detail.type
          this.name = detail.name
          this.groupId = detail.groupId
          this.groupName = detail.groupName
          this.planTypeDtoList = detail.planTypeDtoList
          this.startWay = detail.startWay
          console.log('planTypeDtoList',this.planTypeDtoList)
        }else{
          this.$message({
            message: '获取详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 分组列表
    getGroupList(){
      reqPageGroup({
        pageNo: 0,
        pageSize: 1000
      }).then(res => {
        if(res && res.code === 0){
          this.FollowGroupDatas = res.data.list
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 更改分组
    changeGroup(val){
      console.log('val',val)
      this.groupName = val.name
      this.groupId = val.id
    },
    // 阶段第几天
    changePlanWhichDay(val,idx){
      console.log('第几天',val,idx)
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],whichDay:val})
    },
    // 阶段的开始时间：
    changePlanStartway(val,idx){
      console.log('changePlanStartway',val,idx)
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],startWay:val})
      console.log('1',this.planTypeDtoList)
    },
    // 阶段周期
    changePlanStageCycle(val,idx){
      console.log('changePlanStageCycle',val)
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],stageCycle:val})
    },
    // 阶段频率
    changeFrequency(val,idx){
      console.log('changeFrequency',val)
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],frequency:val})
      console.log('2',this.planTypeDtoList)
      //
    },
    // 阶段描述
    changeComment(val,idx){
      console.log('changeComment',val,val.target,val.target.value)
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],comment:val.target.value})
    },
    // 删除阶段
    deletePlan(idx){
      if(this.planTypeDtoList[idx].id){
        this.$set(this.planTypeDtoList,idx,{id:this.planTypeDtoList[idx].id,delFlag:2})
      }else{
        this.planTypeDtoList.splice(idx,1)
      }
      
    },
    // 添加随防内容
    addContent(idx){
      this.currentPlanIdx = idx
      this.currentPlanContentDtoList = this.planTypeDtoList[idx].planContentDtoList
      this.contentDialogVisible = true
    },
    // 添加提醒
    addRemind(){
    },
    handleReturn(){
      this.$router.push({ path: '/followPlan'})
    },
    // 添加随访内容
    addFollow(data){
      const idx = this.currentPlanIdx
      this.contentDialogVisible = false
      this.$set(this.planTypeDtoList,idx,{...this.planTypeDtoList[idx],planContentDtoList:data})
    },
    // 更改随访内容的次数
    changeConteTimes(val,idx,conIdx){
      let curPlan =  _.cloneDeep(this.planTypeDtoList[idx]) 
      console.log('changeConteTimes',val.target.value)
      curPlan.planContentDtoList[conIdx].times = Number(val.target.value)
      this.$set(this.planTypeDtoList,idx, curPlan)
      console.log('当前的总数据',this.planTypeDtoList)
    },
    addStage(){
      //this.contentDialogVisible = true
      if(!this.type){
        this.$message({
          message: '请先选择随访类型',
          type: 'wanring'
        }); 
        return false
      }
      if(this.planTypeDtoList.length){
        const lastPlan = this.planTypeDtoList[this.planTypeDtoList.length-1]
        if(!lastPlan.planContentDtoList.length){
          this.$message({
            message: '请先添加上一阶段随访内容',
            type: 'wanring'
          }); 
          return false
        }
        if(this.type === 'stage_visit'){        
          if(!lastPlan.startWay){
            this.$message({
              message: '请先添加上一阶段开始时间',
              type: 'wanring'
            }); 
            return false
          }
          if(!lastPlan.frequency){
            this.$message({
              message: '请先添加上一阶段频率',
              type: 'wanring'
            }); 
            return false
          }
        }else if(this.type === 'time_visit' ){
          if(lastPlan.whichDay == ''){
            this.$message({
              message: '请先添加上一阶段时间',
              type: 'wanring'
            }); 
            return false
          }
        }
      }
      this.addPlan.watchDay = 0
      let addObj = this.addPlan
      this.whichDay = 0
      this.startWay = 0
      this.sequenceNum++
      addObj.sequence = this.sequenceNum
      this.planTypeDtoList.push(addObj)
    },
    closeContentDialog(){
      this.contentDialogVisible = false
    },
    // 新增计划
    handleAddFollow(){
      if(!this.type){
        this.$message({
          message: '请先选择随访类型',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.name){
        this.$message({
          message: '请先填写随访名称',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.planTypeDtoList.length){
        this.$message({
          message: '请先添加随访阶段',
          type: 'wanring'
        }); 
        return false
      }
      const params = {
        name: this.name,
        groupId: this.groupId,
        groupName: this.groupName,
        planTypeDtoList: this.planTypeDtoList,
        startWay: this.startWay,
        type: this.type,
      }
      if(this.id){
        params.delFlag = 1
        params.id = this.id
      }
      console.log('params',params,this.id)
      if(this.id){
        reqUpdatePlan(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.handleReturn()
          }else{
            this.$message({
              message: '修改失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        reqCreatePlan(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.handleReturn()
          }else{
            this.$message({
              message: '新增失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    handleType(){
      this.planTypeDtoList = []
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .afollow
    .detail-top
      display flex
      justify-content space-between
      align-items center
      border-bottom 1px solid #D8D8D8
      padding 0 20px 10px 0
      .detail-top-left
        display flex
        align-items center
        font-size 18px
        color  #414141
        font-weight bold
        .return-icon
          transform rotate(180deg)
          margin-right 13px
  .afollow-con
    display flex
    width 100%
    .afollow-left
      width 297px
      padding-right 20px
      border-right 1px solid #D8D8D8
      height 100%
      .afoll-item
        margin-top 16px
        .afoll-title
          margin-bottom 12px
        .afoll-input
          width 277px
          height 46px
          line-height 46px
          text-indent 5px
          border-radius 5px
          background #EFF0F6
          >>>input
            border none 
            outline none 
            background transparent
            padding 10px
            width 257px
      .from-item-btns
        display flex 
        padding  40px 30px
        justify-content space-around
        .from-item-btn
          padding 0 9px 0 17px
          height 30px
          line-height 30px
          background #F0F0F5
          border-radius 5px
          letter-spacing 8px
          margin 15px 0 10px
          cursor pointer
        .from-item-btn-blue
          background #009375
          color #fff
    .afollow-right
      flex 1
      padding 39px 30px
      .right-info
        border-radius 5px
        box-sizing border-box
        border 1px solid #B3DFD6
        margin-bottom 40px
        .afolr-top
          height 33px
          line-height 33px 
          color #009375
          font-size 16px
          border-radius 5px 5px 0px 0px
          background #EFF0F6
          border-bottom 1px solid #B3DFD6
          padding 0 15px
          display flex
          .afolr-top-left
            width 330px
          .afolr-top-right
            display flex
            flex 1
            justify-content space-between
            span
              display inline-block
        .afolr-main
          display flex
          width 100%
          .stage-left
            padding 19px 13px 19px 16px
            display inline-block
            width 330px
            border-right 1px dashed #D8D8D8
            .stage-item
              display inline-block
              font-size 14px
              align-items center 
              margin-bottom 10px
              position relative
              .stage-name
                display inline-block
                width 83px
              .stage-input
                width 215px
                height 31px
                border-radius 5px
                background #EFF0F6
                >>>.el-input
                  height 35px
                >>>input
                  width 100%
                  border none 
                  outline none 
                  background #EFF0F6
                  height 31px
              .stage-input-week
                padding-right 20px
              .stage-item-t
                position absolute
                right 10px
                top 10px
              .stage-text
                width 297px
                height 95px
                border-radius 5px
                background #EFF0F6
                border none 
                margin-top 10px
                padding 10px
                resize none
          .stage-right
            padding 23px
            flex 1
            .stage-right-con
              text-align center
              .satge-right-table
                margin-bottom 50px
                text-align left
                .satge-table-item
                  display flex
                  margin-bottom 4px
                  .table-span
                    line-height 31px
                    border-radius 5px
                    padding 0 5px
                    &:hover
                      background #EFF0F6
                  .table-span-small
                    display inline-block
                    width 60px
                  .table-span-flex
                    flex 1
                    text-align center
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
              .satge-add
                display inline-block
                width 144px
                height 40px
                line-height 40px
                text-align center
                border-radius 5px
                border 1px solid #D8D8D8
                margin 0 auto
                cursor pointer
                img
                  position relative
                  margin-right 10px
      .right-add
        text-align center
        .right-satge-add
          display inline-block
          width 144px
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          margin 0 auto
          cursor pointer
          img
            position relative
            margin-right 10px
</style>