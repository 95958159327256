<template>
  <el-dialog
    :title="memberTitle"
    class="dialog-main"
    :visible=true
    :close-on-click-modal=false
    @close="closeAddMember"
    width="800px">
    <div class="dialog-con">
      <div class="dialog-member">
        <div class="dialog-member-half">
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text">*</span>姓名</p>
            <input type="text" v-model="name" class="dialog-dept-text" placeholder="请输入姓名" />
          </div>
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text">*</span>手机号</p>
            <input type="text" v-model="mobilePhone" class="dialog-dept-text" placeholder="请输入手机号" />
          </div>
          <div class="dialog-dept-item" v-if="isInitPwd">
            <p class="dialog-dept-name"><span class="red-text">*</span>初始密码</p>
            <input type="password" v-model="password" class="dialog-dept-text" placeholder="默认手机号码后六位" />
          </div>
          <div class="dialog-dept-item" v-else>
            <p class="dialog-dept-name"><span class="red-text">*</span>初始密码</p>
            <input type="password" v-model="password" class="dialog-dept-text" placeholder="请输入密码" />
          </div>
          
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text"></span>工号</p>
            <input type="text"  v-model="jobNo" class="dialog-dept-text" disabled placeholder="默认由系统生成唯一标识" />
          </div>
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text">*</span>成员状态</p>
            <el-select v-model="status" class="dialog-dept-select"  placeholder="请选择">
              <el-option
                label="启用"
                value="Y">
              </el-option>
              <el-option
                label="停用"
                value="N">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dialog-member-half">
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text">*</span>部门</p>
            <!-- <input type="text" class="dialog-dept-text" /> -->
            <div class="dept-role-zw" v-if="deptIdList.length">
              <span class="role-zw-item" v-for="(item,index) in deptIdList" :key="index">
                {{item.name}}
                <img src="../../../assets/images/close.png" class="close-zw-close" @click="handleDeleteBm(index)"/>
              </span>
            </div>
            <el-select 
              ref="selectTree" 
              v-model="detpParentId" 
              placeholder="请选择" 
              class="dialog-dept-text dialog-dept-select"
              popper-class="popperTreeSelect"
            >
              <el-option :value="detpParentId" :label="parentName">
                <el-tree 
                  ref="tree" 
                  :data="parentOrgArr" 
                  default-expand-all
                  :check-strictly="true"
                  :props="defaultProps" 
                  node-key="id"
                  :expand-on-click-node=false
                  style="min-height:100px;"
                  @node-click="handleDeptParentId"
                >
                  <span class="custom-tree-node" slot-scope="{ node,data}">
                      <span :style="data.glay? 'color:#ccc': ''">{{ node.label }}</span>
                  </span>
                </el-tree>
              </el-option>
            </el-select>
          </div>
          <div class="dialog-dept-item">
            <p class="dialog-dept-name"><span class="red-text">*</span>角色职务</p>
            <div class="dept-role-zw" v-if="roleIdList.length">
              <span class="role-zw-item" v-for="(item,index) in roleIdList" :key="index">
                {{item.name}}
                <img src="../../../assets/images/close.png" class="close-zw-close" @click="handleDeleteZw(index)"/>
              </span>
            </div>
            <el-select 
              ref="selectRoleTree" 
              v-model="roleParentId" 
              width="300px"
              placeholder="请选择" 
              class="dialog-dept-text dialog-dept-select"
              popper-class="popperTreeSelect"
              @click="beforRole"
            >
              <el-option :value="roleParentId" :label="parentRoleName">
                <el-tree 
                  ref="tree" 
                  :data="parentOrgRoleArr" 
                  default-expand-all
                  :props="defaultProps" 
                  node-key="id"
                  :expand-on-click-node=false
                  style="min-height:100px;"
                  @node-click="handleRoleParentId"
                >
                  <span class="custom-tree-node" slot-scope="{ node,data}">
                      <span :style="data.type !== 'role'? 'color:#ccc': ''">{{ node.label }}</span>
                  </span>
                </el-tree>
              </el-option>
            </el-select>
          </div>
          
          <div class="dialog-dept-item">
              <p class="dialog-dept-name"><span class="red-text">*</span>电子签名</p>
              <el-upload
                v-if="!eleSign"
                class="upload-demo"
                drag
                :http-request="handleUpload"
                ref="upload" 
                action="customize"
                accept=".jpg,.png,.bmp,.gif"
                :before-upload="uploadLicense"
                :show-file-list="false"
                >
                <img src="../../../assets/images/upload.png" class="upload-img "/>
                <p class="link-color">拖拽文件或选择上传你文件</p>
              </el-upload>
              <div class="qualif-img" v-else>
                <img src="../../../assets/images/close.png" class="close-qualif" @click="handleDeleteSign"/>
                <img :src="eleSign" class="qualif-image" />
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddMember">取消</span>
      <span class="dialog-btn" @click="handleAddMember">确定</span>
    </div>
  </el-dialog>
</template>
<script>
import {
  regAddMember,
  regDetailMember,
  regOrgTree,
  regDeptTree,
  regFromUpload,
  regDeptAndRoleTree,
  regEditMember,
  regDeptAndRoleTreeByDept,
  regListByModule
} from '../../../api/index'
import { maxFileSize } from '../../../utils/variate'
import OnecSelectTree from '@/components/SystemSetting/SystemCompontents/OnecSelectTree'
export default {
  name:'StructAddMemberDialog',
  data(){
    return{
      // 成员信息
      jobNo: '', // 工号
      mobilePhone: '',
      name: '',
      password: '',
      status: 'Y', //  启用状态Y 启用/ N 停用
      roleIdList: [], //角色职务id列表
      eleSign: '', // 电子签名
      deptIdList: [], // 部门id列表
      memberDetail: {}, // 用户详情
      childOrg:[],
      parentOrgArr: [], // 机构和部门组成的树
      mainOrgDeptTree:{},
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      detpParentId: null, 
      parentName: '',
      roleParentId: null,
      parentRoleName: '',
      parentOrgRoleArr: [],
      isInitPwd: true, // 密码初始化后6位
    }
  },
  props:{
    memberId: Number,
    memberTitle: String,
    orgName: String
  },
  components:{ OnecSelectTree },
  mounted(){
    if(this.memberId){
      this.getMemberDetail()
    }
    console.log('n9',this.orgName)
    this.getOrgTree()
    this.getAllModule()
  },
  methods:{
    getAllModule(){
      regListByModule({configModule:'base'}).then(res => {
        if(res.code === 0){
          let data = res.data
          for(let i=0; i<data.length; i++){
            if(data[i].configCode === 'base.member.initpwd'){
              this.isInitPwd = data[i].configValue  === 'Y' ? true : false
            }
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    getMemberDetail(){
      regDetailMember({
        id: this.memberId
      }).then(res => {
        if(res.code === 0){
          this.name = res.data.name
          //this.orgName = res.data.orgName
          this.roleName = res.data.roleName
          this.jobNo = res.data.jobNo
          this.mobilePhone = res.data.mobilePhone
          this.eleSign = res.data.eleSign
          this.flagAdmin = res.data.flagAdmin
          this.roleIdList = res.data.roleList
          this.deptIdList = res.data.deptList
        }else{
          this.$notify.error({
            title: '错误',
            message: '查看人员详情失败！'+ res.msg
          });
          
        }
      })
    },
    // 获取一级组织下的部门角色树
    getOneOrgDeptRole(theData){
      let data =  _.cloneDeep(theData)
      console.log('datadata',data)
      regDeptAndRoleTree({
        id: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          console.log('datadata res',res)
          //this.parentOrgRoleArr[0] = res.data
          // this.parentOrgRoleArr.unshift({
          //   children: res.data,
          //   id: data.id,
          //   name: data.orgName
          // })
          const childData = data.children
          console.log('childData',childData)
          for(let i=0; i<childData.length; i++){
            console.log('childData[i]',childData[i])
            this.getDeptRoleTree(childData[i],i+1)
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门角色树失败！'+ res.msg
          });
        }
      })
    },
      // 获取组织下的部门角色树
    getDeptRoleTree(data,i){
      console.log('after',data)
      regDeptAndRoleTree({
        id: data.id
      }).then(res => {
        if(res.code === 0){
          // this.parentOrgRoleArr.push({
          //   children: res.data,
          //   id: data.id,
          //   name: data.orgName
          // })
          // console.log('parentOrgRoleArr',this.parentOrgRoleArr)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    handleUpload(){},
    // 用戶上傳簽名
    uploadLicense(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.eleSign = res.data.fileUrl
          console.log('this.eleSign',this.eleSign)
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 刪除用戶簽名
    handleDeleteSign(){
      this.eleSign = ''
    },
    // 新增成员
    handleAddMember(){
      if(!this.mobilePhone || this.mobilePhone.length !== 11){
        this.$message({
          message: '手机号格式不对',
          type: 'warning'
        }); 
        return false; 
      }
      if(!this.isInitPwd && this.password.length < 6){
        this.$message({
          message: '密码至少6位',
          type: 'warning'
        }); 
        return false; 
      }
      let roleIdListIds = this.roleIdList.map(function (obj) {
          return obj.id; // 这里将返回每个对象的 "name" 属性作为新数组的元素
      });
      let deptListIds = this.deptIdList.map(function (obj) {
          return obj.id; // 这里将返回每个对象的 "name" 属性作为新数组的元素
      });
      let params = {
        mobilePhone: this.mobilePhone,
        name: this.name,
        password: '',
        roleIdList: roleIdListIds.join(','), //角色职务id列表
        eleSign: this.eleSign, // 电子签名
        deptIdList: deptListIds.join(','),
        status: this.status
      }
      //params.append("roleIdList", ids)
      if(this.password.trim() !== ''){
        params.password = this.$md5(this.password).toUpperCase()
      }else{
        params.password =  ''
        //this.$md5(this.mobilePhone.substring(this.mobilePhone.length - 6)).toUpperCase()
      }
      console.log('params',params)
      if(this.memberId){
        params.id = this.memberId
        regEditMember(params).then(res => {
        if(res.code === 0){
          this.$message({
            message: '编辑成员成功',
            type: 'success'
          }); 
          this.$emit('addMemberSuccess')
        }else{
          this.$notify.error({
            title: '错误',
            message: '编辑成员失败！'+res.msg
          });
        }
      })
      }else{
        regAddMember(params).then(res => {
          if(res.code === 0){
            this.$message({
              message: '新增成员成功',
              type: 'success'
            }); 
            this.$emit('addMemberSuccess')
          }else{
            this.$notify.error({
              title: '错误',
              message: '新增成员失败！'+res.msg
            });
            
          }
        })
      }
    },
    // 获取组织树
    getOrgTree(){
      regOrgTree({}).then(res => {
        if(res.code === 0){
          console.log('组织树树',res.data)
          let resData =  _.cloneDeep(res.data) 
          this.getOneOrgDept(resData)
          //this.parentOrgRoleArr = resData.children
          this.getOneOrgDeptRole(resData)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取组织树失败！' + res.msg
          });
          
        }
      })
    },
    // 获取一级组织下的部门树
    getOneOrgDept(theData){
      let data =  _.cloneDeep(theData)
      regDeptTree({
        orgId: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          this.firstOrgDeptArr = res.data
          this.mainOrgDeptTree = {
            id: this.orgId,
            orgName: this.orgName,
            name: this.orgName,
            children: res.data
          }
          console.log('mainOrgDeptTree0',this.mainOrgDeptTree,res.data[0].name)
          let childData = data.children
          this.childOrg = childData
          //if()
          this.parentOrgArr = res.data
          for(let i=0; i<childData.length; i++){
            this.getDeptTree(childData[i],i,this.mainOrgDeptTree)
          }
          console.log('组织下部门树',res.data,this.mainOrgDeptTree)
          
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
     // 获取组织下的部门树
    getDeptTree(data,i,oneTree){
      regDeptTree({
        orgId: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          console.log('getDept',i,this.childOrg)
          this.childOrg[i].children = res.data
           if(i === this.childOrg.length-1){
              this.getParentOrgArr(oneTree)
            }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    // 获取机构和部门组成的树
    getParentOrgArr(oneTree){
      let parentOrgArr =  _.cloneDeep(this.childOrg) 
      for(let i=0; i< parentOrgArr.length; i++){
        if(parentOrgArr[i].orgName != undefined){
          this.childOrg[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      console.log('parentOrgArr',oneTree,this.mainOrgDeptTree)
      oneTree.name = this.orgName
      console.log('oneTree',oneTree,this.orgName)
      parentOrgArr.unshift(oneTree)
      for(let i=0; i< parentOrgArr.length; i++){
        parentOrgArr[i].glay = true
        if(parentOrgArr[i].orgName != undefined){
          parentOrgArr[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      this.parentOrgArr =  parentOrgArr
      console.log('this.parentOrgArr',this.parentOrgArr)
    },
    // 更改上级部门id
    handleDeptParentId(data) {
      console.log('handleNodeClick',data)
      if(data.glay) return false
      if(this.deptIdList.filter(item => item.id === data.id ).length == 0){
        this.deptIdList.push(data)
      }
      // this.parentId = data.id
      // this.value = data.label;
      this.$refs.selectTree.blur();
      this.detpParentId = data.id
      this.parentName = data.name
      //this.deptParentId = Number(data.id) 
    },
    handleRoleParentId(data){
      console.log('handleNodeClick',data)
      if(data.type !== 'role') return false
      //if(this.roleIdList.filter())
      console.log('roleIdList befor',this.roleIdList)
      if(this.roleIdList.filter(item => item.id === data.id ).length == 0){
        this.roleIdList.push(data)
      }
      // this.parentId = data.id
      // this.value = data.label;
      this.$refs.selectRoleTree.blur();
      this.roleParentId = data.id
      this.parentRoleName = data.name
    },
    // 删除成员角色
    handleDeleteZw(idx){
      this.roleIdList.splice(idx,1)
    },
    // 删除部门
    handleDeleteBm(idx){
      this.deptIdList.splice(idx,1)
    },
     // 关闭
    closeAddMember(){
      this.$emit('closeAddMember')
      
    },
    beforRole(){
      if(!this.deptIdList.length){
        this.$notify.error({
            title: '警告',
            message: '请先选择部门！'
          });
      }
    }
  },
  watch:{
    deptIdList(){
      if(this.deptIdList.length){
        console.log('this.deptIdList',this.deptIdList,this.deptIdList.map(item => {return item.id}).join(','))
        regDeptAndRoleTreeByDept({
          deptIds: this.deptIdList.map(item => {return item.id}).join(',')
        }).then(res => {
          if(res.code === 0){
            console.log('res',res)
            this.parentOrgRoleArr = res.data
          }else{
            this.$notify.error({
              title: '错误',
              message: '获取当前部门下角色树失败！'+ res.msg
            });
          }
        })
      }
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  >>>.el-dialog
    margin-top 10px
  .dialog-con
    padding 30px
    .dialog-dept-item
      width 462px
      margin-bottom 22px
      .dialog-dept-name
        margin-bottom 11px
      .dept-role-zw
        width 300px
        min-height 35px
        border-radius 5px
        border 1px solid #DADADA
        margin-bottom 8px
        padding-bottom 5px
        .role-zw-item
          display inline-block
          height 24px
          line-height 20px
          border-radius 5px
          background #D0E6E6
          padding 0 5px
          margin 5px 0 0 5px
          position relative
          font-size 12px
          align-items center
          .close-zw-close
            position relative 
            top 4px
            left 3px
            cursor  pointer
      .el-select
        width 460px
      .dialog-dept-text
        width 100%
        height 38px
        line-height 38px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
      .dialog-dept-select
        width 100%
        border none
      .upload-demo
        >>>.el-upload-dragger
          width 200px
          height 89px
          color #009375
          border 1px dashed #009375
          display flex
          flex-direction column
        .upload-img
          width 20px
          margin 15px auto 12px
      .qualif-img 
        position relative
        margin-right 10px
        .qualif-image
          max-width 200px
          max-height 100px
        .close-qualif
          position absolute 
          width 20px
          top -8px
          right -8px
          background #fcfcfc
    .modify-bars
      margin-bottom 50px
      display flex
      align-items center
      .modify-bar
        display inline-block
        width 20px
        height 20px
        line-height 20px
        border-radius 25px
        background #D8D8D8
        color #fff
        margin 0 20px
        text-align center
      .modify-bar-ac
        width 55px
        background #E0F2EE
        color #009375
      .bar-link
        flex 1
        height 1px 
        background #ccc
    .modify-success
      text-align center
      .modify-par
        color #9e9e9e
        margin 15px 0 22px
      .modify-sencend
        font-size 12px
        color #009375
        margin-bottom 60px
    .dialog-member
      display flex
      .dialog-member-half
        flex 1
        margin-right 18px
        .dialog-dept-item
          width 300px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .dialog-btns-right
    text-align right
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
</style>