<template>
  <div class="regiter">
    <div class="reg-left">
      <img src="../../assets/images/logo.png" class="reg-logo" />
      <div class="reg-copy">版权所有 © 2023 legu Health  - 保留所有权利</div>
    </div>
    <div class="reg-main">
      <div class="reg-con">
        <div class="reg-progess">
          <span :class="{'reg-progess-item-active':regOrder >= 1 }" class="reg-progess-item "></span>
          <span :class="{'reg-progess-item-active':regOrder >= 2 }" class="reg-progess-item"></span>
          <span :class="{'reg-progess-item-active':regOrder >= 3 }" class="reg-progess-item"></span>
          <span :class="{'reg-progess-item-active':regOrder >= 4 }" class="reg-progess-item"></span>
        </div>
        <!-- 个人账号基础信息 -->
        <div v-if="regOrder === 1"> 
          <div class="reg-title" v-if="userType === '1'">在 乐咕健康 创建个人帐户</div>
          <div class="reg-title" v-else>在 乐咕健康 创建组织帐户</div>
          <div class="reg-par">创建一个帐户以向 乐咕健康 注册用户提供健康管理服务。</div>
          <div class="reg-from">
            <div class="reg-from-item">
              <span class="regfrom-name" v-if="userType === '1'"><span class="red-text">*</span>姓名</span>
              <span class="regfrom-name" v-if="userType !== '1'"><span class="red-text">*</span>管理员姓名</span>
              <el-input class="reg-input"  v-model.trim="name" placeholder="输入真实姓名" />
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>身份证号</span>
              <el-input class="reg-input" v-model.trim="identityCardno" placeholder="输入身份证号" />
            </div>
            
            <div class="reg-from-item reg-from-hasimg">
              <el-upload
                class="upload-demo reg-from-ident"
                drag
                :http-request="handleUpload"
                ref="upload" 
                action="customize"
                accept=".jpg,.png,.bmp,.gif"
                :before-upload="uploadIdentyZM"
                :show-file-list="false"
                v-if="!identityFrontImg"
                >
                <img src="../../assets/images/upload.png" class="upload-img "/>
                <p class="link-color">上传身份证人像面</p>
              </el-upload>
              <div class="qualif-img" v-else>
                <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteZM"/>
                <img :src="identityFrontImg" class="qualif-image" />
              </div>
              <el-upload
                class="upload-demo reg-from-ident"
                drag
                :http-request="handleUpload"
                ref="upload" 
                action="customize"
                accept=".jpg,.png,.bmp,.gif"
                :before-upload="uploadIdentyFM"
                :show-file-list="false"
                v-if="!identityBackImg"
                >
                <img src="../../assets/images/upload.png" class="upload-img "/>
                <p class="link-color">上传身份证国徽面</p>
              </el-upload>
              <div class="qualif-img" v-else>
                <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteFM"/>
                <img :src="identityBackImg" class="qualif-image" />
              </div>
            </div>


            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>联系电话</span>
              <el-input class="reg-input reg-tell" v-model="mobilePhone" placeholder="输入手机号码" />
            </div>
            <div class="reg-from-item" v-if="userType == '1'">
              <!-- <span class="red-text">*</span> -->
              <span class="regfrom-name">所在组织</span>
              <el-input class="reg-input" v-model.trim="orgName" placeholder="输入您的所在组织" />
            </div>
            <div class="reg-from-item" v-if="userType == '1'">
              <!-- <span class="red-text">*</span> -->
              <span class="regfrom-name">所在部门</span>
              <el-input class="reg-input" v-model.trim="deptName" placeholder="输入您的所在部门" />
            </div>
            <div class="reg-from-item" v-if="userType == '1'">
              <span class="regfrom-name">职位信息</span>
              <el-input class="reg-input" v-model.trim="job" placeholder="输入您的职位信息" />
            </div>
            <div class="reg-from-item" v-if="userType == '1'">
              <span class="regfrom-name">职   称</span>
              <el-input class="reg-input" v-model.trim="professional" placeholder="输入您的职称" />
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>邮箱地址</span>
              <el-input class="reg-input" v-model.trim="email" placeholder="输入您的邮箱地址" />
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>密码</span>
              <el-input class="reg-input" show-password  
                @focus="handleShowPassnote" 
                v-model="password" 
                placeholder="输入密码" 
                @change="checkPass($event)"
                @blur="isShowPassNote = false"
              />
              <!-- <i class="reg-wanning el-icon-warning-outline" ></i> -->
              <div class="pass-note" v-show="isShowPassNote">
                <p aria-colcount="note-par note-success">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[0]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  最小字符数：8 
                </p>
                <p aria-colcount="note-par note-wanning">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[1]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个大写字母
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[2]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个小写字母
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[3]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个数字
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[4]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  <!-- <img src="../../assets/images/pass-note.png" class="pass-note-icon"/> -->
                  包含特殊字符
                </p>
              </div>
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>重复密码</span>
              <el-input class="reg-input" show-password  v-model="notPassword" placeholder="再次输入密码" />
              <!-- <i class="reg-wanning el-icon-warning-outline"></i> -->
            </div>
          </div>
          <div class="reg-term">
            <el-checkbox label="" v-model="acceptRadio">我接受<span class="link-color"> 使用条款</span> 和<span class="link-color"> 隐私政策</span></el-checkbox>
          </div>
        </div>
        <!-- 个人账号基础信息 end-->
        <!-- 验证码 -->
        <div v-if="regOrder === 2"> 
          <div class="reg-title">输入 短信 验证码</div>
          <div class="reg-par">我们已将验证码发送至您提供的联系方式中。输入您收到的代码以继续注册。</div>
          <div class="reg-phone">
            <p class="reg-tellnum">+86 {{mobilePhone}}</p>
            <div class="phone-nums">
              <input 
                type="text" 
                v-for="(mess, index) in messageList" 
                :key="index" 
                :value="mess"
                @blur="enterCode($event,index)"
                @keyup="keyboard($event, index)" 
                @keydown="expurgate(index)"
                class="phone-num" 
              />
            </div>
            <p>还没有收到您的号码吗？<span class="link-color" @click="getPhoneCode">{{waitText}}</span></p>
          </div>
        </div>
        <!-- 验证码 end -->
        <!-- 资质 -->
        <div v-if="regOrder === 3 && userType === '1'"> 
          <div class="reg-title">补充您的 资质 信息</div>
          <div class="reg-par">请上传您获取的相关资质信息证明</div>
          <div class="credential">
            <div class="cred-item" :class="{'cred-item-active':qualification === '心理咨询' }" @click="handleQualify('心理咨询')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              心理咨询
            </div>
            <div class="cred-item" :class="{'cred-item-active':qualification === '营养师' }" @click="handleQualify('营养师')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              营养师
            </div>
            <div class="cred-item" :class="{'cred-item-active':qualification === '健康管理师' }" @click="handleQualify('健康管理师')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              健康管理师
            </div>
            <div class="cred-item" :class="{'cred-item-active':qualification === '职业医师' }" @click="handleQualify('职业医师')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              职业医师
            </div>
            <div class="cred-item" :class="{'cred-item-active':qualification === '护士' }" @click="handleQualify('护士')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              护士
            </div>
            <div class="cred-item" :class="{'cred-item-active':qualification === '其它' }" @click="handleQualify('其它')">
              <span class="cred-icon cred-icon-cricle"></span>
              <img src="../../assets/images/gou.png" class="cred-icon cred-gou"/>
              其它
            </div>
          </div>
          <div class="upload-con">
            <span class="upload-name">{{qualification}}资质</span>
            <div class="cred-upload" v-if="!qualificationImgUrl">
              <el-upload
                class="upload-demo"
                drag
                :http-request="handleUpload"
                ref="upload" 
                action="customize"
                accept=".jpg,.png,.bmp,.gif"
                :before-upload="uploadQual"
                :show-file-list="false"
                >
                <img src="../../assets/images/upload.png" class="upload-img "/>
                <p class="link-color">拖拽文件或选择上传你文件</p>
              </el-upload>
            </div>
            <div class="qualif-img" v-else>
              <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteQualif"/>
              <img :src="qualificationImgUrl" class="qualif-image" />
            </div>
          </div>
        </div>   
        <!-- 资质end -->
        <!-- 企业单位 资质 -->
        <div v-if="regOrder === 3 && (userType === '2' || userType === '3')"> 
          <div class="reg-title">完善 资质 信息</div>
          <div class="reg-par">请上传您获取的相关资质信息证明</div>
          <div class="reg-from">
            <div class="reg-from-item no-flex">
              <span class="regfrom-name" ><span class="red-text">*</span>用户名</span>
              <el-input class="reg-input" v-model.trim="userName" placeholder="输入用户名" />
              <p class="reg-note">体现企业特性的用户名称。不合规用户名称无法通过审核。</p>
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name" ><span class="red-text">*</span>组织类型</span>
              <el-select v-model="orgType" class="reg-input" placeholder="请选择">
                <el-option label="公司企业" value="公司企业"></el-option>
                <el-option label="事业单位" value="事业单位"></el-option>
              </el-select>
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>组织名称</span>
              <el-input class="reg-input" v-model.trim="orgName" placeholder="输入您所在企业、机构名称" />
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>执照资质</span>
              <div class="cred-upload" v-if="!license">
                <el-upload
                  class="upload-demo"
                  drag
                  :http-request="handleUpload"
                  ref="upload" 
                  action="customize"
                  accept=".jpg,.png,.bmp,.gif"
                  :before-upload="uploadLicense"
                  :show-file-list="false"
                  >
                  <img src="../../assets/images/upload.png" class="upload-img "/>
                  <p class="link-color">拖拽文件或选择上传你文件</p>
                </el-upload>
              </div>
              <div class="qualif-img" v-else>
                <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteLicense"/>
                <img :src="license" class="qualif-image" />
              </div>
              <div class="cred-upload-par">
                上传企业或机构的执照与资质证明复印件盖鲜章，如企业营业执照、医疗机构执业许可证等。
              </div>
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name"><span class="red-text">*</span>申请公函</span>
              <div class="cred-upload" v-if="!officialLetter">
                <el-upload
                  class="upload-demo"
                  drag
                  :http-request="handleUpload"
                  ref="upload" 
                  action="customize"
                  accept=".jpg,.png,.bmp,.gif"
                  :before-upload="uploadOfficialLetter"
                  :show-file-list="false"
                  >
                  <img src="../../assets/images/upload.png" class="upload-img "/>
                  <p class="link-color">拖拽文件或选择上传你文件</p>
                </el-upload>
              </div>
              <div class="qualif-img" v-else>
                <img src="../../assets/images/close.png" class="close-qualif" @click="handleDeleteOfficialLetter"/>
                <img :src="officialLetter" class="qualif-image" />
              </div>
              <div class="cred-upload-par">
                下载填写《申请公函》，并加盖公章。上传清晰无残缺加盖公章的公函扫描件。<span class="green-text">查看示例</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 完成 -->
        <div v-if="regOrder === 4"> 
          <div class="reg-title">帐户已创建，您将在几天内收到审核信息</div>
          <div class="reg-par">您现在可以将 乐咕颊囊 用于医疗保健实体！</div>
          <div class="computed-con">
            <img src="../../assets/images/computed.png" class="computed-img" />
          </div>
          <div class="coput-par">
            <p>感谢您创建帐户！我们目前正在为您审核。我们将在接下来的几天内将其审核结果发送到您的电子邮件。</p>
            <p>如果您有任何疑问，请访问我们的帮助中心 pomoc.legujk.com</p>
          </div>
        </div>
        <!-- 完成end -->
        <div class="base-btns" v-if="!isModify">
          <span class="base-btn-quest">已有账户？ <span class="link-color" @click="toLogin">登陆</span></span>
          <span class="base-btn glay-btn" v-if="regOrder !== 4" @click="returnHome">返回主页</span>
          <span class="base-btn color-btn" v-if="regOrder === 1" @click="confirmReg">确定</span>
          <span class="base-btn color-btn" v-else-if="regOrder === 4" @click="toLogin">进入系统</span>
          <span class="base-btn color-btn" v-else @click="confirmReg">继续</span>
        </div>
        <!-- 更改认证 -->
        <div v-else>
          <span class="base-btn color-btn"  @click="modifyAuth">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  reqRegPhoneCode,
  reqRegCode,
  regFromUpload,
  regSubmit,
  regUserInfo,
  reqEditPersonalAuthInfo,
  reqEditOrgAuthInfo
} from '../../api/index'
import { maxFileSize } from '../../utils/variate'
import { getImageBase64, validateIDCard } from '../../utils/index'
export default {
   data () {
    return {
      maxFileSize,
      regOrder: 1, // 当前注册进度
      isShowPassNote: false,
      passCheckArr: [false,false,false,false,false],
      acceptRadio: false,
      name: '',
      identityCardno: '',
      mobilePhone: '',
      orgName: '',
      deptName: '',
      email: '',
      password: '',
      notPassword: '',
      job: '',
      professional: '',
      license: '',  // 企业机构 执照
      officialLetter: '', // 企业机构 公函
      orgType: '', // 企业机构 组织类型
      qualification: '', // 个人 资质证明
      qualificationImgUrl: '', // (针对个人的注册)资质证明
      userName: '', // 机构
      userType: '', //注册用户的类型: 1 个人/ 2 企业/ 3 医疗机构
      messageList: ['','','','','',''],
      identityFrontImg: '', // 身份证正面
      identityBackImg:'', // 身份证反面
      isModify: undefined, // 是否为更改认证
      waiting: false, // 等待验证码中
      waitText: '再发一次', // 验证码倒计时
    }
  },
  mounted(){
    console.log('this.$route.params',this.$route.params)
    this.isModify = this.$route.params.isModify
    if(this.$route.params && this.$route.params.type){
      this.userType = this.$route.params.type
    }else{
      this.$router.push({ path: '/seletReg'})
    }
    if(this.isModify){
      this.regOrder = 3
      this.getUserInfo()
    }
  },
  methods:{
    // 键盘松开事件
    keyboard(e, index) {
      let domNode = document.getElementsByClassName("phone-num"),
        currInput = domNode[index],
        nextInput = domNode[index + 1],
        lastInput = domNode[index - 1];
      if (e.keyCode != 8) {
        if (index < this.messageList.length - 1) {
          nextInput.focus();
        } else {
          currInput.blur();
        }
      } else {
        if (index != 0) {
          lastInput.focus();
        }
      }
    },
    // 输入手机验证码
    enterCode(e,i){
      console.log('e',e.target.value,this.messageList)
      this.messageList[i] = e.target.value
    },
    // 键盘按下触发
    expurgate(index) {
      this.messageList[index] = "";
    },
    // 获取手机验证码
    async getPhoneCode(){
      if(this.waiting){
        return false
      }
      console.log('getPhoneCode')
      await reqRegPhoneCode({
        mobilePhone: this.mobilePhone
      }).then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          this.regOrder = 2
          let timeLeft = 60
          this.waiting = true;
          // 更新按钮上的文字
          this.waitText = timeLeft + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft--;
        
            // 更新按钮上的文字
            this.waitText = timeLeft + 's后再次获取';
        
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft === 0) {
              clearInterval(timer);
              this.waiting = false;
              this.waitText = '获取验证码';
            }
          }, 1000);
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取手机验证码失败，' + res.msg,
          });
        }
      })
    },
    // 获取临时token
    getTempToken(){
      const code = this.messageList.join("")
      console.log('code',code)
      reqRegCode({
        mobilePhone: this.mobilePhone,
        verfiyCode: code
      }).then(res => {
        if(res.code === 0){
          this.tempToken = res.data.tempToken
          this.regOrder = 3
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取临时token失败，请联调管理员' + res.msg,
          });
          
        }
      })
    },
    // 去注册
    toRegister(){
      let params = {}
      if(this.userType === 1){ // 个人
        if(!this.orgName){
          this.$message({
            message: '未设置组织',
            type: 'warning'
          }); 
          return false; 
        }
        if(!this.orgName){
          this.$message({
            message: '未设置部门',
            type: 'warning'
          }); 
          return false; 
        }
        params = {
          name: this.name,
          identityCardno: this.identityCardno,
          mobilePhone: this.mobilePhone,
          orgName: this.orgName,
          email: this.email,
          password: this.$md5(this.password).toUpperCase() ,
          tempToken: this.tempToken,
          qualification: this.qualification, // 个人 资质证明
          qualificationImgUrl: this.qualificationImgUrl,
          identityFrontImg: this.identityFrontImg,
          identityBackImg: this.identityBackImg,
          job: this.job,
          deptName: this.deptName,
          professional: this.professional,
          userType: this.userType //注册用户的类型: 1 个人/ 2 企业/ 3 医疗机构
        }
      }else{ // 企业 单位
        
        params = {
          name: this.name,
          identityCardno: this.identityCardno,
          mobilePhone: this.mobilePhone,
          orgName: this.orgName,
          email: this.email,
          password: this.$md5(this.password).toUpperCase() ,
          tempToken: this.tempToken,
          identityFrontImg: this.identityFrontImg,
          identityBackImg: this.identityBackImg,
          license: this.license,  // 企业机构 执照
          officialLetter: this.officialLetter, // 企业机构 公函
          orgType: this.orgType, // 企业机构 组织类型
          //qualification: this.qualification, // 个人 资质证明
          userName: this.userName, // 机构
          userType: this.userType //注册用户的类型: 1 个人/ 2 企业/ 3 医疗机构
        }
      }
      
      regSubmit(params).then(res => {
        if(res.code === 0){
          this.regOrder = 4
        }else{
          this.$notify.error({
            title: '错误',
            message: '注册失败，'+ res.msg
          });
          
        }
      })
    },
    // 显示密码提示
    handleShowPassnote(){
      this.isShowPassNote = true
    },
    // 判断是否符合规范
    checkPass(val){
      if(val){
        if(val.length >=8) {
          this.passCheckArr[0] = true
        }else{
          this.passCheckArr[0] = false
        }
        if (/^(?=.*[A-Z])/.test(val)) {
          this.passCheckArr[1] = true
        }else{
          this.passCheckArr[1] = false
        }
        if (/^(?=.*[a-z])/.test(val)) {
          this.passCheckArr[2] = true
        }else{
          this.passCheckArr[2] = false
        }
        if (/^(?=.*\d)/.test(val)) {
          this.passCheckArr[3] = true
        }else{
          this.passCheckArr[3] = false
        }
        if (/^(?=.*[!@#$%^&*])/.test(val)) {
          this.passCheckArr[4] = true
        }else{
          this.passCheckArr[4] = false
        }
      }else{
        this.passCheckArr = [false,false,false,false,false]
      }
      
    },
    // 去登录
    toLogin(){
      this.$router.push({ path: '/login'})
    },
    // 去首页
    toLogin(){
      this.$router.push({ path: '/login'})
    },
    // 确定注册
    confirmReg(){
      console.log('this.regOrder',this.regOrder)
      if(this.regOrder === 1){
        if(!this.name){
          this.$message({
            message: '未设置姓名',
            type: 'warning'
          }); 
          return false; 
        }
        if(!this.identityCardno){
          this.$message({
            message: '未设置身份证号',
            type: 'warning'
          }); 
          return false; 
        }
        if( this.identityCardno && this.identityCardno.length != 18){
          this.$message({
            message: '身份证长度不对',
            type: 'warning'
          }); 
          return false; 
        }
        // if( this.identityCardno && !validateIDCard(this.identityCardno)){
        //   this.$message({
        //     message: '身份证格式不对',
        //     type: 'warning'
        //   }); 
        //   return false; 
        // }
        if(!this.identityFrontImg){
          this.$message({
            message: '未上传身份证人像面',
            type: 'warning'
          }); 
          return false; 
        }
        if(!this.identityBackImg){
          this.$message({
            message: '未上传身份证国徽面',
            type: 'warning'
          }); 
          return false; 
        }
        if(!this.mobilePhone ){
          this.$message({
            message: '未设置手机号码',
            type: 'warning'
          }); 
          return false; 
        }
        if(!(/^1[3456789]\d{9}$/.test(this.mobilePhone))){ 
          this.$message({
            message: '手机号码格式错误',
            type: 'warning'
          }); 
          return false; 
        }
        if (this.email.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) === -1){
          this.$message({
            message: '邮箱格式错误',
            type: 'warning'
          }); 
          return false; 
        }
        if(!this.password){
          this.$message({
            message: '未设置密码',
            type: 'warning'
          }); 
          return false; 
        }
        if(this.password != this.notPassword){
          this.$message({
            message: '密码与确认密码不一致',
            type: 'warning'
          });
          return false; 
        }
        console.log('this.passCheckArr',this.passCheckArr)
        if(this.passCheckArr.filter(item => item === true).length < 5){
          this.$message({
            message: '密码格式不正确',
            type: 'warning'
          });
          return false; 
        }
        if(!this.acceptRadio){
          this.$message({
            message: '请先确认使用条款和隐私政策',
            type: 'warning'
          }); 
          return false; 
        }
                
        this.getPhoneCode()
      }
      if(this.regOrder === 2){
        const code = this.messageList.join("")
        if(code.length < 6){
          this.$message({
            message: '请输入验证码',
            type: 'warning'
          });
          return false
        }
        this.getTempToken()
      }
      if(this.regOrder === 3){
        if(this.userType === '2' || this.userType === '3'){
          if(!this.userName){
            this.$message({
              message: '未设置用户名',
              type: 'warning'
            }); 
            return false; 
          }
          if(!this.orgType){
            this.$message({
              message: '未设置组织类型',
              type: 'warning'
            }); 
            return false; 
          }
          if(!this.orgName){
            this.$message({
              message: '未设置组织名称',
              type: 'warning'
            }); 
            return false; 
          } 
          if(!this.officialLetter){
            this.$message({
              message: '未上传申请公函',
              type: 'warning'
            }); 
            return false; 
          }
          if(!this.license){
            this.$message({
              message: '未上传执照资质',
              type: 'warning'
            }); 
            return false; 
          }
        }else{
          if(!this.qualificationImgUrl || !this.qualification ){
            this.$message({
              message: '资质或资质图片缺失！',
              type: 'warning'
            });
            return false
          }
        }
        this.toRegister()
      }
      if(this.regOrder === 4){
        
      }
    },
    // 返回主页
    returnHome(){
      this.regOrder = 1
      this.$router.push({ path: '/seletReg'})
    },
    handleQualify(name){
      if(name === this.qualification){
        return false
      }
      this.qualification = name
      this.qualificationImgUrl = ''
    },
    handleUpload(){},
    // 身份证正面
    uploadIdentyZM(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.identityFrontImg = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 身份证反面
    uploadIdentyFM(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.identityBackImg = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 上传资质
    uploadQual (file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.qualificationImgUrl = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 执照
    uploadLicense(file){
      console.log('file',file,this.maxFileSize)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.license = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    uploadOfficialLetter(file){
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.officialLetter = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 删除掉上除的资质图片
    handleDeleteQualif(){
      this.qualificationImgUrl = ''
    },
    handleDeleteLicense(){
      this.license = ''
    },
    handleDeleteZM(){
      this.identityFrontImg = ''
    },
    handleDeleteFM(){
      this.identityBackImg = ''
    },
    handleDeleteOfficialLetter(){
      this.officialLetter = ''
    },
    // 获取个人信息
    getUserInfo(){
      console.log('this.userType',this.userType)
      regUserInfo({}).then(res => {
        if(res.code === 0){
          if(this.userType == 1){ // 个人
            this.qualification = res.data.qualification
            this.qualificationImgUrl = res.data.qualificationImgUrl
            console.log('qualification',this.qualification)
          }else{
            this.orgType = res.data.orgInfo.orgType
            this.orgName = res.data.orgInfo.orgName
            this.officialLetter = res.data.orgInfo.officialLetter
            this.license = res.data.orgInfo.license
            this.userName = res.data.adminInfo.name
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取基础信息失败！'+ res.msg
          });
          
        }
      })
    },
    // 更改认证
    modifyAuth(){
      console.log('this.userType更改认证',this.userType)
      if(this.userType == 1){ // 个人
        reqEditPersonalAuthInfo({
          qualification:this.qualification,
          qualificationImgUrl: this.qualificationImgUrl,
          tempToken: sessionStorage.getItem('tempToken')
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.$router.push({ path: `/system/1` })
          }else{
            this.$notify.error({
              title: '错误',
              message: '更改认证失败！' + res.msg
            });
          }
        })
      }else{
        reqEditOrgAuthInfo({
          orgType: this.orgType, 
          orgName: this.orgName, 
          officialLetter: this.officialLetter, 
          license: this.license,
          tempToken: sessionStorage.getItem('tempToken'),
          userName: this.userName
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.$router.push({ path: `/system/1` })
          }else{
            this.$notify.error({
              title: '错误',
              message: '更改认证失败！' + res.msg
            });
          }
        })
      }
    }
  },
  watch:{
    password(val){
      this.checkPass(val)
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.regiter
  display flex
  height 100%
  .reg-left
    width 40%
    height 100%
    background #EDF0F5
    position relative
    .reg-logo
      display inline-block
      margin 40% 0 0 30%
      color #009375
      font-size 32px
      font-weight bold
    .reg-copy
      width 100%
      position absolute
      bottom 100px
      text-align center
  .reg-main
    flex 1
    overflow-y auto
    .reg-con
      margin 50px 100px
      .reg-progess
        margin-bottom 40px
        .reg-progess-item
          display inline-block
          width 120px
          height 5px
          background #eee
          margin-right 20px
        .reg-progess-item-active
          background #009375
      .reg-title
        font-size 26px
        font-weight 400
        margin-bottom 15px
      .reg-par
        color #9E9E9E
        margin-bottom 25px
      .reg-from-item
        display flex 
        margin-bottom 25px
        position relative
        align-items center
        .regfrom-name
          display inline-block
          width 92px
        .reg-input
          width 433px
          height 40px
          line-height 40px
        .reg-note
          text-indent 110px
          color #9E9E9E
          font-size 12px
          padding-top 11px
        .reg-tell
          position relative
          &::before
            content: "+86"
            display inline-block
            width 80px
            height 40px
            text-align center
            border-right 1px solid #ccc
            position absolute
          >>>.el-input__inner
            text-indent 100px
        .reg-wanning
          position absolute
          left 530px
          top 16px
          color #ccc
          z-index 1
          cursor pointer
        .pass-note
          display inline-block
          min-width  150px
          height 130px
          line-height 22px
          border 1px solid #CED4DA
          border-radius 5px
          padding 10px
          position absolute
          left 420px
          top -120px
          background #fff
          color #9e9e9e
          font-size 12px
          .note-par
            .pass-note-icon
              margin-right 7px
          .note-success
            color #009375
          .note-wanning
            color #FF630F
        .cred-upload
          display inline-block
          width 192px
          height 101px
          border-radius 5px
          background #FFFFFF
          text-align center
          >>>.el-upload-dragger
            display inline-block
            width 192px
            height 101px 
          .upload-img
            margin 20px auto
        .qualif-img
          position relative
          margin-right 50px
          .qualif-image
            max-width 200px
            max-height 100px
          .close-qualif
            position absolute 
            width 20px
            top -8px
            right -8px
            background #fcfcfc
        .cred-upload-par
          display inline-block
          width 180px
          margin-left 20px
          font-family Microsoft YaHei
          font-size 12px
          font-weight normal
          line-height 20px
          color #9E9E9E
        .reg-from-ident
          margin-right 20px
          >>>.el-upload-dragger
            width 150px!important
            height 100px!important
            display flex
            flex-direction column
            .upload-img
              width 40px
              margin 20px auto
      .reg-from-hasimg
        padding-left 89px
      .no-flex
        display inline-block
      .reg-term
        color #4a4a4a
        margin 15px 0 60px
      .base-btns
        position relative
        text-align center
        .base-btn-quest
          position absolute
          left 0
          cursor pointer
        .base-btn
          margin 0 20px
      .reg-phone
        margin-bottom 150px
        .reg-tellnum
          margin 50px 0 22px
          font-size 18px
      .phone-nums
        display block
        margin-bottom 60px
        .phone-num
          width 50px
          height 70px
          border-radius 5px
          background #EDF0F5
          border 1px solid #9E9E9E
          margin-right 25px
          text-align center
          font-size 30px
          font-weight bold
          color #4a4a4a
      .credential
        margin-bottom 45px
        .cred-item
          display inline-block
          padding 0 10px
          height 40px
          line-height 40px
          border: 1px solid #9E9E9E;
          border-radius 5px
          color #9E9E9E
          margin 0  25px 25px 0
          cursor pointer
          .cred-icon
            display inline-block
            margin-right 14px
          .cred-icon-cricle
            width 10px
            height 10px
            border-radius 50%
            border 1px solid #9E9E9E
          .cred-gou
            display none
        .cred-item-active
          color #fff
          background #009375
          border-color #009375
          .cred-gou
            display inline-block
          .cred-icon-cricle
            display none
      .upload-con
        width 515px
        border-bottom 1px solid #d8d8d8
        padding-bottom 20px
        margin-bottom 100px
        display flex
        .upload-name
          display inline
          font-size 16px
          font-weight bold
          margin-right 20px
        .cred-upload
          width 192px
          height 101px
          border-radius 5px
          background #FFFFFF
          text-align center
          >>>.el-upload-dragger
            display inline-block
            width 192px
            height 101px 
          .upload-img
            margin 20px auto
        .qualif-img
          position relative
          .qualif-image
            max-width 200px
            max-height 100px
          .close-qualif
            position absolute 
            width 20px
            top -8px
            right -8px
            background #fcfcfc
      .computed-con
        margin 55px 0 100px 0
        .computed-img
          margin-left 200px
      .coput-par
        margin-bottom 90px
        font-size 16px
        p
          margin-top 20px
      
</style>