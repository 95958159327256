<template>
  <div class="follow-drawer">
    <div class="drawer-top">
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">计划名称</span>
          <span  class="drawer-put">{{name}}</span> 
        </div>
      </div>
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">所属分组</span>
          <span  class="drawer-put">{{groupName}}</span> 
        </div>
      </div>
      <!-- <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">随访方式</span>
          <span  class="drawer-put">{{name}}</span> 
        </div>
      </div> -->
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">开始时间</span>
          <span  class="drawer-put">{{startWayVal}}</span>
        </div>
      </div>
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">随访类型</span>
          <span  class="drawer-put">{{typeName}}</span> 
        </div>
      </div>
    </div>
    <div class="follow-detail">
      <div class="follow-det-con">
        <div class=follow-eds>
          <img src="../../assets/images/follow_line.png" class="follow-line-img" />
          <!-- follow-eds-item -->
          <template v-if="type === 'stage_visit'">
            <div class="follow-eds-item" v-for="(item,i) in planTypeDtoList" :key="i">
              <div class="follow-eds-left">
                <div class="follow-eds-name">阶段{{i+1}}</div>
                <div class="follow-eds-cir">
                  <span class="follow-eds-cricle"></span>
                  <span class="follow-des-line"></span>
                </div>
              </div>
              <div class="follow-eds-right">
                <div class="eds-right-con">
                  <div class="eds-item">
                    <div class="eds-flex">
                      <span class="eds-name">开始时间：</span>
                      <div v-for="time in startTimes">
                        <div class="eds-text" v-if="time.code === item.startWay">{{time.name}}</div>
                      </div>
                    </div>
                    <div class="eds-flex">
                      <span class="eds-name">阶段周期：</span>
                      <div class="eds-text">{{item.stageCycle}}周</div>
                    </div>
                  </div>
                  <div class="eds-item">
                    <div class="eds-flex">
                      <span class="eds-name">频   率：</span>
                      <div v-for="time in frequencyList">
                        <div class="eds-text" v-if="time.code === item.frequency">{{time.name}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="eds-item">
                    <div class="eds-flex">
                      <span class="eds-name">阶段描述：</span>
                      <div class="eds-text">{{item.comment}}</div>
                    </div>
                  </div>
                </div>
                <div class="eds-right-blue">
                  <div class="eds-blue-title">随访内容</div>
                  <div class="eds-blue-con">
                    <div class="eds-blue-item" v-for="(plan,planIdx) in item.planContentDtoList" :key="planIdx">
                      <span class="blue-item-one">{{planIdx+1}}</span>
                      <span class="blue-item-one" v-if="plan.type ==='remind'">提醒</span>
                      <span class="blue-item-one" v-else-if="plan.type ==='scale'">量表</span>
                      <span class="blue-item-one" v-else-if="plan.type ==='questionnaire'">问卷</span>
                      <span class="blue-item-one blue-item-more" v-if="plan.type ==='remind'">{{plan.planRemindDto.content}}</span>
                      <span class="blue-item-one blue-item-more" v-else>{{plan.contentName}}</span>
                      <span class="blue-item-one">{{plan.times}}次</span>
                    </div>
                    <!-- <div class="eds-blue-item">
                      <span class="blue-item-one">1</span>
                      <span class="blue-item-one">量表</span>
                      <span class="blue-item-one blue-item-more">心理健康测评量表</span>
                      <span class="blue-item-one">1次</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="follow-eds-item" v-for="(item,i) in planTypeDtoList" :key="i">
              <div class="follow-eds-left">
                <div class="follow-eds-name">第{{item.whichDay}}天</div>
                <div class="follow-eds-cir">
                  <span class="follow-eds-cricle"></span>
                  <span class="follow-des-line"></span>
                </div>
              </div>
              <div class="follow-eds-right">
                <div class="eds-right-blue">
                  <div class="eds-blue-title">随访内容</div>
                  <div class="eds-blue-con">
                    <div class="eds-blue-item" v-for="(plan,planIdx) in item.planContentDtoList" :key="planIdx">
                      <span class="blue-item-one">{{planIdx+1}}</span>
                      <span class="blue-item-one" v-if="plan.type ==='remind'">提醒</span>
                      <span class="blue-item-one" v-else-if="plan.type ==='scale'">量表</span>
                      <span class="blue-item-one" v-else-if="plan.type ==='questionnaire'">问卷</span>
                      <span class="blue-item-one blue-item-more" v-if="plan.type ==='remind'">
                        <span v-for="time in remindTypes">
                          <template v-if="time.code === plan.planRemindDto.type">{{time.name}}</template>
                        </span>
                      </span>
                      <span class="blue-item-one blue-item-more" v-else>{{plan.contentName}}</span>
                      <span class="blue-item-one">{{plan.times}}次</span>
                    </div>
                    <!-- <div class="eds-blue-item">
                      <span class="blue-item-one">1</span>
                      <span class="blue-item-one">量表</span>
                      <span class="blue-item-one blue-item-more">心理健康测评量表</span>
                      <span class="blue-item-one">1次</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  reqConstants
} from '../../api/index'
export default {
  props:{
    planDetail: Object,
  },
  data () {
    return {
      startTimes: [],
      frequencyList: [],
      remindTypes: [],
      name: '',
      groupName: '',
      type: '', // 阶段随访
      typeName: '',
      startWayVal: '',
      startWay: '',
      planTypeDtoList: []
    }
  },
  mounted(){
    console.log('planDetail',this.planDetail)
    this.setData()
    this.startParams()
    
  },
  
  methods:{
    // 初始化下拉列表
    startParams(){
      //this.setData()
      reqConstants('StageStartWayEnum').then(res => {
        console.log('res',res,this.startWay)
        if(res && res.code === 0){
          this.startTimes = res.data
          for(let i=0; i<this.startTimes.length; i++){
            if(this.startTimes[i].code == this.startWay){
              this.startWayVal = this.startTimes[i].name
            }
          }
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      // 阶段频率
      reqConstants('StageFrequencyEnum').then(res => {
        console.log('StageFrequencyEnum',res)
        if(res && res.code === 0){
          this.frequencyList = res.data
        }else{
          this.$message({
            message: '获取随访阶段频率失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      reqConstants('RemindTypeEnum').then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          this.remindTypes = res.data
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    setData(){
      this.name = this.planDetail.name
      this.groupName = this.planDetail.groupName
      this.type = this.planDetail.type
      if(this.type === 'time_visit'){
        this.typeName = "时间线随防"
      }else{
        this.typeName = "阶段随访"
      }
      this.startWay = this.planDetail.startWay
      this.planTypeDtoList = this.planDetail.planTypeDtoList
    }
  },
  watch:{
    planDetail(){
      this.setData()
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.follow-drawer
  width 614px
  .drawer-top
    padding 35px
    font-size 16px
    .drawer-item
      display flex
      align-items center
      .drawer-it
        flex 1
        display flex
        margin-bottom 14px
        height 46px
        line-height 46px
        .drawer-title
          width 100px
          color #050919
        .drawer-put
          width 456px
          padding-left 20px
          color #1F1F1F
          border-radius 8px
          opacity 1
          background #EFF0F6
  .follow-detail
    .follow-det-con
      padding 10px 35px 10px 25px
      .follow-eds
        position relative
        .follow-line-img
          position absolute
          top 0
          width 1px
          left 10px
          height 100%
        .follow-eds-item
          display flex
          margin-bottom 33px
          .follow-eds-left
            width 82px
            .follow-eds-name
              font-size 14px
              margin-bottom 14px
            .follow-eds-cir
              display flex 
              align-items center
              .follow-eds-cricle
                display inline-block
                width 20px
                height 20px
                opacity 1
                border-radius 50%
                background #fff
                box-sizing border-box
                border 6px solid #009375
                box-shadow 0px 4px 6px 0px rgba(23, 39, 117, 0.2)
                position relative
                z-index 2
              .follow-des-line
                width 50px
                height 4px
                border-radius 0 4px 4px 0
                background #009375
          .follow-eds-right
            flex 1
            border-radius 5px
            border 1px solid #D0E6E6
            padding-top 10px
            .eds-right-con
              padding-top 10px
              font-size 14px
              .eds-item
                display flex
                margin-bottom 11px
                line-height 18px
                .eds-flex
                  display flex
                  flex 1
                  .eds-name
                    width 100px!important
                    margin-right 20px
                    text-align right
                    color #050919
                  .eds-text
                    color #1F1F1F
                    word-wrap normal
            .eds-right-blue
              border-radius 5px
              background #E0F2EE
              padding 10px 16px
              margin-top 5px
              .eds-blue-title
                font-size 16px
                color #050919
                line-height 24px
              .eds-blue-con
                background #E0F2EE
                .eds-blue-item
                  padding 10px 5px
                  text-align center
                  line-height 18px
                  font-size 14px
                  color #1F1F1F
                  display flex
                  background #E0F2EE
                  border-radius 5px
                  .blue-item-one
                    width 50px
                  .blue-item-more
                    flex 1
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                  &:nth-of-type(even)
                    background #fff
</style>