<template>
  <div class="assess-manages" >
    <!-- :class="{'assess-manages-short': !showConfig}" -->
    <span 
      class="assess-item" 
      :class="{ 'assess-item-active' : (routeLink === 'accessDetail' || routeLink === 'accessAudit'|| routeLink === 'accessManage')}" 
      @click="toLink('accessManage')"
    >测评报告</span>
    <span 
      class="assess-item" 
      :class="{ 'assess-item-active' : (routeLink === 'accesScale' || routeLink === 'accessGroupManage')}"   @click="toLink('accesScale')"
    >量表管理</span>
    <span 
      
      class="assess-item" 
      :class="{ 'assess-item-active' : routeLink === 'accessConfiger'}" 
      @click="toLink('accessConfiger')"
    >应用配置</span>
    <!-- v-has="'mgr.moduleConfig.query'" -->
  </div>
</template>

<script>
import { powerRight } from '../../utils/index'
export default {
  props:{
    routeLink: String
  },
  data () {
    return {
      showConfig:false
    }
    
  },
  mounted(){
    if(powerRight('mgr.patient.query')){
      this.showConfig = true
    }
  },
  methods:{
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess-manages
  height 46px
  width 367px
  line-height 46px
  box-sizing border-box
  border 1px solid #E8E8E8
  display flex
  justify-content space-between
  border-radius 25px
  margin 0 auto 0
  .assess-item
    display inline-block
    width 100px
    height 40px
    line-height 40px
    text-align center
    margin  3px
    font-size 14px
    cursor pointer
  .assess-item-active
    border-radius 25px
    background #E0F2EE
    color #009375
    font-weight  bold
.assess-manages-short
  width 233px!important
</style>