<template>
  <div class="assess">
    <follow-nav routeLink="questionFromList"></follow-nav>
    <div class="form-list">
      <div class="list-left" v-has="'mgr.visitGeneration.query'">
        <div class="left-top">问卷</div>
        <div class="lists-temps" v-has="'mgr.visitGeneration.query'">
          <!-- temps-item -->
          <div class="temps-item" @click="selectQuest(item)" v-for="(item,index) in QuestionList" :key="index">
            <div class="temps-item-con" :class="{'temps-item-active': item.id == quId}">
              <div class="temps-title">
                <div class="temps-title-par">{{item.questionnaireName}}</div>
                <el-popover
                  popper-class="table-popover"
                  placement="bottom"
       
                  style="padding:0;min-width:80px!important;font-size:12px;height:12px"
                  trigger="click">
                  <div class="handle-con" style="line-height:28px;padding:5px 0;font-size:12px;">
                    <div class="handle-popver-item" style="padding:0 3px;cursor:pointer;" v-has="'mgr.visitQrcode.add'" @click="downCode(item)">下载二维码</div>
                    <div class="handle-popver-item" style="padding:0 10px;" @click="brohQuest(item)" v-if="item.questionnaireStatus == '1'">禁用</div>
                    <div class="handle-popver-item" style="padding:0 10px;" @click="brohQuest(item)" v-if="item.questionnaireStatus == '2'">启用</div>
                    <div class="handle-popver-item" style="padding:0 10px;" @click="deleteQuest(item.id)" v-has="'mgr.visitGeneration.delete'">删除</div>
                    <div class="handle-popver-item" style="padding:0 10px;" v-has="'mgr.visitQuestionnaire.query'" @click="seeQuest(item.id)">查看</div>
                    <div class="handle-popver-item"  style="padding:0 10px;" @click="editQuest(item.id)" v-has="'mgr.visitGeneration.edit'">编辑</div> 
                    <!-- v-has="'mgr.visitQuestionnaire.edit'" -->
                  </div>
                  <el-button
                    type="text"
                    style="font-size:22px;letter-spacing:inherit;margin-left:65px;color:#000;position:relative;top:-7px;"
                    class="popover-more table-green-btn" 
                    slot="reference"
                    size="small">
                    ...
                  </el-button>
                </el-popover>
              </div>
              <div class="temps-bot">
                <span class="temps-time">{{item.updateTime}}</span>
                <span class="temps-hand link-color" v-if="item.questionnaireStatus == '1'">启用</span>
                <span class="temps-hand link-color red-link-color" v-if="item.questionnaireStatus == '2'">禁用</span>
              </div>
            </div>
          </div>
         

        </div>
      </div>
      <div class="list-right">
        <div class="manage-search">
          <div class="search-input-out">
            <el-input class="search-input" placeholder="搜索电话、姓名、诊断" />
            <img src="../../assets/images/search.png" class="search-icon" />
          </div>
          <div class="search-right">
            <span class="search-btn" v-has="'mgr.visitQuestionnaire.deleteQuestionnaire'" @click="toAdd"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增问卷</span>
          </div>
        </div>
        <div class="access-con">
          <el-table
            :data="ReportListData"
            class="table-div"
            style="width: 100%"
            height="calc(100vh - 248px)"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              prop="name"
              label="用户名"
              width="280">
            </el-table-column>
            <el-table-column
              prop="questionnaireName"
              label="问卷标题">
            </el-table-column>
            <!-- <el-table-column
              prop="payType"
              label="题数">
              <template slot-scope="scope">
                <template v-if="scope.row.status === 'paid'">付费</template>
                <template v-else>免费</template>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="titleNum"
              label="题数">
            </el-table-column>
            <el-table-column
              prop="numofQuestion"
              label="得分">
            </el-table-column>
            <el-table-column
              prop="answerCost"
              label="答题耗时">
            </el-table-column>
            <el-table-column
              label="操作">
                <template slot-scope="scope">
                  <el-button class="group-link " v-has="'mgr.visitGeneration.query'" @click="seePerQuest(scope.row)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">查看</el-button>
                  <el-button class="group-link " v-has="'mgr.visitGeneration.delete'" @click="deletePerQuest(scope.row)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
          </el-table>
          <div class="pager">
            <!-- @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage4" -->
            <el-pagination
              :current-page="pageNo"
              :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
              :page-size="pageSize"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
              layout="total, sizes, prev, pager, next"
              :total="total">
            </el-pagination>
            </div>
          <!-- 分组 -->
        </div>
      </div>
    </div>
    <el-drawer
    title="问卷详情"
    class="exam-drawer"
    @close="closeDrawer"
    :visible="questionDrawerVisible">
      <question-drawer :questionDetail="questionDetail"
      ></question-drawer>
    </el-drawer>
    <el-drawer
    title="问卷详情"
    class="exam-drawer"
    @close="closePerDrawer"
    v-if="perQuestDetail"
    :visible="questionPerVisible">
      <question-per-drawer  :perQuestDetail="perQuestDetail"></question-per-drawer>
    </el-drawer>
    <question-code-dia v-if="showQcodeDailog" :questionData="questionData" @closeQuestDialog="closeQuestDialog"></question-code-dia>
  </div>
</template>

<script>
import {
  reqAQuestionList,
  reqQuPageByCondition,
  reqDeleteQuestion,
  reqIsBindingOtherSy,
  reqEnableQuestion,
  reqDeleteGenQuestion,
  reqGenQuestDetail,
  reqQuestionDetail
} from '../../api/index'
import { powerRight } from '../../utils/index'
import FollowNav from '@/components/FollowUp/FollowNav'
import QuestionDrawer from '@/components/Access/QuestionDrawer'
import QuestionPerDrawer from '@/components/Access/QuestionPerDrawer'
import QuestionCodeDia from '@/components/Access/QuestionCodeDia' 
export default {
  components: {FollowNav, QuestionDrawer ,QuestionPerDrawer, QuestionCodeDia},
  data () {
    return {
      QuestionList: [],
      ReportListData: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
      quId: null, // 当前问卷
      questionDrawerVisible: false, // 问卷
      questionPerVisible: false,
      perQuestDetail: null, // 做题问卷详情
      questionDetail: null, // 问卷详情
      showQcodeDailog: false,
      questionData: null
    }
    
  },
  mounted(){
    if(powerRight('mgr.visitQuestionnaire.query')){
      this.getLists()
    }
    
  },
  methods:{
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getLists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getLists()
    },
    // 问卷列表
    getLists(){
      reqAQuestionList({
        pageModel:{
          pageNo: 1,
          pageSize: 1000
        }
      }).then(res => {
        if(res && res.code === 0){
          this.QuestionList = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    handleSelectionChange(){

    },
    // 新增问卷
    toAdd(){
      this.$router.push('/questionFrom')
    },
    // 点击问卷
    selectQuest(data){
      if(data){
        this.quId = data.id
        console.log('this.quId',this.quId)
      }
      reqQuPageByCondition({
        visitQuGenSearchDto:{
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          quId: this.quId,
          quName: data.quName
        }
      }).then(res => {
        if(res && res.code === 0){
          this.ReportListData = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 下载问卷二维码
    downCode(data){
      console.log('downCode',data)
      this.questionData = data
      this.showQcodeDailog = true
    },
    // 关闭生成二维码
    closeQuestDialog(){
      this.showQcodeDailog = false
    },
    // 禁启用问卷
    brohQuest(data){
      const status =  data.questionnaireStatus == '1' ? '2' : '1'
      reqEnableQuestion({
        quId:data.id,
        questionnaireStatus: status //1:没有禁用,2:已经禁用
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.getLists()
        }else{
          this.$message({
            message: '操作失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 删除问卷
    deleteQuest(id){
      // 先判断是否绑定到其它系统
      reqIsBindingOtherSy(id).then(res => {
        if(res && res.code === 0){
          if(res.data){
            this.$confirm('此问卷是已经绑定到随访或者其他系统, 是否删除?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.handleDelete(id)
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });          
            });
          }else{
            this.handleDelete(id)
          }
        }else{
          this.$message({
            message: '判断是否绑定到其它系统失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
     
    },
    handleDelete(id){
       reqDeleteQuestion([id]).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.getLists()
        }else{
          this.$message({
            message: '删除失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 查看问卷
    seeQuest(id){
      //this.$router.push({ path: `/QuestionFrom/${id}`})
      reqQuestionDetail(id).then(res => {
        if(res && res.code === 0){
          if(res.data){
            this.questionDetail = res.data
            this.questionDrawerVisible = true
          }
        }else{
          this.$message({
            message: '获取详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      
    },
    // 编辑问卷
    editQuest(id){
      this.$router.push({ path: `/QuestionFrom/${id}`})
    },
    // ----
    // 查看用户做的问卷
    seePerQuest(data){
      reqGenQuestDetail({
        genId:data.id
      }).then(res => {
        if(res && res.code === 0){
          this.perQuestDetail = res.data
          //this.closePerDrawer = true
        }else{
          this.$message({
            message: '获取详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      this.closePerDrawer = true
    },
    // 删除用户做的问卷
    deletePerQuest(data){
      reqDeleteGenQuestion({
        genId:data.id
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.selectQuest()
        }else{
          this.$message({
            message: '删除失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 查看
    seeExam(data){
      this.$router.push({ path: `/QuestionFrom/${data.id}`})
    },
    // 关闭问卷
    closeDrawer(){
      this.questionDrawerVisible = false
    },
    // 关闭个人问卷
    closePerDrawer(){
      this.questionPerVisible = false
    }
  
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .form-list
    display flex
    .list-left
      width 321px
      margin-right 30px
      .left-top
        font-size 18px
        font-weight bold
        padding-bottom 15px
        border-bottom 1px solid #ccc
      .lists-temps
        padding-top 20px
        height calc(100vh - 165px)
        overflow auto
        .temps-item
          padding 8px 0 6px 0
          border-bottom 1px dashed #D8D8D8;
          .temps-item-con
            padding 3px 10px 3px 3px
            cursor pointer
            &:hover
              border-radius 5px
              background #E0F2EE
              .temps-title
                color #009375
          .temps-item-active
            border-radius 5px
            background #E0F2EE
            .temps-title
              color #009375
          .temps-title
            display flex
            line-height 21px
            margin-bottom 8px
            align-items flex-start
            .temps-title-par
              flex 1
              white-space nowrap/* 确保文本在一行内显示 */
              overflow hidden /* 隐藏溢出的内容 */
              text-overflow ellipsis
          .temps-bot
            font-size 14px
            .temps-time
              margin-right 10px
            .link-color
              color #009375
            .red-link-color
              color red
    .list-right
      flex 1
      .manage-search
        margin-bottom 14px
        display flex
        justify-content space-between
        .search-input-out
          display inline-block
          width 320px
          height 40px
          line-height 40px
          margin-right 17px
          position relative
          .search-icon
            position absolute
            right 10px
            top 10px
            cursor pointer
      .access-con
        .table-div
          width 100%
          font-size 14px
          overflow auto
          height calc(100vh - 270px)
          overflow auto
      .search-right
        .search-btn
          display inline-block
          height 40px
          line-height 40px
          padding 0 10px
          border 1px solid #D8D8D8
          border-radius 5px
          margin-left 25px
          align-items center
          cursor pointer
        .search-btn-icon
          margin-right 10px
          position relative
          top 3px
  .exam-drawer
    /deep/.el-drawer__header 
      color #009375
      font-size 16px
      font-weight bold
      padding 20px 0 20px 20px
      position relative
      margin-bottom 5px
      &:after
        content ""
        position absolute
        display inline-block
        width 100%
        height 1px
        bottom 0
        right 0
        background #d8d8d8
      .el-icon-close
        color #4a4a4a
        font-size 16px
</style>