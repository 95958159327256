<template>
  <div class="rule">
    <div class="rule-item" v-for="(item,index) in moduleList" :key="index">
      <span class="rule-title">{{item.configName}}</span>
      <el-switch
        class="applic-switch"
        v-model="item.configValue"
        active-color="#009375"
        active-text="开"
        inactive-text="关"
        active-value="Y"
        inactive-value="N"
        @change="changeConfig($event,item.configCode)"
        inactive-color="#ccc">
      </el-switch>
      <div class="rule-right">
        <p>{{item.configExplain}}</p>
        <p class="green-text"> {{item.configExplain.slice(-6)}} </p>
      </div>
    </div>
    <!-- <div class="rule-item">
      <span class="rule-title">强制更改密码</span>
      <el-switch
        class="applic-switch"
        v-model="switchValue"
        active-color="#009375"
        active-text="开启"
        inactive-text="关闭"
        inactive-color="#ccc">
      </el-switch>
      <div class="rule-right">
        <p>操作人员十五分钟内未做任何操作时，系统会自动锁屏。以保证其账号操作安全。</p>
        <p class="green-text">建议开启。</p>
      </div>
    </div>
    <div class="rule-item">
      <span class="rule-title">操作员初始密码</span>
      <el-switch
        class="applic-switch"
        v-model="switchValue"
        active-color="#009375"
        active-text="开启"
        inactive-text="关闭"
        inactive-color="#ccc">
      </el-switch>
      <div class="rule-right">
        <p>添加操作人员时，默认基于用户手机号后六位生成初始密码，关闭后需手动输入初始密码。</p>
        <p class="green-text">建议开启。</p>
      </div>
    </div>
    <div class="rule-item">
      <span class="rule-title">删除用户</span>
      <el-switch
        class="applic-switch"
        v-model="switchValue"
        active-color="#009375"
        active-text="开启"
        inactive-text="关闭"
        inactive-color="#ccc">
      </el-switch>
      <div class="rule-right">
        <p>开启后允许删除用户，删除用户数据无法恢复。</p>
        <p class="green-text">建议开启。</p>
      </div>
    </div> -->
  </div>
</template>

<script>

import {
  regListByModule,
  regModuleConfig,
  regSaveConfig
} from '../../api/index'
import bus from '@/utils/bus'
export default {
  name: 'RuleSetting',
  props:{
    configModule: String
  },
  data() {
    return {
      moduleList: []
    }
  },
  mounted(){
    this.getAllModule()
  },
  methods:{
    getAllModule(){
      regListByModule({configModule:this.configModule}).then(res => {
        if(res.code === 0){
         this.moduleList = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    changeConfig(val,code){
      console.log('val,code',val,code)
      regSaveConfig({
        configCode: code,
        configValue: val
      }).then(res => {
        if(res.code === 0){
         this.getAllModule()
         bus.$emit('changeConfig')
        }else{
          this.$notify.error({
            title: '错误',
            message: '修改失败！' + res.msg
          });
          
        }
      })
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.rule
  padding 45px 65px
  .rule-item
    display flex
    align-items center
    padding-bottom 10px 
    border-bottom 1px solid #d8d8d8
    margin-bottom 22px
    .rule-title
      width 180px
      font-size 16px
    .applic-switch
      margin-right 70px
      /deep/.el-switch__label
        color transparent
      /deep/.el-switch__label--left.is-active
        color #9e9e9e
      /deep/.el-switch__label--right.is-active
        color #009375
    .rule-right
      line-height 22px
      color #9e9e9e
</style>