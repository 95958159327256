<template>
<div>333333
  <el-dialog
    title="暂停账号"
    class="batch-upload"
    :visible=true
    width="455px">
    <div class="dialog-con">
      <img src="../../../assets/images/suspend.png" class="wanning-ico" />
      <p class="wanning-par wanning-par-color">确认暂停当前“{{suspendName.name}}”账号使用权限？</p>
      <p class="wanning-par">暂停账号将无法再进入系统。</p>
    </div>
    <div class="batch-btns">
      <el-button @click="confirmSuspend" class="batch-btn success-btn" size="small" type="">确定</el-button>
      <el-button @click="cancleSuspend" class="batch-btn" size="small" type="">取消</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
export default {
  props: {
    suspendName: Object
  },
  data () {
    return {
    }
    
  },
  methods:{
    confirmSuspend(){
      this.$emit('confirmSuspend',this.suspendName)
    },
    cancleSuspend(){
      this.$emit('cancleSuspend')
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.batch-upload
  .dialog-con
    text-align center
    .wanning-par
      margin-top 15px
      line-height 18px
      text-align left 
      width 250px
      margin 10px auto 0 
    .wanning-par-color
      color #FF630F
  .batch-btns
    padding 30px 100px 20px
    display flex
    justify-content space-around
    .batch-btn
      width:70px
      height 34px
      border-radius 5px
      background #F0F3F6
      border none
    .success-btn
      background #009375
      color #fff
</style>