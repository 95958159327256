<template>
  <div >
    <div class="examdr-detail">
      <div class="examdr-con">
        <div class="drawer-top">
          <div class="drawer-item">
            <div class="drawer-it">
              <span class="drawer-title">姓       名：</span>
              <span class="drawer-text">{{name}}</span>
            </div>
            <div class="drawer-it">
              <span class="drawer-title">性       别：</span>
              <span class="drawer-text">男性</span>
            </div>
          </div>
          <div class="drawer-item">
            <div class="drawer-it">
              <span class="drawer-title">手机号码：</span>
              <span class="drawer-text">151****8188</span>
            </div>
            <div class="drawer-it">
              <span class="drawer-title">地     址： </span>
              <span class="drawer-text" title="重庆市南岸区回龙路90号">重庆市南岸区回龙路90号</span>
            </div>
          </div>
          <div class="drawer-item">
            <div class="drawer-it">
              <span class="drawer-title">回答时常：</span>
              <span class="drawer-text"> 36"</span>
            </div>
            <div class="drawer-it">
              <span class="drawer-title">得       分：</span>
              <span class="drawer-text"> 96</span>
            </div>
          </div>
          <div drawer-item-line></div>
          <div class="drawer-item" v-for="(item,i) in addItem" :key="i">
            <div class="drawer-it">
              <span class="drawer-title">{{item.newProjectName}}</span>
              <span class="drawer-text"> xxxxx</span>
            </div>
          </div>
        </div>
        <div class="examdr-main examdr-main-quest">
          <div class="examdr-title">测评项</div>
          <div class="examdr-quest"> 
            <div class="question-item" >
              <div class="question-title">
              <!-- <span class="question-num">1、</span>你是如何看待朋友关系的？ -->
                你是如何看待朋友关系的？
              </div>
              <div class="question-options" >
                <div class="question-option" >
                  1.我不是是有
                </div>
                <div class="question-option question-option-sel" >
                  1.我不是是有
                </div>
                <div class="question-option" >
                  1.我不是是有
                </div>
              </div>
            </div>
            <!-- <div class="question-item" v-for="(item,index) in questions" :key="index">
              <div class="question-title">
                {{item.examName}}
              </div>
              <div class="question-options" v-if="item.examAnswer && item.examAnswer.options">
                <div class="question-option" v-for="(question,j) in item.examAnswer.options" :key="j">
                  {{question}}
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   regEditExaml
// } from '../../../api/index'
export default {
  props:{
    perQuestDetail: Object
  },
  data () {
    return {
      name: "",
      sex: "",
      mobilePhone: "",
      ntaiveplace: "",
      answerCost: "",
      score: "",
      addItem: [],
      titleDtos: [],
      questions:[
        {
          examName: 'examName',
          examAnswer:{options:[
              'options'
          ]}
        }
      ]
    }
  },
  mounted(){
    console.log('perQuestDetail',this.perQuestDetail)
    this.setData()
  },
  methods:{
    setData(){
      if(this.perQuestDetail){
        this.name = this.perQuestDetail.patientInfoVo.name
        this.sex = this.perQuestDetail.patientInfoVo.sex
        this.mobilePhone = this.perQuestDetail.patientInfoVo.mobilePhone
        this.nativeplace = this.perQuestDetail.patientInfoVo.nativeplace
        this.answerCost = this.perQuestDetail.answerCost
        this.score = this.perQuestDetail.score
        this.addItem = this.perQuestDetail.item
        this.titleDtos =  this.perQuestDetail.titleDtos
      }
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.examdr-con
  padding 22px 24px
  .drawer-top
    font-size 16px
    .drawer-item
      display flex
      .drawer-it
        flex 1
        display flex
        margin-bottom 25px
        .drawer-title
          width 90px
          font-weight bold
        .drawer-text
          display inline-block
          flex 1
          width 150px
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
    .drawer-item-line
      border-top  1px dotted #d8d8d8    
      padding-top 10px
  .examdr-item
    display  flex
    margin-bottom 20px
    line-height 25px
    .examdr-name
      min-width 80px
      margin-right 10px
      font-weight bold
    .examdr-text
      width 300px
      padding 0 10px
      height 30px
      line-height 30px
      border 1px solid #ccc
      border-radius 3px
  .examdr-main
    margin-bottom 14px
    .examdr-title
      font-size 14px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 15px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 10px
          .question-num
            color #FF3243
        .question-option
          padding 8px 0 8px 30px
          border-radius 5px
        .question-option-sel
          background #E0F2EE
          color #009375
</style>