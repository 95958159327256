<template>
  <div class="base">
    <!-- 账户信息 -->
    <div class="base-top">
      <div class="base-top-left">
        <p class="base-top-name">账户信息</p>
        <p class="base-top-par">在此更新账户基础信息</p>
      </div>
      <div  class="to-edits" v-if="isBaseEdit">
        <span class="to-edit to-edit-handle" @click="cancleAcco">取消</span>
        <span class="to-edit to-edit-confir" @click="changeAcco">确定</span>
      </div>
      <span v-else class="to-edit" @click="isBaseEdit = true">编辑</span>
    </div>
    <div class="base-items">
      <div class="base-item" v-if="userType !== 1">
        <span class="base-item-name">机构名</span>
        <div class="base-item-center">
          <div class="base-item-par" v-if="!isBaseEdit">{{orgName}}</div>
          <el-input class="base-input" v-else v-model="orgName"  />
        </div>
      </div>
      <div class="base-item" >
        <span class="base-item-name" v-if="userType === 1">用户头像</span>
        <span class="base-item-name" v-else>机构头像</span>
        <div class="base-item-center">
          <div class=" base-user-img"> <img :src="logo"  class="base-user-image" v-if="logo" /> </div>
          <el-upload
            v-if="isBaseEdit"
            class="upload-demo"
            drag
            :http-request="handleUpload"
            ref="upload" 
            action="customize"
            accept=".jpg,.png,.bmp,.gif"
            :before-upload="uploadLicense"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖拽文件或选择上传你文件</div>
          </el-upload>
        </div>
      </div>
      <div class="base-item">
        <span class="base-item-name" v-if="userType === 1">简介</span>
        <span class="base-item-name" v-else>简介</span>
        <div class="base-item-center">
          <textarea class="base-area" resize="false" v-if="isBaseEdit" v-model="intro"></textarea>
          <div v-else class="base-item-par">
            <!-- <p>乐咕健康官方账号 </p>
            <p>每天分享精神心理🥳与躯体健康知识🤒 </p>
            <p>专注全民心身健康享受快乐生活</p> -->
            {{intro}}
          </div>
        </div>
      </div>
      <div class="base-item">
        <span class="base-item-name">认证类型</span>
        <div class="base-item-center">
          <div class="base-item-par" >{{orgType}}</div>
        </div>
        <span class="base-elink" @click="changeAuthInfo">
          更改认证
        </span>
      </div>
      <div class="base-item">
        <span class="base-item-name">登录密码</span>
        <div class="base-item-center">
          <div class="base-item-par" >已设置</div>
        </div>
        <span class="base-elink" @click="changePass">
          重置密码
        </span>
      </div>
    </div>
    <!-- 账户信息 -->
    <div class="base-top">
      <div class="base-top-left">
        <p class="base-top-name">运营信息</p>
        <p class="base-top-par">在此更新账户运营关联信息</p>
      </div>
      <div  class="to-edits" v-if="isOperate">
        <span class="to-edit to-edit-handle" @click="isOperate = false">取消</span>
        <span class="to-edit to-edit-confir" @click="changeBusi">确定</span>
      </div>
      <span v-else class="to-edit" @click="toOperate">编辑</span>
    </div>
    <div class="base-items">
      <div class="base-item" v-if="userType !== 1">
        <span class="base-item-name">组织名称</span>
        <div class="base-item-center">
          <div class="base-item-par" v-if="!isOperate">{{orgName}}</div>
          <el-input class="base-input" v-else  v-model="orgName" />
        </div>
      </div>
      <div class="base-item" v-if="userType !== 1">
        <span class="base-item-name">社会统一信用代码</span>
        <div class="base-item-center">
          <div class="base-item-par" v-if="!isOperate">{{orgCreditCode}}</div>
          <el-input class="base-input" v-else v-model="orgCreditCode" />
        </div>
      </div>
      <div class="base-item" v-if="userType !== 1">
        <span class="base-item-name">运营人</span>
        <div class="base-item-center">
          <div class="base-item-par" v-if="!isOperate">{{yyrName}}</div>
          <el-input class="base-input" v-else  v-model="yyrName" />
        </div>
      </div>
      <div class="base-item">
        <span class="base-item-name">联系方式</span>
        <div class="base-item-center">
          <div class="base-item-par">{{mobilePhone}}</div>
        </div>
        <span class="base-elink" v-if="isOperate" @click="changeMobile">
          更改/设置
        </span>
      </div>
      <div class="base-item">
        <span class="base-item-name">邮箱地址</span>
        <div class="base-item-center">
          <div class="base-item-par" v-if="!isOperate">{{email}}</div>
          <el-input class="base-input" v-else v-model="email"  />
        </div>
      </div>
    </div>
    <!-- 设置联系方式 -->
    <el-dialog
      title="设置联系方式"
      class="dialog-main"
      :visible.sync="setContactVisiable"
      width="520px">
      <div class="dialog-con">
        <div class="dialog-from-item">
          <span class="dialog-from-name">手机号</span>
          <input type="text" class="phone-text" placeholder="输入手机号吗" />
        </div>
        <div class="dialog-from-item">
          <span class="dialog-from-name">验证码</span>
          <div class="code-con">
            <input type="text" class="phone-text phone-code" placeholder="输入验证码" />
            <span class="phone-link">发送验证码</span>
          </div>
        </div>
      </div>
      <div class="dialog-btns">
        <span class="dialog-btn">绑定号码</span>
      </div>
    </el-dialog>
    <!-- 设置联系方式 end -->
    <!-- 管理员认证 -->
    <el-dialog
      title="管理员认证"
      class="dialog-main"
      :visible.sync="authVisiable"
      width="520px">
      <div class="dialog-con">
        <div class="dialog-from-item">
          <span class="dialog-from-name">手机号</span>
          <input type="text" class="phone-text" disabled v-model="mobilePhone" placeholder="输入手机号吗" />
        </div>
        <div class="dialog-from-item">
          <span class="dialog-from-name">验证码</span>
          <div class="code-con">
            <input type="text" v-model="authVerfiyCode" class="phone-text phone-code" placeholder="输入验证码" />
            <span class="phone-link" :class="{'glay-link':waiting}" @click="SendMobileVerifyCode">{{waitText}}</span>
          </div>
        </div>
        <div class="dialog-btns">
          
          <span class="dialog-btn dialog-btns-right" v-if="auditStatus ==0" >审核中</span>
            <span class="dialog-btn dialog-btns-right" @click="toConfirmAuth">立即验证</span>
          </div>
      </div>
    </el-dialog>
    <!-- 管理员认证 end -->
    <!-- 联系方式修改 -->
    <el-dialog
      title="联系方式修改"
      class="dialog-main"
      :visible.sync="modifyContactVisiable"
      width="520px">
      <div class="dialog-con">
        <div class="modify-bars">
          <span class="modify-bar " :class="{'modify-bar-ac':modifyNum >= 1}">1</span>
          <span class="bar-link" ></span>
          <span class="modify-bar" :class="{'modify-bar-ac':modifyNum >= 2}">2</span>
          <span class="bar-link" ></span>
          <span class="modify-bar" :class="{'modify-bar-ac':modifyNum >= 3}">3</span>
        </div>
        <template v-if="modifyNum ===1">
          <div class="dialog-from-item">
            <span class="dialog-from-name">原手机号</span>
            {{mobilePhone}}
          </div>
          <div class="dialog-from-item">
            <span class="dialog-from-name">验证码</span>
            <div class="code-con">
              <input type="text" class="phone-text phone-code" v-model="verfiyCode" placeholder="输入验证码" />
              <span class="phone-link" @click="toSendOldMobileVerifyCode">{{waitText1}}</span>
            </div>
          </div>
          <div class="dialog-btns dialog-btns-right">
            <span class="dialog-btn" @click="toCheckOldMobileVerifyCode">下一步</span>
          </div>
        </template>
        <template v-if="modifyNum === 2">
          <div class="dialog-from-item">
            <span class="dialog-from-name">手机号</span>
            <input type="text" class="phone-text" v-model="newmobilePhone" placeholder="输入手机号吗" />
          </div>
          <div class="dialog-from-item">
            <span class="dialog-from-name">验证码</span>
            <div class="code-con">
              <input type="text" v-model="newverfiyCode" class="phone-text phone-code" placeholder="输入验证码" />
              <span class="phone-link" @click="toSendNewMobileVerifyCode">发送验证码</span>
            </div>
          </div>
          <div class="dialog-btns">
            <span class="dialog-btn dialog-btns-right" @click="ToEitMobile">下一步</span>
          </div>
        </template>
        <div class="modify-success" v-if="modifyNum === 3">
          <img src="../../assets/images/modify_success.png" class="modify-img"/>
          <p class="modify-par">恭喜您以成功修改手机号码</p>
          <p class="modify-sencend">{{successSencend}}s 后自动关闭</p>
          <div class="dialog-btns">
            <span class="dialog-btn">完成</span>
          </div>
        </div>
      </div>
      
    </el-dialog>
    <!-- 联系方式修改 end -->
    <!-- 密码修改 -->
    <el-dialog
      title="设置密码"
      class="dialog-main"
      :visible.sync="resetPassVisiable"
      width="520px">
      <reset-password
        v-if="resetPassVisiable"
        :mobilePhone="mobilePhone"
        @closeRestPass="closeRestPass"
      ></reset-password>
    </el-dialog>
  </div>
</template>

<script>
import { maxFileSize } from '../../utils/variate'
import ResetPassword from '@/components/SystemSetting/BaseInfo/ResetPassword'
import {
  regUserInfo,
  sendOldMobileVerifyCode,
  checkOldMobileVerifyCode,
  sendNewMobileVerifyCode,
  editMobile,
  regFromUpload,
  reqEditOrgAccountInfo,
  reqEditOrgBusinessInfo,
  reqEditPersonalAccountInfo,
  reqSendMobileVerifyCode,
  reqCheckVerifyCode,
  reqSendPerMobileVerifyCode,
  reqCheckPerVerifyCode,
  reqSendVerifyCode,
  reqEditOrgAdminInfo,
  reqEditPersonalAdminInfo
} from '../../api/index'
import bus from '@/utils/bus'
export default {
  name: 'BaseInfo',
  data() {
    return {
      maxFileSize,
      isBaseEdit: false, // 当前是否编辑基础信息
      isOperate: false, // 运营信息
      setContactVisiable: false, // 设置联系方式
      modifyContactVisiable: false, // 修改联系方式
      modifyNum: 1, // 修改的步骤
      successSencend: 3,
      userType: 1, // 类型: 1 个人/ 2 企业/3 医疗机构
      name: '',
      yyrName: '',
      mobilePhone: '',
      orgName:'',
      logo: '',
      beforLogo: '', // 点击更改前
      intro: '',
      orgType: '',
      verfiyCode: '', // 旧手机的验证码
      tempToken: '',
      newmobilePhone: '',
      newverfiyCode:'',
      email: '',
      orgCreditCode: '',
      authVisiable: false,
      authVerfiyCode: '',
      waiting: false, // 等待验证码中
      waitText: '获取验证码', // 验证码倒计时
      waiting1: false, // 等待验证码中
      waitText1: '获取验证码', // 验证码倒计时
      auditStatus: null,
      resetPassVisiable: false
    }
  },
  components: { ResetPassword },
  mounted(){
    this.getUserInfo()
    console.log('base')
  },
  methods:{
    getUserInfo(){
      regUserInfo({}).then(res => {
        if(res.code === 0){
          console.log('用户信息',res.data)
          this.userData = res.data
          this.mobilePhone = res.data.mobilePhone
          this.name = res.data.name
          this.userType = res.data.userType
          if(this.userType != 1){
            this.intro = res.data.orgInfo.intro
            this.license = res.data.orgInfo.license
            this.officialLetter = res.data.orgInfo.officialLetter
            this.orgName = res.data.orgInfo.orgName
            this.orgType =  res.data.orgInfo.orgType
            this.logo =res.data.orgInfo.logo
            this.beforLogo = this.logo
            this.orgCreditCode = res.data.orgInfo.orgCreditCode
          }else{
            this.logo = res.data.headImgUrl
            this.beforLogo = this.logo
            this.intro = res.data.intro
            this.orgType = '个人'
          }
          this.email = res.data.email
          this.yyrName = res.data.adminInfo.name
          this.auditStatus = res.data.adminInfo.auditStatus || null
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取基础信息失败！'+ res.msg,
          });
          
        }
      })
    },
    countdown() {
      var timer = setInterval(() => {
        this.successSencend--;
        if (this.successSencend < 0) {
          clearInterval(timer);
          this.modifyContactVisiable = false
          this.getUserInfo()
        }
      }, 1000);
    },
    changeMobile(){
      //this.setContactVisiable = true
      this.modifyContactVisiable = true
    },
    //发送验证码到旧手机号
    toSendOldMobileVerifyCode(){
      this.verfiyCode = ''
      if(this.waiting1){
        return false
      }
      sendOldMobileVerifyCode().then(res => {
        if(res && res.code === 0){
          // 验证码倒计时
          let timeLeft1 = 60
          this.waiting1 = true;
          // 更新按钮上的文字
          this.waitText1 = timeLeft1 + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft1--;
            // 更新按钮上的文字
            this.waitText1 = timeLeft1 + 's后再次获取';
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft1 === 0) {
              clearInterval(timer);
              this.waiting1 = false;
              this.waitText1 = '获取验证码';
            }
          }, 1000);
        }else{
          this.$message({
            message: '获取验证码失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 验证旧手机号的验证码
    toCheckOldMobileVerifyCode(){
      if(!this.verfiyCode){
        this.$message({
          message: '请先输入验证码',
          type: 'wanring'
        }); 
        return false
      }
      checkOldMobileVerifyCode({
        verfiyCode: this.verfiyCode
      }).then(res => {
        if(res && res.code === 0){
          this.modifyNum = 2
          this.tempToken = res.data.tempToken
        }else{
          this.$message({
            message: '验证码错误，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 获取新手机验证码
    toSendNewMobileVerifyCode(){
      sendNewMobileVerifyCode().then(res => {
        if(res && res.code === 0){
          
        }else{
          this.$message({
            message: '获取验证码失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 提交新手机号
    ToEitMobile(){
      if(!this.newverfiyCode){
        this.$message({
          message: '请先输入验证码',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.newmobilePhone){
        this.$message({
          message: '请先填写电话号码',
          type: 'wanring'
        }); 
        return false
      }
      editMobile({
        mobilePhone: this.newmobilePhone,
        verfiyCode: this.newverfiyCode
      }).then(res => {
        if(res && res.code === 0){
          // this.$message({
          //   message: '修改成功！',
          //   type: 'success'
          // });
          this.modifyNum = 3
          this.countdown()
          sessionStorage.setItem('token',res.data.token)
          sessionStorage.setItem('name',res.data.name)
        }else{
          this.$message({
            message: '验证码错误，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    handleUpload(){},
    // 用戶上傳簽名
    uploadLicense(file) {
      console.log('file',file,file.raw,file.size/(1024*1024),this.maxFileSize)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          this.logo = res.data.fileUrl
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 更改账号
    changeAcco(){
      if(this.userType === 1){
        reqEditPersonalAccountInfo({
          intro:this.intro,
          headImgUrl: this.logo
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.beforLogo = this.logo
            this.isBaseEdit = false
            console.log('去掉亲爱')
            bus.$emit('changeUserInfo', true)
            this.getUserInfo()
          }else{
            this.$notify.error({
              title: '错误',
              message: '更改账号失败，'+ res.msg
            });
          }
        })
      }else{
        reqEditOrgAccountInfo({
          orgName:this.orgName,
          intro:this.intro,
          logo: this.logo
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.beforLogo = this.logo
            this.isBaseEdit = false
            console.log('去掉亲爱')
            bus.$emit('changeUserInfo', true)
            this.getUserInfo()
          }else{
            this.$notify.error({
              title: '错误',
              message: '更改账号失败，'+ res.msg
            });
          }
        })
      }
    },
    // 取消
    cancleAcco(){
      this.isBaseEdit = false
      this.logo = this.beforLogo
    },
    // 重置密码
    changePass(){
      this.resetPassVisiable = true
    },
    // 关闭重置密码
    closeRestPass(){
      this.resetPassVisiable = false
    },
    // 更改认证
    changeAuthInfo(){
      this.scene = 'auth'
      this.authVisiable = true
    },
    // 获取授权验证码
    SendMobileVerifyCode(){
      this.authVerfiyCode = ''
      if(this.waiting){
        return false
      }
      reqSendVerifyCode({
        scene: this.scene
      }).then(res => {
        if(res.code === 0){
          // 验证码倒计时
          let timeLeft = 60
          this.waiting = true;
          // 更新按钮上的文字
          this.waitText = timeLeft + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft--;
        
            // 更新按钮上的文字
            this.waitText = timeLeft + 's后再次获取';
        
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft === 0) {
              clearInterval(timer);
              this.waiting = false;
              this.waitText = '获取验证码';
            }
          }, 1000);
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取授权验证码失败，'+ res.msg
          });
        }
      })
    },
    // 验证验证码
    toConfirmAuth(){
      reqCheckVerifyCode({
        verfiyCode: this.authVerfiyCode,
        scene: this.scene
      }).then(res => {
        if(res.code === 0){
          this.authVisiable = false
          sessionStorage.setItem('tempToken',res.data.tempToken)
          this.getUserInfo()
          if(this.scene === 'auth'){
            this.$router.push({ path: `/register/${this.userType}/1` })
          }else{
            this.isOperate = true
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '验证码验证失败，'+ res.msg
          });
        }
      })
    },
    toOperate(){
      this.scene = 'adminInfo'
      this.authVisiable = true
      
    },
    // 修改组织运营信息
    changeBusi(){
      if(this.userType === 1){
      // 个人 
        const params = {
          email: this.email,
          name: this.name,
          mobilePhone: this.mobilePhone,
          tempToken:sessionStorage.getItem('tempToken')
        }
        reqEditPersonalAdminInfo(params).then(res => {
          if(res.code === 0){
            this.isOperate = false
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.getUserInfo()
          }else{
            this.$notify.error({
              title: '错误',
              message: '修改组织运营信息失败，'+ res.msg
            });
          }
        })
      }else{
        const params = {
          email: this.email,
          name: this.name,
          orgCreditCode: this.orgCreditCode,
          orgName: this.orgName,
          mobilePhone: this.mobilePhone,
          tempToken:sessionStorage.getItem('tempToken')
        }
        reqEditOrgAdminInfo(params).then(res => {
          if(res.code === 0){
            this.isOperate = false
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.getUserInfo()
          }else{
            this.$notify.error({
              title: '错误',
              message: '修改组织运营信息失败，'+ res.msg
            });
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.base
  padding 30px 140px 40px
  .base-top
    display flex
    justify-content space-between
    margin-bottom 30px
    .base-top-left
      .base-top-par
        color #9E9E9E
      .base-top-name
        font-size 16px
        margin-bottom 10px
    .to-edit
      display inline-block
      width 80px
      height 36px
      line-height 36px
      text-align center
      border-radius 5px
      background #D0E6E6
      color #009375
      cursor pointer
    .to-edit-handle
      background #F0F3F6
      color #4a4a4a
      margin-right 35px
      cursor pointer
    .to-edit-confir
      background #009375
      color #fff
      cursor pointer
  .base-items
    margin-bottom 60px
    .base-item
      display flex
      padding 12px 0
      border-bottom 1px solid #d8d8d8
      align-items center
      .base-item-name
        width 210px
      .base-item-center
        flex 1
        display flex
        align-items center
        line-height 24px
        .base-input
          height 37px
          max-width 420px
          line-height 37px
        .upload-demo
          >>>.el-upload-dragger
            width 312px
            height 100px
            color #009375
            border 1px solid #dadada
            .el-upload__text
              color #009375
          >>>.el-icon-upload
            margin 2px 0 13px
            &::before
              content ""
              display inline-block
              width 40px
              height 30px
              background url(../../assets/images/upload.png) no-repeat
        .base-user-img
          width 60px
          height 60px
          border-radius 50%
          margin-right 30px
          overflow hidden
          .base-user-image
            max-width 100%
            max-height auto
        .base-item-par
          padding 10px 0
        .base-area
          width 419px
          height 81px
          border-radius 5px
          overflow auto
          border 1px solid #DADADA
          padding 5px
          resize none
      .base-elink
        padding 0 40px
        color #009375
        cursor pointer
  .dialog-main
    .dialog-con
      padding 30px
      .dialog-from-item
        display flex
        align-items center
        margin-bottom 25px
        position relative
        .dialog-from-name
          width 100px
        .phone-text
          width 302px
          height 38px
          line-height 38px
          border 1px solid #DADADA
          border-radius 5px
          padding 0 10px
          &:focus
            border-color #DADADA
        .code-con
          display flex
          .phone-code
            width 194px
            margin-right 13px
          .phone-link
            display inline-block
            width 95px
            height 38px
            line-height 38px
            text-align center
            border-radius 5px
            border 1px solid #DADADA
            font-size 14px
            cursor pointer
          .glay-link
            color #7e7e7e
      .modify-bars
        margin-bottom 50px
        display flex
        align-items center
        .modify-bar
          display inline-block
          width 20px
          height 20px
          line-height 20px
          border-radius 25px
          background #D8D8D8
          color #fff
          margin 0 20px
          text-align center
        .modify-bar-ac
          width 55px
          background #E0F2EE
          color #009375
        .bar-link
          flex 1
          height 1px 
          background #ccc
      .modify-success
        text-align center
        .modify-par
          color #9e9e9e
          margin 15px 0 22px
        .modify-sencend
          font-size 12px
          color #009375
          margin-bottom 60px
    .dialog-btns
      text-align center
      .dialog-btn
        display inline-block
        width 100px
        height 34px
        line-height 34px
        border-radius 5px
        background #009375 
        color #fff
        text-align center
    .dialog-btns-right
      text-align right
    >>>.el-dialog__header
      border-bottom 1px solid #d8d8d8
      font-size 16px
      font-weight bolder
    
</style>