<template>
  <div class="drawer">
    <div class="drawer-title">
      <p class="drawer-title-text green-text">人员详情</p>
      <img src="../../../assets/images/close.png" class="close-drawer-icon" @click="closeDrawer" />
    </div>
    <div class="drawer-con">
      <div class="drawer-item">
        <span class="deawer-item-name">姓名</span>
        <div class="drawer-item-middle" >{{name}}</div>
        <!-- <div class="drawer-item-middle" v-else><input type="text" class="drawer-text" v-model="name" /></div> -->
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">组织</span>
        <div class="drawer-item-middle">{{orgName}}</div>
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">职务</span>
        <div class="drawer-item-middle" v-if="flagAdmin === 'Y'">超管理员</div>
        <div class="drawer-item-middle" v-else>普通用户</div>
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">手机号</span>
        <div class="drawer-item-middle" >{{mobilePhone}}</div>
        <!-- <div class="drawer-item-middle" v-else><input type="text" class="drawer-text" v-model="name" /></div> -->
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">成员状态</span>
        <div class="drawer-item-middle" v-if="status == 'Y'">启用</div>
        <div class="drawer-item-middle" v-else>禁用</div>
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">工号</span>
        <div class="drawer-item-middle" >{{jobNo}}</div>
        <!-- <div class="drawer-item-middle" v-else><input type="text" class="drawer-text" v-model="name" /></div> -->
      </div>
      <div class="drawer-item">
        <span class="deawer-item-name">电子签名</span>
          <div class="drawer-item-middle">
            <img :src="eleSign" class="user-singn" />
          </div>
        </div>
        <div class="drawer-item">
          <span class="deawer-item-name">权限分组</span>
          <div class="drawer-item-middle">{{roleName}}</div>
        </div>
        <div class="drawer-item">
          <span class="deawer-item-name">私有权限</span>
          <div class="drawer-item-middle" v-if="hasRight === 0">无</div>
          <div class="drawer-item-middle" v-else>有</div>
          <span class="drawer-link green-text" v-has="'mgr.role.query'"  @click="toEditSYMember">编辑</span>
        </div>
        <div class="drawer-item">
          <span class="deawer-item-name">密码</span>
          <div class="drawer-item-middle green-text"  v-has="'mgr.member.edit'"  @click="resetPassword">重置密码</div>
        </div>
      </div>
      <div class="deawer-btn" @click="toEditMember" v-has="'mgr.member.edit'">
        <img src="../../../assets/images/edit_icon.png" class="deawer-btn-img" />编辑
      </div>
    </div>
  </div>
</template>
<script>
import {
  regDetailMember,
  regMemberResetPassword
} from '../../../api/index'
export default {
  props:{
    memberId: Number
  },
  data(){
    return{
      isEditMember: false,
      name: '',
      orgName: '',
      roleName: '',
      jobNo: '',
      mobilePhone: '',
      eleSign:'',
      flagAdmin:'',
      orgId: null,
      rightIdList: [],
      roleIdList: [],
      status: '',
      hasRight: 0, // 是否有私有权限
    }
  },
  mounted(){
    console.log('查看用户详情')
    this.handleSeeMember()
  },
  methods:{
    // toEdit(){
    //   this.isEditMember = true
    // },
    // 查看人员
    handleSeeMember(){
      regDetailMember({
        id: this.memberId
      }).then(res => {
        if(res.code === 0){
          this.detailDrawer = true
          this.memberDetail = res.data
          this.name = res.data.name
          let deptList = res.data.deptList
          this.orgName = deptList.map(obj => obj.name).join(', ')
          this.roleName = res.data.roleName
          this.jobNo = res.data.jobNo
          this.mobilePhone = res.data.mobilePhone
          this.eleSign = res.data.eleSign
          this.flagAdmin = res.data.flagAdmin
          this.status = res.data.status
          this.hasRight = res.data.rightIdList.length
        }else{
          this.$notify.error({
            title: '错误',
            message: '查看人员详情失败！'+ res.msg
          });
          
        }
      })
    },
    // 关闭抽屉
    closeDrawer(){
      this.$emit('closeDrawer')
    },
    // 重置密码
    resetPassword(){
      regMemberResetPassword({
        id: this.memberId
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: '重置密码成功！',
            type: 'success'
          });
        }else{
          this.$notify.error({
            title: '错误',
            message: '重置密码失败！'+ res.msg
          });
        }
      })
    },
    // 编辑
    toEditMember(){
      this.$emit('toEditMember',this.memberId)
    },// 编辑私有
    toEditSYMember(){
      this.$emit('toEditSYMember',this.memberId,this.name)
    },
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.drawer
  padding 0 16px 
  .drawer-title
    height 66px
    line-height 66px
    position relative
    font-size 16px
    border-bottom 1px solid #D8D8D8
    .drawer-title-text
      font-weight bolder
    .close-drawer-icon
      position absolute
      right 25px
      top 20px
      cursor pointer
  .drawer-con
    padding 32px 7px
    .drawer-item
      display flex
      margin-bottom 30px
      align-items center
      .deawer-item-name
        color #979797
        width 90px
      .drawer-item-middle
        color #979797
        flex 1
        .drawer-text
          border-color #ccc
          height 24px
          line-height 24px
          outline 1px solid #ccc
          padding 0 10px
        .user-singn
          max-width 200px
          max-height 100px
      .drawer-link
        width 50px
      .green-text
        cursor pointer
        color #009375
  .deawer-btn
    text-align center
    margin-top 20px
    width 104px
    height 40px
    line-height 40px
    border-radius 5px
    border 1px solid #D8D8D8
    display flex
    justify-content center
    align-items center
    margin 0 auto
    cursor pointer
    .deawer-btn-img
      margin-right 10px
</style>