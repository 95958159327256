<template>
  <div class="home-page">
    <div class="homepage-con">
      <div class="homepage-top">
        <p class="home-slogen">欢迎访问乐咕PaaS健康管理系统</p>
        <p class="home-des">Welcome to Legu PaaS Health Management system</p>
      </div>
      <div class="homepage-main">
        <div class="home-left-con">
          <div class="home-left">
            <div class="statistics-items">
              <div class="statistics-item">
                <p class="statis-title">今日新增</p>
                <div class="statis-main">
                  <span class="statis-color" v-if="percentTodayCount<0"><img src="../../assets/images/top_arrow.png" /> {{percentTodayCount}}%</span>
                   <span class="statis-color statis-color-up" v-else><img src="../../assets/images/top_arrow2.png" /> {{percentTodayCount}}%</span>
                  <span class="link-color big-text">{{todayCount}}</span>人
                </div>
              </div>
              <div class="statistics-item">
                <p class="statis-title">今日咨询</p>
                <div class="statis-main">
                   <span class="statis-color"  v-if="percentTodayConsult<0"><img src="../../assets/images/top_arrow.png" /> {{percentTodayConsult}}%</span>
                  <span class="statis-color statis-color-up" v-else><img src="../../assets/images/top_arrow2.png" /> {{percentTodayConsult}}%</span>
                  <span class="link-color big-text">{{todayConsult}}</span>人
                </div>
              </div>
              <div class="statistics-item statistics-item-long">
                <div class="statis-inner statis-inner-one">
                  <p class="statis-title">男性患者</p>
                  <div class="statis-main">
                    <span class="link-color big-text">{{sexManCount}}</span>人
                  </div>
                </div>
                <div class="statis-inner">
                  <p class="statis-title">女性患者</p>
                  <div class="statis-main">
                    <span class="link-color big-text">{{sexWomanCount}}</span>人
                  </div>
                </div>
              </div>
            </div>
            <div class="home-echart">
              <div id="fllowUp"></div>
              <div class="echart-title">
                <span class="echart-title-text">随访记录</span>
                <span class="echart-tips">
                  <span class="echart-tip">●  男</span>
                  <span class="echart-tip echart-tip2">●  女</span>
                </span>
              </div>
            </div>
          </div>
          <div class="shortcuts">
            <p class="shortcuts-title">快捷功能</p>
            <div class="shortcut-items">
              <div class="shortcuts-item" @click="toAddUser" v-has="'mgr.patient.addPatient'">
                <img src="../../assets/images/short_01.png" class="short-icon" />
                添加用户
              </div>
              <div class="shortcuts-item"  @click="toFPUser">
                <img src="../../assets/images/short_02.png" class="short-icon" />
                分配计划
              </div>
              <!-- <div class="shortcuts-item">
                <img src="../../assets/images/short_03.png" class="short-icon" />
                推送消息
              </div> -->
            </div>
          </div>
        </div>
        <div class="home-schedule">
          <div class="schedule-top">
            日程安排<span class="add-schedu" @click="addSchedule">添加日程</span>
          </div>
          <div class="data-analysis">
            <el-calendar v-model="calendarVal" >
              <template
                slot="dateCell"
                slot-scope="{date, data}"
              >
                <div @click="changeCanle(data.day)">
                  <div v-for="(item, key) in activeday" :key="key">
                    <el-badge v-if="data.day == item.dat" is-dot class="item"></el-badge>
                  </div>
                  <div class="spandate">{{ data.day.split('-').slice(2).join('-') }}</div>
                </div>
              </template>
            </el-calendar>
          </div>
          <div class="calen-lists">
            <div class="calen-list" v-for="item in curentScheduleList">
              <p class="calen-title">
                <span class="calen-icon" :class="'calen-'+`${item.color}`">●</span> <span class="link-color">{{item.scheduleTime}}</span>
                <span class="calen-del" @click="deleteSche(item.id)">删除</span>
              </p>
              <div class="calen-des">
                <p class="calen-title">{{item.title}}</p>
                <p>{{item.content}}</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="homepage-copy link-color">版权所有 © 2023 legu Health  - 保留所有权利</div>
    <schedule
      v-if="showScheDialog"
      :currentData="currentData"
      @closeScheDialog="closeScheDialog"
      @addSchedSucc="addSchedSucc"
    ></schedule>
  </div>
</template>

<script>
import {
  reqStatPatient,
  reqListByDay,
  reqRemoveSchedule,
  reqDaysByMonth,
} from '../../api/index'
import bus from '@/utils/bus'
import Schedule from '@/components/HomePage/Schedule'
import * as echarts from "echarts"
export default {
  name: '',
  data(){
    return{
      showScheDialog: false,
      calendarVal:new Date(),
      activeday: [ // 日历组件选中的日期，小红点，时间格式必须为yyyy-MM-dd，比如3月5号要写成03-05而不是3-5
       
      ],
      todayCount: 0, // 今日新增
      todayConsult: 0, // 今日咨询数量
      sexManCount: 0, //
      sexWomanCount: 0,
      curentScheduleList: [],
      currentData:'',
      percentTodayCount: 0,
      percentTodayConsult: 0,
    }
  },
  components: {Schedule},
  mounted(){
    this.initEcharts()
    this.getIndexData()
    this.getDaysByMonth()
   
    bus.$on('reNewalarm', () => {
      this.currentData = new Date().toISOString().slice(0, 10) + ' 00:00:00';
      //this.changeCanle(this.currentData)
    })
  },
  methods: {
    // 添加用户
    toAddUser(){
      this.$emit('changePage','EditUser')
      this.$router.push({ path: '/EditUser'})
    },
    // 分配计划
    toFPUser(){
      this.$emit('changePage','UserManage')
      this.$router.push({ path: '/UserManage'})
    },
    getIndexData(){
      reqStatPatient().then(res => {
        if(res.code === 0){
          console.log('获取所有表单',res.data)
          this.todayCount = res.data.todayCount
          this.yesterdayCount = res.data.yesterdayCount
          this.todayConsult = res.data.todayConsult
          this.yesterdayConsult = res.data.yesterdayConsult
          this.sexManCount = res.data.sexManCount
          this.sexWomanCount = res.data.sexWomanCount
          this.percentTodayCount = res.data.percentTodayCount
          this.percentTodayConsult = res.data.percentTodayConsult
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取首页失败！'+ res.msg,
          });
        }
      })
    },
    // 当前月的日程
    getDaysByMonth(theDate = null){
      let param = ''
      if(theDate){
        param = theDate.substring(0, 7);
      }else{
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1; 
        if(month<10) month = '0'+month
        param = year+ '-' + month
      }
      reqDaysByMonth({
         month: param
      }).then(res => {
        if(res.code === 0){
          this.currentData = new Date().toISOString().slice(0, 10);
          if(theDate){
            this.changeCanle(theDate)
          }else{
            this.changeCanle(this.currentData)
          }
          let list = res.data
          let arr = []
          if(list && list.length){
            for(let i=0; i<list.length; i++){
              arr.push({
                dat: list[i]
              })
            }
            console.log('arr',arr)
            this.activeday = arr
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取当前月的日程失败！'+ res.msg,
          });
        }
      })
    },
    addSchedule(){
      this.showScheDialog = true
    },
    closeScheDialog(){
      this.showScheDialog = false
    },
    addSchedSucc(theDate){
      console.log('theDate',theDate)
      this.currentData = theDate
      this.changeCanle(theDate)
      this.showScheDialog = false
      this.getDaysByMonth(theDate)
    },
    // 删除日程
    deleteSche(id){
      reqRemoveSchedule({
        id
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: '删除成功',
            type: 'success'
          }); 
          this.changeCanle(this.currentData)
        }else{
          this.$notify.error({
            title: '错误',
            message: '删除日程失败！'+ res.msg
          });
        }
      })
    },
    changeCanle(val){
      console.log('changeCanle',val)
      this.currentData = val
      reqListByDay({
        day:val
      }).then(res => {
        if(res.code === 0){
          this.curentScheduleList = res.data
          if(this.currentData == new Date().toISOString().slice(0, 10)){ // 是今天
            // 设置闹钟
            bus.$emit('alarm',this.curentScheduleList)
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取当前日期日程失败！'+ res.msg
          });
        }
      })
    },
    initEcharts() {
      const constoption = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['12', '13', '14', '15', '16', '17', '18'],
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine:{
            show:false // 不显示网格线
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          splitLine:{
            show:false // 不显示网格线
          },
        },
        series: [
          {
            data: [43, 24, 44, 22, 32, 43, 25],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 1,
              color:'#afc0e7'
            },
            showSymbol: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  opacity: 0.1,
                  color: 'rgba(175, 192, 231, 0.2)'
                },
                {
                  offset: 1,
                  opacity: 0.8,
                  color: 'rgba(175, 192, 231, 0)'
                }
              ])
            }
          },
          {
            data: [12, 34, 45, 34, 44, 23, 33],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 1,
              color: '#ffc09f'
            },
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 192, 159, 0.1)'
                },
                {
                  offset: 1,
                  opacity: 0.8,
                  color: 'rgba(255, 192, 159, 0)'
                }
              ])
            }
          }
        ]
      };
      const myChart = echarts.init(document.getElementById("fllowUp"));// 图标初始化
      myChart.setOption(constoption);// 渲染页面
      myChart.on('mousemove', params=> {
        var dataIndex = params.dataIndex;
        var seriesIndex = params.seriesIndex;
        var data = constoption.series[seriesIndex].data;
        var value = data[dataIndex];
        var xAxisData = constoption.xAxis.data[dataIndex];
        myChart.setOption({
          tooltip: {
            show: true,
            trigger: 'item',
            position: [params.event.offsetX, params.event.offsetY],
            formatter: function () {
              return '时间：' + xAxisData + '<br/>数据：' + value;
            }
          }
        });
      });
      // 添加事件监听，实现鼠标移出点后隐藏数据
      myChart.on('mouseout', ()=> {
        myChart.setOption({
          tooltip: {
            show: false
          }
        });
      });
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.home-page
  padding 75px 0 0 55px
  padding-bottom 60px
  position absolute
  top 0
  left 0 
  right 0
  bottom 0
  overflow-x auto
  &::-webkit-scrollbar{
    width: 7px;
    height: 7px;
  } 
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  } 
  &::-webkit-scrollbar-track {
    background: #ccc;
  } 
  .homepage-top
    margin-bottom 80px
    .home-slogen 
      color #2CA58D
      font-size  22px
      font-weight bold
      margin-bottom 15px
    .home-des
      font-size 16px
      color #8a8a8a
  .homepage-main
    display flex
    justify-content space-around
    .home-left-con
      .home-left
        display flex
        .statistics-items
          display flex
          width 410px
          justify-content space-between
          flex-wrap wrap
          .statistics-item
            display inline-block
            width 180px
            padding 14px 16px
            border-radius 10px
            border 1px solid #D8D8D8
            margin 0  25px 22px 0
            .statis-title
              font-size 16px
              color #000
              margin-bottom 20px
            .statis-main
              width 100%
              text-align right
              color #9E9E9E
              font-size 16px
              .statis-color
                display inline-block
                padding 0 6px
                height 16px
                line-height 16px
                border-radius 2px
                color #009375
                background #E0F2EE
                font-size 10px
                margin-right 10px
              .statis-color-up
                background #FAE8DF 
                color #FF630F
              .big-text
                font-size 32px
                margin-right 14px
          .statistics-item-long
            width 385px
            display flex
            .statis-inner
              display inline-block
              width 180px
              padding-left 30px
            .statis-inner-one
              border-right 1px dashed  #D8D8D8
              padding 0 30px 0 0
        .home-echart
          width 387px
          height 231px
          border-radius 10px
          border 1px solid #D8D8D8
          position relative
          #fllowUp
            width 387px
            height 231px
          .echart-title
            position absolute
            top 15px
            left 18px
            z-index 1
            .echart-title-text
              font-size 16px
              color #000
            .echart-tips
              margin-left 120px
              .echart-tip
                color #3862C4
              .echart-tip2
                color #FF630F
                margin-left 24px
      .shortcuts
        margin 55px 0
        .shortcuts-title
          font-size 18px
          font-weight bold
          color #000
          margin-bottom 55px
        .shortcuts-item
          display inline-block
          padding 0 17px 0 20px
          height 48px
          line-height 48px
          background #A1A9FB
          color #fff
          font-size 18px
          font-weight bold
          border-radius 12px
          margin-right 50px
          cursor pointer
          .short-icon
            position relative
            top 7px
            margin-right 4px
        .shortcuts-item:nth-child(2)
          background #8CD3FD
        .shortcuts-item:nth-child(3)
          background #E97D7D
    .home-schedule
      display inline-block
      width 270px
      padding 13px 20px  
      border 1px solid #D8D8D8
      margin-left 55px
      border-radius 10px
      margin-bottom 50px
      max-height calc(100vh - 280px)
      overflow auto
      .schedule-top
        font-size 16px
        position relative
        margin-bottom 20px
        .add-schedu
          display inline-block
          height 19px
          line-height 22px
          padding 0 8px
          border-radius 10px
          font-size 10px
          color #ffffff
          background #009375
          position absolute
          right 0
          top 0
          cursor pointer
      .data-analysis
        background red
        >>>.el-button-group
          .el-button
            padding 2px 5px
          .el-button:not(:first-child):not(:last-child)
            display none
        >>>.el-calendar__body
          padding 0
          .el-calendar-day
            height auto
            position relative
            .el-badge
              display block
              .el-badge__content
                position absolute 
                width 10px
                height 10px 
                //background rgba(224, 242, 238, 0.6)
                background #009375
                right -7px
                top -7px
              .spandate
                color #009375
      .calen-lists
        margin-top 20px
        .calen-list
          padding-bottom 22px
          position relative
          .calen-title
            font-size 12px 
            color #4A4A4A
            .calen-icon
              color #E97D7D
            .calen-blue
              color #8cd3fd
            .calen-pur
              color #A1A9FB
            .calen-green
              color #8cd3fd
            .calen-del
              float right
              cursor pointer
          .calen-des
            padding 10px 
            background rgba(224, 242, 238, 0.6)
            border-radius 5px
            font-size 12px
            color #555
            line-height 18px
            margin 15px 0 0 17px
            .calen-title
              color #009375
              font-weight bold
              margin-bottom 7px
          &:after
            content ''
            display inline-block
            position absolute
            left 3px
            top 9px
            width 2px
            height  calc(100% - 5px)
            background #D8D8D8 
          &:last-child:after
            display none
  .homepage-copy
    text-align center
    position absolute
    bottom 0
    width 100%
    margin-bottom 20px 
</style>