//转换成base64方法
export  function getImageBase64(file) {
  return new Promise(function (resolve, reject) {
    let newImagereader = new FileReader();
    let imgInfo = "";
    newImagereader.readAsDataURL(file);
    newImagereader.onload = function () {
      imgInfo = newImagereader.result;
    };
    newImagereader.onerror = function (error) {
      reject(error);
    };
    newImagereader.onloadend = function () {
      resolve(imgInfo);
    };
  });
}

// 校验身份证号码
export function validateIDCard(id) {
  // 校验身份证号码长度为18位
  if (id.length !== 18) return false;
  
  var regExp = /^[0-9]{17}[\dXx]$/i;
  // 根据身份证号码的前17位数字计算最后一位校验码
  function calculateCheckCode() {
      var factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      var checksums = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
      
      var sum = 0;
      for (var i = 0; i < id.length - 1; i++) {
          sum += parseInt(id[i]) * factors[i];
      }
      
      var remainder = sum % 11;
      return checksums[remainder];
  }
  
  // 获取身份证号码的第18位校验码并与计算结果比对
  var lastChar = id[id.length - 1].toUpperCase();
  var calculatedLastChar = calculateCheckCode().toUpperCase();
  
  return regExp.test(id) && lastChar === calculatedLastChar;
}

// 是否有权限
export function powerRight(value) {
  const rightCodeList = JSON.parse(sessionStorage.getItem('rightCodeList'))
  if (rightCodeList.indexOf(value) !== -1) {
      return true
  } else {
      return false
  }
}