import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=65a06186&scoped=true"
import script from "./UserDetail.vue?vue&type=script&lang=js"
export * from "./UserDetail.vue?vue&type=script&lang=js"
import style0 from "./UserDetail.vue?vue&type=style&index=0&id=65a06186&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a06186",
  null
  
)

export default component.exports