<template>
  <div class="distri">
    <el-dialog
    title="分配计划"
    class="batch-upload"
    :visible=true
    @close="closeDistribute"
    width="560px">
      <div class="batch-table">
        <div class="distribute-tit">
          <p class="distri-to">当前计划</p>
          <p class="distri-name">{{distriData.name}}</p>
          <p class="distri-to">用 户 名</p>
          <div class="distri-tabs">
            <el-select v-has="'mgr.group.query'" class="distri-select" v-model="groupId"  placeholder="分组">
              <el-option
                v-for="item in batchListDatas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-input type="text " class="distri-select distri-input"  v-model.trim="name" placeholder="输入用户用搜索" />
            <div class="search-cons" v-if="manageData.length">
              <div class="search-item" v-for="(item,i) in manageData" @mouseleave="blurSearch" :key="i" @click="selectUser(item)" >
                <span class="search-span">{{item.name}}</span>
                <span class="search-span-middle">{{item.mobilePhone}}</span>
                <span class="search-span">{{item.diagnose}}</span>
              </div>
            </div>
            <img src="../../assets/images/search.png" class="search-icon" @click="getUserLists" />
          </div>
        </div>
        <el-table                                          
          :data="selectData"
          class="user-table">     
          <el-table-column
            prop="name"
            width="160"
            label="用户名">
          </el-table-column>
          <el-table-column
            prop="sex"
            label="性别">
          </el-table-column>
          <el-table-column
            prop="mobilePhone"
            label="电话"
            width="140">
          </el-table-column>
          <el-table-column
            prop="groupName"
            label="分组">
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <img src="../../assets/images/delete.png" class="tab-dele" @click="deleteUser(scope.row.id)" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-btns">
        <span class="dialog-btn dialog-cancle-btn" @click="closeDistribute">取消</span>
        <span class="dialog-btn" @click="handleConfirm">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  regUserGroupList,
  regUserLists,
  bindPlanPerson
} from '../../api/index'
export default {
  props:{
    distriData: Object
  },
  data () {
    return {
      groupTotal: 0,
      groupId: '',
      name: '',
      batchListDatas: [],
      manageData: [],
      selectData: [],
      DistributeDatas: [{
        name:'xt dg '
      }]
    }
    
  },
  mounted(){
    this.getGroupDatas()
    //this.getUserLists()
  },
  watch:{
    name(){
      if(!this.name){
        this.manageData = []
        return false
      }
      this.getUserLists()
    }
  },
  methods:{
    // 当前用户的部门分组
    getGroupDatas(){
      regUserGroupList({
        pageNo: 1,
        pageSize: 10000,
        name: ''
      }).then(res => {
        if(res && res.code === 0){
          this.batchListDatas = res.data.list
          if(this.batchListDatas.length){
            this.batchListDatas.push({
              id:null,
              name: '全部'
            })
          }
        }else{
          this.$message({
            message: '获取分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 列表
    getUserLists(){
      const params = {
        pageNo: 1,
        pageSize: 10000,
        belongTo: '',
        groupId: this.groupId,
        keyword: this.name
      }
      console.log('params',params)
      regUserLists(params).then(res => {
        console.log('列表',res)
        if(res && res.code === 0){
          this.manageData = res.data.list
        }else{
          this.$message({
            message: '获取列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 离开
    blurSearch(){
      this.manageData = []
    },
    deleteUser(id){
      const idx = this.selectUser.findIndex(item => item.id === id)
      this.selectUser.splice(idx,1)
    },
    handleConfirm(){ 
      console.log('this.se',this.selectData)
      let params = []
      for(let i=0; i<this.selectData.length; i++){
        params.push({
          personId: this.selectData[i].id,
          personName:  this.selectData[i].name,
          planId: this.distriData.id
        })
      }
      if(params.length == 0){
         this.$message({
          message: '请先选择用户',
          type: 'wanring'
        }); 
        return false
      }
      console.log('params',params)
      bindPlanPerson(params).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '分配成功',
            type: 'success'
          }); 
          this.closeDistribute()
        }else{
          this.$message({
            message: '获取列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 选择用户
    selectUser(data){
      const idx = this.selectData.findIndex(item => item.id === data.id);
      if(idx === -1){
        this.selectData.push(data)
      }
    },
    closeDistribute(){
      console.log('closeDistribute')
      this.$emit('closeDistribute')
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.distri
  .batch-table
    >>>.has-gutter
      border-radius 5px
      overflow hidden
    >>>td
      padding 9px 0
      height 25px
      line-height 25px
    >>>tr
      &:hover
        cursor pointer
        border-radius 3px
        >td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
    >>>th
      padding 3px 0
      height 22px
      line-height 22px
      background #F0F0F5
      border none
    .distribute-tit
      .distri-to
        margin-bottom 12px
      .distri-name
        height 38px
        line-height 38px
        border-radius 5px
        background #F4F4F4
        border 1px solid #DADADA
        padding-left 15px
        margin-bottom 22px
      .distri-tabs
        display  flex
        margin-bottom 20px
        position relative
        .distri-select
          height 38px
          width 150px
          margin-right 17px
        .distri-input
          width 380px
        .search-icon
          position absolute
          right 26px
          top 10px
          cursor pointer
        .search-cons
          padding 5px 0
          display inline-block
          width 350px
          max-height 90px
          overflow auto
          border 1px solid #D8D8D8
          background #fff
          box-shadow 0px 4px 10px 0px rgba(0, 0, 0, 0.3)
          position absolute
          top 40px
          right 14px
          z-index 12
          border-radius 5px
          .search-item
            display flex
            padding 0 10px
            line-height 24px
            .search-span 
              flex 1
            .search-span-middle
              width 100px
            &:hover
              background #D0E6E6
              color #00A389
  .dialog-btns
    text-align center
    margin-top 20px
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .dialog-btns-right
    text-align right
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
        
</style>