<template>
  <el-dialog
    :title="title"
    class="dialog-main"
    :visible=true
    @close="closeAddRole"
    width="600px">
    <div class="dialog-con">
      <div class="dialog-dept-item">
        <p class="dialog-dept-name"><span class="red-text">*</span>角色名称</p>
        <input type="text" v-model="name" class="dialog-dept-text" />
      </div>
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name">角色描述</p >
        <textarea class="dialog-area" v-model="memo"></textarea>
      </div>
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name"><span class="red-text">*</span>归属部门</p >
        <el-select 
          ref="selectTree" 
          v-model="detpParentId" 
          placeholder="请选择" 
          popper-class="popperTreeSelect"
        >
          <el-option :value="detpParentId" :label="parentName">
            <el-tree 
              ref="tree" 
              :data="parentOrgArr" 
              default-expand-all
              :props="defaultProps" 
              node-key="id"
              :expand-on-click-node=false
              style="min-height:100px;"
              :filter-node-method="filterNode"
              @node-click="handleDeptParentId"
            >
              <span class="custom-tree-node" slot-scope="{ node,data}" >
                  <span :style="(data.glay || data.type === 'role')? 'color:#ccc': ''" >{{ node.label }}</span>
              </span>
            </el-tree>
          </el-option>
        </el-select>
      </div>
      <div class="dialog-dept-item" v-if="roleId">
        <p  class="dialog-dept-name"><span class="red-text">*</span>角色权限</p >
        <div class="dialog-role-qx">
          <div class="dialog-role-con">
            <span class="role-power-item" v-for="(item,index) in rightTree" :key="index">
              {{item.rightName}}
              <img src="../../../assets/images/close.png" class="role-power-close" @click="deleteQx(item.id)" />
            </span>
          </div>
          <div class="role-power-bottom" v-has="'mgr.member.edit'">
            <img src="../../../assets/images/edit.png" v-has="'mgr.role.query'" class="power-bottom-icon" @click="toAuto" />
            <!-- <img src="../../../assets/images/delete.png" class="power-bottom-icon" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddRole">取消</span>
      <span class="dialog-btn" @click="handleAddRole">确定</span>
    </div>
    
  </el-dialog>
</template>
<script>
import {
  regDeptList,
  regAddRole,
  regModifyRole
} from '../../../api/index'

export default {
  props:{
    childOrg: Array,
    mainOrgDeptTree: Object,
    roleDetail: Object,
    title: String
  },
  data(){
    return{
      theChildOrg: this.childOrg,
      parentOrgArr: [], // 机构和部门组成的树
      name: '', // 角色名称
      deptId:'', // 部门id
      memo: '', // 角色描述
      parentId: null, // 上级部门id
      orgId: null, // 上级组织id
      deptArr: [] ,// 某个组织的所有部门列表
      showPurview: false,
      detpParentId: null, // 选择器中的父级id
      parentName : '', // 机构部门树选中name
      rightIds: [], // 权限
      rightTree:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      }, 
      roleId: null // 当前角色 id
    }
  },
  mounted(){
    console.log('this.theChildOrg',this.theChildOrg)
    this.getParentOrgArr()
    if(this.roleDetail){
      this.setRole()
    }
  },
  methods:{
    // 获取机构和部门组成的树
    getParentOrgArr(){
      let parentOrgArr =  _.cloneDeep(this.theChildOrg) 
      for(let i=0; i< parentOrgArr.length; i++){
        if(parentOrgArr[i].orgName != undefined){
          this.theChildOrg[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      console.log('parentOrgArr',parentOrgArr,this.mainOrgDeptTree)
      parentOrgArr.unshift(this.mainOrgDeptTree)
      for(let i=0; i< parentOrgArr.length; i++){
        parentOrgArr[i].glay = true
        if(parentOrgArr[i].orgName != undefined){
          parentOrgArr[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      this.parentOrgArr =  parentOrgArr
      console.log('this.parentOrgArr',this.parentOrgArr)
    },
    // role详情
    setRole(){
      this.name = this.roleDetail.name
      this.roleId = this.roleDetail.id
      this.memo = this.roleDetail.memo
      this.detpParentId = this.roleDetail.deptId
      this.parentName = this.roleDetail.deptName
      //this.rightTree = this.roleDetail.rightTree
      let selectRight = []
      let allRight = this.roleDetail.rightTree
      for(let i=0; i<allRight.length; i++){
        for(let j=0; j<allRight[i].children.length; j++){
          if(allRight[i].children[j].checked){
            selectRight.push({
              id:allRight[i].children[j].id,
              rightName:allRight[i].children[j].rightName
            })
          }
        }
      }
      this.rightTree = selectRight
      this.rightIds = this.rightTree.map(obj =>{ return obj.id})
    },
    deleteQx(id){
      console.log('deleteQx',id)
      const idx = this.rightIds.indexOf(id)
      this.rightIds.splice(idx, 1);
      this.$nextTick(() => {
        this.rightTree.splice(idx, 1);
       // this.rightTree = this.rightTree
        console.log('this.rightTree',this.rightTree)
      })
      
    },
    // 更改上级部门id
    handleDeptParentId(data) {
      console.log('handleNodeClick',data)
      if(data.glay) return false
      this.parentId = data.id
      this.value = data.label;
      this.$refs.selectTree.blur();
      this.detpParentId = data.id
      this.parentName = data.name
      //this.deptParentId = Number(data.id) 
    },
    // 确认新增
    handleAddRole(){
      console.log('rightIds',this.rightIds,this.rightIds.join(','))
      const params = {
        deptId: this.detpParentId,
        name:this.name,
        memo: this.memo,
        rightIds: this.rightIds.join(',')
      }
      if(this.roleId){
        params.id = this.roleId
        regModifyRole(params).then(res => {
          console.log('修改成功了',res)
          if(res.code === 0){
            this.$message({
              message: '修改成功',
              type: 'success'
            }); 
            this.$emit('modifyRoleSucc')
          }else{
            this.$notify.error({
              title: '错误',
              message: '修改失败！' + res.msg
            })
          }
        })
      }else{
        regAddRole(params).then(res => {
          if(res.code === 0){
            this.roleId = res.data
            this.$message({
              message: '新增成功',
              type: 'success'
            }); 
            this.$emit('addRoleSucc',res.data)
          }else{
            this.$notify.error({
              title: '错误',
              message: '新增失败！' + res.msg
            })
          }
        })
      }
    },
    closeAddRole(){
      this.$emit('closeAddRole')
    },
    // 去授权
    toAuto(){
      this.$emit('toAuto',this.rightTree,this.name)
    },
    filterNode(value, data) {
      console.log('filter',value, data)
      return value.type !== 'role';
    }
  },
  watch:{
    roleDetail(){
      this.setRole()
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 30px
    .dialog-dept-item
      width 462px
      margin-bottom 22px
      .dialog-dept-name
        margin-bottom 11px
      .el-select
        width 460px
      .dialog-area
        width 460px
        height 80px
        padding 10px
        border 1px solid #DADADA
        border-radius 5px
        resize none
      .dialog-dept-text
        width 100%
        height 38px
        line-height 38px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
      .dialog-dept-select
        width 100%
        border none
      .upload-demo
        >>>.el-upload-dragger
          width 200px
          height 89px
          color #009375
          border 1px dashed #009375
          display flex
          flex-direction column
        .upload-img
          width 20px
          margin 15px auto 12px
      .qualif-img 
        position relative
        margin-right 10px
        .qualif-image
          max-width 200px
          max-height 100px
        .close-qualif
          position absolute 
          width 20px
          top -8px
          right -8px
          background #fcfcfc
      .dialog-role-qx
        display inline-block
        width 460px
        height 170px
        padding 10px
        border 1px solid #DADADA
        border-radius 5px
        padding-bottom 30px
        position relative
        .dialog-role-con
          height 130px
          overflow-y auto
          .role-power-item
            display inline-block
            height 24px
            line-height 18px
            border-radius 5px
            background #D0E6E6
            padding 0 8px
            margin 0  10px 10px 0
            color #009375
            font-size 12px
            .role-power-close
              margin-left 5px
              position relative
              top 3px
              cursor pointer
        .role-power-bottom
          position absolute
          bottom 0   
          right 0
          .power-bottom-icon
            margin 0 16px 6px 0
            cursor pointer
    .modify-bars
      margin-bottom 50px
      display flex
      align-items center
      .modify-bar
        display inline-block
        width 20px
        height 20px
        line-height 20px
        border-radius 25px
        background #D8D8D8
        color #fff
        margin 0 20px
        text-align center
      .modify-bar-ac
        width 55px
        background #E0F2EE
        color #009375
      .bar-link
        flex 1
        height 1px 
        background #ccc
    .modify-success
      text-align center
      .modify-par
        color #9e9e9e
        margin 15px 0 22px
      .modify-sencend
        font-size 12px
        color #009375
        margin-bottom 60px
    .dialog-member
      display flex
      .dialog-member-half
        flex 1
        margin-right 18px
        .dialog-dept-item
          width 300px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .dialog-btns-right
    text-align right
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
</style>