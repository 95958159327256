<template>
  <!-- 批量导入 table -->
  <el-dialog
    title="添加日程"
    class="schedule-dialog"
    :visible=true
    @close="closeScheDialog"
    width="380px">
    <div>
      <div class="sche-item">
        <p class="sche-title">选择时间</p>
        <!-- <el-date-picker
          v-model="scheduleTime"
          type="datetime"
          class="sche-time"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker> -->
        <el-date-picker
          v-model="scheduleTime"
          class="sche-time"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </div>
      <div class="sche-item">
        <p class="sche-title">标题</p>
        <input type="text" v-model="title" placeholder="请输入" class="sche-text"/>
      </div>
      <div class="sche-item">
        <p class="sche-title">内容</p>
        <textarea type="text" v-model="content" placeholder="请输入" class="sche-text sche-textarea"></textarea>
      </div>
      <div class="sche-item">
        <p class="sche-title">颜色</p>
        <div class="colors">
          <span class="color-item color-red" @click="setColor('red')" :class="colorVal=='red'?'color-item-active':''">
            <span class="color-item-in "></span>
          </span>
          <span class="color-item  color-pur" @click="setColor('pur')" :class="colorVal=='pur'?'color-item-active':''">
            <span class="color-item-in"></span>
          </span>
          <span class="color-item  color-blue" @click="setColor('blue')" :class="colorVal=='blue'?'color-item-active':''">
            <span class="color-item-in"></span>
          </span>
          <span class="color-item color-green" @click="setColor('green')" :class="colorVal=='green'?'color-item-active':''">
            <span class="color-item-in "></span>
          </span>
        </div>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn" @click="handleAddSche">确定</span>
    </div>
  </el-dialog>
  <!-- 导入文件table end -->
</template>

<script>
import {
  addSchedule
} from '../../api/index'
export default {
  props:{
    currentData: String
  },
  data () {
    return {
      scheduleTime: this.currentData,
      title: '',
      content: '',
      colorVal: 'red'
    }
  },
  mounted(){
    console.log('currentData',this.currentData)
  },
  methods:{
    closeScheDialog(){
      this.$emit('closeScheDialog')
    },
    handleAddSche(){
      if(this.scheduleTime === '' || this.title == '' || this.content == ''){
        this.$message({
          message: '有数据未填写',
          type: 'wanring'
        }); 
        return false
      }
      const parmas = {
        scheduleTime: this.getSimpleDate(this.scheduleTime),
        title: this.title,
        content: this.content,
        color: this.colorVal
      }
      addSchedule(parmas).then(res => {
        if(res.code === 0){
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.$emit('addSchedSucc',this.getYearMountDate(this.scheduleTime))
        }else{
          this.$notify.error({
            title: '错误',
            message: '添加日程失败！'+ res.msg
          });
          
        }
      })
    },
    setColor(co){
      this.colorVal = co
    },
    // 中国标准时间 转换成 年月日
    getSimpleDate(date) {
      console.log('getSimpleDate',date,typeof date,date.length)
      if(!date){
        return false
      }
      if(date.length == 10){
        return date
      }
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? ('0' + minute) : minute;
      var s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s;
    },
    getYearMountDate(date) {
      if(date.length == 10){
        return date
      }
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    },

  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.schedule-dialog
  .sche-item
    .sche-title
      font-size 14px
      color #009375
      font-weight bold
      margin-bottom 10px
    .sche-time
      width 340px
      height 30px
      line-height 30px
      margin-bottom 15px
      >>>.el-input__inner
        height 30px
        line-height 30px
      >>>.el-icon-time:before
          position relative
          top -5px 
    .sche-text
      width 340px
      height 30px
      line-height 30px
      margin-bottom 15px
      background  #efefef
      border-radius 5px
      padding 5px
      font-size 12px
    .sche-textarea
      height 60px
      border none
      resize none
    .colors
      padding-top 10px
      .color-item
        display inline-block
        width 18px
        height 18px
        padding 2px
        margin 0 10px 0 5px
        cursor pointer
        .color-item-in
          display inline-block
          width 12px
          height 12px
          background #E97D7D
          border-radius 50%
      .color-item-active
        border-radius 50%
        border 1px solid #E97D7D
      .color-pur
        .color-item-in
          background #A1A9FB
      .color-blue
        .color-item-in
          background #8cd3fd
      .color-green
        .color-item-in
          background #009375
      .color-pur.color-item-active
        border-color #A1A9FB
      .color-blue.color-item-active
        border-color #8cd3fd
      .color-green.color-item-active
        border-color #009375
  .dialog-btns
    .dialog-btn
      display inline-block
      width 70px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer   
      margin-left 270px
</style>