<template>
  <div class="manage">
    <div class="manage-top" v-has="'mgr.patient.query'">
      <div class="vital-item">
        <div class="vital-con">
          <p class="vital-title">总用户数</p>
          <div class="vital-mes">
            <span class="vital-num">+{{totalCount}} </span>
            <span class="vital-sj" :class="{'vital-sj-red': percentCount>0}">
              <img src="../../assets/images/top_arrow2.png" v-if="percentCount>0" /> 
              <img src="../../assets/images/top_arrow.png" v-if="percentCount<0" /> 
              {{percentCount}}%
            </span>
          </div>
        </div>
      </div>
      <div class="vital-item">
        <div class="vital-con">
          <p class="vital-title">今日随访</p>
          <div class="vital-mes">
            <span class="vital-num">+{{todayVisit}} </span>
            <span class="vital-sj" :class="{'vital-sj-red': percentVisit>0}">
              <img src="../../assets/images/top_arrow2.png" v-if="percentVisit>0" /> 
              <img src="../../assets/images/top_arrow.png" v-if="percentCount<0" /> 
              {{percentVisit}}%
            </span>
          </div>
        </div>
      </div>
      <div class="vital-item">
        <div class="vital-con">
          <p class="vital-title">今日评估</p>
          <div class="vital-mes">
            <span class="vital-num">+{{todayExam}} </span>
            <span class="vital-sj" :class="{'vital-sj-red': percentExam>0}">
              <img src="../../assets/images/top_arrow2.png" v-if="percentExam>0" /> 
              <img src="../../assets/images/top_arrow.png" v-if="percentCount<0" /> 
              {{percentExam}}%
            </span>
          </div>
        </div>
      </div>
      <div class="vital-item">
        <div class="vital-con">
          <p class="vital-title">今日完善档案</p>
          <div class="vital-mes">
            <span class="vital-num">+1{{todayPerfect}} </span>
            <span class="vital-sj" :class="{'vital-sj-red': percentPerfect>0}">
              <img src="../../assets/images/top_arrow2.png" v-if="percentPerfect>0" /> 
              <img src="../../assets/images/top_arrow.png" v-if="percentCount<0" /> 
              {{percentPerfect}}%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-search" >
      <div class="search-left" v-has="'mgr.patient.query'">
        <div class="search-input-out">
          <el-input class="search-input" v-model="keywordText" placeholder="搜索电话、姓名、诊断" />
          <img src="../../assets/images/search.png" class="search-icon" @click="getUserLists" />
        </div>
        <template v-if="isPerson">
          <el-select  class="search-select" v-model="belongTo" placeholder="来源">
            <el-option
              v-for="item in deptDatas"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
        <el-select class="search-select" style="width:180px;" v-model="showTableList" multiple  collapse-tags placeholder="数据展示">
          <el-option
            v-for="item in showSelcts"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="search-select" v-model="groupId" placeholder="分组" v-has="'mgr.group.query'">
          <el-option
            v-for="item in batchListDatas"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="search-right">
        <span class="search-btn" v-has="'mgr.group.query'" @click="handleGroup"><img src="../../assets/images/container.png" class="search-btn-icon"> 分组管理</span>
        <span class="search-btn" v-has="'mgr.patient.import'" @click="getImoprtTemplate"><img src="../../assets/images/import.png"  class="search-btn-icon"> 获取导入模板</span>
        <span class="search-btn" v-has="'mgr.patient.import'" @click="handleImportant"><img src="../../assets/images/import.png" class="search-btn-icon"> 导入数据</span>
        <span class="search-btn" @click="addUser" v-has="'mgr.patient.addPatient'"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增患者</span>
      </div>
    </div>
    <div class="manage-con">
       <el-table
       v-has="'mgr.patient.query'"
      :data="manageData"
      height="calc(100vh - 280px)"
      :header-cell-style="{ background:'#F0F3F6',lineHeight:'18px',padding:'8px 0',color:'#000'}"
      class="mainel-table">
        <el-table-column
          type="index"
          label="编号"
          width="100">
        </el-table-column>
        <el-table-column
          v-if="showTableList.includes('name')"
          prop="name"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column
          v-if="showTableList.includes('sex')"
          prop="sex"
          label="性别">
        </el-table-column>
        <el-table-column
          v-if="showTableList.includes('sex')"
          prop="groupName"
          label="分组">
        </el-table-column>
        <el-table-column
        v-if="showTableList.includes('mobilePhone')"
          prop="mobilePhone"
          label="电话">
        </el-table-column>
        <el-table-column
          prop="diagnose"
          v-if="showTableList.includes('diagnose')"
          label="诊断">
        </el-table-column>
        <el-table-column
          prop="belongTo"
          v-if="showTableList.includes('belongTo')"
          label="来源">
        </el-table-column>
        <el-table-column
          prop="diagnoseTime"
          v-if="showTableList.includes('diagnoseTime')"
          label="诊断时间">
        </el-table-column>
        <el-table-column
          prop="createTime"
          v-if="showTableList.includes('createTime')"
          label="创建时间">
        </el-table-column>
        <el-table-column
          width="180"
          class="table-handles"
          style="white-space: nowrap;width:160px;"
          label="操作">
          <template slot-scope="scope"> <!-- slot-scope="scope" -->
             <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
            <el-button
              type="text"
              @click="toUserDetail(scope.row)"
              class="table-green-btn"
              size="small">
              查看
            </el-button>
            <el-button
              v-has="'mgr.patient.editPatient'"
              type="text"
              class="table-green-btn"
              @click="toUserEdit(scope.row)"
              size="small">
              编辑
            </el-button>
            <el-button
              v-has="'mgr.chat.chat'"
              type="text"
              class="table-green-btn"
              @click="toUseMessage(scope.row)"
              size="small">
              沟通
            </el-button>
            <el-popover
              popper-class="table-popover"
              placement="bottom"
              width="90"
              style="padding:0;min-width:90px!important;"
              trigger="click">
              <div class="handle-con" style="line-height:28px;padding:5px 0;">
                <div class="handle-popver-item" style="padding:0 10px;cursor:pointer;" @click="toAccess(scope.row)" v-has="'mgr.exam.assignExam'">分配测评</div>
                <div class="handle-popver-item" style="padding:0 10px;" v-has="'mgr.visitPlan.bindPlanPerson'"  @click="toPlan(scope.row)">随防计划</div>
                <div class="handle-popver-item" style="padding:0 10px;border-top:1px solid #d8d8d8;" v-if="isShowDelete != 'N' && hasDelete" @click="hanldeDeletePatient(scope.row)" >删除</div>
              </div>
              <el-button
                type="text"
                style="font-size:8px;letter-spacing:inherit;margin-left:5px;"
                class="popover-more table-green-btn" 
                slot="reference"
                size="small">
                ● ● ●
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager" v-has="'mgr.patient.query'">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
        <el-pagination
        :current-page="pageNo"
        :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="total, sizes, prev, pager, next"
        :total="total">
      </el-pagination>
      </div>
    </div>
    <!-- 导入文件 -->
    <el-dialog
      title="批量导入"
      class="batch-upload"
      :visible.sync="uploadDialogVisible"
      width="680px">
      <el-upload
        class="upload-demo"
        drag
        ref="upload" 
        accept=".xls,.xlsx"
        action="https://localhost:8080/posts/"
        :on-change="uploadExcel"
        :show-file-list="false"
        :auto-upload="false"
        multiple>
        <div class="loading" v-if="loadingExcel"><i class="el-icon-loading"></i></div>
        <template v-else>
          <i class="el-icon-upload" ></i>
          <div class="el-upload__text">拖拽文件或选择上传你文件，只能上传excel文档</div>
        </template>
      </el-upload>
      
    </el-dialog>
    <!-- 导入文件end -->
    <batch-add-user
      v-if="uploadTableVisible"
      :menuData="menuData"
      :excelName="excelName"
      @closeAddUser="closeAddUser"
      @batchAddSuccess="batchAddSuccess"
      @closeExcel="closeExcel"
    ></batch-add-user>
    <!-- 删除用户 table -->
    <el-dialog
      title="删除用户"
      class="batch-upload"
      :visible.sync="deleteVisible"
      width="455px">
      <div class="dialog-con">
        <div class="delete-con">
          <img src="../../assets/images/wanning.png" class="wanning-ico" />
          <p class="wanning-par">确定要删除当前患者“xxx”忠者的信息吗？</p>
        </div>
        <div class="delete-par">请谨慎操作，确认删除后当前用户所有数据将被清空，数据无法恢复。正在执行计划也将自动终止，请谨慎操作。</div>
      </div>
      <div class="batch-btns">
        <el-button class="batch-btn" size="small" type="">取消</el-button>
        <el-button class="batch-btn success-btn" size="small" type="">确定</el-button>
      </div>
    </el-dialog>
    <!-- 删除用户 end -->
    <!-- 新增分组 -->
    <el-dialog
      :title="addDialogName"
      class="batch-upload"
      :visible.sync="addGroupVisible"
      width="455px">
      <div class="dialog-con">
        <el-input class="add-group" v-model="currentGroupName"  placeholder="输入分组名"></el-input>
      </div>
      <div class="batch-btns">
        <el-button class="batch-btn" size="small" type="" @click="addGroupVisible = false">取消</el-button>
        <el-button class="batch-btn success-btn" size="small" @click="handleModifyGroup" type="">确定</el-button>
      </div>
    </el-dialog>
    <!-- 新增分组 end -->
    <!-- 分组管理 table -->
    <el-dialog
      title="分组管理"
      class="batch-upload"
      :visible.sync="groupListVisible"
      width="780px">
      <div class="batch-table">
        <el-table                                          
          :data="batchListDatas"
          maxHeight="440px"
          class="user-table">     
          <el-table-column
            type="index"
            label="编号"
            width="100">
          </el-table-column>
          <el-table-column
            prop="name"
            label="分组名">
          </el-table-column>
          <el-table-column
            prop="numofPatient"
            width="80"
            label="成员数">
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间">
          </el-table-column>
          <el-table-column
            width="160"
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <el-button class="group-link" @click="editGroup(scope.row)"  v-has="'mgr.group.editGroup'" style="color:#4a4a4a" type="text" size="small">编辑</el-button>
              <el-button class="group-link" @click="moveGroup(scope.row)"  v-has="'mgr.group.transfer'" style="color:#4a4a4a" type="text" size="small">转移</el-button>
              <el-button class="group-link" @click="deleteGroup(scope.row)"  v-has="'mgr.group.removeGroup'" style="color:#4a4a4a" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add-groupcon">
        <span  class="add-btn" v-has="'mgr.group.addGroup'" @click="toAddGroup"> <span class="add-green">+ </span>新增分组</span>
        <el-pagination
          background
          class="group-pager"
          layout="prev, pager, next"
          :total="groupTotal">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 分组管理 end -->
    <!-- 分组转移 -->
    <el-dialog
      title="分组转移"
      class="batch-upload"
      :visible.sync="transferVisible"
      width="455px">
      <div class="dialog-con">
        <div class="dialog-flex">
          <div class="transfer-item">
            <span>当前分组</span>
            <span class="bold-text">{{currentGroup.name}}</span>
          </div>
          <div class="transfer-item">
            <span>分组成员</span>
            <span class="bold-text">{{currentGroup.numofPatient}}/ 人</span>
          </div>
        </div>
        <div class="transfer-text">—— 转移至以下分组 ——</div>
        <el-select v-model="moveGroupValue" v-has="'mgr.group.query'"  placeholder="请选择" class="transfer-select">
          <el-option
            v-for="item in allGruop"
            :key="item.id"
            :label="item.name"
            :disabled="item.Id === currentGroup.id "
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="batch-btns">
        <el-button @click="removeGroup" class="batch-btn success-btn" size="small" type="">确定</el-button>
        <el-button @click="transferVisible = false" class="batch-btn" size="small" type="">取消</el-button>
      </div>
    </el-dialog>
    <!-- 分组转移 end -->
    <!-- 分组转移确认 -->
    <el-dialog
      title=" 分组转移"
      class="batch-upload"
      :visible.sync="confirmTransGroup"
      width="455px">
      <div class="dialog-con">
        <div class="delete-con">
          <img src="../../assets/images/wanning.png" class="wanning-ico" />
          <p class="wanning-par">转移完成无法撤销，确认转移分组成员吗？</p>
        </div>
      </div>
      <div class="batch-btns">
        <el-button class="batch-btn success-btn" size="small" type="">确定</el-button>
      </div>
    </el-dialog>
    <!-- 分组转移确认 end -->
    <!-- 删除分组 -->
    <el-dialog
      title="删除分组"
      class="batch-upload"
      :visible.sync="deleteTransGroup"
      width="455px">
      <div class="dialog-con">
        <div class="delete-con">
          <img src="../../assets/images/wanning.png" class="wanning-ico" />
          <p class="wanning-par">删除分组前，请先转移分组内的所有成员</p>
        </div>
      </div>
      <div class="batch-btns">
        <el-button class="batch-btn success-btn" size="small" @click="deleteTransGroup = false" type="">确定</el-button>
      </div>
    </el-dialog>
    <!-- 删除分组 end -->
    <access-assign 
      v-if="assignVisable" 
      :assignUser="assignUser"
      @closeAssign="closeAssign"
    ></access-assign>
    <plan-assign
      v-if="assignPlanVisable" 
      :assignUser="assignUser"
      @closePlanAssign="closePlanAssign"
    ></plan-assign>
  </div>
</template>
<script>
//import xlsx from 'xlsx'
import _ from "lodash";
import {
  regUserLists,
  regUserGroupList,
  regAllUserGroup,
  regModifyGroup,
  regAddrGroup,
  regMoveGroup,
  regDeleteGroup,
  regRemovePatient,
  reqUploadTemplate,
  reqUploadPatient,
  regCurDeptList,
  regPatientData,
  regListByModule
} from '../../api/index'
import { powerRight } from '../../utils/index'
import BatchAddUser from '@/components/UserManage/UserCompontent/BatchAddUser'
import AccessAssign from '@/components/UserManage/UserCompontent/AccessAssign'
import PlanAssign from '@/components/UserManage/UserCompontent/PlanAssign'
const xlsx = require('xlsx')
export default {
  data () {
    return {
      uploadDialogVisible: false, //批量导入弹窗
      uploadTableVisible: false, // 批量导入解析table
      deleteVisible: false, // 删除用户
      addGroupVisible: false, // 新增分组
      groupListVisible: false, // 分组管理
      transferVisible: false, // 分组转移
      confirmTransGroup: false, // 确认分组转移
      deleteTransGroup: false, // 删除分组
      menuData: [],
      options:[],
      manageData: [],
      batchListDatas: [
      ],
      belongTo: null, // search 来源
      deptDatas: [],
      groupId: null, // search 分组
      pageNo: 1,
      pageSize:  20,
      total: 0,
      addDialogName: '新增分组',
      currentGroup: {}, // 当前分组
      currentGroupName: '', // 当前分组名
      groupTotal: 0,
      groupNo: 1,
      groupPageSize: 100,
      allGruop: [], // 
      moveGroupValue: '',
      excelName: '', // 导入的excel文件名
      loadingExcel: false, // 是否在解析excel中
      keywordText: '',
      showSelcts:[
        {
          value: 'name',
          label: '姓名'
        },{
          value: 'sex',
          label: '性别'
        },{
          value: 'mobilePhone',
          label: '电话'
        },{
          value: 'diagnose',
          label: '诊断'
        },{
          value: 'belongTo',
          label: '来源'
        },{
          value: 'groupName',
          label: '分组'
        },{
          value: 'diagnoseTime',
          label: '诊断时间'
        },{
          value: 'createTime',
          label: '创建时间'
        }
      ],
      showTableList: ['name','sex','mobilePhone','diagnose','belongTo','groupName','diagnoseTime','createTime'],
      assignVisable: false, // 分配量表
      assignUser: null, // 分配量表用户
      assignPlanVisable: false, // 分配计划
      totalCount: 0, // 总用户数
      todayVisit: 0, //今日随访数量
      todayExam: 0, //今日评测数量
      todayPerfect: 0, //今日完善档案数量
      percentCount: '',
      percentExam: '',
      percentPerfect: '',
      percentVisit: '',
      isShowDelete: 'Y' // 是否显示删除用户
    }
  },
  components:{
    BatchAddUser,
    AccessAssign,
    PlanAssign
  },
  computed:{
    isPerson(){
      return sessionStorage.getItem('isPerson') == 'true' ? true :false
    },
    hasDelete(){
      return powerRight('mgr.patient.remove')
    }
  },
  mounted(){
    if(powerRight('mgr.patient.query')){
      this.getUserLists()
    }
    if(this.isPerson){
      this.getDepts()
    }
    if(powerRight('mgr.group.query')){
      this.getGroupDatas()
    }
    if(powerRight('mgr.patient.query')){
      this.getTopData()
    }
    this.getAllModule()
  },
  watch:{
    // showTableList(val){

    // }
  },
  methods:{
    // 是否可以删除
    getAllModule(){
      regListByModule({configModule:'base'}).then(res => {
        if(res.code === 0){
          let data = res.data
          for(let i=0; i<data.length; i++){
            if(data[i].configCode === 'base.patient.remove'){
              this.isShowDelete = data[i].configValue 
            }
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    // 头部统计信息
    getTopData(){
      regPatientData().then(res => {
        if(res && res.code === 0){
          this.totalCount = res.data.totalCount
          this.todayVisit = res.data.todayVisit
          this.todayExam = res.data.todayExam
          this.todayPerfect = res.data.todayPerfect
          this.percentCount = Number(res.data.percentCount)
          this.percentExam = Number(res.data.percentExam)
          this.percentPerfect = Number(res.data.percentPerfect)
          this.percentVisit = Number(res.data.percentVisit)
          //this.contPer = this.calculatePercentage(res.data.todayCount/res.data.yesterdayCount)
        }else{
          this.$message({
            message: '获取统计信息失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    calculatePercentage(numerator, denominator) {
      console.log('calc',numerator,denominator)
      if (denominator === 0) {
        return '0%'
      }
      return (numerator / denominator * 100).toFixed(2) + '%'; // 保留两位小数
    },
    // 当前用户的部门列表
    getDepts(){
      regCurDeptList().then(res => {
        if(res && res.code === 0){
          this.deptDatas = res.data
        }else{
          this.$message({
            message: '获取部门列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getUserLists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getUserLists()
    },
    // 列表
    getUserLists(){
      console.log('pageSize',this.pageSize,this.pageNo)
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        belongTo: this.belongTo,
        groupId: this.groupId,
        keyword: this.keywordText
      }
      console.log('params',params)
      regUserLists(params).then(res => {
        console.log('列表',res)
        if(res && res.code === 0){
          this.manageData = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$message({
            message: '获取列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 批量导入
    handleImportant(){
      this.uploadDialogVisible = true
    },
    uploadExcel (file, fileList) {
      console.log('fileList',fileList)
      const files = { 0: file.raw }// 取到File
      this.loadingExcel = true
      console.log('true')
      this.readExcel(files)
    },
    readExcel (files) { // 表格导入
      if (files.length <= 0) { // 如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      console.log('files',files)
      this.excelName = files[0].name
      const fileReader = new FileReader()
      fileReader.onload = (files) => {
        try {
          const data = files.target.result
          
          const workbook = xlsx.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0]// 取第一张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
          this.menuData = _.map(ws, obj => ({  
            name: obj.姓名, 
            sex: obj.性别,
            mobilePhone: obj.手机号,
            diagnose: obj.诊断,
            diagnoseTime: obj.诊断时间,
            birthday: obj.生日,
            belongTo: obj.归属,             
          }));
          if(this.menuData.length){
            this.uploadTableVisible = true
          }
          console.log( 'ws是表格里的数据，且是json格式',ws,this.menuData)
          // 重写数据
          this.loadingExcel = false
          this.$refs.upload.value = ''
          // return ws
        } 
        catch (e) {
          console.log(e)
          this.loadingExcel = false
          return false
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
    // 新增用户
    addUser() {
      console.log('新增患者')
      this.$router.push({ path: '/EditUser'})
    },
    // 删除分组
    deleteGroup(data){
      if(data.numofPatient > 0){
        this.deleteTransGroup = true
        return false
      }
      regDeleteGroup({
        id:data.id
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.handleGroup()
        }else{
          this.$message({
            message: '删除分组失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 打开分组
    handleGroup(){
      this.groupListVisible = true
      this.getGroupDatas()
    },
    getGroupDatas(){
      regUserGroupList({
        pageNo: this.groupNo,
        pageSize: this.groupPageSize,
        name: ''
      }).then(res => {
        if(res && res.code === 0){
          this.batchListDatas = res.data.list
          this.groupTotal = res.data.totalCount
        }else{
          this.$message({
            message: '获取分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 编辑分组
    editGroup(data){
      console.log('data',data)
      this.currentGroup = data
      this.currentGroupName = data.name
      this.addDialogName = '编辑分组'
      this.addGroupVisible = true
    },
    moveGroup(data){
      this.currentGroup = data
      console.log('currentGroup',this.currentGroup)
      this.transferVisible = true
      this.getAllGroup()
    },
    // 点击新增分组
    toAddGroup(){
      this.addGroupVisible = true
      this.addDialogName = '新增分组'
      this.currentGroupName = ''
      this.currentGroup = {}
    },
    // 所有分组
    getAllGroup(){
      regAllUserGroup({}).then(res => {
        if(res && res.code === 0){
          this.allGruop = res.data
        }else{
          this.$message({
            message: '分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 新增修改分组
    handleModifyGroup(){
      if(this.currentGroup && this.currentGroup.id){
        // 修改
        regModifyGroup({
          name: this.currentGroupName,
          id: this.currentGroup.id
        }).then(res => {
          if(res && res.code === 0){
            this.addGroupVisible = false
            this.handleGroup()
          }else{
            this.$message({
              message: '修改分组失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        regAddrGroup({
          name: this.currentGroupName,
        }).then(res => {
          if(res && res.code === 0){
            this.addGroupVisible = false
            this.handleGroup()
          }else{
            this.$message({
              message: '修改分组失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    removeGroup(){
      regMoveGroup({
        newId: this.moveGroupValue,
        id: this.currentGroup.id
      }).then(res => {
        if(res && res.code === 0){
          this.transferVisible = false
          this.handleGroup()
          this.$message({
            message: '转移成功',
            type: 'success'
          }); 
        }else{
          this.$message({
            message: '转移分组失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 查看患者
    toUserDetail(data){
      console.log('data',data)
      this.$router.push({ path: `/UserDetail/${data.id}`})
    },
    // 编辑患者
    toUserEdit(data){
      console.log('data',data)
      this.$router.push({ path: `/EditUser/${data.id}`})
    },
    // 跟患者沟通
    toUseMessage(data){
      console.log('data',data)
      this.$router.push({ path: `/weChat`,query: { userId: data.userId }})
    },
    // 删除患者
    async hanldeDeletePatient(data){
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
      }
      regRemovePatient({
        id: data.id
      }).then(res => {
        if(res && res.code === 0){
          this.getUserLists()
        }else{
          this.$message({
            message: '删除患者失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 获取批量导入模板
    getImoprtTemplate(){
      reqUploadTemplate().then(res => {
        if(res && res.code === 0){
          if(res.data && res.data.url){
            window.open(res.data.url)
          }
        }else{
          this.$message({
            message: '获取批量导入模板失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 分配测评
    toAccess(data){
      this.assignUser = data
      this.assignVisable = true
      console.log('toAccess',data)
    },
    closeAssign(){
      this.assignVisable =  false
    },
    toPlan(data){
      this.assignUser = data
      this.assignPlanVisable = true
    },
    closePlanAssign(){
      this.assignPlanVisable =  false
    },
    // 批量新增
    closeAddUser(){
      this.uploadTableVisible = false
    },
    // 删除整个批量上传的excel
    closeExcel(){
      this.menuData = []
      this.uploadTableVisible = false
      this.uploadDialogVisible = true
    },
    // 批量新增成功
    batchAddSuccess(){
      this.uploadTableVisible = false
      this.uploadDialogVisible = false
      this.getUserLists()
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.manage
  padding 18px 20px
  .manage-top
    border 1px solid #D8D8D8
    border-radius 10px
    display flex
    margin-bottom 25px
    .vital-item
      padding 19px  24px
      border-right 1px solid #D8D8D8
      flex 1
      .vital-con
        .vital-title
          margin-bottom 14px
        .vital-mes
          display flex
          justify-content space-between
          align-items flex-end
          .vital-num
            font-size 28px
            font-weight bold
          .vital-sj
            display inline-block
            height 16px
            line-height 16px
            background #E0F2EE
            color #009375
            padding 0 3px
            border-radius 2px
          .vital-sj-red
            background #FAE8DF 
            color #FF630F
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .manage-con
    .mainel-table
      width 100%
      font-size 14px
      overflow auto
      >>>tr
        line-height 18px
        margin 4px 0
        >>>.el-button--text
          color #4a4a4a!important
        &:hover>td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
          .table-green-btn
            color #00A389!important
            font-weight 700!important
      >>>.table-handles
        white-space nowrap
      .table-green-btn
        font-size 14px
        color #4a4a4a!important
        font-weight bold
      .popover-more
        margin-left 10px!important
        position relative
        top -4px
    .pager
      margin 30px 0 10px
      text-align center
      position relative
      >>>.el-pagination__total
        position absolute
        left 0
        top 0
      >>>.el-pagination__sizes
        position absolute
        left 80px
        top 0
      >>>.el-pager
        .number
          min-width 32px
          &:hover
            color 00A389
        .active
          background #00A389
          color #fff
          border-radius 3px
          width 32px
  .batch-upload
    >>>.el-icon-upload
      margin 22px 0 22px
      &::before
        content ""
        display inline-block
        width 40px
        height 30px
        background url(../../assets/images/upload.png) no-repeat
    >>>.el-dialog__header
      border-bottom 1px solid #d8d8d8
      font-size 16px
      font-weight bolder
    >>>.el-upload__text
      color #009375
    >>>.el-upload-dragger
      width 642px
      height 137px
      border-color #009375 
    >>>.loading
      margin-top 40px
      i
        font-size 30px
    .batch-table
      >>>.has-gutter
        border-radius 5px
        overflow hidden
      >>>td
        padding 9px 0
        height 25px
        line-height 25px
      >>>tr
        &:hover
          cursor pointer
          border-radius 3px
          >td 
            background  #E0F2EE
            color #00A389
            font-weight  bolder
      >>>th
        padding 3px 0
        height 34px
        line-height 34px
        background #F0F0F5
        border none
    .batch-btns
      padding 20px 100px
      display flex
      justify-content space-around
      .batch-btn
        width:70px
        height 34px
        border-radius 5px
        background #F0F3F6
        border none
      .success-btn
        background #009375
        color #fff
    .dialog-con
      text-align center
      .delete-con
        color #FF630F
        font-size 14px
        .wanning-ico
          width 28px
          margin-bottom 20px
      .delete-par
        margin-top 15px
        line-height 18px
        margin-bottom 10px
      .add-group
        width 300px
        background #F7F8FA
      .dialog-flex
        display flex
        width 100%
        margin-bottom 24px
        .transfer-item
          display flex
          flex 1
          justify-content space-around
      .transfer-text
        text-align center
        color #009375
        margin-bottom 20px
      .transfer-select
        margin-bottom 20px
        >>>.el-input__inner
          width 250px
          height 30px
          line-height 30px
          >>>.el-input__icon
            line-height 30px
    .add-groupcon
      margin-top 30px
      display flex
      justify-content space-between
      align-content center
      .add-btn
        display inline-block
        width 104px
        height 40px
        line-height 35px
        border-radius 5px
        border 1px solid #D8D8D8
        text-align center
        cursor pointer
        .add-green
          color #00A389
          font-size 24px
          position relative
          top 3px
      .group-pager
        >>>.el-pager li:not(.disabled).active
          background #00A389
          border-radius 8px
</style>