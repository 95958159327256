<template>
  <div class="from-detail">
     <!-- detail-con -->
    <div class="detail-con">
      <!-- detail-left -->
      <div class="detail-left">
        <el-form 
          :model="ruleForm" 
          status-icon 
          ref="ruleForm" 
          label-width="100px" 
          class="demo-ruleForm"
          label-position="top"
        >
        <div class="detail-item">
          <div class="detail-item-title">患者详细信息</div>
            <div class="detail-inner">
              <div class="from-par">
                <el-form-item label="姓名" prop="pass"  class="tform-item tform-item-require" >
                  <el-input 
                    type="text" 
                    disabled
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="checkPass"  class="tform-item tform-item-require">
                  <el-input type="text" disabled  autocomplete="off"></el-input>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="联系方式" prop="pass"  class="tform-item tform-item-require">
                  <el-input 
                    disabled 
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="出生日期" prop="checkPass"  class="tform-item tform-item-require">
                  <el-date-picker
                    align="right"
                    disabled 
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="民族" prop="pass"  class="tform-item tform-item-require">
                  <el-select disabled v-model="nullSelect" placeholder="民族"><!--  v-model="value" -->
                    
                  </el-select>
                </el-form-item>
                <el-form-item label="职业" disabled  prop="checkPass"  class="tform-item">
                  <el-input disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="婚姻" disabled  prop="checkPass"  class="tform-item">
                  <el-input disabled autocomplete="off"></el-input>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="居住地址" prop="pass"  class="tform-item">
                  <el-select disabled style="width:30%" v-model="nullSelect"  placeholder="省份" class="tform-item"></el-select>
                  <el-select disabled style="width:30%" v-model="nullSelect"  placeholder="城市" class="tform-item"></el-select>
                  <el-input disabled style="width:30%"  v-model="nullSelect" placeholder="详细地址" class="tform-item"></el-input>
                </el-form-item>
                </div>
            </div>
            
          <div class="new-from">
            <div class="new-from-title">自定义字段</div>
            <div class="new-from-con">
               <div class="from-zdy">
                <!-- 
                <el-form-item label="性别" prop="checkPass"  class="tform-item tform-item-require">
                  <el-input type="text" disabled autocomplete="off"  />
                </el-form-item> -->
                <el-form-item :label="item.fieldTitle"  v-for="(item,index) in fields" :key="index" prop="pass"  class="tform-item " :class="item.fieldRequired === 'Y'?'tform-item-require' : ''" >
                  <span class="from-handle">
                    <span class="form-handle-item"  @click="editItem(index)">编辑</span> | <span @click="delItem(index)" class="form-handle-item">删除</span>
                  </span>
                  <el-input type="text" v-if="item.fieldType === 'input'"  disabled :placeholder="item.fieldGuide" autocomplete="off"  />
                  <textarea type="text" v-else-if="item.fieldType === 'textarea'" class="from-texarea" disabled  autocomplete="off"  />
                  <el-radio-group class="configer-radios" v-else-if="item.fieldType === 'single_select'" >
                    <el-radio disabled v-for="(sen,j) in item.fieldOpts" :key="j">{{sen}}</el-radio>
                  </el-radio-group>
                  <el-checkbox-group v-else-if="item.fieldType === 'multi_select'">
                    <el-checkbox disabled v-for="(sen,j) in item.fieldOpts" :key="j">{{sen}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <!--<el-form-item  prop="pass" label="标题"   class="tform-item tform-item-require" >
                  <textarea type="text" class="from-texarea" disabled  autocomplete="off"  />
                </el-form-item>
                <el-form-item  prop="pass" label="标题"   class="tform-item tform-item-require" >
                  <input type="text" disabled  autocomplete="off"  />
                </el-form-item>
                <el-form-item  prop="pass" label="标题"   class="tform-item tform-item-require" >
                  <el-radio-group class="configer-radios" >
                    <el-radio  label="Y">开启</el-radio>
                    <el-radio label="N">关闭</el-radio>
                    <el-radio  label="Y">开启</el-radio>
                    <el-radio label="N">关闭</el-radio>
                    <el-radio  label="Y">开启</el-radio>
                    <el-radio label="N">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item  prop="pass" label="标题"   class="tform-item tform-item-require" >
                  <el-checkbox-group>
                    <el-checkbox disabled>备选项</el-checkbox>
                    <el-checkbox disabled>备选项</el-checkbox>
                    <el-checkbox disabled>备选项</el-checkbox>
                    <el-checkbox disabled>备选项</el-checkbox>
                    <el-checkbox disabled>备选项</el-checkbox>
                    <el-checkbox disabled>备选项</el-checkbox>
                  </el-checkbox-group>
                </el-form-item> -->
              </div>
            </div>
          </div>
        </div>
        </el-form>
      </div>
      <!-- detail-left -->
      <div class="detail-right">
        <div class="add-cons" v-if="!isAdd" @click="toAdd">
          <span class="add-con-btn" ><img src="../../assets/images/add.png" class="btn-icon" /> 新增字段</span>
        </div>
        <div class="add-froms" v-else>
          <div class="add-froms-title">新增字段</div>
          <div class="add-froms-items" >
            <div class="add-froms-item">
              <span class="add-froms-name">字段类型：</span>
              <div class="add-froms-right">
                <span 
                  class="from-type-name" 
                  :class="{'from-type-name-active': fieldType==='input'}" 
                  @click="handleChangeType('input')"
                >输入框</span>
                <!-- <span 
                  class="from-type-name" 
                  :class="{'from-type-name-active': fieldType===2}" 
                  @click="handleChangeType(2)"
                >下拉列表</span> -->
                <span 
                  class="from-type-name" 
                  :class="{'from-type-name-active': fieldType==='textarea'}" 
                  @click="handleChangeType('textarea')"
                >多行文本</span>
                <span 
                  class="from-type-name" 
                  :class="{'from-type-name-active': fieldType==='single_select'}" 
                  @click="handleChangeType('single_select')"
                >单选</span>
                <span 
                  class="from-type-name" 
                  :class="{'from-type-name-active': fieldType==='multi_select'}" 
                  @click="handleChangeType('multi_select')"
                >多选</span>
              </div>
            </div>
            <!-- 内容开始 -->
            <div class="add-froms-item add-froms-top">
              <span class="add-froms-name">标   题：</span>
              <div class="add-froms-right">
                <input type="text" class="add-input" v-model.trim="fieldTitle" />
                <p class="add-from-note"> 输入最长不超过8个字符标题 </p>
              </div>
            </div>
            <template v-if="fieldType === 'input'">
              <div class="add-froms-item add-froms-top">
                <span class="add-froms-name">引 导 语：</span>
                <div class="add-froms-right">
                  <input type="text" class="add-input" v-model.trim="fieldGuide" />
                  <p class="add-from-note"> 最长不超过14个字符，用于提示用户如何输入 </p>
                </div>
              </div>
            </template>
            <template v-if="fieldType === 2">
              <div class="add-froms-item add-froms-top">
                <span class="add-froms-name">列 表 值：</span>
                <div class="add-froms-right">
                  <textarea type="text" class="add-input add-textarea"></textarea>
                  <p class="add-from-note"> 多个值用回车隔开 </p>
                </div>
              </div>
            </template>
            <template v-if="fieldType === 'textarea'">
              <div class="add-froms-item add-froms-top">
                <span class="add-froms-name">列 表 值：</span>
                <div class="add-froms-right">
                  <textarea type="text" class="add-input add-textarea" v-model="fieldGuide"></textarea>
                  <p class="add-from-note"> 最长不超过24个字符，用于提示用户如何输入。 </p>
                </div>
              </div>
            </template>
            <template v-if="fieldType === 'single_select' || fieldType === 'multi_select'">
              <div class="add-froms-item add-froms-top">
                <span class="add-froms-name">选 项 值：</span>
                <div class="add-froms-right">
                  <div  v-for="(item, index) in selectItems" :key="index">
                    <div class="add-froms-sle">
                      <input type="text" class="add-input" :value="item" @change="changeSelItem($event,index)" />
                      <span v-if="index === 0" class="add-sel-icon" @click="handleAddSel">+</span>
                      <span v-else class="add-sel-icon"  @click="handleDelSel(index)">-</span>
                    </div>
                    <p class="add-from-note marg-bot"> 最长不超过24个字符，用于提示用户如何输入。 </p>
                  </div>
                  <!-- <div class="add-froms-sle"><input type="text" class="add-input"/><span class="add-sel-icon">-</span></div>
                  <p class="add-from-note"> 最长不超过24个字符，用于提示用户如何输入。 </p> -->
                </div>
              </div>
            </template>
            <div class="add-froms-item">
              <span class="add-froms-name">是否必填：</span>
              <div class="add-froms-right">
                <span class="add-ismust">
                  <span class="ismust-item" @click="handleChangeMust('Y')" :class="{'ismust-item-active':fieldRequired === 'Y' }"><span class="select-cicle"></span>必填</span>
                  <span class="ismust-item" @click="handleChangeMust('N')" :class="{'ismust-item-active': fieldRequired === 'N' }"><span class="select-cicle"></span>选填</span>
                </span>
              </div>
            </div>
            <!-- 内容开始 -->
          </div>
        </div>
        <div class="from-item-btns" v-if="isAdd">
          <span class="from-item-btn" @click="noAdd">取消</span>
          <span class="from-item-btn from-item-btn-blue" @click="confirmAdd" v-has="'mgr.form.editForm'">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  RegFormdynList,
  regFormdynDetail,
  regAddform,
  regEditForm
} from '../../api/index'
export default {
  name: 'FromDetail',
  data() {
    return {
      id: null,
      formName: '',
      editIdx: null, // 当前在编辑第几个
      isAdd: false, // 是否在新增中
      ruleForm: { 
      },
      fieldType: 'input', // input 单行输入/textarea多行输入/multi_select多选/single_select单选
      fieldTitle: '', // 标题
      fieldGuide: '', // 指导语
      fieldRequired: 'Y', // 是否必填Y/N
      selectItems:[
        ''
      ],
      nullSelect: '',
      fields: [], // 新增的
    }
  },
  mounted(){
    this.getAallFrom()
    if(this.$route.query.detailId){
      console.log('id',this.$route.query.detailId)
      this.getDetail(this.$route.query.detailId)
      this.id = this.$route.query.detailId
    }
  },
  methods:{
    handleChangeMust(type){
      this.fieldRequired = type 
    },
    handleChangeType(type){
      this.fieldType = type
      this.fieldTitle =  '' // 标题
      this.fieldGuide = '' // 指导语
      this.fieldRequired = 'Y'// 是否必填Y/N
      this.selectItems = ['']
    },
    getDetail(id){
      regFormdynDetail({
        id
      }).then(res => {
        if(res.code === 0){
          this.fields = JSON.parse(res.data.fields)
          this.formName = res.data.formName
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取表单详情失败！'
          });
          
        }
      })
    },
    toAdd(){
      this.isAdd = true
      this.editIdx = null
    },
    noAdd(){
      this.isAdd = false
      this.fieldTitle = ''
      this.fieldGuide = ''
      this.fieldRequired= 'Y'
      this.selectItems=['']
    },
    // 新增选项
    handleAddSel(){
      let prevItem = this.selectItems[this.selectItems.length - 1]
      console.log('prevItem',prevItem)
      if(prevItem.length === 0){
        this.$message.warning('请先填写上一项再添加！')
        return false
      }
      this.selectItems.push('')
    },
    handleDelSel(i){
      //this.selectItems.pop()
      this.selectItems.splice(i,1)
    },
    changeSelItem(event,i){
      console.log('change',event.target.value,i)
      this.selectItems[i] = event.target.value.trim()
    },
    // 获取所有表单
    getAallFrom(){
      RegFormdynList().then(res => {
        if(res.code === 0){
          console.log('获取所有表单',res.data)
          
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取所有表单失败！'
          });
          
        }
      })
    },
    // 确认新增
    confirmAdd(){
      if(!this.fieldTitle.length){
        this.$message.warning('标题不能为空')
        return false
      }else if(this.fieldTitle.length>8){
        this.$message.warning('标题不能超过8个字符')
        return false
      }
      if(this.fieldType === 'input' && this.fieldGuide.length >14){
        this.$message.warning('引导语不能超过14个字符')
        return false
      }
      if(this.fieldType === 'textarea' && this.fieldGuide.length >24){
        this.$message.warning('引导语不能超过24个字符')
        return false
      }
      let params = {
        fieldType: this.fieldType,
        fieldTitle: this.fieldTitle,
        fieldRequired: this.fieldRequired
      }
      if(this.fieldType === 'textarea' || this.fieldType === 'input'){
        params.fieldGuide = this.fieldGuide
      }
      if(this.fieldType === 'multi_select' || this.fieldType === 'single_select'){
        params.fieldOpts = this.selectItems
      }
      console.log('add par',params)
      if(this.editIdx != null){
        this.fields.splice(this.editIdx,1,params)
      }else{
        this.fields.push(params)
      }
      
      this.fieldTitle = ''
      this.fieldGuide = ''
      this.fieldRequired= 'Y'
      this.selectItems=['']
      if(this.id){
        this.editFrom()
      }
    },
    // 删除
    delItem(idx){
      this.fields.splice(idx,1)
      this.editFrom()
    },
    // 编辑idx
    editItem(idx){
      this.isAdd = true
      this.editIdx  = idx
      const data = this.fields[idx]
      console.log('this.data',data)
      this.fieldTitle = data.fieldTitle
      this.fieldGuide = data.fieldGuide
      this.fieldRequired= data.fieldRequired
      this.fieldType = data.fieldType
      this.selectItems= data.fieldOpts
    },
    // 编辑表单
    editFrom(){
      regEditForm({
        fieldCount: this.fields.length,
        fields: JSON.stringify(this.fields),
        id: this.id,
        formName: this.formName
      }).then(res => {
        if(res.code === 0){
          console.log('获取所有表单',res.data)
          this.$message({
            message: '操作成功',
            type: 'success'
          }); 
          this.isAdd = false
        }else{
          this.$notify.error({
            title: '错误',
            message: '操作失败！'
          });          
        }
      })
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.from-detail
  padding 10px 29px
  .detail-con
    display flex
    padding 0
    border-radius 5px
    border 1px solid #CED4DA
    .detail-left,.detail-right
      flex 1
      .detail-item
        display inline-block
        width 100%
        margin-bottom 20px
        .detail-item-title
          height 40px
          line-height 40px
          padding 0 8px
          border-radius 5px 0 0px 0px
          background #F0F0F5
          border-bottom 1px solid #CED4DA
          font-size 14px
          color #000
        .detail-inner
          padding 10px 20px
          .from-par
            display flex
            margin-bottom 0
            .tform-item
              flex 1
              margin-right 20px
              >>>.el-form-item__label
                height 24px
                line-height 24px
              >>>.el-form-item__content
                height 30px
                .el-input__inner
                  height 30px
                  line-height 30px
                  border-radius 3px
                .el-date-editor--date
                  width 100%
              .from-flex
                display inline-block
                flex 1
            &:nth-child
              margin-right 0
            .tform-item-require
              .el-form-item__label
              &::before
                display inline-block
                content "*"
                color red
                margin-right 5px
          .from-item-btns
            display flex 
            padding 0 40px
            justify-content space-around
            .from-item-btn
              padding 0 9px 0 17px
              height 34px
              line-height 34px
              background #F0F0F5
              border-radius 5px
              letter-spacing 8px
              margin 15px 0 10px
              cursor pointer
            .from-item-btn-blue
              background #009375
              color #fff
        .new-from
          .new-from-title
            padding 10px 20px
            font-size 16px
            font-weight bold
            border-bottom 1px solid  #CED4DA
            margin-bottom 10px 
          .new-from-con
            padding 20px 29px
            .from-par
              display flex
              margin-bottom 0
              .tform-item
                flex 1
                margin-right 20px
                >>>.el-form-item__label
                  height 24px
                  line-height 24px
                >>>.el-form-item__content
                  height 30px
                  .el-input__inner
                    height 30px
                    line-height 30px
                    border-radius 3px
                  .el-date-editor--date
                    width 100%
                .from-flex
                  display inline-block
                  flex 1
                // .from-texarea
                //   height 40px
                //   resize none
              &:nth-child
                margin-right 0
              .tform-item-require
                .el-form-item__label
                &::before
                  display inline-block
                  content "*"
                  color red
                  margin-right 5px
            .from-zdy
              display flex
              flex-wrap wrap
              .tform-item-require
                .el-form-item__label
                &::before
                  display inline-block
                  content "*"
                  color red
                  margin-right 5px
              .tform-item
                width 270px
                margin-right 30px
                position relative
                .from-handle
                  position absolute
                  right 10px 
                  top -30px
                  font-size 12px
                  color #009375
                  .form-handle-item
                    cursor pointer
                >>>.el-form-item__label
                  height 24px
                  line-height 24px
                >>>.el-form-item__content
                  height 30px
                  .el-input__inner
                    height 30px
                    line-height 30px
                    border-radius 3px
                  .el-date-editor--date
                    width 100%
                >>>.el-radio__inner
                  background #fff
                >>>.el-radio__inner::after
                  background #fff
                input ,textarea
                  width 100%
                  height 34px
                  line-height 34px
                  background #F5F7FA
                  border 1px solid #E4E7ED
                textarea
                  margin-top 2px
                .el-checkbox-group
                  line-height 1.2
                  padding-top 12px
                .el-checkbox
                  height 20px
                  line-height 1
                  padding 0
                  margin  0 20px 0 0
                .from-flex
                  display inline-block
                  flex 1

      .other-main
        padding 14px 20px
        border-bottom 1px solid #F0F0F5
        .other-title
          line-height 18px
          margin-bottom 15px
        .diseas
          margin-bottom  15px
          .diseas-item
            display inline-block
            padding 0 15px
            height 26px
            line-height 26px
            border-radius 3px
            margin 0  12px 4px 0
            cursor pointer
          .diseas-item-active
            background #E0F2EE
            color #009375 
        .person
          display flex
          .person-name
            margin-right 24px
          .person-items
            flex 1
            .person-item
              display flex
              align-items center
              margin-bottom 10px
              >>>.el-form-item__content
                height 30px
              >>>.el-input__inner
                height 30px
                line-height 30px
                font-size 12px
              .dieas-type
                width 150px
                margin-right 19px
                >>>.el-input__icon
                  line-height 30px
              .dieas-des
                width 40%
              .handle-span
                display inline-block
                width 20px
                height 20px
                line-height 20px
                border 1px solid #BFBFBF
                border-radius 3px
                color #BFBFBF
                text-align center 
                margin-left 12px
                font-size 18px
                cursor pointer
        &:last-child
          border-bottom none
    .detail-left
      border-right 1px solid #d8d8d8
    .detail-right
      flex 1
      .add-cons
        margin 80px 0
        text-align center
        .add-con-btn
          width 110px
          height 40px
          line-height 40px
          border-radius 5px
          background #FFFFFF
          border 1px solid #D8D8D8
          padding 0 15px 
          display flex
          align-items center
          cursor pointer
          margin 0 auto
          .btn-icon
            margin-right 7px
      .add-froms
        padding 16px 33px
        .add-froms-title
          font-weight  bold
          margin-bottom 30px
        .xx
          .search-btn
            display inline-block
            height 40px
            padding 0 10px
            border 1px solid #d8d8d8
            border-radius 5px
            margin-left 25px
            align-items center
            cursor pointer
            .search-btn-icon
              margin-right 10px
              position relative
              top 3px
        .add-froms-items
          .add-froms-item
            margin-bottom 30px
            display flex 
            align-items center
            font-size 14px
            color #4A4A4A
            margin-bottom 25px
            .add-froms-name
              width 80px
              margin-right 15px
            .add-froms-right
              flex 1
              .from-type-name
                display inline-block
                width 77px
                height 30px
                line-height 30px
                border-radius 5px
                border 1px solid #E8E8E8
                text-align center
                margin-right 19px
                cursor pointer
              .from-type-name-active
                border none 
                background #E0F2EE 
                color #009375 
              .add-input
                width 80%
                height 30px
                padding 5px 
                line-height 20px
                border-radius 3px
                border 1px solid #CED4DA
                outline none
              .add-sel-icon
                display inline-block
                width 22px
                height 22px
                line-height 20px
                border-radius 50%
                border 1px solid #999
                color #999
                text-align center
                margin-left 20px
                cursor pointer
              .add-textarea
                height 60px
                resize none
              .add-from-note
                font-size 12px
                color #9e9e9e
                margin-top 7px
              .marg-bot
                margin-bottom 19px
              .ismust-item
                display inline-block
                width 88px
                height 30px
                line-height 30px
                border-radius 3px
                border 1px solid #CED4DA
                margin-right 35px
                cursor pointer
                .select-cicle
                  display inline-block
                  width 14px
                  height 14px
                  background #D8D8D8
                  border-radius 50%
                  margin 0 20px 0 8px
                  position relative
                  top 2px
                  font-size 16px
              .ismust-item-active
                border 1px solid #009375
                color #009375
                background #E0F2EE
                .select-cicle
                  background #009375
        .add-froms-top
          align-items flex-start!important
          .add-froms-name
            margin-top 5px   
      .from-item-btns
        display flex 
        padding 0 40px
        justify-content space-around
        .from-item-btn
          padding 0 9px 0 17px
          height 34px
          line-height 34px
          background #F0F0F5
          border-radius 5px
          letter-spacing 8px
          margin 15px 0 10px
          cursor pointer
        .from-item-btn-blue
          background #009375
          color #fff   
</style>