<template>
  <el-dialog
    title="编辑权限"
    class="dialog-main"
    :visible=true
    @close="closePurview"
    width="700px">
    <div class="dialog-con">
      <div v-if="memberId" class="pur-label">当前正对「{{memberName}}」的权限进行管理!</div>
      <div class="pur-label" v-else>当前正对「{{currentRoleName}}」角色的权限进行管理!</div>
      <div class="purview-con">
        <div class="purview-item" v-has="'mgr.member.query'">
          <p class="purview-name" 
            v-for="(item,index) in permissionTree" 
            :key="index"
            :class="{'purview-name-active':item.id === parentId}"
            @click="changeFirst(item.id)"
            :title="item.rightName"
          >{{item.rightName}}</p>
          <!-- <p class="purview-name purview-name-active">总      控</p>
          <p class="purview-name">组织架构</p>
          <p class="purview-name">人员管理</p>
          <p class="purview-name">职位权限</p> -->
        </div>
        <div class="purview-item">
          <div class="purview-title">功能表</div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group 
            v-model="selectPower" 
            @change="handleCheckedPurview()"
          >
            <el-checkbox 
              v-for="item in rightArr" 
              :key="item.id"
              :class="{'purview-name-active': selectPower.indexOf(item.id) > -1}"
              @change="setPower(item)"
              :label="item.id"
            >{{item.rightName}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="purview-item">
          <div class="purview-title">已分配权限</div>
          <div v-if="selectPower.length">
            <div v-for="(item,index) in permissionTree" :key="index">
              <p class="purview-name"  v-if="parentIds.indexOf(item.id) > -1">{{item.rightName}}</p>
              <div v-for="(exam,i) in item.children" :key="i">
                <div 
                  class="purview-sec"
                  :title="exam.rightName"
                  v-if="selectPower.indexOf(exam.id) > -1"
                >
                  <span class="purview-sec-name">{{exam.rightName}}</span>
                  <img src="../../../assets/images/delete_black.png" class="purview-delete" @click="deletePower(exam.id)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closePurview">取消</span>
      <span class="dialog-btn" @click="handleConfirmAuth">确定</span>
    </div>
  </el-dialog>
</template>
<script>
import {
  regRightTree,
  regRoleAuth,
  regRoleaAuthTree,
  regAuthTree,
  regAuthToQx,
  regDetailMember
} from '../../../api/index'
import _ from "lodash";
export default {
  data(){
    return{
      checkAll: false,
      isIndeterminate: false,
      permissionTree: [],
      parentId: null, // 当前选中父结点id
      rightArr: [], // 二级权限树
      selectPower: [], // 选中的权限
      parentIds: []
    }
  },
  props:{
    orgId: Number,
    roleId: Number,
    currentRoles: Array,
    memberId: Number,
    memberName: String,
    currentRoleName: String
  },
  mounted(){
     this.getRightTree()
    if(this.memberId){
      // 获取人的权限
      regDetailMember({
        id:this.memberId
      }).then(res => {
        if(res.code === 0){
          console.log('----',res)
          this.selectPower = res.data.rightIdList
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取权限失败！' + res.msg
          })
        }
      })
    }else{
      console.log('currentRoles',this.currentRoles)
      let roles = this.currentRoles
      let powers = []
      for(let i=0; i<roles.length; i++){
        powers.push(roles[i].id)
      }
      this.selectPower = powers
    }
  },
  methods:{
    // 人的权限
    getMemberAuth(){
      regAuthTree({
        id: this.memberId
      }).then(res => {
        if(res.code === 0){
          this.permissionTree = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取权限树失败！' + res.msg
          })
        }
      })
    },
    // 拉取权限
    getRightTree(){
      regRightTree().then(res => {
        if(res.code === 0){
          this.permissionTree = res.data
          if(this.memberId){
            this.getMemberAuth()
          }else{
            this.getRoleaAuthTree()
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取权限树失败！' + res.msg
          })
        }
      })
    },
    // 获取角色授权情况
    getRoleaAuthTree(){
      regRoleaAuthTree({
        id: this.roleId,
      }).then(res => {
        if(res.code === 0){

        }else{
          this.$notify.error({
            title: '错误',
            message: '获取角色授权情况失败！' + res.msg
          })
        }
      })
    },
    // getMemberAuthTree(){
    //   regAuthToQx().then(res => {
    //     if(res.code === 0){

    //     }else{
    //       this.$notify.error({
    //         title: '错误',
    //         message: '获取用户授权情况失败！' + res.msg
    //       })
    //     }
    //   })
    // },
    handleCheckAllChange(val){
      console.log('handleCheckAllChange 是不是全选哪',val)
      const newArray = _.map(this.rightArr, 'id');
      console.log('當前全选有哪些',newArray)
      if(val){ // 全选
        const mergedArray = _.concat(newArray, this.selectPower);
        this.selectPower = _.uniq(mergedArray);
      }else{
        this.selectPower = _.differenceWith(this.selectPower, newArray);
      }
      console.log('this.selectPower',this.selectPower)
    },
    handleCheckedPurview(value){
      //console.log('value 是我变邓',value,this.selectPower)
      // let checkedCount = value.length;
      // this.checkAll = checkedCount === this.rightArr.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.rightArr.length;
    },
    // 关闭
    closePurview(){
      this.$emit('closePurview')
    },
    // 点击量表分类
    changeFirst(id){
      this.parentId = id
      this.getRightArr(id)
    },
    getRightArr(id){
      const parentId = this.parentId
      this.rightArr =  _.find(this.permissionTree, function(obj) { return obj.id === parentId; }).children
      console.log('this.rightArr',this.rightArr)
    },
    // 点击选择权限
    setPower(item){
      console.log('点击选择权限',item)
      const id = item.id
      let idx = this.selectPower.indexOf(id)
      if( idx > -1){
        this.selectPower.splice(idx,1)
      }else{
        this.selectPower.push(item.id)
      }
      this.setAllValue()
      // this.checkAll = _.intersection(this.selectPower,this.rightArr).length === this.selectPower.length ? true : false
    },
    deletePower(id){
      const idx = this.selectPower.indexOf(id)
      this.selectPower.splice(idx,1)
      this.setAllValue()
    },
    // 设置当前全选状态
    setAllValue(){
      this.checkAll = _.intersection(this.selectPower,this.rightArr.map(a => a.id)).length === this.rightArr.length ? true : false
      if(this.checkAll){
        this.isIndeterminate = false 
        return false
      }
      this.isIndeterminate = _.intersection(this.selectPower,this.rightArr.map(a => a.id)).length > 0 ? true : false
    },
    // 确认
    handleConfirmAuth(){
      console.log('handleConfirmAuth',this.selectPower.join(','))
      if(this.memberId){
        regAuthToQx({
          id: this.memberId,
          rightIds:  this.selectPower.join(',')
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '授权成功',
              type: 'success'
            });
            this.$emit('authSucccess')
          }else{
            this.$notify.error({
              title: '错误',
              message: '授权失败！' + res.msg
            })
          }
        })
      }else{
        regRoleAuth({
          id: this.roleId,
          rightIds:  this.selectPower.join(',')
        }).then(res => {
          if(res.code === 0){
            this.$message({
              message: '授权成功',
              type: 'success'
            });
            this.$emit('authSucccess')
          }else{
            this.$notify.error({
              title: '错误',
              message: '授权失败！' + res.msg
            })
          }
        })
      }
    }
  },
  watch:{
    rightArr(){
      this.setAllValue()
    },
    selectPower(val){
      let parentIds = []
      for(let i=0; i<this.permissionTree.length; i++){
        for(let j=0; j<this.permissionTree[i].children.length; j++){
          if(this.selectPower.indexOf(this.permissionTree[i].children[j].id) > -1){
            parentIds.push(this.permissionTree[i].id)
          }
        }
      }
      this.parentIds = parentIds
    }
    
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 10px
  .pur-label
    font-size 14px
    margin-bottom 14px
  .purview-con
    display flex
    width 631px
    height 307px
    border-radius 5px
    border 1px solid #DADADA
    font-size 14px
    .purview-item
      flex 1
      border-right 1px solid #DADADA
      padding 10px
      overflow auto
      .purview-title
        font-size 16px
        height 35px
        line-height 35px
        font-weight bold
      .purview-name
        height 28px
        padding 4px 12px
        line-height 20px
        margin 4px 0
        cursor pointer
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      .purview-sec
        padding 0 44px 0 20px
        height 24px
        line-height 24px
        line-height 24px
        border-radius 3px
        display flex
        justify-content space-between
        align-items center
        cursor pointer
        white-space nowrap
        position relative
        .purview-sec-name
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
        .purview-delete
          position absolute
          width 15px
          right 8px
          top 4px
          text-indent 9999px
          opacity 0
        &:hover
          background #D0E6E6
          color #009375
          .purview-delete
            text-indent 0
            opacity 1
      .purview-name-active
        border-radius 5px
        // background #D0E6E6
        color #009375
      .el-checkbox
        display block
        margin-bottom 10px
      /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
        background-color #009375
        border-color #009375
      .is-checked
        /deep/.el-checkbox__inner
          background-color #009375
          border-color #009375
        // .el-checkbox__input.is-indeterminate .el-checkbox__inner
        //   background-color #009375
        //   border-color #009375
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label 
          color #009375
        /deep/.el-checkbox.is-bordered.is-checked
          border-color rgba(47, 84, 201, 1)
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>