<template>
  <el-dialog
    :title="diaTitle"
    class="dialog-main"
    :visible=true
    @close="closePurview"
    width="700px">
    <div class="dialog-con">
      <div class="pur-label">
        分组名称：<input type="text" class="group-name" v-model="name" />
      </div>
      <div class="purview-con">
        <div class="purview-item">
          <div class="purview-title">量表分类</div>
          <p class="purview-name" 
            v-for="(item,index) in examTree" 
            :key="index"
            :class="{'purview-name-active':item.categoryId === categoryId}"
            @click="changeFirst(item.categoryId)"
            :title="item.categoryName"
          >{{item.categoryName}}</p>
        </div>
        <div class="purview-item">
          <div class="purview-title">量表名</div>
          <p class="purview-name" 
            v-for="(item,index) in examArr" 
            :key="index"
            :class="{'purview-name-active': selectExam.indexOf(item.id) > -1}"
            @click="setExam(item)"
          >{{item.name}}</p>
        </div>
        <div class="purview-item">
          <div class="purview-title">已选量表</div>
          <div v-if="selectExam.length">
            <div v-for="(item,index) in examTree" :key="index">
              <template  v-if="parentEx.includes(item.categoryId)">
                <p class="purview-name" >{{item.categoryName}}</p>
                <div v-for="(exam,i) in item.exams" :key="i">
                  <div 
                    class="purview-sec"
                    :title="exam.name"
                    v-if="selectExam.indexOf(exam.id) > -1"
                  >
                    <span class="purview-sec-name">{{exam.name}}</span>
                    <img src="../../../assets/images/delete_black.png" class="purview-delete" @click="deleteExam(exam.id)" />
                  </div>
                </div>
              </template>
            </div>
            <!-- <div class="purview-sec">
              量表名称1
              <img src="../../../assets/images/delete_black.png" class="purview-delete"/>
            </div>
            <div class="purview-sec">
              量表名称1
              <img src="../../../assets/images/delete_black.png" class="purview-delete"/>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="group-des">
      <div class="group-price">
        分组价格：<input type="text" class="price-text" v-model="price" />
        <span class="pirce-dw">元</span>
      </div>
      <div class="group-status">
        分组状态：   
        <el-switch
          v-model="status"
          active-color="#13ce66"
          active-text="开"
          inactive-text="关"
          inactive-color="#ccc">
        </el-switch>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closePurview">取消</span>
      <span class="dialog-btn" @click="confirmAdd">确定</span>
    </div>
  </el-dialog>
</template>
<script>
import _ from "lodash";
import {
  regExamTree,
  regAddGroup,
  regEditGroup
} from '../../../api/index'
export default {
  props:{
    examDetail: Object,
    isEdit: Boolean
  },
  data(){
    return{
      diaTitle:'新增分组',
      name: '',
      groupId: null,
      payType: '',
      isIndeterminate: true,
      checkedPurviews: [],
      examTree:[], // 
      categoryId: '', // 当前量表
      examArr: [], // 二级量表分类
      examId: null,
      selectExam:[],
      parentEx:[],
      status: null,
      price: '',
    }
  },
  mounted(){
    this.getExamTree()
    if(this.isEdit){
      this.diaTitle = '分组编辑'
    }else{
      this.diaTitle = '新增分组'
    }
    if(this.isEdit){
      this.setData()
    }
  },
  watch:{
    isEdit(val){
      console.log('isEdit',val)
      if(val){
        this.diaTitle = '分组编辑'
      }else{
        this.diaTitle = '新增分组'
      }
    }
  },
  methods:{
    setData(){
      this.groupId = this.examDetail.id
      this.name = this.examDetail.name
      this.payType = this.examDetail.payType
      this.price = this.examDetail.price/100
      this.status = this.examDetail.status == 'Y' ? true : false
      this.examTree = this.examDetail.examTree
      const examTree = this.examDetail.examTree
      console.log('examTree',examTree)
      let selectExam = []
      for(let i=0; i<examTree.length; i++){
        if(examTree[i].exams && examTree[i].exams.length){

          //selectExam = selectExam.concat(examTree[i].exams)
          for(let j=0;j<examTree[i].exams.length; j++){
             selectExam = selectExam.concat(examTree[i].exams[j].id)
          }
        }
      }
      
      this.selectExam = selectExam
      console.log('this.selectExam',this.selectExam)
      this.getParentArr()
    },
    // 拉取权限
    getExamTree(){
      regExamTree().then(res => {
        if(res && res.code === 0){
          this.examTree = res.data
        }else{
          this.$message({
            message: '获取权限失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 关闭
    closePurview(){
      this.$emit('closePurview')
    },
    // 点击量表分类
    changeFirst(id){
      this.categoryId = id
      this.getExamArr(id)
    },
    // 获取分类下的量表
    getExamArr(id){
      const categoryId = this.categoryId
      this.examArr =  _.find(this.examTree, function(obj) { return obj.categoryId === categoryId; }).exams
      console.log('this.examArr',this.examArr)
    },
    // 点击选择量表
    setExam(item){
      const id = item.id
      let idx = this.selectExam.indexOf(id)
      if( idx > -1){
        this.selectExam.splice(idx,1)
      }else{
        this.selectExam.push(item.id)
      }
      this.getParentArr()
    },
    getParentArr(){
      this.parentEx =[]
      for(let i=0; i<this.examTree.length; i++){
        for(let j=0; j<this.examTree[i].exams.length;j++){
          if(this.selectExam.includes(this.examTree[i].exams[j].id)){
            this.parentEx.push(this.examTree[i].categoryId)
          }
        }
      }
      console.log('this.parentEx',this.examTree,this.parentEx)
    },
    deleteExam(id){
      const idx = this.selectExam.indexOf(id)
      this.selectExam.splice(idx,1)
      this.getParentArr()
    },
    // 新增
    confirmAdd(){
      console.log('this.status',this.status)
      if(!this.selectExam.length){
        this.$message({
          message: '请先选择量表',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.name){
        this.$message({
          message: '请先填写分组名',
          type: 'wanring'
        }); 
        return false
      }
      let params ={
        name: this.name,
        status: this.status ? 'Y':'N',
        examIds: this.selectExam.join(','),
        price: this.price *100,
        payType: 'free'
      }
      if(this.price){
        params.payType = 'paid'
      }
      if(this.isEdit){
        params.id = this.groupId
        regEditGroup(params).then(res => {
          if(res && res.code === 0){
            this.$emit('addGroupSuccess')
            this.$message({
              message: '修改成功！',
              type: 'success'
            });
          }else{
            this.$message({
              message: '编辑失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        regAddGroup(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '新增成功！',
              type: 'success'
            });
            this.$emit('addGroupSuccess')
          }else{
            this.$message({
              message: '新增失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
      console.log('params',params)
      // payType
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 10px
  .pur-label
    font-size 14px
    margin-bottom 14px
    .group-name
      width 530px
      height 38px
      border-radius 5px
      border 1px solid #DADADA
      margin-left 30px
      padding 0 10px
  .purview-con
    display flex
    width 631px
    height 307px
    border-radius 5px
    border 1px solid #DADADA
    font-size 14px
    .purview-item
      flex 1
      width 33%
      border-right 1px solid #DADADA
      padding 10px
      overflow auto
      .purview-title
        font-size 16px
        height 35px
        line-height 35px
        font-weight bold
      .purview-name
        height 28px
        padding 4px 12px
        line-height 20px
        margin 4px 0
        cursor pointer
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      .purview-sec
        padding 0 44px 0 20px
        height 24px
        line-height 24px
        line-height 24px
        border-radius 3px
        display flex
        justify-content space-between
        align-items center
        cursor pointer
        white-space nowrap
        position relative
        .purview-sec-name
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
        .purview-delete
          position absolute
          width 15px
          right 8px
          top 4px
          text-indent 9999px
          opacity 0
        &:hover
          background #D0E6E6
          color #009375
          .purview-delete
            text-indent 0
            opacity 1
      .purview-name-active
        border-radius 5px
        background #D0E6E6
        color #009375
      .el-checkbox
        display block
        margin-bottom 10px
      /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
        background-color #009375
        border-color #009375
      .is-checked
        /deep/.el-checkbox__inner
          background-color #009375
          border-color #009375
        // .el-checkbox__input.is-indeterminate .el-checkbox__inner
        //   background-color #009375
        //   border-color #009375
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label 
          color #009375
        /deep/.el-checkbox.is-bordered.is-checked
          border-color rgba(47, 84, 201, 1)
  .group-des
    display flex
    margin 23px 30px 25px 0
    justify-content space-between
    align-items center
    .group-price
      position relative
      .price-text
        width 118px
        height 38px
        border-radius 5px
        border 1px solid #DADADA
        padding 0 30px 0 10px
      .pirce-dw
        position absolute
        right 10px
        top 13px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>