<template>
  <!-- <a-config-provider > -->
    <div id="app">
      <router-view></router-view>
    </div>
  <!-- </a-config-provider> -->
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus">
body 
  min-width 1280px
  overflow-x scroll
  overflow-y hidden
#app {
  width 100%
  height 100%
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  min-width 1280px
  overflow-x auto
  overflow-y hidden
}
*::-webkit-scrollbar{
  width: 8px;
  height: 8px;
} 
*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
} 
*::-webkit-scrollbar-track {
  background: #fff;
} 
.main-container *::-webkit-scrollbar{
  width: 3px;
  height: 3px;
} 
.el-popover{
  min-width: 0!important;
  padding: 0!important;
}
.handle-popver-item{
  border: 3px;
  margin: 0 3px;
  cursor: pointer;
}
.handle-popver-item:hover{
  background: #D0E6E6;
  color: #009375;
   
}
.bold-text{
  font-weight:bold;
}
.green-text{
  color #009375
}
.red-text{
  color #C40606
}
input:hover,
input:focus,
textarea:hover,
textarea:focus{
  outline  none
}
.el-select-dropdown__item
  padding 0 20px
  height auto!important
.el-tree-node
  padding-left 10px
.pager
  margin 30px 0 0
  text-align right 
  position relative
.pager .el-pager .number
  min-width 32px
.pager .el-pager .number:hover
  color 00A389
.pager .el-pager .active
  background #00A389
  color #fff
  border-radius 3px
  width 32px
.pagination-right .el-pager li 
  text-align: right;
.pager .el-pagination .el-pagination__sizes
  float left
.pager .el-pagination .el-pagination__total 
  float left
.pager .el-pagination .el-pagination__jump 
  float left
</style>
