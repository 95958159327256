<template>
  <transition name="review" v-if="acc">
    <div class="notify">
      <div class="notify-con">
        <div class="notify-left">
          <img src="../../assets/images/message.png" class="notify-icon" />
          <p>{{theTime}}</p>
        </div>
        <div class="notify-right">
          <p class="notify-title">{{notifyObj.title}}</p>
          <div class="notify-des">{{notifyObj.content}}</div>
          <div class="notify-btns">
            <span class="notify-btn" @click="delayNot">稍后提示</span>
            <span class="notify-btn notify-btn-blue" @click="confirmNot">确认</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  reqRemindLater
} from '../../api/index'
import bus from '@/utils/bus'
export default {
  props:{
    isShow: Boolean,
    notifyObj: Object
  },
  data () {
    return {
      acc: this.isShow,
      theTime: ''
    }
  },
  mounted(){
    if(this.notifyObj && this.notifyObj.nextRemindTime){
      this.theTime = this.notifyObj.nextRemindTime.substring(11)
    }
  },
  methods:{
    confirmNot(){
      this.acc = false
    },
    delayNot(){
      reqRemindLater({id:this.notifyObj.id}).then(res => {
        if(res.code === 0){
          this.acc = false
          bus.$emit('reNewalarm')
        }else{
          this.$notify.error({
            title: '错误',
            message: '设置失败！'+ res.msg
          });
        }
      })
    }
  },
  watch: {
    isShow(newVal){
      console.log('isShow change',newVal)
      this.acc = newVal
    },
    notifyObj(newVal){
      if(this.notifyObj && this.notifyObj.nextRemindTime){
        this.theTime = this.notifyObj.nextRemindTime.substring(11)
      }
    }
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.notify
  display inline-block
  position fixed
  bottom 30px
  right 17px
  z-index 99
  width 353px;
  height 110px
  border-radius 5px
  border 1px solid #009375
  background #fff
  &.review-enter-active
    animation: msgbox-fade-in .3s;
  &.review-leave-active
    animation: msgbox-fade-out .3s;
    @keyframes msgbox-fade-in
    {
      0% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
      }
      100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
    @keyframes msgbox-fade-out
    {
      0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      100% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
      }
    }
  .notify-con
    display flex
    padding 10px 20px 8px
    align-items center
    .notify-left
      font-size 12px
      color #009375
      width 40px
      .notify-icon
        margin-bottom 6px
    .notify-right
      flex 1
      .notify-title
        font-size 16px
        font-weight bold
        line-height 22px
        margin-bottom 10px
        color #009375
      .notify-des
        color #555
        font-size 14px
        line-height 16px
        height 32px
        overflow hidden
      .notify-btns
        text-align right
        .notify-btn
          display inline-block
          height 28px
          line-height 28px
          border-radius 5px
          box-sizing border-box
          border 1px solid #D8D8D8
          font-size 12px
          padding 0 15px
          margin-left 16px
          cursor pointer
        .notify-btn-blue
          background #009375
          border-color #009375
          color #fff
</style>