<template>
  <el-dialog
    title="分配计划"
    class="dialog-main"
    :visible=true
    @close="closePlanDialog"
    width="480px">
    <div class="dialog-con">
      <div class="invite-con">
        <div class="invite-item">
          <div class="invite-title">当前计划</div>
          <div class="invite-item-con">
            <p  class="plan-input" >{{distriData.name}}</p>
          </div>
        </div>
        <div class="invite-code-outer">
          <img v-if="codeUrl" :src="codeUrl" class="invite-code" />
          <img v-else src="../../assets/images/code.png" class="invite-code" />
          <div class="invite-right">
            <p class="invite-par">二维码有效期</p>
            <el-date-picker
              v-model="dateVal"
              type="daterange"
              class="invite-date"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleChangeDate"
              >
            </el-date-picker>
            <span class="set-btn"  v-if="codeUrl" @click="downCode"><img src="../../assets/images/dowen.png" class="invite-code" />二维码下载</span>
            <span class="set-btn" v-else @click="getExamlQrcode"><img src="../../assets/images/get.png" class="invite-code" />生成二维码</span>
          </div>
        </div>
        <p class="invite-bottom"><img src="../../assets/images/note.png" class="note-icon"/>   用户扫描二维码自动加入当前计划</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  reqInsertQrcode
} from '../../api/index'
export default {
  props:{
    distriData: Object
  },
  data () {
    return {
      dateVal: ['',''],
      startTime:'',	
      endTime	:'',
      codeUrl: '',
      base64url: ''
    }
  },
  methods:{
    // 修改日期
    handleChangeDate(data){
      console.log('data',data,this.dateVal)
    },
    closePlanDialog(){
      this.$emit('closePlanDialog')
    },
    getExamlQrcode(){
      reqInsertQrcode({
        eventId: this.distriData.id,	
        startTime: this.dateVal[0],	
        endTime	: this.dateVal[1],	
        type: 'visit'
      }).then(res => {
        if(res && res.code === 0){
          this.base64url = res.data.base64
          // 转成图片文件
          this.codeUrl = this.base64ToUrl(res.data.base64)
          console.log('codeUrl',this.codeUrl)
        }else{
          this.$message({
            message: '生成失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    },
    base64ToUrl(base) {
      //需要手动去掉data:image/png;base64,
      //base64-->blob
      const byteCharacters = atob(base);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });
      //blob-->url
      let binaryData = [];
      binaryData.push(blob);
      let url = URL.createObjectURL(new Blob(binaryData));
      return url;
    },
    // 下载
    downCode(){
      let aLink = document.createElement("a"); // 生成一个a元素
      //let event = new MouseEvent("click"); // 创建一个单击事件
      let event = document.createEvent("HTMLEvents")
      event.initEvent('click',true,true)
      aLink.download =  "二维码.jpg"; // 设置图片名称
      aLink.href = this.codeUrl
      aLink.click()
      // a.href = this.codeUrl; // 将生成的URL设置为a.href属性
      // a.dispatchEvent(event); // 触发a的单击事件
    },
    base64ToBolb(base){
      const byteCharacters = atob(base);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      return blob
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  >>>.el-dialog__header
    border-bottom 1px solid #D8D8D8
  .dialog-con
    .invite-item
      margin-bottom 18px
      .invite-title
        margin-bottom 11px
      .invite-item-con
        .plan-input
          width 415px
          height 38px
          line-height 38px
          padding-left 10px
          border-radius 5px
          background #F4F4F4
          border 1px solid #DADADA
    .invite-code-outer
      display flex
      margin 15px 0 30px
      .invite-code
        width 135px
        height 135px
        margin-right 25px
      .invite-par
        font-size 14px
        font-weight bold
        color #4b4b4b
        margin-top 5px
      .invite-date
        width 250px
        height 30px
        line-height 30px
        color #009375
        margin 12px 0 24px
        >>>.el-range-separator
          width 10%
          line-height 22px
        >>>.el-date-editor .el-range__icon
          line-height 22px
        >>>.el-range-input
          color #009375
      .set-btn
        display flex
        width 116px
        height 36px
        line-height 36px
        border-radius 3px
        padding 0 9px
        font-size 12px
        color #009375
        border 1px solid #CED4DA
        align-items center
        cursor pointer
        .invite-code
          width 17px
          height 17px
          margin-right 10px
    .invite-bottom
      color #009375
      text-align center
      .note-icon
        position relative
        top 3px
        margin-right 7px
</style>