<template>
  <div >
    <div class="examdr-detail">
      <div class="examdr-con">
        <div class="examdr-item">
          <span class="examdr-name">分组名称</span>
          <span class="examdr-des">{{name}}</span>
        </div>
        <div class="examdr-item">
          <span class="examdr-name">分组量表</span>
          <div class="exam-tree">
            <el-tree 
              :data="examTree" 
              :props="defaultProps" 
              class="org-tree"
              :default-expand-all=true
            >
            </el-tree>
          </div>
          <!-- <el-select v-if="edit" v-model="categoryName" class="search-select" placeholder="量表分类" @change="changeCateogry">
            <el-option
              v-for="item in allCategory"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item">
            </el-option>
          </el-select>
          <span class="examdr-des" v-else>{{categoryName}}</span> -->
        </div>
        <div class="examdr-item" v-if="payType ==='paid'">
          <span class="examdr-name">价格</span>
          <span class="examdr-des" >￥{{price}}</span>
        </div>
        <div class="examdr-item">
          <span class="examdr-name">状态</span>
          <span class="examdr-des">
            <template v-if="status">启用</template>
            <template v-else>禁用</template>
          </span>
        </div>
        <div class="examdr-item"  v-has="'mgr.examGroup.addQrcode'">
          <span class="examdr-name">二维码</span>
          <span class="examdr-des">
            <span class="get-ewm" @click="handleGetCode">
              <img src="../../../assets/images/code.png" class="ewm-icon" />生成二维码
            </span>
          </span>
        </div>
      </div>
      <div class="exmdr-bottom" v-has="'mgr.examGroup.editGroup'">
        <span class="exmdr-border-btn" @click="toEdit"><img src="../../../assets/images/edit1.png" class="btn-icon" />编辑</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  regEditExaml
} from '../../../api/index'
export default {
  props:{
    examDetail: Object
  },
  data () {
    return {
      groupId: null,
      examTree: [],
      defaultProps: {
        children: 'exams',
        label: 'categoryName'
      }, 
      name: '',
      numofExam: '',
      payType:'',
      price: '',
      status: ''
    }
    
  },
  mounted(){
    console.log('examDetail',this.examDetail)
    if(this.examDetail){
      this.setData()
    }
  },
  methods:{
    setData(){
      console.log('进来',this.examDetail)
      this.groupId = this.examDetail.id
      const examTree = this.examDetail.examTree
      for(let i=0; i<examTree.length; i++){
        if(examTree[i].exams && examTree[i].exams.length){
          for(let j=0;j<examTree[i].exams.length; j++){
            examTree[i].exams[j].categoryName = examTree[i].exams[j].name
          }
        }
      }
      console.log('examTree',examTree)
      this.examTree = examTree
      this.name = this.examDetail.name
      this.numofExam = this.examDetail.numofExam
      this.payType = this.examDetail.payType
      this.price = this.examDetail.price/100
      this.status = this.examDetail.status === 'Y' ? true : false
      
    },
    toEdit(){
      this.$emit('toEdtiGroup',this.groupId)
    },
    handleGetCode(){
      this.$emit('handleGetCode',false, this.examDetail)
    }
    
  },
  watch:{
    examDetail(){
      if(this.examDetail){
        this.setData()
      }
    }
  }
  
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.examdr-con
  padding 22px 24px
  .examdr-item
    display  flex
    margin-bottom 20px
    line-height 25px
    .examdr-name
      min-width 80px
      margin-right 10px
    .examdr-text
      width 300px
      padding 0 10px
      height 30px
      line-height 30px
      border 1px solid #ccc
      border-radius 3px
    .exam-tree 
      width 384px
      height 289px
      border-radius 5px
      border 1px solid #D8D8D8
      overflow auto
    .examdr-textarea
      width 300px
      padding 2px 10px
      height 120px
      line-height 22px
      border 1px solid #ccc
      border-radius 3px
    .get-ewm
      display flex
      width 116px
      height 36px
      line-height 36px
      border-radius 3px
      padding 0 9px
      font-size 12px
      color #009375
      border 1px solid #CED4DA
      align-items center
      cursor pointer
      .ewm-icon
        width 25px
        margin-right 10px
  .examdr-main
    margin-bottom 14px
    .examdr-title
      font-size 16px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-des
      border-radius 6px
      background rgba(179, 223, 214, 0.1)
      padding 14px
      line-height 24px
      text-indent 36px
      .examdr-code
        width 120px
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 15px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 10px
          .question-num
            color #FF3243
        .question-option
          padding-left 30px
          margin-top 15px
  .examdr-main-quest
    margin-top 30px
.exmdr-bottom
  display flex
  justify-content space-around
  margin-bottom 40px
  padding 0 50px
  .exmdr-btn
    display inline-block
    padding 0 20px
    height 34px
    line-height 34px
    text-align center
    border-radius 5px
    background #F0F3F6
    cursor pointer
  .exmdr-btn-blue
    background #009375
    color #fff
  .exmdr-border-btn
    height 40px
    line-height 40px
    border-radius 5px
    background #FFFFFF
    border 1px solid #D8D8D8
    padding 0 15px 
    display flex
    align-items center
    cursor pointer
    .btn-icon
      margin-right 7px
</style>