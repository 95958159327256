<template>
  <div class="follow-drawer">
    <div class="qadetail-return" @click="returnList">
      <img src="../../assets/images/return_arrow.png" class="return-icon"  />
      <span >返回报告列表</span>
    </div>
    <div class="tqo">
      <span class="tqo-title">随访内容</span>
      <span>{{name}}</span>
    </div>
    <div class="examdr-main examdr-main-quest">
      <!-- <div class="examdr-quest"> 
        <div class="question-item" v-for="(item,index) in addItem" :key="index">
          <div class="question-title">
           <span style="color:red;">{{index+1}}、</span> {{item.newProjectName}}
          </div>
          <div class="question-options"   v-if="item.projectType == '1' || item.projectType == '2'"> 
            <div class="question-option "   v-for="(ques,queIdx) in item.userInputContent" :key="queIdx"  @click="selectAns(index,item,ques,queIdx)">
              {{ques}}
            </div>
          </div>
          <div class="question-option"  v-else-if="item.projectType == '3'">
            <input type="text" class="quest-input"  />
          </div>
          <div class="question-option" v-else-if="item.projectType == '4'">
            <textarea class="quest-input quest-area"></textarea> 
          </div>
        </div>
      </div> -->
      <div class="examdr-quest"> 
        <div class="question-item" v-for="(item,index) in titleDtos" :key="index">
          <div class="question-title">
            <span style="color:red;">{{index+1}}、</span>{{item.title}}
            <template v-if="item.detailedOptionsType == '1'">(单选)</template>
            <template v-if="item.detailedOptionsType == '2'">(多选)</template>
          </div>
          <!-- <div class="question-option" >
            <input type="text" class="quest-input"  />
          </div>
          <div class="question-option" >
            <textarea class="quest-input quest-area"></textarea> question-option-select
          </div> -->
          <div class="question-options"   v-if="item.detailedOptionsType == '1'  || item.detailedOptionsType == '2'"> <!-- 单选-->
            <div class="question-option " :class="(allSelectId[index] &&  allSelectId[index].indexOf(ques.id) > -1) ? 'question-option-select' :''"  v-for="(ques,queIdx) in item.options" :key="queIdx"  >
            <div @click="selectAns(index,item,ques,queIdx)">{{ques.optionContent}} -{{ques.id}} </div>
              <template v-if="ques.subOptions && ques.subOptions.length">
                <div 
                  class="question-option" 
                  :class="(allSelectId[index] &&  allSelectId[index].indexOf(sen.id) > -1) ? 'question-option-select' :''" 
                  @click.stop="selectAns(index,item,ques,queIdx,sen,senIdx)" v-for="(sen,senIdx) in ques.subOptions" :key="senIdx"
                 >
                  {{sen.optionContent}}  {{sen.id}}
                </div>
              </template>
            </div>
          </div> 
          <div class="question-option"  v-else-if="item.detailedOptionsType == '3'">
            <input type="text" class="quest-input" @blur="ztInput($event,index)" />
          </div>
          <div class="question-option" v-else-if="item.detailedOptionsType == '4'">
            <textarea class="quest-input quest-area" @blur="ztTextarea($event,index)" ></textarea> 
          </div>
        </div>
      </div>
    </div>
    <div class="batch-btns" v-has="'mgr.visitGeneration.answerQuestion'">
      <el-button class="batch-btn" size="small" type="" @click="returnList">取消</el-button>
      <el-button class="batch-btn success-btn" size="small" @click="handleAnswer"  type="">确定</el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  reqAnswerQuestionByPlan
} from '../../api/index'
export default {
  props:{
    startReplyDetail:Object,
    doExamId: String,
    questPlanId:String
  },
  mounted(){
    console.log('startReplyDetail',this.startReplyDetail)
    this.setData()
  },
  data () {
    return {
      name: '',
      addItem: [],
      titleDtos: [],
      titleAnswer: [], // 做了的题
      allSelectId: [], // 选择了的id
    }
    
  },
  methods:{
    setData(){
      this.name = this.startReplyDetail.questionnaireName
      let itemArr = this.startReplyDetail.item 
      for(let i=0; i<itemArr.length; i++){
        if(typeof itemArr[i].userInputContent === 'string'){
          itemArr[i].userInputContent = JSON.parse(itemArr[i].userInputContent)
        }
      }
      this.addItem = itemArr
      // let titleDtos = this.startReplyDetail.titleDtos
      // for(let i=0; i<titleDtos.length; i++){
      //   titleDtos[i].userInputContent = JSON.parse(titleDtos[i].userInputContent)
      // }
      this.titleDtos = this.startReplyDetail.titleDtos
      console.log('addItem',this.addItem)
      console.log('titleDtos',this.titleDtos)
    },
    returnList(){
      this.$emit('returnFoll')
    },
    // 选择题
    selectAns(index,item,ques,queIdx,sen = null,senIdx =null){
      console.log('selectAns',index,item,ques,queIdx,sen,senIdx)
      if(index > this.titleAnswer.length ){
        this.$message({
          message: '请先填做上一题',
          type: 'wanring'
        }); 
        return false
      }
      if(sen == null && ques.subOptions && ques.subOptions.length >0){
        return false
      }
      if(sen && sen.subOptions && sen.subOptions.length){
        console.log('sen.subOptions[senIdx]',sen.subOptions[senIdx])
      }
      if(item.detailedOptionsType == '1'){ // 单选题
        let quest = {}
        if(sen!== null){ // 二层
          // quest = {
          //   content: sen.optionContent,
          //   parentOptionId: sen.parentId,
          //   ptId: sen.id,
          //   score: sen.optionScore
          // }
          quest = {
            content: sen.optionContent,
            parentOptionId: sen.parentId,
            ptId: sen.id,
            score: sen.optionScore
          }
        }else{
          quest = {
            content:item.options[queIdx].optionContent,
            parentOptionId: item.id,
            ptId: item.options[queIdx].id,
            score: item.options[queIdx].optionScore
          }
        }
        if(sen!== null){
          this.$set(this.allSelectId,index, [sen.id])
        }else{
          this.$set(this.allSelectId,index, [item.options[queIdx].id])
        }
        //this.allSelectId = Array.from(new Set(this.allSelectId))
        if(index + 1 <=  this.titleAnswer.length  ){  // 
          this.titleAnswer.splice(index,1,[quest])
        }else{
          this.titleAnswer.push([quest])
        }
      }
      if(item.detailedOptionsType == '2'){ //  多选题
        let quest = {}
        if(sen!== null){ // 二层
          quest = {
           content: sen.optionContent,
            parentOptionId: sen.parentId,
            ptId: sen.id,
            score: sen.optionScore
          }
        }else{
          quest = {
            content:item.options[queIdx].optionContent,
            parentOptionId: item.id,
            ptId: item.options[queIdx].id,
            score: item.options[queIdx].optionScore
          }
        }
        if(index + 1 <=  this.titleAnswer.length  ){  
          console.log('hasThis',this.titleAnswer[index],item.options[queIdx].id)
          let cur = this.titleAnswer[index]
          let hasThis = -1
          for(let i=0; i<cur.length;i++){
            if(cur[i].ptId === item.options[queIdx].id){
              hasThis = i 
            }
          }
          if(hasThis > -1){ // 多选已经有这选项了了
            if(this.titleAnswer[index].length >1){
              this.titleAnswer[index].splice(hasThis,1)
              this.$nextTick(() => {
                let ls = _.clone(this.allSelectId[index])
                ls.splice(hasThis,1)
                //ls[hasThis]= item.options[queIdx].id
                this.$set(this.allSelectId,index,ls)
                //this.allSelectId[index][hasThis] = item.options[queIdx].id
              })
              
            }else{
              this.$message({
                message: '至少选择一个选项',
                type: 'wanring'
              }); 
            }
            
          }else{
            if(sen!== null){
              this.allSelectId[index].push(ques.subOptions[senIdx].id)
              this.titleAnswer[index].push({
                content: sen.optionContent,
                parentOptionId: sen.parentId,
                ptId: sen.id,
                score: sen.optionScore
              })
            }else{
              this.allSelectId[index].push(item.options[queIdx].id)
              this.titleAnswer[index].push({
                content:item.options[queIdx].optionContent,
                parentOptionId: item.id,
                ptId: item.options[queIdx].id,
                score: item.options[queIdx].optionScore
              })
            }
          }
        }else{
          this.titleAnswer.push([quest])
          if(sen!== null){
            this.$set(this.allSelectId,index, [sen.id])
          }else{
            this.$set(this.allSelectId,index, [item.options[queIdx].id])
          }
          //this.allSelectId[index] = [item.options[queIdx].id]
          
        }
      }
      console.log('titleAnswer',this.titleAnswer,this.allSelectId)
    },
    // 选择二层
    selectAns2(index,item,ques,queIdx,sen,senIdx){
      console.log('12222',index,item,ques,queIdx,sen,senIdx)
    },
    isSelectQuest(index,idx){
      // console.log('replyDataJson',this.replyDataJson,index,idx)
      // return {
      //   'question-option-select':  this.replyDataJson[index] && this.replyDataJson[index].filter(item => item.ptId === this.titleDtos[index].options[idx].id).length > 0
      // }
    },
    // :class="isSelectQuest(ques,index)" 
    // isSelectQuest(quest,index){
    //   console.log('replyDataJson',quest,index,this.allSelectId)
    //   //  :class="(allSelectId[index] && allSelectId[index].indexOf(ques.id) > -1) 
    //   return {
    //     'question-option-select':  this.allSelectId[index] && this.allSelectId[index].filter(item => item === quest.id).length > 0
    //   }
    // },
    // 做题
    handleAnswer(){
      // if(this.replyDataJson.length<this.titleDtos.length){
      //   this.$message({
      //     message: '请先做完题',
      //     type: 'wanring'
      //   });
      //   return false
      // }
      console.log('this.titleAnswer',this.titleAnswer)
      const params = {
        visitQuAnswerPlanDto:{
          //genId: this.replyId,
          operation: 1,
          planId: this.questPlanId,
          quId: this.doExamId,
          genId: this.doExamId,
          titleAnswer: JSON.stringify(this.titleAnswer),
        }
      }
      console.log('params',params)
      // return false
      reqAnswerQuestionByPlan(params).then(res => {
        if(res && res.code === 0){   
          this.$message({
            message: "更新任务成功！",
            type: "success",
          });
        }else{
          this.$message({
            message: '提交失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 做题 单行文本
    ztInput(e,index){
      console.log('eee',e)
    },
    // 做题 多行文本
    ztTextarea(e,index){
      console.log('eee',e)
    },
  },
  computed:{
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.follow-drawer
  width 614px
  .qadetail-return
    width 100%
    display block
    height 70px
    display flex
    align-items center
    line-height 70px
    font-size 14px
    cursor pointer
    .return-icon
      width 30px
      margin 0 13px 0 40px
  .tqo
    font-size 18px
    padding 20px 35px 30px
    display flex
    border-bottom 1px solid #D8D8D8
    .tqo-title
      font-size 16px
      width 80px
  .examdr-main
    margin-bottom 14px
    padding-left 20px
    .examdr-title
      font-size 14px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 30px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 15px
          .question-num
            color #FF3243
        .question-option
          width 380px
          padding 5px 0 5px 10px
          margin 10px 30px 0
          border-radius 5px
          cursor pointer
          .quest-input
            height 30px
            width 300px
            border 1px solid #ececec
          .quest-area
            height 60px
            resize none
        .question-option-select
          background red
          color #009375
          background #e0f2ee
  .batch-btns
    padding 20px 0
    display fle
    justify-content space-around
    .batch-btn
      width 70px
      height 34px
      border-radius 5px
      background #F0F3F6
      border none
      margin 0 100px
    .success-btn
      background #009375
      color #fff
</style>