<template>
  <!-- chart-right -->
  <div class="chart-right " :class="{'hidden-right':hideRight}">
    <div class="chart-des">
      <!-- chart-des-top -->
      <div class="chart-des-top" v-has="'mgr.patient.query'">                   
        <img src="../../assets/images/chart_arrow.png" :class="{'chart-arrow-rotate': hideRight}" class="chart-sq" @click="changeHide" />
        <div class="chart-top-item">
          <span class="chart-top-icon">
            <img :src="icons.iconBaseGreen" v-if="icons && icons.iconBaseGreen" class="chart-nav-icon" />
          </span>
          <span class="chart-top-text">基本信息</span>
        </div>
        <div class="chart-top-item">
          <span class="chart-top-icon">
            <img :src="icons.iconExamGreen" v-if="icons && icons.iconExamGreen" class="chart-nav-icon" />
          </span>
          <span class="chart-top-text">测评</span>
        </div>
        <div class="chart-top-item">
          <span class="chart-top-icon">
            <img :src="icons.iconVisitGreen" v-if="icons && icons.iconVisitGreen" class="chart-nav-icon" />
          </span>
          <span class="chart-top-text">随访</span>
        </div>
        <span class="chart-top-more">● ● ●</span>
      </div>
      <!-- right-item -->
      <div class="right-item">
        <div class="right-item-top">
          <div class="right-item-main">
            <img :src="userDetail.headImgUrl" v-if="userDetail.headImgUrl" class="right-user-img" />
            {{userDetail.name}}
          </div>
          <div class="right-top-right">
            <span class="right-top-link" @click="editUser">编辑</span>
            <span v-if="rightShowOne" class="right-top-link" @click="changeArrow(1)">收起
              <img  src="../../assets/images/arrow.png" class="right-arrow-img right-arrow-rotota" />
            </span>
            <span v-else class="right-top-link" @click="changeArrow(1)">展开
              <img src="../../assets/images/arrow.png" class="right-arrow-img" />
            </span>
          </div>
        </div>
        <div class="right-main " :class="{'right-user-sq':!rightShowOne}">
          <div class="user-item">
            <span class="user-item-title">姓名：</span>
            <span class="user-item-des">{{userDetail.name}}</span>
          </div>
          <div class="user-item">
            <span class="user-item-title">性别：</span>
            <span class="user-item-des">{{userDetail.sex}}</span>
          </div>
          <div class="user-item">
            <span class="user-item-title">生日：</span>
            <span class="user-item-des">{{userDetail.birthday}} </span>
          </div>
          <div class="user-item">
            <span class="user-item-title">民族：</span>
            <span class="user-item-des">{{userDetail.nationstate}} </span>
          </div>
          <div class="user-item">
            <span class="user-item-title">婚姻：</span>
            <span class="user-item-des">{{userDetail.marriage}} </span>
          </div>
          <div class="user-item">
            <span class="user-item-title">职业：</span>
            <span class="user-item-des">{{userDetail.job}} </span>
          </div>
          <div class="user-item">
            <span class="user-item-title">居住地：</span>
            <span class="user-item-des">{{userDetail.currentProvince}} - {{userDetail.currentCity}} - {{userDetail.currentArea}} </span>
          </div>
        </div>
      </div>
      <!-- right-item -->
      <div class="right-item">
        <div class="right-item-top">
          <div class="right-item-main">
            <span class="right-user-img right-user-icon">
                <img :src="icons.iconExamGray" v-if="icons" class="right-top-icon">
            </span>测评
          </div>
          <div class="right-top-right">
            <span class="right-top-link" @click="toAccess" v-has="'mgr.exam.assignExam'">+分配测评</span>
            <span v-if="rightShowTwo" class="right-top-link" @click="changeArrow(2)">收起
              <img  src="../../assets/images/arrow.png" class="right-arrow-img right-arrow-rotota" />
            </span>
            <span v-else class="right-top-link" @click="changeArrow(2)">展开
              <img src="../../assets/images/arrow.png" class="right-arrow-img" />
            </span>
          </div>
        </div>
        <div class="right-main" :class="{'right-user-sq':!rightShowTwo}">
            <div class="right-table">
              <div class="right-table-item right-table-title">
                <span class="right-tab-one">项目</span>
                <span class="right-tab-two">测评时间</span>
                <span class="right-tab-three">预警状态</span>
              </div>
              <div class="right-table-item"  v-for="(item,index) in replyList" :key="index">
                <span class="right-tab-one" @click="toUserEdit(item)">{{item.examName}}</span>
                <span class="right-tab-two">{{item.examTime}}</span>
                <span class="right-tab-three link-color">{{item.warnStatusDesc}}</span>
              </div>
            </div>
        </div>
      </div>
      <!-- right-item -->
      <div class="right-item">
        <div class="right-item-top">
          <div class="right-item-main">
            <span class="right-user-img right-user-icon">
              <img :src="icons.iconVisitGray" v-if="icons" class="right-top-icon">
            </span>随访
          </div>
          <div class="right-top-right">
            <span class="right-top-link" @click="toPlan" v-has="'mgr.visitPlan.bindPlanPerson'">+分配随访</span>
            <span v-if="rightShowThree" class="right-top-link" @click="changeArrow(3)">收起
              <img  src="../../assets/images/arrow.png" class="right-arrow-img right-arrow-rotota" />
            </span>
            <span v-else class="right-top-link" @click="changeArrow(3)">展开
              <img src="../../assets/images/arrow.png" class="right-arrow-img" />
            </span>
          </div>
        </div>
        <div class="right-main " v-has="'mgr.visitReport.query'" :class="{'right-user-sq':!rightShowThree}"> <!--right-main-nopadding -->
          <div class="right-table">
            <div class="right-table-item right-table-title">
                <span class="right-tab-one">随访计划</span>
                <span class="right-tab-two">随访开始时间</span>
                <span class="right-tab-three">分组</span>
              </div>
              <div class="right-table-item" v-for="(item,index) in visitList" :key="index">
                <span class="right-tab-one">{{item.name}}</span>
                <span class="right-tab-two">{{item.startWay}}</span>
                <span class="right-tab-three">{{item.groupName}}</span>
              </div>
            </div>
          <!-- <div class="fllow-top">
            <div class="fllow-top-item">
              <span class="fllow-name">计划名</span>
              <span class="fllow-des">脂肪肝二期院外随访</span>
            </div>
            <div class="fllow-top-two">
              <div class="fllow-top-item">
                <span class="fllow-name">阶段</span>
                <span class="fllow-des">第二阶段</span>
              </div>
              <div class="fllow-top-item">
                <span class="fllow-name">状态</span>
                <span class="fllow-des">随访中</span>
              </div>
            </div>
          </div>
          <div class="fllow-main">
            <div class="fllow-main-item">
              <div class="fllow-main-flex fllow-main-bold">项目</div>
              <div class="fllow-main-flex fllow-main-bold">状态</div>
              <div class="fllow-main-flex">scl-90</div>
              <div class="fllow-main-flex">
                <img src="../../assets/images/success_icon.png" class="fllow-item-icon"/>
                <span class="link-color">2023-12-12</span>
              </div>
              <div class="fllow-main-flex">scl-90</div>
              <div class="fllow-main-flex">
                <img src="../../assets/images/edit_icon.png" class="fllow-item-icon"/>
              </div>
              <div class="fllow-main-clear"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 分配测评 -->
    <access-assign 
      v-if="assignVisable" 
      :assignUser="currentUser"
      @closeAssign="closeAssign"
    ></access-assign>
    <!-- 分配随访 -->
    <plan-assign
      v-if="assignPlanVisable" 
      :assignUser="currentUser"
      @closePlanAssign="closePlanAssign"
    ></plan-assign>
  </div>
  <!-- chart-right end-->
</template>

<script>
import {
  regPatientDetail,
  regReplyList,
  reqVisitReport,
  regExamReport
} from '../../api/index'
import AccessAssign from '@/components/UserManage/UserCompontent/AccessAssign'
import PlanAssign from '@/components/UserManage/UserCompontent/PlanAssign'
import { powerRight } from '../../utils/index'
export default {
  data () {
    return {
      hideRight: true, // 隐藏右侧
      rightShowOne: true,
      rightShowTwo: true,
      rightShowThree: true,
      userDetail: {},
      visitList: [],
      replyList: [],
      icons: null,
      assignVisable: false,
      assignPlanVisable: false
    }
  },
  components:{
    AccessAssign,
    PlanAssign
  },
  props:{
    currentUser: Object
  },
  mounted(){
    this.rightStart()
    this.icons = JSON.parse(sessionStorage.getItem('icons'))
    console.log('this.hideRight',this.hideRight)
    // this.getReplylist()
    // this.getVisitReport()
  },
  watch:{
    currentUser(){
      console.log('neew')
      if(this.currentUser){
        this.hideRight = false
      }else{
        this.hideRight = true
      }
       
      if(powerRight('mgr.examReport.query')){
        this.getReplylist()
      }
      if(powerRight('mgr.visitReport.query')){
        this.getVisitReport()
      }
      // if(this.currentUser && this.currentUser.patientId){
      //   this.getPatientDetail()
        
      // }else{
        
      // }
    }
  },
  methods:{
    // 隐藏右侧
    rightStart(){
      console.log('rightStart')
      if(this.currentUser && this.currentUser.patientId){
        this.getPatientDetail()
        this.hideRight = false
      }else{
        this.hideRight = true
      }
    },
    changeHide(){
      if(!this.currentUser){
        return false
      }
      this.hideRight = !this.hideRight
    },
    // 右侧time收起展开
    changeArrow(num){
      if(!this.currentUser){
        return false
      }
      if(num === 1){
        console.log('thie',this.rightShowOne)
        this.rightShowOne = !this.rightShowOne
      }else if(num === 2){
        this.rightShowTwo = !this.rightShowTwo
      }else if(num === 3){
        this.rightShowThree = !this.rightShowThree
      }
      console.log('this.rightShowThree',this.rightShowThree)
    },
    // 当前的测试报告 
    getReplylist(){
      const params = {
        pageNo: 1,
        pageSize: 1000,
        //auditStatus: this.replyStatus, // 1 已审核 / 0 未审核 / 2 审核不通过
        keyword: '',
        patientId: this.currentUser.id,
        userId:this.currentUser.userId 
      }
      regReplyList(params).then(res => {
        if(res && res.code === 0){
          let reply = res.data.list
          console.log('reply',reply,res.data)
          for(let i=0; i<reply.length ;i++){
            if(reply[i].examTime && reply[i].examTime.length>10){
              reply[i].examTime = reply[i].examTime.substr(0,10)
            }
          }
          this.replyList = reply
          console.log('replyList',this.replyList)
        }else{
          this.$message({
            message: '获取审核列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 随访报告
    getVisitReport(){
      console.log("随访报告")
      reqVisitReport({
        pageModel: {
          pageNo: 1,
          pageSize: 1000
        },
        userId: this.currentUser.userId 
      }).then(res => {
        if(res && res.code === 0){
          this.visitList= res.data.list
          console.log('visitList',this.visitList)
        }else{
          this.$message({
            message: '获取随访报告失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 编辑
    editUser(){
      if(this.currentUser.patientId){
        this.$router.push({ path: `/EditUser/${this.currentUser.patientId}`})
      }
    },
    // 获取患者详情
    getPatientDetail(data = null){
      console.log('data',data)
      let patientId = ''
      if(data){
        patientId = data.patientId
      }else{
        patientId = this.currentUser.patientId 
      }
      regPatientDetail({
        id: patientId
      }).then(res => {
        console.log('获取患者详情',res)
        if(res && res.code === 0){
          this.userDetail = res.data
        }else{
          this.$message({
            message: '获取患者详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 测评详情
    toUserEdit(data){
      const replyId = data.replyId
      regExamReport({replyId:replyId}).then(res => {
        if(res && res.code === 0){
          this.$router.push({ path: `/accessDetail/${replyId}`})
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
      
    },
    // 分配测评
    toAccess(){
      this.assignVisable = true
    },
    closeAssign(){
      this.assignVisable =  false
      this.getReplylist()
    },
    // 随访
    toPlan(){
      this.assignPlanVisable = true
    },
    closePlanAssign(){
      this.assignPlanVisable =  false
      this.getVisitReport()
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.chart-right
  padding 0 10px 20px
  height calc(100vh - 30px)
  overflow auto
  .chart-des
    position relative
    .chart-des-top
      border-bottom 1px solid #D8D8D8
      position sticky
      background #fff
      top 0
      width 100%
      padding-top 10px
      z-index 3
      text-align right
      .chart-sq
        margin 2px 0 0 0
        cursor pointer
        float left
      .chart-arrow-rotate
        transform rotate(180deg) 
        transition all .2s ease
        margin 0 0 10px 14px!important
      .chart-top-item
        display inline-block
        padding 0 10px 0 15px
        margin-right 5px
        height 40px
        line-height 40px
        font-size 12px
        border-right 1px solid #d8d8d8
        cursor pointer
        .chart-top-icon
          display inline-block
          height 35px
          width 35px
          background #D8D8D8
          margin-right 8px
          text-align center
          position relative
          top 0
          background #E0F2EE
          border-radius 5px
          .chart-nav-icon
            width 20px
            margin 7px
        .chart-top-text
          display inline-block
          height 40px
          line-height 40px 
          position relative
          top -15px
      .chart-top-more
        display inline-block
        height 40px
        line-height 40px  
        position relative
        top -15px
        padding 0 5px 
    .right-item
      margin 15px 5px 0 
      width 390px
      border-radius 5px
      border 1px solid #CED4DA
      border-radius 5px
      overflow hidden
      .right-item-top
        font-size 12px
        height 58px
        line-height 58px
        padding 0 20px 0 14px
        background #f0f0f0
        border-bottom 1px solid #CED4DA
        display flex
        justify-content space-between
        .right-item-main
          padding 9px 0
          display flex
          align-items center
          color #4A4A4A
          .right-user-img
            width 40px
            height 40px
            border-radius 50%
            margin-right 20px
          .right-user-icon
            border-radius 5px
            background #D8D8D8
            .right-top-icon
              width 26px
              margin 7px
        .right-top-right
          .right-top-link
            margin 0 10px
            display inline-block
            cursor pointer
            .right-arrow-img
              position relative
              top 4px
              transition all .5s ease
            .right-arrow-rotota
              transform rotate(180deg) 
              transition all .5s ease
      .right-main
        padding 10px 25px 20px 
        font-size 14px
        max-height auto
        .user-item
          padding 5px
          line-height 24px
          .user-item-title
            display inline-block
            width 58px
            text-align right
            color #9E9E9E
            margin-right 15px
            transition all 1s ease
        .right-table
          .right-table-item
            line-height 22px
            margin 7px 0
            display flex
            .right-tab-one
              display inline-block
              width 145px
            .right-tab-two
              display inline-block
              flex 1
            .right-tab-three
              display inline-block
              width 60px
          .right-table-title
            margin-bottom 20px
            font-weight bold!important
        .fllow-top
          border-bottom 1px dashed #ccc
          padding 10px 13px 0 
          .fllow-top-item
            display flex
            line-height 20px
            margin 10px 0
            .fllow-name
              display inline-block
              width 44px
              margin-right 30px
              text-align right
              font-weight bold
          .fllow-top-two
            display flex
            .fllow-top-item
              width 50%
              display flex
              flex-wrap wrap
              .fllow-main-flex
                display inline-block
                width 210px
                margin-top 13px
              .fllow-main-clear
                clear both
                height 20px
        .fllow-main
          padding 13px 13px 30px
          clear both
          .fllow-main-item
            .fllow-main-flex
              display inline-block
              float left
              width 50%
              margin 10px 0
              .fllow-item-icon
                position relative 
                top 4px
                margin-right 5px
            .fllow-main-bold
              font-weight bold
              margin-bottom 10px
      .right-main-nopadding
        padding 0
        overflow hidden 
        min-height 10px
      .right-user-sq
        transition all 1s ease
        max-height 0
        padding-bottom 0
        clear both
.hidden-right
  width 100px
  // transform rotate(180deg) 
  transition all .5s ease
  .right-item
    display none
  .chart-top-text
    display none!important
  .chart-top-more
    display none!important
  .chart-des-top
    border-bottom none
    .chart-top-item
      border-right none!important
  .chart-sq
    margin-left 50px
</style>