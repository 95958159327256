import axios from '../utils/axiosConfig'
import router from '../router'
import qs from 'qs'
//import {getUrlParam} from "../utils"


export default function ajax(url = '', data = {}, type = 'POST',isToken = false, isFrom = false,isJson=false,putObj = false) {
  return new Promise(function (resolve, reject) {
    let promise
    let token = ''
    let contentType = "application/x-www-form-urlencoded"
    if(isFrom){
      console.log('ifrom')
      contentType =  "multipart/form-data"
    }
    if(isJson){
      contentType =  "application/json; charset=utf-8"
    }
    if (type === 'GET') {
      // 准备url query参数数据
      // let dataStr = '' //数据拼接字符串
      // Object.keys(data).forEach(key => {
      //   dataStr += key + '=' + data[key] + '&'
      // })
      // if (dataStr !== '') {
      //   dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
      //   url = url + '?' + dataStr
      // }
      // 发送get请求
      //if(isSetHeader){
        // const {tecrun} = getUrlParam(location.search)
        // const {token} = getUrlParam(location.search)
        promise = axios({
          method: 'GET',
          url: url +'/'+data,
          headers: {
            token: sessionStorage.getItem('token')
          },
        })
      //}else{
      //  promise = axios.get(url)
      //}
     
    } else if((type === 'PUT')){
      if(putObj){
        promise = axios.put(url,data, {
          headers: {
            "content-type": contentType,
            token: sessionStorage.getItem('token')
          }
        }) 
      }else{
        for (var key in data) {
          console.log('data[key]',data,data[key])
          url += '/' + data[key]
          // 进行相关操作
        }
        promise = axios.put(url,{}, {
          headers: {
            "content-type": contentType,
            token: sessionStorage.getItem('token')
          }
        }) 
      }
      
    } else if(type === 'DELETE'){
        promise = axios({
          method: 'DELETE',
          url: url +'/'+data,
          headers: {
            token: sessionStorage.getItem('token')
          },
        })
    }else {
      if(isToken){
        // const {tecrun} = getUrlParam(location.search)
        // const {token} = getUrlParam(location.search)
        promise = axios({
          method: 'POST',
          url,
          data:Object.assign(data),
          headers: {
            "content-type": contentType,
            token: sessionStorage.getItem('token')
          },
        })
      }else {
        // 发送post请求
        promise = axios({
          method: 'POST',
          url,
          data:Object.assign(data),
          headers: {
            "content-type": contentType
          },
        })
      }

    }

    promise.then(response => {
      if(response.data.code === 403){
        alert('token过期，将跳转到登录页！')
        setTimeout(() => {
          router.push({ path: '/login'})
        }, 1000);
        return false
      }      
      resolve(response.data)
    })
      .catch(error => {
        console.log('xxx',error)
        // ({
        //   MessageBoxtitle: "系统提示",
        //   summary: error.message,
        //   showCancelButton: false,
        // }).then(info => {
        // });
        reject(error)
      })
  })
}
