import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
import VDistpicker from 'v-distpicker'
//import directive from './directive'

Vue.component('v-distpicker', VDistpicker)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI);
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
//Vue.use(directive)

//定义一个全局指令 在main.js中引入
Vue.directive('has', {
        inserted: function(el, binding) {
            if (!hasArray(binding.value)) {
                el.parentNode.removeChild(el)
            }
        }
    })
    //真实array数据应该是后台传递过来的权限数组
let array = JSON.parse(sessionStorage.getItem('rightCodeList'))

function hasArray(val) {
    return array.includes(val)
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')