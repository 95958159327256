<template>
  <div class="login">
    <div class="login-flex">
      <div class="login-left">
        <img src="../../assets/images/cricle.png" class="login-icon login-icon-cricle" />
        <img src="../../assets/images/cricle2.png" class="login-icon login-icon-cricle2" />
        <img src="../../assets/images/start.png" class="login-icon login-icon-start" />
        <img src="../../assets/images/triangle.png" class="login-icon login-icon-triangle" />
        <img src="../../assets/images/reg_logo.png" class="login-icon login-icon-slogen" />
      </div>
      <div class="login-max">
        <div class="login-con">
          <el-tabs class="login-tabs" v-model="activeLoginName" @tab-click="handleChangeTab">
            <el-tab-pane label="账号登录" name="num">账号登录</el-tab-pane>
            <el-tab-pane label="短信登录" name="phone">短信登录</el-tab-pane>
          </el-tabs>
          <div class="login-from" v-if="activeLoginName === 'num'">
            <div class="login-item"><el-input class="login-input" v-model="mobilePhone" placeholder="输入账号"></el-input></div>
            <div class="login-item"><el-input class="login-input" v-model="password" show-password placeholder="密码"></el-input></div>
          </div>
          <div class="login-from"  v-if="activeLoginName === 'phone'">
            <div class="login-item"><el-input class="login-input" v-model="mobilePhone" placeholder="输入手机号"></el-input></div>
            <div class="login-item login-code-item">
              <el-input v-model="verfiyCode" class="login-input login-code" placeholder="验证码" />
              <span type="primary" class="get-code link-color" :class="{'glay-link':waiting}" @click="getPhoneCode">{{waitText}}</span>
            </div>
          </div>
          <div class="login-buttons">
            <span class="glay-btn">取消</span>
            <span class="color-btn" @click="handleLogin">登录</span>
          </div>
          <div class="login-toreg">
            没有账户？ <span class="link-color" @click="toReg" >立即注册</span>
          </div>
        </div>
        <div class="login-copy">
          版权所有 © 2023 legu Health  - 保留所有权利
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  regPassLogin,
  regCodeLogin,
  regPhoneCodeLogin
} from '../../api/index'
export default {
  data () {
    return {
      activeLoginName: 'num',
      mobilePhone:'',  // 账号
      password: '', // 密码
      verfiyCode: '',  // 验证码
      waiting: false, // 等待验证码中
      waitText: '获取验证码', // 验证码倒计时
    }
  },
  methods:{
    handleChangeTab(){
      
    },
    // 获取登录验证码
    getPhoneCode(){
      this.verfiyCode = ''
      if(this.waiting){
        return false
      }
      if(this.mobilePhone.length < 11){
        this.$message({
          message: '清输入正确的手机号码',
          type: 'error'
        }); 
        return false
      }
      regPhoneCodeLogin({
        mobilePhone: this.mobilePhone
      }).then(res => {
        if(res && res.code === 0){
          // 验证码倒计时
          let timeLeft = 60
          this.waiting = true;
          // 更新按钮上的文字
          this.waitText = timeLeft + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft--;
        
            // 更新按钮上的文字
            this.waitText = timeLeft + 's后再次获取';
        
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft === 0) {
              clearInterval(timer);
              this.waiting = false;
              this.waitText = '获取验证码';
            }
          }, 1000);
        }else{
          this.$message({
            message: '获取验证码失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    handleLogin(){
      if(this.mobilePhone.length < 11){
        this.$message({
          message: '清输入正确的手机号码',
          type: 'error'
        }); 
        return false
      }
      if(this.activeLoginName === 'num'){ // zhao
        if(!this.password) return false
        const params = {
          mobilePhone: this.mobilePhone,
          password: this.$md5(this.password).toUpperCase() 
        }
        console.log('num',params)
        regPassLogin(params).then(res => {
          if(res && res.code === 0){
            sessionStorage.setItem('token',res.data.token)
            sessionStorage.setItem('name',res.data.name)
            sessionStorage.setItem('rightCodeList',JSON.stringify(res.data.rightCodeList))
            sessionStorage.setItem('rePass',false)
            if(res.data.firstLogin === 'Y'){
              this.$router.push({ path: '/resetPassword',query: { mobilePhone: this.mobilePhone} })
            }else{
              if(res.data.orgList.length){
                sessionStorage.setItem('isPerson',true)
              }else{
                sessionStorage.setItem('isPerson',false)
              }
              
              this.$router.push({ path: '/homePage'})
            }            
          }else{
            this.$message({
              message: '登录失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        if(!this.verfiyCode) return false
        const params = {
          mobilePhone: this.mobilePhone,
          verfiyCode: this.verfiyCode
        }
        regCodeLogin(params).then(res => {
          if(res && res.code === 0){
            sessionStorage.setItem('token',res.data.token)
            sessionStorage.setItem('name',res.data.name)
            sessionStorage.setItem('rightCodeList',JSON.stringify(res.data.rightCodeList))
            sessionStorage.setItem('rePass',false)
            if(res.data.firstLogin === 'Y'){
              this.$router.push({ path: '/resetPassword',query: { mobilePhone: this.mobilePhone} })
            }else{
              this.$router.push({ path: '/homePage'})
            }
          }else{
            this.$message({
              message: '登录失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    // 去reg
    toReg(){
      this.$router.push({ path: '/seletReg'})
    },
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.login
  width 100%
  height 100%
  .login-flex
    display  flex
    width 100%
    height 100%
    .login-left
      width 690px
      height 100%
      background #009375
      position relative
      .login-icon
        position absolute
      .login-icon-cricle
        top 20px
        left -50px
      .login-icon-cricle2
        bottom -400px
        left -100px
      .login-icon-triangle
        top 162px
        left 406px
      .login-icon-start
        right 159px
        bottom 165px
      .login-icon-slogen
        left 50%
        margin-left -146px
        top 48%
    .login-max
      flex 1
      position relative
      .login-con
        display inline-block
        width 435px
        position absolute
        top 50%
        left 50%
        margin -260px 0 0 -218px
        .login-tabs
          margin-bottom 34px
          >>>.el-tabs__item.is-active,>>>.el-tabs__item:hover
            color #009375!important
          >>>.el-tabs__active-bar
            background  #009375!important
          >>>.el-tabs__nav-wrap::after,>>>.el-tabs__content
            display none
        .login-from
          .login-item
            margin-bottom 40px
            width 433px
            display flex
            .login-input>>>.el-input__inner
              width 433px
              height 50px
              line-height 50px
              border-color #CED4DA
            .login-code>>>.el-input__inner
              width 290px
            .get-code
              line-height 50px
              cursor pointer
            .glay-link
              color #7e7e7e
          .login-code-item>>>.login-input
            width  290px
            margin-right 50px
        .login-buttons
          text-align center
          span
            margin 0 18px
        .login-toreg
          margin-top 120px
          text-align center
          font-size 16px
          .link-color
            cursor pointer
      .login-copy
        text-align center
        position absolute
        width 100%
        text-align center
        bottom 30px
</style>