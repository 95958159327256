<template>
  <div class="assess">
    <access-links :routeLink="'accessManage'"></access-links>
    <div class="manage-search">
      <div class="search-left">
        <div class="search-input-out">
          <el-input class="search-input" v-model="keyword" placeholder="搜索电话、姓名、诊断" />
          <img src="../../assets/images/search.png" @click="getExportList" class="search-icon" />
        </div>
        <!-- <el-select class="search-select" placeholder="部门">
          <el-option
            v-for="item in departArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
        <el-date-picker
          v-model="startDate"
          type="date"
          class="search-date"
          placeholder="开始日期">
        </el-date-picker>
        <el-date-picker
          v-model="endDate"
          type="date"
          class="search-date"
          placeholder="结束日期">
        </el-date-picker>
        <el-select class="search-select" v-model="auditStatus" placeholder="状态">
          <el-option
            v-for="item in statusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-right">
        <span class="search-btn" v-has="'mgr.examReport.export'"><img src="../../assets/images/import.png" class="search-btn-icon"> 导出数据</span>
        <!-- <span class="search-btn" ><img src="../../assets/images/print.png" class="search-btn-icon"> 打印报告</span> -->
      </div>
    </div>
    <el-table
      :span-method="objectSpanMethod"
      v-has="'mgr.examReport.query'"
      style="width: 100%"
      :data="tableData"
      class="table-div"
      height="calc(100vh -300px)"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="name"
        label="用户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        align="center"
      ></el-table-column>
      <el-table-column prop="mobilePhone" label="电话" align="center"></el-table-column>
      <el-table-column prop="price" label="部门" align="center"></el-table-column>
      <el-table-column prop="examName" label="项目" align="center"></el-table-column>
      <el-table-column prop="examTime" label="测评时间" align="center"></el-table-column>
      <el-table-column prop="warnStatus" label="预警状态" align="center">
        <template slot-scope="scope" >
          <template v-if="scope.row.status == 1">
            <span class="error-text">-</span>
          </template>
          <template v-else-if="scope.row.warnStatus == 0">
            <span class="error-text">正常</span>
          </template>
          <template v-else>
            <span class="error-text">不正常</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="auditStatus" label="审核" align="center">
        <!-- slot-scope="scope" -->
        <!-- <el-button
          type="text"
          @click="toUserDetail(scope.row)"
          class="table-green-btn"
          size="small">
          未审核
        </el-button> -->
        <template slot-scope="scope" >
          <template v-if="scope.row.auditStatus == 1">
             <el-popover
                popper-class="status-popover"
                placement="bottom"
                width="220"
                style="padding:5px;min-width:200px!important;border-radius:10px;line-height:22px;max-height:160px;overflow-y:auto;"
                trigger="hover">
                <div class="handle-con" style="line-height:28px;padding:5px 0;">
                  <div class="sh-item">
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">项目</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">{{scope.row.examName}} {{scope.row.examTime}}</p>
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">审核状态</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">已审核</p>
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">审核描述</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">{{scope.row.auditStatusDesc}}</p>
                  </div>
                </div>
                <span class="error-text" slot="reference" style="color:#B55E4E;">已审核</span>
              </el-popover>
          </template>
          <template v-else-if="scope.row.auditStatus == 2">
             <el-popover
                popper-class="status-popover"
                placement="bottom"
                width="220"
                style="padding:5px;min-width:200px!important;border-radius:10px;line-height:22px;max-height:160px;overflow-y:auto;"
                trigger="hover">
                <div class="handle-con" style="line-height:28px;padding:5px 0;">
                  <div class="sh-item">
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">项目</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">{{scope.row.examName}}</p>
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">审核状态</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">未通过</p>
                    <p class="sh-item-par" style="color:#009375;padding:0 5px;">审核描述</p>
                    <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;">{{scope.row.statusDesc}}</p>
                  </div>
                </div>
                <span class="error-text" slot="reference" style="color:#B55E4E;">未通过</span>
              </el-popover>
            
          </template>
          <template v-else>
            <!-- @click="handleStatus(scope.row)" -->
            <span class="error-text" >未审核</span>
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="remark" label="审核" align="center">
        <el-popover
          popper-class="table-popover"
          placement="bottom"
          width="90"
          style="padding:0;min-width:90px!important;"
          trigger="click">
          <div class="handle-con" style="line-height:28px;padding:5px 0;">
            <div class="sh-item">
              <p class="sh-item-par">项目</p>
              <p class="sh-item-par">SAS焦虑测评量表</p>
            </div>
          </div>
        </el-popover>
      </el-table-column> -->
      <el-table-column
          width="160"
          class="table-handles"
          style="white-space: nowrap;width:140px;"
          label="操作">
          <template slot-scope="scope"> <!-- slot-scope="scope" -->
             <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
            <el-button
              type="text"
              v-has="'mgr.examReport.query'"
              @click="toAccessDetail(scope.row)"
              class="table-green-btn"
              size="small">
              预览
            </el-button>
            <el-button
              v-has="'mgr.examReport.audit'"
              type="text"
              class="table-green-btn"
              @click="toUserEdit(scope.row)"
              size="small">
              审核
            </el-button>
            <el-button
              type="text"
              v-has="'mgr.exam.assignExam'"
              class="table-green-btn"
              @click="toUserDist(scope.row)"
              size="small">
              分配
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    <!-- <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      class="groupmul-table"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="日期"
        width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="120">
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        show-overflow-tooltip>
      </el-table-column>
    </el-table> -->
      <div class="pager" style="margin-top:10px;" v-has="'mgr.examReport.query'">
          <!-- @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage4" -->
      <el-pagination
        :current-page="pageNo"
        :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="total, sizes, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <!-- 详情抽屉 -->
    <el-drawer
      title=""
      :size="700"
      :visible.sync="shoDetailDrawer"
      :with-header="false">
        <access-detail-drawer
          :replyId="replyId"
          :detailObj="detailObj"
          @closeDrawer="closeDrawer"
        ></access-detail-drawer>
    </el-drawer>
    <!-- 分配量表 -->
    <access-assign 
      v-if="assignVisable" 
      :assignUser="assignUser"
      @closeAssign="closeAssign"
    ></access-assign>
  </div>
</template>
<script>
import {
  regExamReportList,
  regExamReport
} from '../../api/index'
import _ from "lodash";
import { powerRight } from '../../utils/index'
import AccessLinks from '@/components/Access/AccessLinks'
import AccessDetailDrawer from '@/components/Access/AccessDetailDrawer'
import AccessAssign from '@/components/UserManage/UserCompontent/AccessAssign'
export default {
  components:{AccessDetailDrawer,AccessLinks,AccessAssign},
  data () {
    return {
      replyId: null,
      startDate: null,
      endDate: null,
      shoDetailDrawer: false,
      departArr:[
        {
          label: '部门1',
          value: 1
        },
        {
          label: '部门2',
          value: 2
        }
      ],
      tableData: [],
      auditStatus: '', // 审核状态
      statusArr: [{
        value:1,
        label: '已审核'
      },{
        value:0,
        label: '待审核'
      },{
        value:2,
        label: '审核不通过'
      }],
      keyword: '',
      pageNo: 1,
      pageSize:  20,
      total: 0, // 成员数
      cellList: [], // 单元格数组
      count: null, // 计数
      multipleSelection: [],
      detailObj: null,
      assignVisable: false, // 分配量表
      assignUser: null
    }
  },
  mounted(){
    if(powerRight('mgr.examReport.query')){
      this.getExportList()
    }
  },
  methods:{
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getExportList()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getExportList()
    },
    getExportList(){
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        auditStatus: this.auditStatus,
        createTimeStart: this.startDate,
        createTimeEnd: this.endDate,
        keyword: this.keyword
      }
      regExamReportList(params).then(res => {
        if(res && res.code === 0){
          let theData = _.cloneDeep(res.data.list)
          let tableDatas = []
          this.cellList = []
          //this.tableData = []
          for(let i=0; i<theData.length; i++){
            for(let j=0;j<theData[i].replyList.length; j++){
              let senData = _.cloneDeep(theData[i].replyList[j])
              let parentData =  _.cloneDeep(theData[i])
              parentData.replyList = []
              console.log('parentData',parentData)
              tableDatas.push({
                ...parentData,
                examName: senData.examName,
                replyId: senData.replyId,
                warnStatus: senData.warnStatus,
                examTime: senData.examTime || '',
                auditStatus: senData.auditStatus,
                auditStatusDesc: senData.auditStatusDesc,
                status: senData.status,
                statusDesc: senData.statusDesc,
              })
            }
          }
          console.log('tableData',tableDatas)
          //this.computeCell(this.tableData);
          
          //this.tableData = tableDatas
          let cloneTable = _.cloneDeep(tableDatas)
          this.computeCell(cloneTable);
          this.$nextTick(() => {
            this.tableData = cloneTable
            // this.tableData.sort((a, b) => {
            //   return a.orderNo - b.value;
            // });
            console.log('this.tableData',this.tableData)
          })
        }else{
          this.$message({
            message: '获取测评列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分配
    toUserDist(data){
      this.assignUser = data
      this.assignVisable = true
      console.log('toUserDist',data)
    },
    closeAssign(){
      this.assignVisable =  false
    },
    computeCell(tableData) {
      // 循环遍历表体数据
      for (let i = 0; i < tableData.length; i++) {
        if (i == 0) {
          // 先设置第一项
          this.cellList.push(1); // 初为1，若下一项和此项相同，就往cellList数组中追加0
          this.count = 0; // 初始计数为0
        } else {
          // 判断当前项与上项的设备类别是否相同，因为是合并这一列的单元格
          if (tableData[i].orderNo == tableData[i - 1].orderNo) {
            // 如果相等
            this.cellList[this.count] += 1; // 增加计数
            this.cellList.push(0); // 相等就往cellList数组中追加0
          } else {
            this.cellList.push(1); // 不等就往cellList数组中追加1
            this.count = i; // 将索引赋值为计数
          }
        }
      }
    },
    // 第2步，将计算好的结果返回给el-table，这样的话表格就会根据这个结果做对应合并列渲染
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 给第二列做单元格合并。0是第一列，1是第二列。
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        const rowCell = this.cellList[rowIndex];
        if (rowCell > 0) {
          const colCell = 1;
          return {
            rowspan: rowCell,
            colspan: colCell,
          };
        } else {
          // 清除原有的单元格，必须要加，否则就会出现单元格会被横着挤到后面了！！！
          // 本例中数据是写死的不会出现，数据若是动态后端获取的，就会出现了！！！
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    // 审核
    handleStatus(data){
      this.$router.push({ path: `/accessAudit/${data.replyId}`})
    },
    // 查看详情
    toAccessDetail(data){
      console.log('查看详情',data)
      this.replyId = data.replyId
      regExamReport({replyId:data.replyId}).then(res => {
        if(res && res.code === 0){
          this.detailObj= res.data
          this.shoDetailDrawer = true
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
      
      //this.$router.push({ path: `/accessDetail/${data.id}`})
    },
    // 关闭详情
    closeDrawer(){
      this.shoDetailDrawer = false
    },
    // 编辑
    toUserEdit(data){
      this.replyId = data.replyId
      regExamReport({replyId:data.replyId}).then(res => {
        if(res && res.code === 0){
          this.$router.push({ path: `/accessAudit/${data.replyId}`})
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
      
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .assess-manages
    width 367px
    height 46px
    line-height 46px
    box-sizing border-box
    border 1px solid #E8E8E8
    display flex
    justify-content space-between
    border-radius 25px
    margin 0 auto 25px
    .assess-item
      display inline-block
      width 100px
      height 40px
      line-height 40px
      text-align center
      margin  3px
      font-size 14px
      cursor pointer
    .assess-item-active
      border-radius 25px
      background #E0F2EE
      color #009375
      font-weight  bold
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-date
      width 136px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .table-div
    width 100%
    font-size 14px
    overflow auto
    height calc(100vh - 230px)
    overflow auto
    >>>tr
      line-height 18px
      margin 4px 0
      >>>.el-button--text
        color #4a4a4a!important
      &:hover>td 
        background  #E0F2EE!important
        color #00A389
        font-weight  bolder
        .table-green-btn
          color #00A389!important
          font-weight 700!important
      .el-table__cell
        padding 10px 0
    >>>.table-handles
      white-space nowrap
    .table-green-btn
      font-size 14px
      color #4a4a4a!important
      font-weight bold
    .popover-more
      margin-left 10px!important
      position relative
      top -4px
</style>