<template>
  <el-dialog
    title="变更部门"
    class="dialog-main"
    :visible=true
    @close="closeAddDept"
    width="600px">
    <div class="dept-con">
        <div class="dept-left">
          <el-tree
            ref="tree"
            :data="parentOrgRoleArr"
            show-checkbox
            node-key="id"
            default-expand-all
            check-strictly
            @check-change="roleChange"
            :default-checked-keys="selectedRole"
            :props="defaultProps">
          </el-tree>
        </div>
        <div class="dept-right">
          <p class="dept-right-top">已选：{{selectBm.length}}个部门
            <span class="dept-clear" @click="clearAll">清空</span>
          </p>
          <div class="depy-alery">
            <div class="depy-alery-item" v-for="(item,index) in selectBm" :key="index">
              {{item.name}}
              <!-- <img src="../../../assets/images/close.png" class="close-btn" @click="deleteRole(item.id)" /> -->
            </div>
          </div>
        </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddDept">取消</span>
      <span class="dialog-btn" @click="handleAddDept">确定</span>
    </div>
  </el-dialog>
  <!-- 新建部门 end --> 
</template>
<script>
import {
  regOrgTree,
  regDeptAndRoleTree,
  regDetailMember,
  regEditDept
} from '../../../api/index'
import _ from "lodash";
export default {
  data(){
    return{
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      parentOrgRoleArr: [],
      selected: [],
      selectedRole: [],
      selectBm: []
    }
  },
  props:{
    memberId: Number || String,
    firstOrgDeptArr: Array,
    childOrg: Array,
    orgId: Number,
    orgName: String
  },
  mounted(){
    this.getOrgTree()
    console.log('firstOrgDeptArr',this.firstOrgDeptArr)
    console.log('childOrg',this.childOrg)
   // this.setTree()
  },
  methods:{
    setTree(){
      let theTree = []
      let one = {
        id: this.orgId,
        name: this.orgName,
        children: this.firstOrgDeptArr
      }
      theTree.push(one)
      theTree.concat(this.childOrg)
      this.parentOrgRoleArr = theTree
      console.log('theTree',theTree)
    },
    // 获取组织树
    getOrgTree(){
      regOrgTree({}).then(res => {
        if(res.code === 0){
          console.log('组织树树',res.data)
          let resData =  _.cloneDeep(res.data) 
          //this.parentOrgRoleArr = resData.children
          this.getOneOrgDeptRole(resData)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取组织树失败！' + res.msg
          });
          
        }
      })
    },
    handleSeeMember(){
      regDetailMember({
        id: this.memberId
      }).then(res => {
        if(res.code === 0){
          console.log('detail',res.data)
          let deptList = res.data.deptList
          let deptIds = deptList.map(item => item.id)
          this.selectedRole = deptIds
          this.selectBm = deptList
          this.selected = []
          for(let i=0; i<deptList.length; i++){
            this.selected.push(deptList[i].id)
          }
          this.$refs.tree.setCheckedKeys(deptList)
          console.log('selectedRole',this.selectedRole)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取人员详情失败！'
          });
          
        }
      })
    },
    // 获取一级组织下的部门角色树
    getOneOrgDeptRole(theData){
      let data =  _.cloneDeep(theData)
      console.log('datadata',data)
      regDeptAndRoleTree({
        id: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          console.log('datadata res',res)
          //this.parentOrgRoleArr[0] = res.data
          this.parentOrgRoleArr.unshift({
            children: res.data,
            id: data.id,
            name: data.orgName
          })
          const childData = data.children
          console.log('childData',childData)
          for(let i=0; i<childData.length; i++){
            console.log('childData[i]',childData[i])
            this.getDeptRoleTree(childData[i],i+1)
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门角色树失败！'+ res.msg
          });
        }
      })
    },
     // 获取组织下的部门角色树
    getDeptRoleTree(data,i){
      console.log('after',data)
      regDeptAndRoleTree({
        id: data.id
      }).then(res => {
        if(res.code === 0){
          // res.data
          //this.parentOrgRoleArr(res.data)
          //this.parentOrgRoleArr[i] = res.data
          this.parentOrgRoleArr.push({
            children: this.treeFilter(res.data),
            id: data.id,
            name: data.orgName
          })
          this.setTreeData(this.parentOrgRoleArr)
          this.handleSeeMember()
          //this.parentOrgRoleArr.splice(i, 1, res.data);
          console.log('parentOrgRoleArr',this.parentOrgRoleArr)
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门树失败！'+ res.msg
          });
        }
      })
    },
    treeFilter(list)  {
      if (!list) {
          return
      }
      return list.filter(item => {
          item.children = this.treeFilter(item.children)
          if (item.children && item.children.length) {
            return true
          } else {
            return  (item.type == undefined || item.type == 'dept')
          }
      })
    },

    setTreeData(tree){
      // for(let i=0; i<tree.length;i++){
      //   if(tree[i].type && tree[i].type === "role"){
      //     tree[i].disabled = true
      //   }else{
      //     tree[i].disabled = false
      //   }
      //   if(tree[i].children){
      //     this.setTreeData(tree[i].children)
      //   }
      // }
    },
    roleChange(checkedNodeKeys, checkedNodes){
      this.selectBm = this.$refs.tree.getCheckedNodes()
      console.log('checkedNodeKeys, checkedNodes',checkedNodeKeys, checkedNodes,this.$refs.tree.getCheckedNodes())
      //this.getSelectedNodes()
    },
    getSelectedNodes() {
      const tree = this.$refs.tree;
      const selectedNodes = tree.getCheckedNodes();
      this.selected = selectedNodes.filter(item => item.type === "role")
    },
    // 部门变更
    handleAddDept(){
      console.log('selectedRole',this.selectedRole)
      let selectArr  = this.$refs.tree.getCheckedNodes().map(obj => obj.id)
      
      regEditDept({
        id: this.memberId,
        deptIdList: selectArr.join(',') //JSON.parse(JSON.stringify(this.selectedRole))
      }).then(res => {
        if(res.code === 0){
          this.$message({
            message: '变更成功',
            type: 'success'
          });
          this.$emit('changeDeptSucc')
        }else{
          this.$notify.error({
            title: '错误',
            message: '变更失败！'+ res.msg
          });
        }
      })
    },
    deleteRole(id){
      console.log('id',id)
      let idx = this.selected.indexOf()
      this.selected = this.selected.filter( (obj) => {
        console.log('obj[id]',obj,obj.id)
          return obj.id !== id;
      });
      console.log('arr',this.selected)
    },
    clearAll(){
      this.selected = []
      this.selectBm = []
      this.$refs.tree.setCheckedKeys([]);
    },
    // 取消
    closeAddDept(){
      this.$emit('closeChangeDept')
    }
  },
  watch:{
    selected(newVal){
      console.log('typeof newVal',typeof newVal,typeof newVal[0])
      if(newVal.length && typeof newVal[0] !== 'number'){
        console.log('selected',newVal,_(newVal).map('id').value())
        if(_(newVal).map('id').value() != undefined){
          this.selectedRole = _(newVal).map('id').value();
          console.log('this.selectedRole',this.selectedRole)
          this.$refs.tree.setCheckedKeys(this.selectedRole)
        }
      }
    },
    childOrg(){
      this.setTree()
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main  
  .dept-con
    border 1px solid #DADADA
    border-radius 5px
    height 270px
    display flex 
    .dept-left
      width 50%
      overflow-y auto
      border-right 1px solid #DADADA
      padding 10px
    .dept-right
      flex 1
      padding 20px 30px
      .dept-right-top
        padding-right 20px
        position relative
        .dept-clear
          position absolute
          right 0
          top 0
          color #009375
          cursor pointer
      .depy-alery
        margin-top 20px
        .depy-alery-item
          margin-top 10px
          position relative
          overflow hidden
          white-space  nowrap
          text-overflow ellipsis
          .close-btn
            position absolute
            right 0
            top 0
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
      margin-top 20px
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>