<template>
    <!-- 分组管理 table -->
    <div>
      <el-dialog
      title="分组管理"
      class="batch-upload"
      :visible=true
      @close="closeGropuList"
      width="780px">
      <div class="batch-table">
        <el-table                                          
          :data="FollowGroupDatas"
          maxHeight="440px"
          class="user-table">     
          <el-table-column
            type="index"
            label="编号"
            width="100">
          </el-table-column>
          <el-table-column
            prop="name"
            label="分组名">
          </el-table-column>
          <el-table-column
            prop="numofPatient"
            label="参与人">
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button class="group-link" v-has="'mgr.visitGroup.updateGroup'" @click="editGroup(scope.row)" style="color:#4a4a4a" type="text" size="small">编辑</el-button>
              <el-button class="group-link" v-has="'mgr.visitGroup.delGroup'" @click="deleteGroup(scope.row)" style="color:#4a4a4a" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add-groupcon">
        <span  class="add-btn" @click="toAddGroup" v-has="'mgr.visitGroup.createGroup'"> <span class="add-green">+ </span>新增分组</span>
        <el-pagination
          background
          class="group-pager"
          layout="prev, pager, next"
          :total="groupTotal">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 新增分组 -->
    <el-dialog
      title="新增分组"
      class="batch-upload"
      :visible.sync="addGroupVisible"
      width="455px">
      <div class="dialog-con">
        <el-input class="add-group" v-model.trim="currentGroupName"  placeholder="输入分组名"></el-input>
      </div>
      <div class="batch-btns">
        <el-button class="batch-btn" size="small" type="" @click="addGroupVisible = false">取消</el-button>
        <el-button class="batch-btn success-btn" size="small" @click="handleModifyGroup" type="">确定</el-button>
      </div>
    </el-dialog>
    <!-- 新增分组 end -->
  </div>
</template>

<script>
import {
  reqCreateGroup,
  reqPageGroup,
  reqUpdateGroup,
  reqDelGroup
} from '../../api/index'
export default {
  data () {
    return {
      FollowGroupDatas: [],
      groupTotal: 0,
      addGroupVisible: false,
      manageDailog: true,
      currentGroupName: '',
      modifyId: null,
    }
    
  },
  mounted(){
    this.getGroupList()
  },
  methods:{
    closeGropuList(){
      this.$emit('closeGropuList')
    },
    toAddGroup(){
      this.modifyId = null
      this.currentGroupName = ''
      this.addGroupVisible = true
    },
    // 分组列表
    getGroupList(){
      reqPageGroup({
        pageNo: 0,
        pageSize: 1000
      }).then(res => {
        if(res && res.code === 0){
          this.FollowGroupDatas = res.data.list
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 新增分组
    handleModifyGroup(){
      console.log('修改修改',this.modifyId)
      if(!this.currentGroupName){
        this.$message({
          message: '请先填写分组名',
          type: 'wanring'
        }); 
        return false
      }
      if(this.modifyId){
        reqUpdateGroup({
          name: this.currentGroupName,
          id: this.modifyId
        }).then(res => {
          if(res && res.code === 0){
            this.addGroupVisible = false
            this.getGroupList()
          }else{
            this.$message({
              message: '修改失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        reqCreateGroup({
          name: this.currentGroupName
        }).then(res => {
          if(res && res.code === 0){
            this.addGroupVisible = false
            this.getGroupList()
          }else{
            this.$message({
              message: '新增失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    editGroup(data){
      console.log('data',data)
      this.addGroupVisible = true
      this.currentGroupName = data.name
      this.modifyId = data.id
    },
    deleteGroup(data){
      reqDelGroup(data.id).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '操作成功',
            type: 'success'
          }); 
          this.getGroupList()
        }else{
          this.$message({
            message: '删除失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
      console.log('data',data)
    }
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.batch-upload
  >>>.el-icon-upload
    margin 22px 0 22px
    &::before
      content ""
      display inline-block
      width 40px
      height 30px
      background url(../../assets/images/upload.png) no-repeat
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
  >>>.el-upload__text
    color #009375
  >>>.el-upload-dragger
    width 642px
    height 137px
    border-color #009375 
  >>>.loading
    margin-top 40px
    i
      font-size 30px
  .batch-table
    >>>.has-gutter
      border-radius 5px
      overflow hidden
    >>>td
      padding 9px 0
      height 25px
      line-height 25px
    >>>tr
      &:hover
        cursor pointer
        border-radius 3px
        >td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
    >>>th
      padding 3px 0
      height 34px
      line-height 34px
      background #F0F0F5
      border none
  .batch-btns
    padding 20px 100px
    display flex
    justify-content space-around
    .batch-btn
      width:70px
      height 34px
      border-radius 5px
      background #F0F3F6
      border none
    .success-btn
      background #009375
      color #fff
  .dialog-con
    text-align center
    .delete-con
      color #FF630F
      font-size 14px
      .wanning-ico
        width 28px
        margin-bottom 20px
    .delete-par
      margin-top 15px
      line-height 18px
      margin-bottom 10px
    .add-group
      width 300px
      background #F7F8FA
    .dialog-flex
      display flex
      width 100%
      margin-bottom 24px
      .transfer-item
        display flex
        flex 1
        justify-content space-around
    .transfer-text
      text-align center
      color #009375
      margin-bottom 20px
    .transfer-select
      margin-bottom 20px
      >>>.el-input__inner
        width 250px
        height 30px
        line-height 30px
        >>>.el-input__icon
          line-height 30px
  .add-groupcon
    margin-top 30px
    display flex
    justify-content space-between
    align-content center
    .add-btn
      display inline-block
      width 104px
      height 40px
      line-height 35px
      border-radius 5px
      border 1px solid #D8D8D8
      text-align center
      cursor pointer
      .add-green
        color #00A389
        font-size 24px
        position relative
        top 3px
    .group-pager
      >>>.el-pager li:not(.disabled).active
        background #00A389
        border-radius 8px
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
</style>