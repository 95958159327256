<template>
  <el-dialog
    title="分配计划"
    class="dialog-main"
    :visible=true
    @close="closePlanAssign"
    width="700px">
    <div class="dialog-con">
      <div class="pur-label">
        <span>分配用户：</span>
        <div class="group-name" >
           <span class="group-names">{{name}}</span>
        </div>
      </div>
      <div class="purview-con">
        <div class="purview-item">
          <div class="purview-title">计划名</div>
          <p class="purview-name" 
            v-for="(item,index) in ReportListData" 
            :key="index"
            :class="{'purview-name-active': selectExam.indexOf(item.id) > -1}"
            @click="setExam(item)"
          >{{item.name}}</p>
        </div>
        <div class="purview-item">
          <div class="purview-title">已选计划</div>
          <div v-if="selectExamObj.length">
            <div v-for="(item,index) in selectExamObj" :key="index">
              <!-- <p class="purview-name" >{{item.name}}</p> -->
              
             
                <div 
                  class="purview-sec"
                >
                  <span class="purview-sec-name">{{item.name}}</span>
                  <img src="../../../assets/images/delete_black.png" class="purview-delete" @click="deleteExam(item.id)" />
                </div>
            </div>
            <!-- <div class="purview-sec">
              量表名称1
              <img src="../../../assets/images/delete_black.png" class="purview-delete"/>
            </div>
            <div class="purview-sec">
              量表名称1
              <img src="../../../assets/images/delete_black.png" class="purview-delete"/>
            </div> -->
          </div>
        </div>
      </div>
    </div>
   
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closePlanAssign">取消</span>
      <span class="dialog-btn" @click="confirmAdd">确定</span>
    </div>
  </el-dialog>
</template>
<script>
import _ from "lodash";
import {
  bindPlanPerson,
  reqVisitPlan
} from '../../../api/index'
export default {
  props:{
    assignUser: Object
  },
  data(){
    return{
      name: '',
      groupId: null,
      payType: '',
      isIndeterminate: true,
      checkedPurviews: [],
      ReportListData:[], // 
      categoryId: '', // 当前量表
      examArr: [], // 二级量表分类
      examId: null,
      selectExam:[],
      selectExamObj: [],
      partientIds: null
    }
  },
  mounted(){
    this.partientIds = this.assignUser.id
    this.name = this.assignUser.name
    this.getPlanLists()
  },
  methods:{
    getPlanLists(){
      const params = {
        endTime: "",
        groupId: "",
        pageModel: {
          pageNo: 1,
          pageSize: 1000
        },
        startTime: "",
        name: ""
       }
      reqVisitPlan(params).then(res => {
        if(res && res.code === 0){   
          this.ReportListData = res.data.list     
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 关闭
    closePlanAssign(){
      this.$emit('closePlanAssign')
    },
    // 点击选择量表
    setExam(item){
      const id = item.id
      let idx = this.selectExam.indexOf(id)
      if( idx > -1){
        this.selectExam.splice(idx,1)
        this.selectExamObj.splice(idx,1)
      }else{
        this.selectExam.push(item.id)
        this.selectExamObj.push(item)
      }
    },
    deleteExam(id){
      const idx = this.selectExam.indexOf(id)
      this.selectExam.splice(idx,1)
      this.selectExamObj.splice(idx,1)
    },
    // 新增
    confirmAdd(){
      if(!this.selectExam.length){
        this.$message({
          message: '请先选择量表',
          type: 'wanring'
        }); 
        return false
      }
      let params = []
      for(let i=0; i<this.selectExam.length; i++){
        params.push({
          personId: this.partientIds,
          planId: this.selectExam[i],
          personName: this.name
        })
      }
      console.log('params',params)
      bindPlanPerson(params).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '分配成功！',
            type: 'success'
          });
          this.$emit('closePlanAssign')
        }else{
          this.$message({
            message: '分配失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 10px
  .pur-label
    font-size 14px
    margin-bottom 14px
    display flex
    align-items center
    .group-name
      width 530px
      height 38px
      line-height 38px
      border-radius 5px
      border 1px solid #DADADA
      margin-left 30px
      padding 0 10px
      .group-names
        display inline-block
        padding 0 15px
        height 26px
        line-height 26px
        border-radius 3px
        background #e0f2ee
  .purview-con
    display flex
    width 631px
    height 307px
    border-radius 5px
    border 1px solid #DADADA
    font-size 14px
    .purview-item
      flex 1
      width 33%
      border-right 1px solid #DADADA
      padding 10px
      overflow auto
      .purview-title
        font-size 16px
        height 35px
        line-height 35px
        font-weight bold
      .purview-name
        height 28px
        padding 4px 12px
        line-height 20px
        margin 4px 0
        cursor pointer
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      .purview-sec
        padding 0 44px 0 20px
        height 24px
        line-height 24px
        line-height 24px
        border-radius 3px
        display flex
        justify-content space-between
        align-items center
        cursor pointer
        white-space nowrap
        position relative
        .purview-sec-name
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
        .purview-delete
          position absolute
          width 15px
          right 8px
          top 4px
          text-indent 9999px
          opacity 0
        &:hover
          background #D0E6E6
          color #009375
          .purview-delete
            text-indent 0
            opacity 1
      .purview-name-active
        border-radius 5px
        background #D0E6E6
        color #009375
      .el-checkbox
        display block
        margin-bottom 10px
      /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
        background-color #009375
        border-color #009375
      .is-checked
        /deep/.el-checkbox__inner
          background-color #009375
          border-color #009375
        // .el-checkbox__input.is-indeterminate .el-checkbox__inner
        //   background-color #009375
        //   border-color #009375
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label 
          color #009375
        /deep/.el-checkbox.is-bordered.is-checked
          border-color rgba(47, 84, 201, 1)
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
</style>