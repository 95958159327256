<template>
  <div class="assess">
    <access-links :routeLink="'accessDetail'"></access-links>
    <div class="qadetail-return"  @click="returnList">
      <img src="../../assets/images/return_arrow.png" class="return-icon" />
      <span >返回报告列表</span>
    </div>
    
    <div class="access-detail">
      <div class="access-set">
        <p class="set-title">打印设置</p>
        <div class="set-con">
          <el-checkbox-group v-model="checkedSets" @change="handleSetChange">
            <el-checkbox v-for="city in setings" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="set-btns">
          <span class="set-btn set-btn-bor">
            <img src="../../assets/images/import.png" class="set-btn-img" />导出
          </span>
          <span class="set-btn "  @click="printRpeort">
            <img src="../../assets/images/print.png" class="set-btn-img"  />打印报告
          </span>
        </div>
      </div>
      <div class="access-main">
        <div class="access-main-con">
          <div class="report"  ref="export" >
            <div class="report-top">
              <p class="report-main-title" v-if="orgName">{{orgName}}</p>
              <p class="report-sentit">{{reportName}}</p>
            </div>
            <template v-if="checkedSets.includes('基本信息')">
              <div class="report-titles">
                <span class="report-title">基础信息</span>
                <span class="report-tit-right">测评日期：{{createTime}}</span>
              </div>
              <div class="report-base">
                <span class="base-item">
                  <span class="base-item-name">姓        名：</span>
                  <span class="base-item-val">{{name}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">性       别：</span>
                  <span class="base-item-val">{{sex}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">生        日：</span>
                  <span class="base-item-val">{{birthday}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">出生日期：</span>
                  <span class="base-item-val">{{education}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">手机号码：</span>
                  <span class="base-item-val">{{mobilePhone}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">科        室：</span>
                  <span class="base-item-val">{{deptName}}</span>
                </span>
              </div>
            </template>
            <template v-if="checkedSets.includes('测评分析')">
              <div class="report-titles">
                <span class="report-title">测评分析</span>
              </div>
              <div class="report-froms">
                <div class="report-from-top">
                  <span class="from-item textindnt">名称</span>
                  <span class="from-item">得分</span>
                  <span class="from-item">参考范围</span>
                  <span class="from-item">提示</span>
                </div>
                <div class="from-sans" v-for="(item,index) in reportItemList" :key="index">
                  <div class="from-san textindnt">{{item.title}}</div>
                  <div class="from-san">{{item.scoreFact}}</div>
                  <div class="from-san from-san-left">
                    <p class="from-san-item">{{item.reference}}</p>
                    <!-- <p class="from-san-item">0-4：无</p>
                    <p class="from-san-item">5-7：轻度</p>
                    <p class="from-san-item">8-15：中度</p>
                    <p class="from-san-item">16-39：中度</p> -->
                  </div>
                  <div class="from-san">-</div>
                </div>
              </div>
            </template>
            <template v-if="checkedSets.includes('指导建议')">
              <div class="report-titles">
                <span class="report-title">指导建议</span>
                
              </div>
              <div class="report-jy">
                <!-- <div class="docter-text" >{{advice}}</div> -->
                <textarea v-model="advice" @focus="showAdviceBtn = true" @blur="blurAdvice"  class="docter-text"></textarea>
              </div>
              <div style="text-align:center;" v-has="'mgr.examReport.editReport'">
                <span class="report-right-btn success-btn" v-if="showAdviceBtn" style="margin-left:20px;" @click="editReport" > 确认</span>
              </div>
            </template>
            <template v-if="checkedSets.includes('自定义项')">
              <div v-for="(item, index) in customArr" :key="index">
                <div class="report-titles">
                  <div class="report-title">
                    <input class="title-input" type="text" v-model.trim="customTitle" v-if="item.addFlag" />
                    <div class="add-title" v-else>{{item.title}}</div>
                    <img src="../../assets/images/delete_red.png" class="delete-report" @click="deleteAdd(index)" />
                  </div>
                </div>
                <div class="report-jy">
                  <textarea class="docter-text" v-model.trim="customContent"  v-if="item.addFlag"></textarea>
                  <div class="docter-text" v-else>
                    {{item.content}}
                  </div>
                </div>
              </div>
              <div style="text-align:center;" class="no-print" >
                <span class="report-btn" @click="addCustom" v-if="!isAdd"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增项目</span>
                <template v-else>
                  <span class="report-btn success-btn" style="margin-left:20px;" @click="confirmAddCu"> 确认</span>
                  <span class="report-btn" style="margin-left:20px;" @click="cancleAddCu"> 取消</span>
                </template>
                
              </div>
            </template>
            <template v-if="checkedSets.includes('测评详情')">
              <div class="report-titles">
                <span class="report-title">测评详情</span>
              </div>
              <div class="report-reply">
                <div class="reply-item" v-for="(item,index) in reply" :key="index">
                  <div class="reply-title">
                      {{item.examName}} 
                      <template v-if="item.examType == 'choice_single'">(单选)</template>
                      <template v-if="item.examType == 'choice_mut'">(多选)</template>
                  </div>
                  <div class="reply-ans" v-for="(ans,ansIdx) in item.replyAnswer" :key="ansIdx">{{ans.content}}</div>
                </div>
              </div>
            </template>
            <template v-if="checkedSets.includes('医生签字')"> 
              <div class="report-bottom">
                <div class="report-qimin">
                  <span>评估人员签字：</span>
                  <img :src="eleSign" class="sigo-img"/>
                </div>
              </div>
            </template>
            <div class="report-bot">
              <span class="report-time">打印时间：{{dateStr}}</span>
              <span>* 本报告数据均来源于本次测评结果，不能代替医生诊断，仅供参考。</span>
            </div>
          </div>



          <div class="report-print" id="printdiv">
            <div class="report-top">
              <p class="report-main-title" v-if="orgName">{{orgName}}</p>
              <p class="report-sentit">{{reportName}}</p>
            </div>
            <template v-if="checkedSets.includes('基本信息')">
              <div class="report-titles">
                <span class="report-title">基础信息</span>
                <span class="report-tit-right">测评日期：{{createTime}}</span>
              </div>
              <div class="report-base">
                <span class="base-item">
                  <span class="base-item-name">姓        名：</span>
                  <span class="base-item-val">{{name}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">性       别：</span>
                  <span class="base-item-val">{{sex}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">生        日：</span>
                  <span class="base-item-val">{{birthday}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">出生日期：</span>
                  <span class="base-item-val">{{education}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">手机号码：</span>
                  <span class="base-item-val">{{mobilePhone}}</span>
                </span>
                <span class="base-item">
                  <span class="base-item-name">科        室：</span>
                  <span class="base-item-val">{{deptName}}</span>
                </span>
              </div>
            </template>
            <template v-if="checkedSets.includes('测评分析')">
              <div class="report-titles">
                <span class="report-title">测评分析</span>
              </div>
              <div class="report-froms">
                <div class="report-from-top">
                  <span class="from-item textindnt">名称</span>
                  <span class="from-item">得分</span>
                  <span class="from-item">参考范围</span>
                  <span class="from-item">提示</span>
                </div>
                <div class="from-sans" v-for="(item,index) in reportItemList" :key="index">
                  <div class="from-san textindnt">{{item.title}}</div>
                  <div class="from-san">{{item.scoreFact}}</div>
                  <div class="from-san from-san-left">
                    <p class="from-san-item">{{item.reference}}</p>
                    <!-- <p class="from-san-item">0-4：无</p>
                    <p class="from-san-item">5-7：轻度</p>
                    <p class="from-san-item">8-15：中度</p>
                    <p class="from-san-item">16-39：中度</p> -->
                  </div>
                  <div class="from-san">-</div>
                </div>
              </div>
            </template>
            <template v-if="checkedSets.includes('指导建议')">
              <div class="report-titles">
                <span class="report-title">指导建议</span>
              </div>
              <div class="report-jy">
                <!-- <div class="docter-text" >{{advice}}</div> -->
                <textarea v-model="advice"  class="docter-text"></textarea>
              </div>
            </template>
            <template v-if="checkedSets.includes('自定义项')">
              <div v-for="(item, index) in customArr" :key="index">
                <div class="report-titles">
                  <div class="report-title">
                    <input class="title-input" type="text" v-model.trim="customTitle" v-if="item.addFlag" />
                    <div class="add-title" v-else>{{item.title}}</div>
                    <img src="../../assets/images/delete_red.png" class="delete-report" @click="deleteAdd(index)" />
                  </div>
                </div>
                <div class="report-jy">
                  <textarea class="docter-text" v-model.trim="customContent"  v-if="item.addFlag"></textarea>
                  <div class="docter-text" v-else>
                    {{item.content}}
                  </div>
                </div>
              </div>
              <div style="text-align:center;" class="no-print" >
                <span class="report-btn" @click="addCustom" v-if="!isAdd"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增项目</span>
                <template v-else>
                  <span class="report-btn success-btn" style="margin-left:20px;" @click="confirmAddCu"> 确认</span>
                  <span class="report-btn" style="margin-left:20px;" @click="cancleAddCu"> 取消</span>
                </template>
                
              </div>
            </template>
            <template v-if="checkedSets.includes('测评详情')">
              <div class="report-titles">
                <span class="report-title">测评详情</span>
              </div>
              <div class="report-reply">
                <div class="reply-item" v-for="(item,index) in reply" :key="index">
                  <div class="reply-title">
                      {{item.examName}} 
                      <template v-if="item.examType == 'choice_single'">(单选)</template>
                      <template v-if="item.examType == 'choice_mut'">(多选)</template>
                  </div>
                  <div class="reply-ans" v-for="(ans,ansIdx) in item.replyAnswer" :key="ansIdx">{{ans.content}}</div>
                </div>
              </div>
            </template>
            <template v-if="checkedSets.includes('医生签字')"> 
              <div class="report-bottom">
                <div class="report-qimin">
                  <span>评估人员签字：</span>
                  <img :src="eleSign" class="sigo-img"/>
                </div>
              </div>
            </template>
            <div class="report-bot">
              <span class="report-time">打印时间：{{dateStr}}</span>
              <span>* 本报告数据均来源于本次测评结果，不能代替医生诊断，仅供参考。</span>
            </div>
          </div>




        </div>
        <!-- <div class="bot-btns">
          <span class="report-btn success-btn" style="margin-left:20px;" @click="editReport"> 确认</span>
        </div> -->
      </div>
      
    </div>
  </div>
</template>

<script>
import {
  regExamlist,
  regExamReport,
  regEditReport
} from '../../api/index'
import printJS from 'print-js'
import AccessLinks from '@/components/Access/AccessLinks'
export default {
  components:{ AccessLinks },
  data () {
    return {
      accessId: null,
      detailObj: null,
      reportName: '',
      orgName: '',
      createTime: '',
      name: '',
      sex: '',
      birthday: '',
      education: '',
      mobilePhone: '',
      deptName: '',
      eleSign: '',
      advice: '',
      reportItemList: [],
      dateStr:'',
      checkedSets: ['基本信息', '测评分析','指导建议', '测评详情','自定义项'],
      setings: ['基本信息', '测评分析', '指导建议', '测评详情','自定义项', '医生签字'], // '测试答案', 
      custom: {
        title: '',
        content: '',
        addFlag: 1,
      },
      customTitle: '',
      customContent: '',
      customArr: [],
      isAdd: false,
      reply: [],
      imagePrintArr:[
          "https://img.php.cn/upload/article/000/465/014/170661115440230.png",
          "https://img.php.cn/upload/article/000/465/014/170661115440230.png",
          "https://img.php.cn/upload/article/000/465/014/170661115440230.png"
      ],
      showAdviceBtn: false, // 显示指导意见确认按钮
    }
    
  },
  mounted(){
    if(this.$route.params.accessId === undefined){
      this.$message({
        message: '非法路径',
        type: 'error'
      }); 
      this.$router.push({ path: '/accessManage'})
    }
    this.accessId  = this.$route.params.accessId
    this.toAccessDetail()
    this.getDate()
    
  },
  methods:{
    // 查看详情
    toAccessDetail(){
      regExamReport({replyId:this.accessId}).then(res => {
        if(res && res.code === 0){
          this.detailObj= res.data
          this.reportName = this.detailObj.reportName
          this.createTime = this.detailObj.createTime
          this.advice = this.detailObj.advice
          this.orgName = this.detailObj.orgName || ''
          this.reportItemList = this.detailObj.reportItemList
          this.name = this.detailObj.userInfo.name
          this.sex = this.detailObj.userInfo.sex
          this.birthday = this.detailObj.userInfo.birthday
          this.education = this.detailObj.userInfo.education
          this.mobilePhone = this.detailObj.userInfo.mobilePhone
          this.deptName = this.detailObj.userInfo.deptName
          this.eleSign = this.detailObj.userInfo.eleSign
          if(this.eleSign){
            this.setings = ['基本信息', '测评分析', '指导建议', '测评详情','自定义项', '医生签字']
          }else {
            this.setings = ['基本信息', '测评分析', '指导建议', '测评详情','自定义项']
          }
          this.customArr = this.detailObj.extendList
          if(this.detailObj.reply && this.detailObj.reply.replyDataJson){
            this.reply = JSON.parse(this.detailObj.reply.replyDataJson)
            console.log('this.reply',this.reply)
            for(let i=0; i<this.reply.length; i++){
              if(this.reply[i].examType == "choice_single" || this.reply[i].examType == "choice_mut"){
                this.reply[i].replyAnswer = JSON.parse(this.reply[i].replyAnswer)
              }
            }
            console.log('reply choice_mut',this.reply)
          }
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
          //this.$router.push({ path: '/accessManage'})
        }
      })
    },
    // 获取当前日期
    getDate(){
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1; 
      var day = now.getDate();
      if(month<10) month = '0'+month
      this.dateStr = year +'-'+month+'-'+day
    },
    returnList(){
      this.$router.push({ path: `/accessManage`})
    },
    blurAdvice(){
      setTimeout(() => {
        this.showAdviceBtn = false
      }, 1000);
    },
    handleSetChange(value) {
      let checkedCount = value.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 打印报告
    printRpeort(){
      //this.$print(this.$refs.export)
      printJS({
        printable: printdiv, // 'printFrom', // 标签元素id
        type: 'html',
        targetStyles: ['*'],
      })
    },
    // 新增自定义
    addCustom(){
      this.isAdd = true
      this.customArr.push(this.custom)
    },
    // 删除多的
    deleteAdd(idx){
      this.customArr.splice(idx,1)
      const param = {
        replyId:this.accessId,
        advice: this.advice,
        reportExtendJson: JSON.stringify(this.customArr) 
      }
      regEditReport(param).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.toAccessDetail()
        }else{
          this.$message({
            message: '删除失败，' + res.msg,
            type: 'error'
          }); 
          this.toAccessDetail()
        }
      })
    },
    confirmAddCu(){
      if(this.customTitle === '' || this.customContent === ''){
        this.$message({
          message: '请先输入信息',
          type: 'wanring'
        }); 
        return false
      }else{
        this.isAdd = false
        this.$set(this.customArr,this.customArr.length -1 ,{title:this.customTitle,content: this.customContent})
        this.customContent = ''
        this.customTitle = ''
        this.editReport()
      }
    },
    // 取消
    cancleAddCu(){
      this.isAdd = false
      this.customArr.splice(this.customArr.length -1,1)
      this.customTitle === ''
      this.customContent === ''
    },
    // 编辑
    editReport(){
      let len = this.customArr.length 
      if(len){
        if(this.customArr[len-1].customTitle === '' || this.customArr[len-1].customContent === ''){
          this.$message({
            message: '有未填写的内容',
            type: 'wanring'
          }); 
          return false
        }
      }
      const param = {
        replyId:this.accessId,
        advice: this.advice,
        reportExtendJson: JSON.stringify(this.customArr) 
      }
      regEditReport(param).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.toAccessDetail()
        }else{
          this.$message({
            message: '编辑失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .assess-manages
    width 367px
    height 46px
    line-height 46px
    box-sizing border-box
    border 1px solid #E8E8E8
    display flex
    justify-content space-between
    border-radius 25px
    margin 0 auto 0
    .assess-item
      display inline-block
      width 100px
      height 40px
      line-height 40px
      text-align center
      margin  3px
      font-size 14px
      cursor pointer
    .assess-item-active
      border-radius 25px
      background #E0F2EE
      color #009375
      font-weight  bold
  .qadetail-return
    width 100%
    display block
    height 70px
    display flex
    align-items center
    line-height 70px
    font-size 14px
    cursor pointer
    .return-icon
      width 30px
      margin 0 13px 0 40px
  .access-detail
    padding 0 0 0 18px
    display  flex
    align-items flex-start
    .access-set
      width 190px
      border-radius 10px
      border 1px solid #9E9E9E
      .set-title
        height 48px
        line-height 48px
        font-size 18px
        font-weight bold
        border-bottom 1px solid #d8d8d8
        text-align center
      .set-con
        padding 20px
        font-size 16px
        color #1f1f1f
        /deep/ .el-checkbox
          margin-bottom 15px
          .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
            background-color #009375
            border-color #009375
          .el-checkbox__input.is-checked+.el-checkbox__label
            color #1f1f1f
      .set-btns
        border-top 1px solid #9e9e9e
        height 50px
        line-height 50px
        display flex
        text-align center
        .set-btn
          flex 1
          cursor pointer
          .set-btn-img
            position relative
            top 2px 
            margin-right 5px
        .set-btn-bor
          border-right  1px solid #9e9e9e
    .access-main
      flex 1
      margin 0 35px
      position relative
      overflow hidden
      .access-main-con
        border 1px solid #9e9e9e
        border-radius 10px
        padding 0 50px
        background #fff
      .report
        position relative
        z-index 1
        background #fff
        .report-top
          padding 36px 0 50px
          text-align center
          font-weight bold
          .report-main-title
            font-size 20px
            line-height 28px
            margin-bottom 10px
          .report-sentit
            font-size 18px
        .report-titles
          height 36px
          line-height 36px
          border-bottom 2px solid #000
          font-size 14px
          font-weight bold
          display flex
          justify-content space-between
          .report-title
            font-weight bold
            display flex
            width 100%
            .title-input
              flex 1
              height 30px
              border 1px solid #CED4DA
              border-radius 5px
              padding 0 5px
            .delete-report
              margin-left 20px
              width 23px
              height 23px
              cursor pointer
            .add-title
              flex 1
          .report-tit-right
            font-size 12px
            font-weight normal
        
        .report-reply
          padding 20px
          overflow-y auto
          .reply-item
            margin-bottom 20px
            .reply-title
              font-size 16px
              line-height 22px
              margin-bottom 10px
            .reply-ans
              line-height 20px
              padding 7px 25px
              margin-left 10px 
              border-radius 5px
              background #E0F2EE
              color #009375
        .report-base
          padding 15px 25px
          display flex
          flex-wrap wrap
          .base-item
            display inline-block
            width 33.3%
            margin-bottom 8px
        .report-froms
          border-bottom 1px solid #000
          margin-bottom 25px
          .textindnt
            text-indent 25px
          .report-from-top
            height 24px
            line-height 24px
            border-bottom 1px solid  #000
            font-size 12px
            display flex
            margin 5px 0
            .from-item
              flex 1
              text-align left
          .from-sans
            display flex
            padding 5px 0
            .from-san
              flex 1
              text-align left
            .from-san-left
              text-align left
            .from-san-item
              margin-bottom 5px
        .report-jy
          min-height 80px
          padding 25px
          .docter-text
            width 100%
            height 80px
            padding 5px 10px
            border-radius 5px
            border 1px solid #eee 
            resize none
            &:focus
              border 1px solid #9e9e9e
        .report-bottom
          padding-bottom 8px
          border-bottom 1px solid #000
          text-align right
          .sigo-img
            width 136px
        .report-bot
          display flex
          font-size 10px
          padding 10px 0 51px
          justify-content space-between
          line-height 22px
        .report-btn
          display inline-block
          width 104px
          margin 20px auto
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          cursor pointer
        .success-btn
          background #009375
          color #fff
        .report-right-btn
          display inline-block
          width 120px
          height 30px
          line-height 30px!important
          cursor pointer
          text-align center
          border-radius 4px
          line-height 24px
          margin 0 auto 10px

      .report-print
        position absolute
        top 0px
        left 50px
        right 50px
        bottom 0
        .report-top
          padding 36px 0 50px
          text-align center
          font-weight bold
          .report-main-title
            font-size 20px
            line-height 28px
            margin-bottom 10px
          .report-sentit
            font-size 18px
        .report-titles
          height 36px
          line-height 36px
          border-bottom 2px solid #000
          font-size 14px
          font-weight bold
          display flex
          justify-content space-between
          .report-title
            font-weight bold
            display flex
            width 100%
            .title-input
              flex 1
              height 30px
              border 1px solid #CED4DA
              border-radius 5px
              padding 0 5px
            .delete-report
              margin-left 20px
              width 23px
              height 23px
              cursor pointer
            .add-title
              flex 1
          .report-tit-right
            font-size 12px
            font-weight normal
        .report-reply
          padding 20px
          height auto!important
          overflow-y hidden
          .reply-item
            margin-bottom 20px
            .reply-title
              font-size 16px
              line-height 22px
              margin-bottom 10px
            .reply-ans
              line-height 20px
              padding 7px 25px
              margin-left 10px 
              border-radius 5px
              background #E0F2EE
              color #009375
        .report-base
          padding 15px 25px
          display flex
          flex-wrap wrap
          .base-item
            display inline-block
            width 33.3%
            margin-bottom 8px
        .no-print
          display none!important
        .report-froms
          border-bottom 1px solid #000
          margin-bottom 25px
          .textindnt
            text-indent 25px
          .report-from-top
            height 24px
            line-height 24px
            border-bottom 1px solid  #000
            font-size 12px
            display flex
            margin 5px 0
            .from-item
              flex 1
              text-align left
          .from-sans
            display flex
            padding 5px 0
            .from-san
              flex 1
              text-align left
            .from-san-left
              text-align left
            .from-san-item
              margin-bottom 5px
        .report-jy
          min-height 80px
          padding 25px
          .docter-text
            width 100%
            height 80px
            padding 5px 10px
            border-radius 5px
            border none 
            resize none
            &:focus
              border 1px solid #9e9e9e
        .report-bottom
          padding-bottom 8px
          border-bottom 1px solid #000
          text-align right
          .sigo-img
            width 136px
        .report-bot
          display flex
          font-size 10px
          padding 10px 0 50px
          justify-content space-between
          line-height 22px
        .report-btn
          display inline-block
          width 104px
          margin 20px auto
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          cursor pointer
        .success-btn
          background #009375
          color #fff      





    .bot-btns
      position relative
      margin 30px 0 10px
      text-align center
      .report-btn
        display inline-block
        width 104px
        margin 20px auto
        height 40px
        line-height 40px
        text-align center
        border-radius 5px
        border 1px solid #D8D8D8
        cursor pointer
      .success-btn
        background #009375
        color #fff
@media print
  .assess
    .access-detail
      .access-main
        .report-print
          display inline-block!important
  
</style>
<style type="text/css">
@media print
{
  .report-print{
    display: inline-block;
  }
  .no-print{
    display:  none!important;
  }
  .report-reply{
    height: auto!important;
  }
}
</style>
  // .report
  //   .report-top
  //     padding 36px 0 50px
  //     text-align center
  //     font-weight bold
  //     .report-main-title
  //       font-size 20px
  //       line-height 28px
  //       margin-bottom 10px
  //     .report-sentit
  //       font-size 18px
  //   .report-titles
  //     height 36px
  //     line-height 36px
  //     border-bottom 2px solid #000
  //     font-size 14px
  //     font-weight bold
  //     display flex
  //     justify-content space-between
  //     .report-title
  //       font-weight bold
  //       .title-input
  //         flex 1
  //         min-width 300px
  //         height 30px
  //         border 1px solid #CED4DA
  //         border-radius 5px
  //         padding 0 5px
  //       .delete-report
  //         display none
  //         opacity 0
  //       .add-title
  //         flex 1
  //     .report-tit-right
  //       font-size 12px
  //       font-weight normal
  //   .no-print
  //     display none
  //   .report-base
  //     padding 15px 25px
  //     display flex
  //     flex-wrap wrap
  //     .base-item
  //       display inline-block
  //       width 33.3%
  //       margin-bottom 8px
  //   .report-froms
  //     border-bottom 1px solid #000
  //     margin-bottom 25px
  //     .textindnt
  //       text-indent 25px
  //     .report-from-top
  //       height 24px
  //       line-height 24px
  //       border-bottom 1px solid  #000
  //       font-size 12px
  //       display flex
  //       margin 5px 0
  //       .from-item
  //         flex 1
  //         text-align left
  //     .from-sans
  //       display flex
  //       padding 5px 0
  //       .from-san
  //         flex 1
  //         text-align left
  //       .from-san-left
  //         text-align left
  //       .from-san-item
  //         margin-bottom 5px
  //   .report-jy
  //     min-height 80px
  //     padding 25px
  //     .docter-text
  //       width 100%
  //       height 80px
  //       padding 5px 10px
  //       border-radius 5px
  //       border none!important
  //       border-color none!important
  //       outline none!important
  //   .report-bottom
  //     padding-bottom 8px
  //     border-bottom 1px solid #000
  //     text-align right
  //     .sigo-img
  //       width 136px
  //   .report-bot
  //     display flex
  //     font-size 10px
  //     padding 10px 0 50px
  //     line-height 30px
  //     justify-content space-between
  //   .report-btn
  //     display inline-block
  //     width 104px
  //     margin 20px auto
  //     height 40px
  //     line-height 40px
  //     text-align center
  //     border-radius 5px
  //     border 1px solid #D8D8D8
  //     cursor pointer
