<template>
  <div class="assess">
    <access-links :routeLink="'accessGroupManage'"></access-links>
    <div class="manage-search">
      <div class="search-left">
        <div class="scale-tabs">
          <span class="scale-tab" @click="toLink('accesScale')">量表</span>
          <span class="scale-tab scale-tab-active">分组</span>
        </div>
        <div class="search-input-out">
          <el-input class="search-input" v-model="name" placeholder="搜索名称" />
          <img src="../../assets/images/search.png" @click="getExamlists" class="search-icon" />
        </div>
        
        <el-select v-model="categoryId" class="search-select" placeholder="量表分类" v-has="'mgr.exam.query'">
          <el-option
            v-for="item in allCategory"
            :key="item.categoryId"
            :label="item.categoryName"
            :value="item.categoryId">
          </el-option>
        </el-select>
        <el-select v-model="payType" class="search-select" placeholder="类型">
          <el-option
            v-for="item in payTypeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="search-select" v-model="status"  placeholder="状态">
          <el-option
            v-for="item in statusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-right">
        <span class="search-btn" @click="toAddGroup" v-has="'mgr.examGroup.addGroup'"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增分组</span>
        <!-- <span class="search-btn" @click="getCode(true)" ><img src="../../assets/images/import.png"  class="search-btn-icon"> 生成二维码</span> -->
      </div>
    </div>
    <div class="access-con">
      <el-table
        v-has="'mgr.examGroup.query'"
        :data="examGroupData"
        class="group-table"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="name"
          label="分组名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80">
          <!-- <template slot-scope="scope">
            <template v-if="scope.row.status === 'Y'">启用</template>
            <template v-else>禁用</template>
          </template> -->
          <template slot-scope="scope">
            <el-switch
              class="applic-switch"
              v-model="scope.row.status"
              @change="changeApplic($event,scope.row)"
              active-color="#009375"
              active-value="Y"
              inactive-value="N"
              inactive-color="#ccc">
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="payType"
          label="量表">
          <template slot-scope="scope">
            <template v-if="scope.row.status === 'paid'">付费</template>
            <template v-else>免费</template>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="numofExam"
          label="量表数">
          <template slot-scope="scope" >
            <el-popover
              popper-class="status-popover"
              placement="bottom"
              width="200"
              @show="getChild(scope.row)"
              style="padding:5px;min-width:200px!important;border-radius:10px;line-height:16px;max-height:160px;overflow-y:auto;"
              trigger="hover">
              <div class="handle-con" style="line-height:22px;padding:5px;">
                <div class="sh-item" v-if="childExam">
                  <p class="sh-item-par" style="margin-bottom:5px;padding:0 5px;cursor:pointer;" v-for="(item,exIdx) in childExam" :key="exIdx">{{item.name}}</p>
                </div>
              </div>
              <span class="error-text" slot="reference">{{scope.row.numofExam}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          v-has="'mgr.examGroup.addQrcode'"
          prop="id"
          label="二维码">
          <template slot-scope="scope">
            <img @click="getCode(false,scope.row)" style="cursor:pointer;" src="../../assets/images/code_small.png" />
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="分组价格">
          <template slot-scope="scope">
            {{scope.row.price/100}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button class="group-link " v-has="'mgr.examGroup.query'" @click="seeExam(scope.row)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">查看</el-button>  | 
              <el-button class="group-link" v-has="'mgr.examGroup.editGroup'" @click="editExam(scope.row)" style="color:#4a4a4a" type="text" size="small">编辑</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="pager" v-has="'mgr.examGroup.query'">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
        <el-pagination
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
        </div>
      <!-- 分组 -->
      
    </div>
    <el-drawer
    title="分组详情"
    class="exam-drawer"
    @close="closeDrawer"
    :visible="examDrawerVisable">
      <group-drawer
      :examDetail="examDetail"
      :allCategory="allCategory"
      @handleGetCode="getCode"
      @toEdtiGroup="toEdtiGroup"
      ></group-drawer>
    </el-drawer>
    <invite-dialog
    :allCategory="allCategory"
    v-if="showInviteDialog"
    isGroup="1"
    :examDetailId="examDetail"
    :selectIds="selectIds"
    @closeInviteDialog="closeInviteDialog"
    ></invite-dialog>
    <add-group-dialog
    v-if="addGroupVisable"
    :isEdit="isEdit"
    :examDetail="examDetail"
    @addGroupSuccess="addGroupSuccess"
    @closePurview="closePurview"
    ></add-group-dialog>
  </div>
</template>
<script>
import {
  regExamGroupList,
  regAllCategory,
  regExamlGroupDetail,
  regEditGroup
} from '../../api/index'
import { powerRight } from '../../utils/index'
import AccessLinks from '@/components/Access/AccessLinks'
import GroupDrawer from '@/views/Access/GroupComponent/GroupDrawer'
import InviteDialog from '@/views/Access/ExamComponent/InviteDialog'
import AddGroupDialog from '@/views/Access/GroupComponent/AddGroupDialog'
export default {
  components:{
    GroupDrawer,
    InviteDialog,
    AddGroupDialog,
    AccessLinks
  },
  data () {
    return {
      name: '', // 搜索量表名称
      isEdit: false,
      examDrawerVisable: false,
      showInviteDialog: false,
      addGroupVisable: false,
      examDrawerTitle: '查看量表',
      allCategory: [], // 所有量表分类
      categoryId: '', // 当前所选量表id
      payTypeArr: [
        {
          label: '免费',
          value: 'free'
        },
        {
          label: '付费',
          value: 'paid'
        }
      ],
      payType: '', // 搜索类型 free 免费/paid 付费
      status: '',
      statusArr:[
        {
          label: '启用',
          value: 'Y'
        },
        {
          label: '禁用',
          value: 'N'
        }
      ],
      examGroupData: [],
      pageNo: 1,
      pageSize:  20,
      total: 0,
      examDetail: null, // 量表详情
      selectIds: '', // 列表选择的Ids
      childExam: []
    }
  },
   mounted(){
    if(powerRight('mgr.exam.query')){
      this.getAllCategory()
    }
    if(powerRight('mgr.examGroup.query')){
      this.getExamlists()
    }
  },
  methods:{
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
    // 查询所有量表类型分类
    getAllCategory(){
      regAllCategory().then(res => {
        if(res && res.code === 0){
          this.allCategory = res.data
        }else{
          this.$message({
            message: '获取量表类型分类失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getExamlists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getExamlists()
    },
    // 量表列表
    getExamlists(){
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name,
        payType: this.payType,
        status: this.status,
        categoryId: this.categoryId
      }
      regExamGroupList(params).then(res => {
        console.log('列表',res)
        if(res && res.code === 0){
          this.examGroupData = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$message({
            message: '获取量表列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 更改分组状态
    // 更改状态
    changeApplic(val,data){
      console.log('ee',val,data)
      let params ={
        status: val,
        id: data.id,
        name: data.name,
        payType: data.payType
      }
      regEditGroup(params).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.getExamlists()
        }else{
          this.$message({
            message: '操作失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 关闭量表详情
    closeDrawer(){
      this.examDrawerVisable = false
    },
    // 查看量表详情
    seeExam(data){
      //this.examId = data.is
      this.getExam(data.id)
    },
    // 编辑量表
    editExam(data){
      this.getExam(data.id,true)
      this.examGroupId = data.id
    },
    getExam(id, isEdit = false,isShow = true){
      regExamlGroupDetail({
        id
      }).then(res => {
        if(res && res.code === 0){
          this.examDetail = res.data
          this.isEdit = isEdit
          if(isEdit && isShow){
            this.addGroupVisable = true
          }else if(isShow){
            this.examDrawerVisable = true
          }
          this.childExam = []
          if(!isShow && res.data.examTree){ // 把所有子量表
            let childExam = []
            for(let i=0; i<res.data.examTree.length; i++){
              childExam = childExam.concat(res.data.examTree[i].exams)
              console.log('eee',res.data.examTree[i].exams,childExam)
            }
            console.log('childExam',this.childExam)
            this.childExam = childExam
          }
        }else{
          this.$message({
            message: '获取量表分组详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 生成二维码
    getCode(isMu,data = null){
      console.log('data',data)
      if(!isMu){
        this.examDetail = data
      }else { // 批量
        console.log('selectIds',this.selectIds)
        if(this.selectIds === ''){
          this.$message({
            message: '请先选择量表',
            type: 'wanring'
          }); 
          return false
        }
      }
      this.showInviteDialog = true
    },
    // table选择框修改
    handleSelectionChange(val) {
      console.log('选择枉',val)
    },
    toEdtiGroup(id){
      this.examDrawerVisable = false
      this.getExam(id,true)
    },
    // 获取分组下的量表
    getChild(data){
      console.log('子',data)
      this.getExam(data.id, false,false)
    },
    // 闭关生成二维码
    closeInviteDialog(){
      this.showInviteDialog =false
    },
    toAddGroup(){
      this.isEdit = false
      this.examDetail = null
      this.addGroupVisable = true
    },
    // 新增分组成功
    addGroupSuccess(){
      this.getExamlists()
      this.addGroupVisable = false
    },
    closePurview(){
      this.addGroupVisable = false
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .assess-manages
    width 367px
    height 46px
    line-height 46px
    box-sizing border-box
    border 1px solid #E8E8E8
    display flex
    justify-content space-between
    border-radius 25px
    margin 0 auto 25px
    .assess-item
      display inline-block
      width 100px
      height 40px
      line-height 40px
      text-align center
      margin  3px
      font-size 14px
      cursor pointer
    .assess-item-active
      border-radius 25px
      background #E0F2EE
      color #009375
      font-weight  bold
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      display flex
      .scale-tabs
        display  inline-block
        height 40px
        padding-right 19px
        margin-right 30px
        border-right 1px solid #ccc
        .scale-tab
          display inline-block
          width 70px
          height 40px
          line-height 40px
          margin-left 15px
          text-align center
          cursor pointer
        .scale-tab-active
          border-radius 25px
          background #D0E6E6
          color #009375
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .access-con
    .group-table
      min-height 300px
      margin-bottom 20px
  .exam-drawer
    /deep/.el-drawer__header 
      color #009375
      font-size 16px
      font-weight bold
      padding 20px 0 20px 20px
      position relative
      margin-bottom 5px
      &:after
        content ""
        position absolute
        display inline-block
        width 440px
        height 1px
        bottom 0
        right 0
        background #d8d8d8
      .el-icon-close
        color #4a4a4a
        font-size 16px
</style>