<template>
  <div class="configer">
    <div class="configer-top">
      <div class="from-tabs">
        <span class="from-tab from-tab-active">系统表单</span>
        <span class="from-tab">自定义表单</span>
      </div>
      <span class="search-btn" v-has="'mgr.form.addForm'" @click="toFromDetail"><img src="../../assets/images/add.png" class="search-btn-icon"> 新增表单</span>
    </div>
    <div class="configer-con">
      <el-table
        v-has="'mgr.form.query'"
        :data="FromDatas"
        height="calc(100vh - 180px)"
        :header-cell-style="{ background:'#F0F3F6',lineHeight:'18px',padding:'8px 0',color:'#000'}"
        class="struct-table">                                                                            
          <el-table-column
            type="index"
            label="编号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="formName"
            label="表单名">
          </el-table-column>
          <el-table-column
            prop="address"
            label="归属分类">
          </el-table-column>
          <el-table-column
            prop="fieldCount"
            label="字段数">
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="编辑时间">
          </el-table-column>
          <el-table-column
            width="160"
            class="table-handles"
            style="white-space: nowrap;width:140px;"
            label="操作">
            <template slot-scope="scope"> <!-- slot-scope="scope" -->
              <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
              <el-button
                v-has="'mgr.form.query'"
                type="text"
                @click="toDetial(scope.row)"
                class="table-green-btn green-text"
                size="small">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager" v-has="'mgr.form.query'">
          <el-pagination
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
            :page-size="pageSize"
            @size-change="getAallFrom"
            @current-change="getAallFrom"
            layout="total, sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
    </div>
  </div>
</template>
<script>
import {
  RegFormdynList,
  RegFormdynListPage
} from '../../api/index'
import { powerRight } from '../../utils/index'
export default {
  name: 'FromConfiger',
  data() {
    return {
      pageNo: 1,
      pageSize:  20,
      total: 0,
      FromDatas:[]
    }
  },
  mounted(){
    if(powerRight('mgr.form.query')){
      this.getAallFrom()
    }
  },
  methods:{
    // 获取所有表单分页
    getAallFrom(){
      RegFormdynListPage({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(res.code === 0){
          this.total = res.data.totalCount    
          this.FromDatas = res.data.list
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取表单列表失败！'
          });
          
        }
      })
    },
    toFromDetail(){
      this.$emit('toFromDetail')
    },
    toDetial(data){
      this.$emit('toEditFromDetail',data)
      
      // this.$router.push({ path: 'search', query: { q: 'keyword' } })
      
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.configer
  padding 0 29px
  .configer-top
    display flex
    justify-content space-between
    margin-bottom 10px
    .from-tabs
      display inline-block
      height 40px
      border-radius 5px
      border 1px solid #DADADA
      .from-tab
        display inline-block
        padding 0 10px
        height 28px
        line-height 28px
        border-radius 5px
        margin 6px 10px
        color #9e9e9e
        cursor pointer
      .from-tab-active
        background #E0F2EE
        color #009375
    .search-btn
      width 104px
      height 40px
      line-height 40px
      text-align center
      border-radius 5px
      border 1px solid #D8D8D8
      cursor pointer
  .configer-con
    .struct-table
      width 100%
      font-size 14px
      overflow auto
      >>>tr
        line-height 18px
        margin 4px 0
        >>>.el-button--text
          color #4a4a4a!important
        &:hover>td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
          .table-green-btn
            color #00A389!important
            font-weight 700!important
      >>>.table-handles
        white-space nowrap
      .table-green-btn
        font-size 14px
        color #4a4a4a!important
        font-weight bold
      .popover-more
        margin-left 10px!important
        position relative
        top -4px
</style>