<template>
  <div class="wechart">
    <div class="wechart-flex">
      <!-- chart-left -->
      <div class="chart-left">
        <div class="chart-left-top">
          <div class="user-top" @click="toInviteUser">
            <span class="user-add-icon link-color">+</span>
            <span class="user-add-text">邀请用户</span>
          </div>
          <p class="user-title">咨询互动</p>
          <div class="user-search">
            <!-- <el-input v-model="searchText" class="search-text" placeholder="搜索" /> @change="getUserLists"
            <img class="user-search-icon" src="../../assets/images/search.png" /> -->
            <el-input type="text " class="search-text"  v-model.trim="searchText" placeholder="搜索" />
            <div class="search-cons" v-if="searchUserData.length">
              <div class="search-item" v-for="(item,i) in searchUserData" :key="i" @click="selectUser(item)">
                <span class="search-span">{{item.name}}</span>
                <span class="search-span-middle">{{item.groupName}}</span>
              </div>
            </div>
          </div>
        </div>
        <el-tabs 
          v-model="activeTab" 
          class="chart-tabs"
          :class="{'new-chart': chatNew,'new-unread': nureadNew}"
         >
          <el-tab-pane label="分组" :class="{'active-tab':activeTab === 'group'}" name="group">
            <chat-group
              @toChat="toChatHandle"
              :userId="userId"
              @getChartCode="getChartCode"
              ref="group"
            ></chat-group>
          </el-tab-pane>
          <el-tab-pane label="聊天" name="chat" :class="{'active-tab':activeTab === 'group'}">
            <chat-list @toChat="toChatHandle" ref="chart" @chartData="chartData"></chat-list>
          </el-tab-pane>
          <el-tab-pane label="未读" name="unread">
            <unchar-list  @toChat="toChatHandle" @hasUnRead="hasUnRead" ref="unread"></unchar-list>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- chart-left end-->
      <!-- chart-center -->
      <div class="chart-center">
        <div class="chart-center-top"  v-if="currentUser">
          <span class="chart-center-user">
            <img :src="currentUser.headImgUrl" class="chart-user-img" />
            <img src="../../assets/images/green_c.png" class="chart-user-online" />
          </span>
          <span class="chart-center-name">{{currentUser.name}}</span>
        </div>
        <div class="chart-center-bar" v-if="currentUser" id="charDiv">
          <div class="chart-con-time" v-if="msgIsAll">
            <span class="chart-con-timet chart-con-no">~消息已经到底了~</span>
          </div>
          <div class="chart-con-time" v-if="msgLoading">
            <i class="el-icon-loading chart-con-no" size="medium"></i>
          </div>
          <div v-for="(item,index) in msgData" :key="index">
            <div class="chart-con-time">
              <span class="chart-con-time-line"></span>
              <span class="chart-con-timet">{{item.createTime}}</span>
              <span class="chart-con-time-line"></span>
            </div>
            <div class="chart-item"  v-if=" item.senderId === currentUser.userId">
              <div class="chart-item-con" >
                <div class="chart-item-user">
                  <img :src="item.senderHeadImgUrl" class="chart-item-user-img" />
                </div>
                <div class="chart-item-content" v-if="item.msgType === 'txt'">{{item.msg}}</div>
                <div class="chart-item-imgouter" v-else-if="item.msgType === 'img'">
                    <img  :src="item.msg.fileUrl" class="chart-item-img chart-item-image" /> 
                </div>
                <div class="chart-item-imgouter" v-else-if="item.msgType === 'file'">
                    <a  :href="item.msg.fileUrl" target="target" download class="chart-item-img chart-item-file" >{{item.msg.fileName}}</a>
                </div>
                <div class="chart-item-kb"></div>
              </div>
            </div>
            <div class="chart-item chart-item-re" v-else>
              <div class="chart-item-con">
                <div class="chart-item-kb"></div>
                <div class="chart-item-content" v-if="item.msgType === 'txt'">{{item.msg}}</div>
                <div class="chart-item-imgouter" v-else-if="item.msgType === 'img'">
                    <img  :src="item.msg.fileUrl" class="chart-item-img chart-item-image" />
                </div>
                <div class="chart-item-imgouter" v-else-if="item.msgType === 'file'">
                    <a  :href="item.msg.fileUrl" target="target" download class="chart-item-img chart-item-file" >{{item.msg.fileName}}</a>
                </div>
                <img :src="item.senderHeadImgUrl" class="chart-item-user" />
              </div>
            </div>
          </div>
          <!-- <div class="chart-item">
            <div class="chart-item-con">
              <img src="../../assets/images/user.png" class="chart-item-user" />
              <div class="chart-item-content">内容</div>
              <div class="chart-item-kb"></div>
            </div>
            <div class="chart-item-imgs">
              <span class="chart-item-img-item">
                <img  src="../../assets/images/computed.png" class="chart-item-img" />
              </span>
              <span class="chart-item-img-item">
                <img  src="../../assets/images/computed.png" class="chart-item-img" />
              </span>
              <span class="chart-item-img-more">+5</span>
            </div>
          </div>
          <div class="chart-item chart-item-re">
            <div class="chart-item-con">
              <div class="chart-item-kb"></div>
              <div class="chart-item-content">内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</div>
              <img src="../../assets/images/user.png" class="chart-item-user" />
            </div>
          </div>
          <div class="chart-con-time">
            <span class="chart-con-time-line"></span>
            <span class="chart-con-timet">10:12</span>
            <span class="chart-con-time-line"></span>
          </div> -->
        </div>
        <div class="chart-center-handle"  v-if="currentUser">
          <div class="chart-center-handle-con">
            <input type="text"  v-model.trim="messageVal" class="send-message" resize="none" placeholder="" ></input>
            <div class="chart-handles">
              <!-- <img src="../../assets/images/smile.png" class="handle-icon" /> -->
              <el-upload
                class="upload-msg"
                drag
                :http-request="handleUpload"
                ref="upload" 
                action="customize"
                accept=".jpg,.png,.bmp,.gif"
                :before-upload="uploadLicense"
                :show-file-list="false"
              >
                <img src="../../assets/images/pic.png" alt="图片" class="handle-icon" />
              </el-upload>
              <el-upload
                class="upload-msg"
                drag
                :http-request="handleUploadFile"
                ref="upload" 
                action="customize"
                accept=".raw,.psd,.pdf,.pptx,.doc,.pdf,.xls,.docx,.txt,.xlsx,.ppt,.mp3,.wma,.wav,.m4a,.mp4,.avi,.wmv,.mpeg,.mov,.rm,.ram,.flv,.3gp,.rmvp,.ofd"
                :before-upload="uploadLicenseFile"
                :show-file-list="false"
              >
                <i class="el-icon-folder handle-icon handle-icon-i " alt="文件"></i>
                <!-- <img src="../../assets/images/pic.png" alt="图片" class="handle-icon" /> -->
              </el-upload>
              
              <!-- <img src="../../assets/images/link.png" class="handle-icon" /> -->
              <img src="../../assets/images/send.png" class="handle-icon handle-send-icon" @click="sendMsg('txt')" />
            </div>
          </div>
        </div>
      </div>
      <!-- chart-center end-->
      <chat-right :currentUser="currentUser" ref="userDetail"></chat-right>
      <invite-user :chartGroupId="chartGroupId" v-if="inviteUserDialog" @closeInviteDialog="closeInviteDialog"></invite-user>
    </div> 
  </div>
</template>

<script>
import {
  regChatMessageList,
  regChatServer,
  regFromUpload,
  regChatSetAllMessageRead,
  regChartUserList
} from '../../api/index'
import ChatGroup from '@/components/WeChat/ChatGroup'
import ChatList from '@/components/WeChat/ChatList'
import ChatRight from '@/components/WeChat/ChatRight'
import UncharList from '@/components/WeChat/UncharList'
import InviteUser from '@/components/WeChat/InviteUser'
import { maxFileSize } from '../../utils/variate'
import bus from '@/utils/bus'
export default {
  name: 'WeChat',
  data() {
    return {
      maxFileSize,
      searchText: '',
      searchUserData: [], // 搜索数组
      activeTab: 'group', // 当前tabs
      nureadNew: true, // 是否有未读
      chatNew: true, // 聊天是否有新消息
      messageVal: '', // 用户正在输入消息
      currentUser: null, // 当前用户
      currentMessageList: '', // 与当前人员历史消息
      msgId: null, // 当前最后一条消息的id
      msgIsAll: false, // 是否已没有消息，消息到底了
      historyMsgData: [],
      msgLoading: false, // 拉取消息loading
      msgData: [], // 处理后的历史消息
      connectUrl: '', // websocket地址
      backWebSocket: "",
      inviteUserDialog: false, // 用户二维码
      chartGroupId: null, // 二维码分组
      userId: ''
    }
  },
  components: {ChatGroup, ChatList, ChatRight, UncharList, InviteUser},
  mounted(){
    this.getChartServer()
    if(this.$route.query && this.$route.query.userId){
      //this.toChat({userId: this.$route.query.userId})
      this.userId = this.$route.query.userId
      bus.$emit('changePage', 'weChat')
    }
    //console.log('e',this.$route.query)
  },
  watch:{
    activeTab(){
      this.getAllList()
    },
    msgData(val){
      console.log('不应该清空么',val)
    },
     // 搜索用户
    searchText(){
      if(!this.searchText){
        this.searchUserData = []
        return false
      }
      regChartUserList({
        keyword: this.searchText
      }).then(res => {
        if(res.code === 0){
          this.searchUserData = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '搜索出错，'+ res.msg
          });
          
        }
      })
    },
  },
  methods:{
    // 获取websocket地址
    getChartServer(){
      if(this.backWebSocket !== '') return false
      regChatServer().then(res => {
        if(res.code === 0){
          this.connectUrl = res.data.serverAddress
          this._initWebsocket()
          //this.currentMessageList = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取服务地址失败了' + res.msg
          });
        }
      })
    },
    _initWebsocket(){
      const token = sessionStorage.getItem('token')
      this.backWebSocket = new WebSocket(this.connectUrl,[token]);
      this.backWebSocket.onopen = (evt) => {
        console.log("open", this.backWebSocket.readyState);
        //this.backWebSocket = backSocket
      };
      this.backWebSocket.onmessage = (evt) => {
        console.log(evt.data);
        let json_data = "";
        json_data = JSON.parse(evt.data);
        if(json_data.pkgType === 'ack'){
          if(json_data.sendResult=='succ'){
            // 给别人发送消息成功了
            this.resetMsg()
            this.toChat({userId: this.currentUser.userId})
            this.messageVal = ""
          }else{
             this.$notify.error({
              title: '错误',
              message: '发送失败了，请重发！'
            });
          }
          
        }
        if(json_data.pkgType === 'senderType'){
          // 有人发消息来了
          if(json_data.senderId === this.currentUser.userId){
            // 当前用户发来消息
            this.resetMsg()
            this.this.getAllList()
            this.toChat({userId: this.currentUser.userId})
          }
        }
      };
      this.backWebSocket.onerror = (evt) => {
        console.log("error");
        //this.backWebSocket.close();
        this._initWebsocket();
      };
      this.backWebSocket.onclose = (evt) => {
        console.log("close");
        this.backWebSocket = 0;
        this._initWebsocket();
      };
    },
    // 重置拉取聊天记录的参数
    resetMsg(){
      this.historyMsgData = []
      this.msgId = null
      this.msgIsAll = false
    },
    // 点击用户进行聊天
    toChatHandle(data){
      this.resetMsg()
      this.msgData = []
      this.historyMsgData = []
      this.currentUser = data
      const _this = this
      console.log('换了个人了',data)
      this.$refs.userDetail.getPatientDetail(this.currentUser)
      this.$nextTick(() => {
        var divElement = document.getElementById("charDiv"); // 根据id选择需要操作的div元素
        console.log('divElement',divElement)
        divElement.addEventListener('scroll', function() {
          if (this.scrollTop === 0) {
            console.log("已经滚动到了顶部！");
            _this.toChat(data)
          }
        });
      })
      this.resetMsg()
      this.toChat(data)
      this.readAll()
    },
    // 生成分组的二维码
    getChartCode(id){
      this.chartGroupId = id
      this.inviteUserDialog = true
    },
    // 是否有未读
    hasUnRead(val){
      this.nureadNew = val
      console.log('nureadNew',val)
    },
    // 聊天列表
    chartData(data){
      console.log('data聊天列表',data)
      let unReadList = data.filter(item => item.unreadMsgCount> 0 ).length
      this.chatNew = unReadList>0 ? true: false
    },
    // 把当前用户全部置为已读
    readAll(){
      regChatSetAllMessageRead({
        userId: this.currentUser.userId
      }).then(res => {
        if(res.code === 0){
          this.getAllList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '当前用户全部置为已读失败，'+ res.msg
          });
        }
      })
    },
    // 拉取列表
    getAllList(){
      this.$refs.unread.getUnreadList()
      this.$refs.group.getGroupList()
      this.$refs.chart.getUnreadList()
    },
    // 拉取聊天记录
    toChat(data){
      console.log('toChat',data)
      this.msgLoading = true
      regChatMessageList({
        userId: data.userId,
        minMsgId: this.msgId
      }).then(res => {
        this.msgLoading = false
        if(res.code === 0){
          let msgData = res.data
          console.log('拉取聊天记录',msgData,this.historyMsgData)
          if(!msgData.length){
            this.msgIsAll = true // 没消息了
            return false
          }
          this.historyMsgData = this.historyMsgData.concat(msgData)
          msgData = this.historyMsgData.slice().reverse()
          if(msgData.length){
            this.getMsg(msgData)
          }
          //this.currentMessageList = res.data
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取聊天记录失败！' + res.msg
          });
        }
      })
    },
    // 把聊天记录进行倒叙
    getMsg(msgData){
      console.log('msgData cs',msgData)
      for(let i=0; i<msgData.length; i++){
        if(msgData[i].content){
          if(msgData[i].msgType === 'txt'){
            msgData[i].msg = JSON.parse(msgData[i].content).message
          }else{
            console.log('JSON.parse(msgData[i].content)',JSON.parse(msgData[i].content))
            msgData[i].msg = JSON.parse(msgData[i].content)
          }
        }else{
          msgData[i].msg = ''
        }
        
      }
      this.msgId = msgData[0].msgId
      console.log('msgData',msgData)
      this.msgData = msgData
      this.$nextTick(() => {
        var myDiv = document.getElementById("charDiv"); // "myDiv"为需要滚动的 div 元素的 id
        // 设置滚动位置为最底部
        myDiv.scrollTop = myDiv.scrollHeight;
      })      
    },
    // 给别人发送消息
    sendMsg(msgType = 'txt',content = null){
      // 是文本时
      if(msgType==='txt' && this.messageVal == ""){
        this.$message.warning('消息内容不能为空')
        return false
      }
      const message = {
        accepterId: this.currentUser.userId,
        msgType,
        //senderId: this.currentUser.userId
      }
      console.log('this.messageVal',this.messageVal,content)
      if(msgType === 'txt'){
        message.content = {message: this.messageVal}
      }else{
        message.content = content
      }
      //console.log('message',message,this.backWebSocket)
      if(this.backWebSocket){
        this.backWebSocket.send(JSON.stringify(message));
      }else{
        this.$message.warning('与服务断联，请刷新后再试！')
      }
      
    },
    handleUpload(){},
    // 上传图片
    uploadLicense(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          //let fileUrl = res.data.fileUrl
          this.sendMsg('img',{
            filePath: res.data.filePath,
            fileUrl: res.data.fileUrl, 
            fileName: res.data.fileName
          })
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    handleUploadFile(){},
    // 上传文件
    uploadLicenseFile(file) {
      console.log('file',file,file.raw)
      if(file.size/(1024*1024)  > this.maxFileSize){
        this.$message.error('图片大小不能大于'+this.maxFileSize+'m')
        return false
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", 'pub');
      formData.append("fileType", file.fileType);
      regFromUpload(formData).then(res => {
        if(res.code === 0){
          let fileUrl = res.data.fileUrl
          this.sendMsg('file',{
            filePath: res.data.filePath,
            fileUrl: res.data.fileUrl, 
            fileName: res.data.fileName
          })
        }else{
          this.$notify.error({
            title: '错误',
            message: '上传文件失败，'+ res.msg
          });
          
        }
      })
    },
    // 去邀请用户
    toInviteUser(){
      this.inviteUserDialog = true
    },
    closeInviteDialog(){
      this.inviteUserDialog = false
    },
    selectUser(item){
      this.toChatHandle(item)
      this.searchUserData = []
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.wechart
  padding 17px 0
  .wechart-flex 
    height calc(100vh - 34px) 
    display flex 
    flex-direction row
    overflow hidden
    .chart-left
      width 330px
      height 100vh
      overflow-y auto
      .chart-left-top
        padding 0 10px 0 17px 
        .user-top
          display inline-block
          width 190px
          height 40px
          line-height 40px
          border-radius 5px
          border 1px solid #BFBFBF
          display flex
          cursor pointer
          .user-add-icon
            display inline-block
            width 60px
            border-right 1px solid #BFBFBF
            text-align center
            font-size 28px
          .user-add-text
            display inline-block
            width 128px
            line-height 40px
            text-align center
        .user-title
          font-size 26px
          font-weight bold
          height 34px
          line-height 34px
          color #000
          margin 40px 0 15px
        .user-search
          position relative
          width 300px
          height 40px
          margin-bottom 27px
          .search-cons
            padding 5px 0
            display inline-block
            width 296px
            max-height 90px
            overflow auto
            border 1px solid #D8D8D8
            background #fff
            box-shadow 0px 4px 10px 0px rgba(0, 0, 0, 0.3)
            position absolute
            top 40px
            right 4px
            z-index 12
            border-radius 5px
            .search-item
              display flex
              padding 0 10px
              line-height 24px
              cursor pointer
              .search-span 
                width 60px
              .search-span-middle
                flex 1
              &:hover
                background #D0E6E6
                color #00A389
          .search-text
            background #F0F3F6
            border-radius 10px
            >>>.el-input__inner
              width 300px
              height 40px
              background #F0F3F6
              border none
              text-indent 10px
              padding-right 42px
          .user-search-icon
            position absolute
            top 12px
            right 20px
            cursor pointer
    .chart-tabs
      height calc(100% - 196px)
      text-align center
      >>>.el-tabs__content
        height calc(100% - 55px)
        .el-tab-pane
          height 100%
      >>>.el-tabs__nav-wrap
        &::after
          display none
        .el-tabs__nav
          font-weight bold
          float none
          .el-tabs__active-bar
            display none 
          .el-tabs__item  
            font-weight bold
            padding 0
            margin 0 20px
            font-size 18px
            &:hover
              color #009375
          .is-active
            color #009375
            position relative
            &::after
              content ""
              display inline-block
              width 26px
              height 3px
              border-radius 5px
              background #009375
              position absolute
              left 5px
              bottom 2px
      >>>.el-tabs__content
        .el-collapse
          border none
          .el-collapse-item
            .el-collapse-item__header
              height 34px
              line-height 34px
              padding 0  0 0 15px
              font-size 16px
              border-bottom none
            .is-active
              color #009375
              background #F7F8FA
            .el-collapse-item__wrap
              padding 15px 0
              border-bottom none
              .el-collapse-item__content
                padding-bottom 0
                .user-item
                  display flex
                  padding 14px
                  position relative
                  cursor pointer
                  font-size 12px
                  color #9E9E9E
                  .user-img
                    width 40px
                    height 40px
                    border-radius 50%
                    margin-right 12px
                  .user-des
                    height 40px
                    flex 1
                    text-align left
                    .user-name 
                      font-size 16px
                      color #000
                      font-weight  bold
                      height 30px
                      line-height 30px
                  .is-new
                    display inline-block
                    width 10px
                    height 10px
                    border-radius 50%
                    background #009375
                    margin 12px 10px 0 0
                  &:hover
                    background #E0F2EE
                    border-radius 10px
                .is-online
                  &::after
                    content ""
                    display inline-block
                    width 8px
                    height 8px
                    border-radius 50%
                    background url(../../assets/images/green_c.png) no-repeat
                    position absolute
                    left 45px
                    bottom 15px
    .new-chart 
      >>>.el-tabs__item:nth-child(3)::before
        content ""
        display inline-block
        width 8px
        height 8px
        border-radius 50%
        background url(../../assets/images/green_c.png) no-repeat
        position absolute
        right -13px
        top 5px
    .new-unread
      >>>.el-tabs__item:nth-child(4)::before
        content ""
        display inline-block
        width 8px
        height 8px
        border-radius 50%
        background url(../../assets/images/green_c.png) no-repeat
        position absolute
        right -13px
        top 5px
  .chart-center
    height 100%
    border-radius 15px
    background #F0F0F5
    flex 1
    border 2px solid #EDF0F5
    position relative
    .chart-center-top
      height 60px
      line-height 30px
      border-radius 15px 15px 0 0
      background #fff
      font-size 16px
      display flex
      .chart-center-user
        display inline-block
        width 30px
        height 30px
        position relative
        margin 15px 9px
        .chart-user-img
          width 30px
          height 30px
          border-radius 50%
        .chart-user-online
          position absolute
          right 0px
          bottom 4px
      .chart-center-name
        height 60px
        line-height 60px
    .chart-center-bar
      padding 35px 27px
      overflow-y auto
      height calc(100% - 100px)
      .chart-item
        margin-bottom 30px
        width 100%
        .chart-item-con
          display flex
          align-items flex-start
          width 100%
          .chart-item-user
            display inline-block
            width 40px
            height 40px
            border-radius 50%
            margin-right 17px
            box-sizing border-box
            font-size 0
            line-height 1
            text-align center
            white-space nowrap
            &:after
              content ""
              display inline-block
              height 100%
              vertical-align: middle
              width: 0
            img
              display inline-block
              max-height 100%
              max-width 100%
              vertical-align middle
          .chart-item-content
            line-height 24px
            padding 5px 8px
            min-height 34px
            border-radius 10px 10px 10px 0px
            box-sizing border-box
            border 1px solid #E1E1E1
            background #fff
            word-wrap break-word
            overflow-wrap break-word
            max-width calc(100vw - 1060px)
          .chart-item-kb 
            display inline-block
            width 57px
          .chart-item-imgouter
            flex 1
            text-align left
            .chart-item-image
              max-height 400px
              max-width 400px
            .chart-item-file
              line-height 50px
              color #409eff
        .chart-item-imgs
          margin-top 10px
          padding-left 55px
          display flex
          align-items center
          .chart-item-img-item
            display inline-block
            width 80px
            height 80px
            background #ffffff
            border-radius 5px
            box-sizing border-box
            font-size 0
            line-height 1
            text-align center
            white-space nowrap
            margin-right 8px
            &::after
              content ""
              display inline-block
              height 100%
              vertical-align middle
              width 0
            .chart-item-img
              display inline-block
              max-height 100%
              max-width 100%
              vertical-align middle
        .chart-item-img-more
          display inline-block
          width 80px
          height 80px
          line-height 80px
          text-align center
          background #D0D3E3
          border-radius 5px
          font-size 16px
          color #009375
          font-weight bold
      .chart-item-re
        .chart-item-con
          justify-content flex-end
          .chart-item-user
            margin-left 17px
            margin-right 0
            box-sizing border-box
            font-size 0
            line-height 1
            text-align center
            white-space nowrap
            &:after
              content ""
              display inline-block
              height 100%
              vertical-align: middle
              width: 0
            img
              display inline-block
              max-height 100%
              max-width 100%
              vertical-align middle
          .chart-item-content
            flex none
            border-radius 10px 10px 0px 10px
            background #D0D3E3
          .chart-item-imgouter
            text-align right
      .chart-con-time
        position relative
        width 60%
        margin 0 20% 40px
        text-align center
        display flex
        align-items center
        .chart-con-timet
          display inline-block
          height 20px
          line-height 20px
          border-radius 10px
          background #D8D8D8
          padding 0 14px
          color #9E9E9E
        .chart-con-no
          margin 0 auto
        .chart-con-time-line
          display inline-block
          flex 1
          height 1px
          background #D8D8D8
          margin 0 8px
    .chart-center-handle
      position absolute
      width 90%
      border 1px solid #d8d8d8
      border-radius 10px
      left 5%
      bottom 20px
      background #fff
      .chart-center-handle-con
        height 30px
        margin 10px 0
        display flex
        align-items center
        .send-message
          flex 1
          min-height 24px
          line-height 16px
          padding 0 
          line-height 1
          border none
          padding 0 10px
          resize none
          >>>.el-textarea__inner
            border none
            padding 0 15px
            // line-height 30px
        .upload-msg
          display inline-block
          width 30px
          height 30px
          margin-left 8px
          >>>.el-upload-dragger
            width 30px
            height 30px
            border none 
            padding 0
            .handle-icon 
              margin 0
              top 4px
        .handle-icon  
          margin-left 12px
          position relative
          top -4px
          font-size 22px
          cursor pointer
          color #bbb
        .handle-icon-i 
          top -6px
        .handle-send-icon
          margin 0 27px
          top 0
  
  // .hidden-right
  //   animation menuWidth 1s forwards
  //   @keyframes menuWidth
  //   {
  //     0%  {width: 420px}
  //     100% {width 100px}
  //   }
</style>
