<template>
  <div class="detail">
    <!-- detail-top -->
    <div class="detail-top">
      <div class="detail-top-left"  @click="handleReturn">
        <img src="../../assets/images/chart_arrow.png" class="return-icon" />
        用户管理/新增患者
      </div>
      <!-- <div class="detail-top-right">
        <div class="top-link-item">
          <span class="top-link-icon"></span>
          <span class="top-link-text">基本信息</span>
        </div>
        <div class="top-link-item">
          <span class="top-link-icon"></span>
          <span class="top-link-text">专病管理</span>
        </div>
        <div class="top-link-item">
          <span class="top-link-icon"></span>
          <span class="top-link-text">专病管理</span>
        </div>
      </div> -->
    </div>
    <!-- detail-con -->
    <div class="detail-con">
      <!-- detail-left -->
      <div class="detail-left">
        <div class="detail-item">
          <div class="detail-item-title">患者详细信息</div>
          <el-form 
            :model="ruleForm" 
            status-icon 
            ref="ruleForm" 
            :rules="rules"
            label-width="100px" 
            class="demo-ruleForm"
            label-position="top"
          >
            <div class="detail-inner">
              <div class="from-par">
                <el-form-item 
                  label="姓名" 
                  prop="checkPass" 
                  class="tform-item must-go">
                  <el-input v-model="ruleForm.name" autocomplete="off" required></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="checkPass"  class="tform-item  must-go">
                  <el-select v-model="ruleForm.sex" placeholder="请选择" >
                    <el-option
                      v-for="(item,index) in sexArr"
                      :key="index"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="联系方式" prop="pass"  class="tform-item  must-go" >
                  <el-input 
                    type="text" 
                    v-model="ruleForm.mobilePhone" 
                    oninput="value=value.replace(/[^\d]/g,'')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="出生日期" prop="checkPass" class="tform-item  must-go">
                  <el-date-picker
                    v-model="ruleForm.birthday"
                    type="date"
                    format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="民族" prop="pass"  class="tform-item">
                  <el-select  placeholder="民族" v-model="ruleForm.nationstate"><!--  v-model="value" -->
                    <el-option
                      v-for="item in mz"
                      :key="item"
                      :label="item"
                      @click.native="changeMz(item)"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="职业" prop="checkPass"  class="tform-item">
                  <el-select  placeholder="职业" v-model="ruleForm.job"><!--  v-model="value" -->
                    <el-option
                      v-for="item in jobs"
                      :key="item"
                      :label="item"
                      @click.native="changeJob(item)"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="婚姻" prop="pass"  class="tform-item">
                  <el-select  placeholder="婚姻" v-model="ruleForm.marriage"><!--  v-model="value" -->
                    <el-option
                      v-for="item in marriageArr"
                      :key="item"
                      :label="item"
                      @click.native="changeMarriage(item)"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学历" prop="checkPass"  class="tform-item">
                  <el-select  placeholder="学历" v-model="ruleForm.education"><!--  v-model="value" -->
                    <el-option
                      v-for="item in educationArr"
                      :key="item"
                      :label="item"
                      @click.native="changeEducation(item)"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="from-par">
                <div class="from-par">
                <el-form-item label="分组" prop="pass" v-has="'mgr.group.query'" class="tform-item">
                 <el-select class="search-select" v-model="ruleForm.groupId" placeholder="分组">
                  <el-option
                    v-for="item in batchListDatas"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                </el-form-item>
              </div>
              </div>
              <div class="from-par">
                <el-form-item label="居住地址" prop="pass"  class="tform-item">
                 <v-distpicker
                  :province="ruleForm.currentProvince" 
                  :city="ruleForm.currentCity" 
                  :area="ruleForm.currentArea"
                  @selected="onSelectedAddr"
                 ></v-distpicker>
                </el-form-item>
              </div>
              <div class="from-par">
                <el-form-item label="详细地址" prop="pass"  class="tform-item" >
                  <el-input 
                    type="text" 
                    v-model="ruleForm.currentAddress" 
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="from-item-btns">
                <span class="from-item-btn" @click="handleReturn">取消</span>
                <span class="from-item-btn from-item-btn-blue"  @click="handleConfirmPatient">确定</span>
              </div>
            </div>
          </el-form>
        </div>
        <!-- 家族史 -->
        <div class="detail-item" v-if="patientId">
          <div class="detail-item-title">家族史</div>
          <!-- 疾病史 -->
          <div class="other-main">
            <div class="other-title">疾病史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasJiaZhu ? 'diseas-item-active' : ''" @click="changeHasJiazhu(false)">无</span>
              <span class="diseas-item" :class="hasJiaZhu ? 'diseas-item-active' : ''"  @click="changeHasJiazhu(true)">有</span>
            </div>
            <!-- <div class="diseas">
              <span class="diseas-item diseas-item-active">父亲</span> 
              <span class="diseas-item" v-for="(item,index) in familyDatas" :key="index">{{item}}</span>
            </div> -->
            <div class="person">
              <!-- <span class="person-name">
                  父亲
              </span> -->
              <!-- <div class="diseas">
                <span class="diseas-item diseas-item-active">父亲</span> 
                <span class="diseas-item" v-for="(item,index) in familyDatas" :key="index">{{item}}</span>
              </div> -->
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in jiazhu" :key="index">
                  <el-select class="dieas-type dieas-type-family" v-if="index === jiazhuNum" v-model="jiazhuRef" placeholder="关系" ><!--  v-model="value" -->
                    <el-option
                      v-for="ref in familyDatas"
                      :key="ref"
                      :label="ref"
                      @click.native="changeJiazhuRef(ref)"
                      :value="ref">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type dieas-type-family jiazhu-value">
                    {{item.ref}}
                  </span>
                  <el-select class="dieas-type" v-if="index === jiazhuNum" v-model="jiazhuType" placeholder="疾病分类" ><!--  v-model="value" -->
                    <el-option
                      v-for="type in dieasClassify"
                      :key="type"
                      :label="type"
                      @click.native="changeJiazhuType(type)"
                      :value="type">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type jiazhu-value">
                    {{item.type}}
                  </span>
                  <el-input v-if="index === jiazhuNum"  placeholder="" v-model="jiazhuDisease" class="dieas-des"></el-input>
                  <span v-else  class="dieas-des jiazhu-value">
                    {{item.disease}}
                  </span>
                  <span class="handle-span" @click="addJiazhu" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceJiazhu(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="jiazhuNum === index" @click="confirmJiazhu(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditJiazhu(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
          <!-- 遗传病史 -->
          <div class="other-main">
            <div class="other-title">遗传病史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasYichuan ? 'diseas-item-active' : ''" @click="changeHasYichuan(false)">无</span>
              <span class="diseas-item" :class="hasYichuan ? 'diseas-item-active' : ''"  @click="changeHasYichuan(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in yichuan" :key="index">
                  <el-select class="dieas-type" v-if="index === yichuanNum" v-model="yichuanType" placeholder="疾病分类" ><!--  v-model="value" -->
                    <el-option
                      v-for="type in yichuanTypes"
                      :key="type"
                      :label="type"
                      @click.native="changeYichuanType(type)"
                      :value="type">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type jiazhu-value">
                    {{item.type}}
                  </span>
                  <el-select class="dieas-type dieas-type-family" v-if="index === yichuanNum" v-model="yichuanRef" placeholder="关系" ><!--  v-model="value" -->
                    <el-option
                      v-for="ref in familyDatas"
                      :key="ref"
                      :label="ref"
                      @click.native="changeYichuanRef(ref)"
                      :value="ref">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type dieas-type-family jiazhu-value">
                    {{item.ref}}
                  </span>
                  <el-input v-if="index === yichuanNum"  placeholder="" v-model="yichuanDisease" class="dieas-des"></el-input>
                  <span v-else  class="dieas-des jiazhu-value">
                    {{item.disease}}
                  </span>
                  <span class="handle-span" @click="addYichuan" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceYichuan(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="yichuanNum === index" @click="confirmYichuan(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditYichuan(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
          <!-- 疾病史 end-->
          <!-- 遗传病史 -->
          <!-- <div class="other-main">
            <div class="other-title">遗传病史</div>
            <div class="diseas">
              <span class="diseas-item diseas-item-active">遗传性</span>
              <span class="diseas-item">家族性</span>
              <span class="diseas-item">先天性</span>
            </div>
            <div class="diseas">
              <span class="diseas-item diseas-item-active">父亲</span>
              <span class="diseas-item" v-for="(item,index) in familyDatas" :key="index">{{item}}</span>
            </div>
            <div class="person">
              <span class="person-name">
                  父亲
              </span>
              <div class="person-items">
                <div class="person-item">
                  <el-select class="dieas-type" placeholder="疾病分类">
                    <el-option label="疾病一" value="shanghai"></el-option>
                    <el-option label="疾病二" value="beijing"></el-option>
                  </el-select>
                  <el-input  placeholder="" class="dieas-des"></el-input>
                  <span class="handle-span">+</span>
                  <span class="handle-span">-</span>
                </div>
                <div class="person-item">
                  <el-select class="dieas-type" placeholder="疾病分类">
                    <el-option label="疾病一" value="shanghai"></el-option>
                    <el-option label="疾病二" value="beijing"></el-option>
                  </el-select>
                  <el-input  placeholder="" class="dieas-des"></el-input>
                  <span class="handle-span">+</span>
                  <span class="handle-span">-</span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 遗传病史 end-->
        </div>
        <!-- 家族史 end-->
      </div>
      <!-- detail-left -->
      <div class="detail-right">
        <div class="detail-item" v-if="patientId">
          <div class="detail-item-title">既往病史</div>
          <!-- 疾病史 -->
          <div class="other-main">
            <div class="other-title">疾病史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasJibing ? 'diseas-item-active' : ''" @click="changeHasJibing(false)">无</span>
              <span class="diseas-item" :class="hasJibing ? 'diseas-item-active' : ''"  @click="changeHasJibing(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in jibing" :key="index">
                  <span class="person-item-tit">疾病</span> 
                  <el-select class="dieas-type" v-if="index === jibingNum" v-model="jibingDisease" placeholder="疾病分类" ><!--  v-model="value" -->
                    <el-option
                      v-for="type in jibingDiseases"
                      :key="type"
                      :label="type"
                      @click.native="changeJibingDisease(type)"
                      :value="type">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type jiazhu-value">
                    {{item.disease}}
                  </span>
                  <span class="person-item-tit">时间 </span> 
                  <el-date-picker
                    v-if="index === jibingNum"
                    class="dieas-des"
                    v-model="jibingTime"
                    style="width:160px;"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                  <span v-else style="width:160px;"  class="dieas-des jiazhu-value">
                    {{item.time}}
                  </span>
                  <el-input v-if="index === jibingNum" style="width:70px;margin-left:10px;"  placeholder="" v-model="jibingYearlimit" type="number" class="dieas-des"></el-input>
                  <span v-else style="width:70px;margin-left:10px;"  class="dieas-des jiazhu-value">
                    {{item.yearlimit}}
                  </span>  /年
                  <span class="handle-span" @click="addJibing" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceJibing(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="jibingNum === index" @click="confirmJibing(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditJibing(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
          <!-- 手术史 -->
          <div class="other-main">
            <div class="other-title">手术史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasShoushu ? 'diseas-item-active' : ''" @click="changeHasShoushu(false)">无</span>
              <span class="diseas-item" :class="hasShoushu ? 'diseas-item-active' : ''"  @click="changeHasShoushu(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in shoushu" :key="index">
                  <span class="person-item-tit">原因</span> 
                  <el-input v-if="index === shoushuNum" style="width:240px;margin-right:20px;"  placeholder="" v-model="shoushuReason" class="dieas-des"></el-input>
                  <span v-else style="width:240px;margin-right:20px;"  class="dieas-des jiazhu-value">
                    {{item.reason}}
                  </span>
                  <span class="person-item-tit">时间 </span> 
                  <el-date-picker
                    v-if="index === shoushuNum"
                    class="dieas-des"
                    v-model="shoushuTime"
                    style="width:160px;"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                  <span v-else style="width:160px;"  class="dieas-des jiazhu-value">
                    {{item.time}}
                  </span>
                  <span class="handle-span" @click="addShoushu" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceShoushu(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="shoushuNum === index" @click="confirmShoushu(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditShoushu(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="other-main">
            <div class="other-title">外伤史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasWaishang ? 'diseas-item-active' : ''" @click="changeHasWaishang(false)">无</span>
              <span class="diseas-item" :class="hasWaishang ? 'diseas-item-active' : ''"  @click="changeHasWaishang(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in waishang" :key="index">
                  <span class="person-item-tit">原因</span> 
                  <el-input v-if="index === waishangNum" style="width:240px;margin-right:20px;"  placeholder="" v-model="waishangReason" class="dieas-des"></el-input>
                  <span v-else style="width:240px;margin-right:20px;"  class="dieas-des jiazhu-value">
                    {{item.reason}}
                  </span>
                  <span class="person-item-tit">时间 </span> 
                  <el-date-picker
                    v-if="index === waishangNum"
                    class="dieas-des"
                    v-model="waishangTime"
                    style="width:160px;"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                  <span v-else style="width:160px;"  class="dieas-des jiazhu-value">
                    {{item.time}}
                  </span>
                  <span class="handle-span" @click="addWaishang" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceWaishang(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="waishangNum === index" @click="confirmWaishang(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditWaishang(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="other-main">
            <div class="other-title">输血史</div>
            <div class="diseas">
              <span class="diseas-item" :class="!hasShuxue ? 'diseas-item-active' : ''" @click="changeHasShuxue(false)">无</span>
              <span class="diseas-item" :class="hasShuxue ? 'diseas-item-active' : ''"  @click="changeHasShuxue(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in shuxue" :key="index">
                  <span class="person-item-tit">原因</span> 
                  <el-input v-if="index === shuxueNum" style="width:240px;margin-right:20px;"  placeholder="" v-model="shuxueReason" class="dieas-des"></el-input>
                  <span v-else style="width:240px;margin-right:20px;"  class="dieas-des jiazhu-value">
                    {{item.reason}}
                  </span>
                  <span class="person-item-tit">时间 </span> 
                  <el-date-picker
                    v-if="index === shuxueNum"
                    class="dieas-des"
                    v-model="shuxueTime"
                    style="width:160px;"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                  <span v-else style="width:160px;"  class="dieas-des jiazhu-value">
                    {{item.time}}
                  </span>
                  <span class="handle-span" @click="addShuxue" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceShuxue(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="shuxueNum === index" @click="confirmShuxue(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditShuxue(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-item" v-if="patientId">
          <div class="detail-item-title">用药史</div>
          <!-- 疾病史 -->
          <div class="other-main">
            <div class="diseas">
              <span class="diseas-item" :class="!hasYongyao ? 'diseas-item-active' : ''" @click="changeHasYongyao(false)">无</span>
              <span class="diseas-item" :class="hasYongyao ? 'diseas-item-active' : ''"  @click="changeHasYongyao(true)">有</span>
            </div>
            <div class="person">
              <div class="person-items">
                <div class="person-item" v-for="(item,index) in yongyao" :key="index">
                  <span class="person-item-tit">类型</span> 
                  <el-select class="dieas-type" v-if="index === yongyaoNum" style="width:100px;" v-model="medicineType" placeholder="疾病分类" ><!--  v-model="value" -->
                    <el-option
                      v-for="type in medicineTypes"
                      :key="type"
                      :label="type"
                      @click.native="changeMedicineType(type)"
                      :value="type">
                    </el-option>
                  </el-select>
                  <span v-else style="width:100px;margin-right:20px;"  class="dieas-des jiazhu-value">
                    {{item.medicineType}}
                  </span>
                  <span class="person-item-tit">药名</span> 
                  <el-input v-if="index === yongyaoNum" style="width:120px;margin-right:20px;"  placeholder="" v-model="yongyaoMedicinal" class="dieas-des"></el-input>
                  <span v-else style="width:120px;margin-right:20px;"  class="dieas-des jiazhu-value">
                    {{item.medicinal}}
                  </span>
                  <span class="person-item-tit">时间 </span>
                  <el-select class="dieas-type" v-if="index === yongyaoNum" v-model="yongyaoTime" placeholder="疾病分类" ><!--  v-model="value" -->
                    <el-option
                      v-for="type in YongyaoTimes"
                      :key="type"
                      :label="type"
                      @click.native="changeYongyaoTime(type)"
                      :value="type">
                    </el-option>
                  </el-select>
                  <span v-else class="dieas-type jiazhu-value">
                    {{item.time}}
                  </span>
                  <span class="handle-span" @click="addYongyao" title="新增一条">+</span>
                  <span class="handle-span" @click="reduceYongyao(index)"  title="删除">-</span>
                  <span class="handle-span handle-span-icon" v-if="yongyaoNum === index" @click="confirmYongyao(index)"><img src="../../assets/images/confirm.png" title="确认" /></span>
                  <span class="handle-span handle-span-icon" v-else @click="toEditYongyao(index)"><img src="../../assets/images/edit.png"  title="编辑" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  regAddPatient,
  regPatientDetail,
  regEditPatient,
  regListAllName,
  regUserGroupList
} from '../../api/index'
import { powerRight } from '../../utils/index'
export default {
  data () {
    return {
      patientId: null,
      patinentDetail: {},
      pickerOptions: {
        disabledDate(time) {
          const now = new Date(); // 当前时间
          return time.getTime() > now.getTime(); // 如果选择的日期大于当前日期，则禁用该日期选择
        }
      },
      ruleForm: {
        name: '',
        sex: '',
        mobilePhone: '',
        birthday: '',
        nationstate: '',
        job: '',
        marriage: '',
        education: '',
        groupId: '',
        currentProvince: '',
        currentCity: '',
        currentArea: '',
        currentAddress: ''
      },
      batchListDatas: [
      ],
      mz:[
        "汉族",
        "壮族",
        "满族",
        "回族",
        "苗族",
        "维吾尔族",
        "土家族",
        "彝族",
        "蒙古族",
        "藏族",
        "布依族",
        "侗族",
        "瑶族",
        "朝鲜族",
        "白族",
        "哈尼族",
        "哈萨克族",
        "黎族",
        "傣族",
        "畲族",
        "傈僳族",
        "仡佬族",
        "东乡族",
        "高山族",
        "拉祜族",
        "水族",
        "佤族",
        "纳西族",
        "羌族",
        "土族",
        "仫佬族",
        "锡伯族",
        "柯尔克孜族",
        "达斡尔族",
        "景颇族",
        "毛南族",
        "撒拉族",
        "布朗族",
        "塔吉克族",
        "阿昌族",
        "普米族",
        "鄂温克族",
        "怒族",
        "京族",
        "基诺族",
        "德昂族",
        "保安族",
        "俄罗斯族",
        "裕固族",
        "乌孜别克族",
        "门巴族",
        "鄂伦春族",
        "独龙族",
        "塔塔尔族",
        "赫哲族",
        "珞巴族"
      ],
      jobs:["技术","企事业单位","商务服务","农业工作者","生产运输","其他"],
      sexArr: ["男","女","未知"],
      marriageArr: ["未婚","已婚","离异","丧偶"],
      educationArr: ["小学","初中","高中","职高","大专","本科","硕士","其他"],
      familyDatas:['父亲','母亲','兄弟姐妹','子女','祖父母'],
      dieasClassify: ["精神心理","呼吸","心血管","血液","消化","肝胆","肾脏","泌尿","肛肠","骨骼","其他"],
      // ------jiazhu------
      jiazhu: [], // 家庭史-疾病史
      hasJiaZhu: false, // 是否有家庭疾病史
      jiazhuRef: '', // 家族-疾病史-关系
      jiazhuType: null, // 疾病史 当前疾病
      jiazhuDisease: '', // 疾病史 当前第几个
      jiazhuNum: -1,
      // --------yichuan----------
      yichuan: [], // 家族-遗传病史
      hasYichuan: false,
      yichuanRef: '',
      yichuanType: null, // 遗传病史 当前疾病
      yichuanDisease: '', // 遗传病史 当前第几个
      yichuanNum: -1,
      yichuanTypes: ['遗传性','家族性','先天性'],
      // --------jibing----------
      jibing: [], // 疾病史
      hasJibing: false,
      jibingDisease: '', // 遗传病史 当前第几个
      jibingTime: null, // 遗传病史 当前疾病
      jibingYearlimit: '',
      jibingNum: -1,
      jibingDiseases: [],
      // --------shoushu----------
      shoushu: [], // 疾病史
      hasShoushu: false,
      shoushuReason: '', // 遗传病史 当前第几个
      shoushuTime: null, // 遗传病史 当前疾病
      shoushuNum: -1,
      // --------waishang----------
      waishang: [], // 疾病史
      hasWaishang: false,
      waishangReason: '', // 遗传病史 当前第几个
      waishangTime: null, // 遗传病史 当前疾病
      waishangNum: -1,
      // --------waishang----------
      shuxue: [], // 疾病史
      hasShuxue: false,
      shuxueReason: '', // 遗传病史 当前第几个
      shuxueTime: null, // 遗传病史 当前疾病
      shuxueNum: -1,
      // --------yongyao----------
      yongyao: [], // 疾病史
      hasYongyao: false,
      medicineTypes: ['处方药','非处方药'],
      medicineType: '',
      yongyaoMedicinal: '', // 遗传病史 当前第几个
      yongyaoTime: null, // 遗传病史 当前疾病
      yongyaoNum: -1,
      YongyaoTimes: ['一个月','三个月','半年','一年','一年以上'],

      family:{
        has: false,
        personArr:[
          {
            disname:'外祖父',
            disArr:[
              {
                type: '',
                des: ''
              },
              {
                type: '',
                des: ''
              }
            ]
          }
        ]
      },
      shoushu:[],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
        ],
        mobilePhone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        birthday: [
          { required: true, message: '请输入出生日期', trigger: 'blur' },
        ],
        currentCity: [
          { required: true, message: '请输入居住地址', trigger: 'blur' },
        ],
      },
      
    }
  },
  mounted() {
    console.log('mounted',this.$route.params)
    if(this.$route.params.patientId !== undefined){
      this.patientId = this.$route.params.patientId
      this.getPatientDetail()
    }
    this.getAllDiease()
    if(powerRight('mgr.group.query')){
      this.getGroupDatas()
    }
  },
  methods:{
    // 获取所有疾病列表    
    getAllDiease(){
      regListAllName().then(res => {
        if(res && res.code === 0){
          this.jibingDiseases = res.data
        }else{
          this.$message({
            message: '拉取疾病列表失败'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    handleReturn(){
      this.$router.go(-1);
    },
    // 分组
    getGroupDatas(){
      regUserGroupList({
        pageNo: 1,
        pageSize: 100,
        name: ''
      }).then(res => {
        if(res && res.code === 0){
          this.batchListDatas = res.data.list
        }else{
          this.$message({
            message: '获取分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 获取患者详情
    getPatientDetail(){
      regPatientDetail({
        id: this.patientId
      }).then(res => {
        console.log('获取患者详情',res)
        if(res && res.code === 0){
          this.patinentDetail = res.data
          this.ruleForm.name = res.data.name
          this.ruleForm.sex = res.data.sex
          this.ruleForm.mobilePhone = res.data.mobilePhone
          this.ruleForm.birthday = res.data.birthday
          this.ruleForm.job = res.data.job
          this.ruleForm.marriage = res.data.marriage
          this.ruleForm.nationstate = res.data.nationstate
          this.ruleForm.education = res.data.education
          this.ruleForm.groupId = res.data.groupId
          this.ruleForm.currentProvince = res.data.currentProvince
          this.ruleForm.currentAddress = res.data.currentAddress
          this.ruleForm.currentCity = res.data.currentCity
          this.ruleForm.currentArea = res.data.currentArea

          this.jiazhu = JSON.parse(res.data.jiazhu)
          this.jibing = JSON.parse(res.data.jibing)
          this.shoushu = JSON.parse(res.data.shoushu)
          this.shuxue = JSON.parse(res.data.shuxue)
          this.waishang = JSON.parse(res.data.waishang)
          this.yichuan = JSON.parse(res.data.yichuan)
          this.yongyao = JSON.parse(res.data.yongyao)
        }else{
          this.$message({
            message: '获取患者详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    changeSex(val){
      this.ruleForm.sex = val
    },
    changeMz(val){
      this.ruleForm.nationstate = val
    },
    changeJob(val){
      this.ruleForm.job = val
    },
    changeMarriage(val){
      this.ruleForm.marriage = val
    },
    changeEducation(val){
      this.ruleForm.education = val
      console.log('this.ruleForm',this.ruleForm)
    },
    onSelectedAddr(data){
      console.log('data 地址',data)
      this.ruleForm.currentProvince = data.province.value;
      this.ruleForm.currentCity = data.city.value;
      this.ruleForm.currentArea = data.area.value;
    },
    // 确定提交
    handleConfirmPatient(){
      console.log('this.',this.ruleForm)
      let params = this.ruleForm
      if(!this.ruleForm.name.trim()){
        this.$message({
          message: '请先填写姓名',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.ruleForm.sex){
        this.$message({
          message: '请先填写性别',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.ruleForm.mobilePhone){
        this.$message({
          message: '请先填写联系方式',
          type: 'wanring'
        }); 
        return false
      }
      if(this.ruleForm.mobilePhone.length != 11){
        this.$message({
          message: '联系方式必须为11位',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.ruleForm.birthday){
        this.$message({
          message: '请先填写出生日期',
          type: 'wanring'
        }); 
        return false
      }
      params.jiazhu = JSON.stringify(this.jiazhu)
      params.jibing = JSON.stringify(this.jibing)
      params.shoushu = JSON.stringify(this.shoushu)
      params.shuxue = JSON.stringify(this.shuxue)
      params.waishang = JSON.stringify(this.waishang)
      params.yichuan =JSON.stringify(this.yichuan)
      params.yongyao = JSON.stringify(this.yongyao)
      console.log('jiazhuNum',this.jiazhuNum)
      if(this.jiazhuNum != -1 || this.yichuanNum != -1 || this.jibingNum  != -1 || this.shoushuNum  != -1 || this.waishangNum  != -1 || this.shuxueNum != -1 ||this.yongyaoNum != -1 ){
         this.$message({
          message: '有未确认√的数据，请操作完再提交',
          type: 'wanring'
        }); 
        return false
      }
      console.log('params.',params)
      if(this.patientId){
        params.id = this.patientId
        regEditPatient(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '修改成功！',
              type: 'success'
            });
            this.$router.push({ path: `/UserManage`})
          }else{
            this.$message({
              message: '修改患者失败'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        regAddPatient(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '新增成功！',
              type: 'success'
            });
            this.patientId = res.data.id
            // this.$router.push({ path: `/UserManage`})
          }else{
            this.$message({
              message: '新增患者失败'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    // Jiazhu ----------------------------
    // 更改有无 家庭疾病史
    changeHasJiazhu(hasjiazhu){
      this.hasJiaZhu = hasjiazhu
      if(!hasjiazhu){
        this.$nextTick(() => {
          this.jiazhu = []
          this.jiazhuNum = -1
        })
      }
      if(!this.jiazhu.length){
        this.jiazhu = [{
          ref:'',
          type: '',
          disease: ''
        }]
        this.jiazhuNum = 0
      }
    },
    // 家庭疾病史关系
    changeJiazhuRef(val){
      this.jiazhuRef = val
    },
    changeJiazhuType(type){
      this.jiazhuType = type
    },
    // 确认了一人家庭疾病史
    confirmJiazhu(index){
      if(!this.jiazhuRef || !this.jiazhuType || !this.jiazhuDisease){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.jiazhu[index] = {
        ref: this.jiazhuRef,
        type:  this.jiazhuType,
        disease:  this.jiazhuDisease
      }
      console.log('this.jiazhu',this.jiazhu)
      this.jiazhuRef = ''
      this.jiazhuType =  null
      this.jiazhuDisease = ''
      this.jiazhuNum = -1
    },
    // 去编辑家庭疾病史
    toEditJiazhu(index){
      console.log('toEditJiazhu',index,this.jiazhu)
      let i = Number(index)
      this.jiazhuNum = index
      this.jiazhuRef = this.jiazhu[index].ref
      this.jiazhuType =  this.jiazhu[index].type
      this.jiazhuDisease = this.jiazhu[index].disease
    },
    addJiazhu(){
      console.log('jiazhuNum befor',this.jiazhuNum)
      if(this.jiazhuNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.jiazhu.push({
        ref:'',
        type: '',
        disease: ''
      })
      this.jiazhuNum = this.jiazhu.length -1 
      console.log('jiazhuNum',this.jiazhuNum)
    },
    async reduceJiazhu(index){
      if(this.jiazhuNum !== index && this.jiazhuNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.jiazhu.splice(index, 1);
      if(this.jiazhu.length === 0){
        this.hasJiaZhu = false
      }
      this.jiazhuNum = -1
    },
    // yichuan ----------------------------
    // 更改有无 家庭疾病史
    changeHasYichuan(hasYichuan){
      this.hasYichuan = hasYichuan
      if(!hasYichuan){
        this.$nextTick(() => {
          this.yichuan = []
          this.yichuanNum = -1
        })
      }
      if(!this.yichuan.length){
        this.yichuan = [{
          ref:'',
          type: '',
          disease: ''
        }]
        this.yichuanNum = 0
      }
    },
    // 家庭疾病史关系
    changeYichuanRef(val){
      this.yichuanRef = val
    },
    changeYichuanType(type){
      this.yichuanType = type
    },
    // 确认了一人家庭疾病史
    confirmYichuan(index){
      if(!this.yichuanRef || !this.yichuanType || !this.yichuanDisease){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.yichuan[index] = {
        ref: this.yichuanRef,
        type:  this.yichuanType,
        disease:  this.yichuanDisease
      }
      console.log('this.yichuan',this.yichuan)
      this.yichuanRef = ''
      this.yichuanType =  null
      this.yichuanDisease = ''
      this.yichuanNum = -1
    },
    // 去编辑家庭疾病史
    toEditYichuan(index){
      let i = Number(index)
      this.yichuanNum = index
      this.yichuanRef = this.yichuan[index].ref
      this.yichuanType =  this.yichuan[index].type
      this.yichuanDisease = this.yichuan[index].disease
    },
    addYichuan(){
      console.log('yichuan befor',this.yichuanNum)
      if(this.yichuanNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.yichuan.push({
        ref:'',
        type: '',
        disease: ''
      })
      this.yichuanNum = this.yichuan.length -1 
      console.log('jiazhuNum',this.yichuanNum)
    },
    async reduceYichuan(index){
      if(this.yichuanNum !== index && this.yichuanNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.yichuan.splice(index, 1);
      if(this.yichuan.length === 0){
        this.hasYichuan = false
      }
      this.yichuanNum = -1
    },
    // Jiazhu ----------------------------
    // 更改有无 家庭疾病史
    changeHasJibing(hasJibing){
      this.hasJibing = hasJibing
      if(!hasJibing){
        this.$nextTick(() => {
          this.jibing = []
          this.jibingNum = -1
        })
      }
      if(!this.jibing.length){
        this.jibing = [{
          disease: '',
          time: null,
          yearlimit: ''
        }]
        this.jibingNum = 0
      }
    },
    // 家庭疾病史关系
    changeJibingDisease(val){
      this.jibingDisease = val
    },
    // changeJiazhuType(type){
    //   this.jiazhuType = type
    // },
    // 确认了一人家庭疾病史
    confirmJibing(index){
      if(!this.jibingDisease || !this.jibingTime || !this.jibingYearlimit){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.jibing[index] = {
        disease: this.jibingDisease,
        time:  this.jibingTime,
        yearlimit:  this.jibingYearlimit
      }
      console.log('this.jibing',this.jibing)
      this.jibingDisease = ''
      this.jibingTime =  null
      this.jibingYearlimit = ''
      this.jibingNum = -1
    },
    // 去编辑家庭疾病史
    toEditJibing(index){
      console.log('toEditJibing',index,this.jibing)
      let i = Number(index)
      this.jibingNum = index
      this.jibingTime = this.jibing[index].time
      this.jibingYearlimit =  this.jibing[index].yearlimit
      this.jibingDisease = this.jibing[index].disease
    },
    addJibing(){
      console.log('jiazhuNum befor',this.jibingNum)
      if(this.jibingNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.jibing.push({
        disease: '',
        time: null,
        yearlimit: ''
      })
      this.jibingNum = this.jibing.length -1 
      console.log('jiazhuNum',this.jibingNum)
    },
    async reduceJibing(index){
      if(this.jibingNum !== index && this.jibingNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.jibing.splice(index, 1);
      if(this.jibing.length === 0){
        this.hasJibing= false
      }
      this.jibingNum = -1
    },
    // shoushu ----------------------------
    // 更改有无 Shoushu
    changeHasShoushu(hasShoushu){
      this.hasShoushu = hasShoushu
      if(!hasShoushu){
        this.$nextTick(() => {
          this.shoushu = []
          this.shoushuNum = -1
        })
      }
      if(!this.shoushu.length){
        this.shoushu = [{
          time:null,
          reason: ''
        }]
        this.shoushuNum = 0
      }
    },
    // 确认了一人家庭疾病史
    confirmShoushu(index){
      if(!this.shoushuTime || !this.shoushuReason){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.shoushu[index] = {
        time: this.shoushuTime,
        reason:  this.shoushuReason
      }
      console.log('this.shoushu',this.shoushu)
      this.shoushuTime = null
      this.shoushuReason =  ''
      this.shoushuNum = -1
    },
    // 去编辑家庭疾病史
    toEditShoushu(index){
      let i = Number(index)
      this.shoushuNum = index
      this.shoushuReason = this.shoushu[index].reason
      this.shoushuTime =  this.shoushu[index].time
    },
    addShoushu(){
      console.log('shoushu befor',this.shoushuNum)
      if(this.shoushuNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.shoushu.push({
        time: null,
        reason:  ''
      })
      this.shoushuNum = this.shoushu.length -1 
      console.log('jiazhuNshoushushoushuum',this.shoushuNum)
    },
    async reduceShoushu(index){
      if(this.shoushuNum !== index && this.shoushuNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.shoushu.splice(index, 1);
      if(this.shoushu.length === 0){
        this.hasShoushu = false
      }
      this.shoushuNum = -1
    },
    // waishang ----------------------------
    // 更改有无 waishang
    changeHasWaishang(hasWaishang){
      this.hasWaishang = hasWaishang
      if(!hasWaishang){
        this.$nextTick(() => {
          this.waishang = []
          this.waishangNum = -1
        })
      }
      if(!this.waishang.length){
        this.waishang = [{
          time:null,
          reason: ''
        }]
        this.waishangNum = 0
      }
    },
    // 确认了一人家庭疾病史
    confirmWaishang(index){
      if(!this.waishangTime || !this.waishangReason){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.waishang[index] = {
        time: this.waishangTime,
        reason:  this.waishangReason
      }
      console.log('this.waishang',this.waishang)
      this.waishangTime = null
      this.waishangReason =  ''
      this.waishangNum = -1
    },
    // 去编辑家庭疾病史
    toEditWaishang(index){
      let i = Number(index)
      this.waishangNum = index
      this.waishangReason = this.waishang[index].reason
      this.waishangTime =  this.waishang[index].time
    },
    addWaishang(){
      console.log('waishang befor',this.waishangNum)
      if(this.waishangNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.waishang.push({
        time: null,
        reason:  ''
      })
      this.waishangNum = this.waishang.length -1 
      console.log('waishang',this.waishangNum)
    },
    async reduceWaishang(index){
      if(this.waishangNum !== index && this.waishangNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.waishang.splice(index, 1);
      if(this.waishang.length === 0){
        this.hasWaishang = false
      }
      this.waishangNum = -1
    },
    // shuxue ----------------------------
    // 更改有无 shuxue
    changeHasShuxue(hasShuxue){
      this.hasShuxue = hasShuxue
      if(!hasShuxue){
        this.$nextTick(() => {
          this.shuxue = []
          this.shuxueNum = -1
        })
      }
      if(!this.shuxue.length){
        this.shuxue = [{
          time:null,
          reason: ''
        }]
        this.shuxueNum = 0
      }
    },
    // 确认了一人家庭疾病史
    confirmShuxue(index){
      if(!this.shuxueTime || !this.shuxueReason){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.shuxue[index] = {
        time: this.shuxueTime,
        reason:  this.shuxueReason
      }
      console.log('this.shuxue',this.shuxue)
      this.shuxueTime = null
      this.shuxueReason =  ''
      this.shuxueNum = -1
    },
    // 去编辑家庭疾病史
    toEditShuxue(index){
      let i = Number(index)
      this.shuxueNum = index
      this.shuxueReason = this.shuxue[index].reason
      this.shuxueTime =  this.shuxue[index].time
    },
    addShuxue(){
      console.log('shuxue befor',this.shuxueNum)
      if(this.shuxueNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.shuxue.push({
        time: null,
        reason:  ''
      })
      this.shuxueNum = this.shuxue.length -1 
      console.log('shuxue',this.shuxueNum)
    },
    async reduceShuxue(index){
      if(this.shuxueNum !== index && this.shuxueNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.shuxue.splice(index, 1);
      if(this.shuxue.length === 0){
        this.hasShuxue = false
      }
      this.shuxueNum = -1
    },
    // yongyao ----------------------------
    // 更改有无 yongyao
    changeHasYongyao(hasYongyao){
      this.hasYongyao = hasYongyao
      if(!hasYongyao){
        this.$nextTick(() => {
          this.yongyao = []
          this.yongyaoNum = -1
        })
      }
      if(!this.yongyao.length){
        this.yongyao = [{
          time:null,
          reason: ''
        }]
        this.yongyaoNum = 0
      }
    },
    // 确认了一人家庭疾病史
    confirmYongyao(index){
      if(!this.yongyaoTime || !this.yongyaoMedicinal){
        this.$message({
          message: '必填数据未填写！',
          type: 'wanning'
        });
        return false
      }
      this.yongyao[index] = {
        time: this.yongyaoTime,
        medicinal:  this.yongyaoMedicinal,
        medicineType:  this.medicineType
      }
      console.log('this.yongyao',this.yongyao)
      this.yongyaoTime = null
      this.yongyaoMedicinal =  ''
      this.yongyaoNum = -1
    },
    // 去编辑家庭疾病史
    toEditYongyao(index){
      let i = Number(index)
      this.yongyaoNum = index
      this.yongyaoMedicinal = this.yongyao[index].medicinal
      this.medicineType = this.yongyao[index].medicineType
      this.yongyaoTime =  this.yongyao[index].time
    },
    addYongyao(){
      console.log('yongyao befor',this.yongyaoNum)
      if(this.yongyaoNum !==  -1) {
        this.$message({
          message: '请先完成当前数据再增加！',
          type: 'wanning'
        });
        return false
      }
      // this.confirmJiazhu(this.jiazhu.length - 2)
      this.yongyao.push({
        time: null,
        medicinal:  '',
        medicineType: ''
      })
      this.yongyaoNum = this.yongyao.length -1 
      console.log('yongyao',this.yongyaoNum)
    },
    changeYongyaoTime(val){
      this.yongyaoTime = val
    },
    changeMedicineType(val){
      this.medicineType = val
    },
    async reduceYongyao(index){
      if(this.yongyaoNum !== index && this.yongyaoNum != -1 ){
        this.$message({
          message: '请先确认当前数据',
          type: 'wanning'
        });
        return false
      }
      const confirmResult=  await this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).catch(error => //只有一个return 只有一个参数 可以省略return {} ()
        console.log('error',error)
      )
      if(confirmResult!=='confirm'){
        return false
          return this.$message.info('已经取消删除')
      }
      this.yongyao.splice(index, 1);
      if(this.yongyao.length === 0){
        this.hasYongyao = false
      }
      this.yongyaoNum = -1
    },
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.detail
  padding 10px 0
  .detail-top
    margin-bottom 18px
    display flex
    justify-content space-between
    align-items center
    border-bottom 1px solid #D8D8D8
    padding 0 20px 10px
    .detail-top-left
      display flex
      align-items center
      cursor pointer
      .return-icon
        transform rotate(180deg)
        margin-right 13px
    .detail-top-right
      .top-link-item
        display inline-block
        padding 0 8px
        border-right 1px solid #D8D8D8 
        align-items center
        height 35px
        line-height 35px
        .top-link-icon
          display inline-block
          width 35px
          height 35px
          background #D8D8D8
          margin-right 8px
        .top-link-text
          display inline-block
          height 35px
          line-height 35px
          position relative
          top -10px
  .detail-con
    display flex
    padding 0 20px
    .detail-left,.detail-right
      flex 1
      margin-right 40px
      .detail-item
        display inline-block
        width 100%
        border-radius 5px
        border 1px solid #CED4DA
        margin-bottom 20px
        .detail-item-title
          height 40px
          line-height 40px
          padding 0 8px
          border-radius 5px 5px 0px 0px
          background #F0F0F5
          border-bottom 1px solid #CED4DA
          font-size 14px
          color #000
        .detail-inner
          padding 10px 20px
          .from-par
            display flex
            margin-bottom 0
            .tform-item
              flex 1
              margin-right 20px
              position relative
              >>>.el-form-item__label
                height 24px
                line-height 24px
              >>>.el-form-item__content
                height 30px
                .el-input__inner
                  height 30px
                  line-height 30px
                  border-radius 3px
                .el-date-editor--date
                  width 100%
                .distpicker-address-wrapper
                  display flex
                  width 100%
                  label
                    flex 1
                    margin-right 20px
                    select  
                      padding 0 10px
                      width 100%
                      height 30px
                      line-height 30px
                      border-radius 3px
                      border 1px solid #DCDFE6
                      outline none
                      margin-right 20px
                      color #606266
                      flex 1
                    &:placeholder
                      color #ccc
                .el-select
                  width 100%
            &:nth-child
              margin-right 0
            .must-go
              &:after
                content "*"
                display inline-block
                position absolute
                left -5px
                top 8px
                color #C40606
          .from-item-btns
            display flex 
            padding 0 40px
            justify-content space-around
            .from-item-btn
              padding 0 9px 0 17px
              height 34px
              line-height 34px
              background #F0F0F5
              border-radius 5px
              letter-spacing 8px
              margin 15px 0 10px
              cursor pointer
            .from-item-btn-blue
              background #009375
              color #fff
      .other-main
        padding 14px 20px
        border-bottom 1px solid #F0F0F5
        .other-title
          line-height 18px
          margin-bottom 15px
        .diseas
          margin-bottom  15px
          .diseas-item
            display inline-block
            padding 0 15px
            height 26px
            line-height 26px
            border-radius 3px
            margin 0  12px 4px 0
            cursor pointer
          .diseas-item-active
            background #E0F2EE
            color #009375 
        .person
          display flex
          .person-name
            margin-right 24px
          .person-items
            flex 1
            .person-item
              display flex
              align-items center
              margin-bottom 10px
              >>>.el-form-item__content
                height 30px
              >>>.el-input__inner
                height 30px
                line-height 30px
                font-size 12px
              .dieas-type
                width 150px
                margin-right 19px
                >>>.el-input__icon
                  line-height 30px
              .dieas-type-family
                width 80px
              .dieas-des
                width 40%
                >>>.el-input__prefix
                  top -5px
              .jiazhu-value
                height 30px
                font-size 12px
                line-height 30px
                color #4a4a4a
                border 1px solid #f0f0f0
                border-radius 5px
                text-indent 5px
              .handle-span
                display inline-block
                width 20px
                height 20px
                line-height 20px
                border 1px solid #BFBFBF
                border-radius 3px
                color #BFBFBF
                text-align center 
                margin-left 12px
                font-size 18px
                cursor pointer
              .handle-span-icon
                border none
              .person-item-tit
                margin-right 10px
        &:last-child
          border-bottom none
    .detail-right
      flex 1
      

</style>