<template>
  <div class="detail">
    <!-- detail-top -->
    <div class="detail-top">
      <div class="detail-top-left" @click="handleReturn">
        <img src="../../assets/images/chart_arrow.png" class="return-icon"  />
        用户管理/用户详情
      </div>
    </div>
    <div class="user-detail">
      <div class="detail-left">
        <!-- left-item -->
        <div class="left-item">
          <div class="item-title">
            <span class="title-con">
              <img :src="patinentDetail.headImgUrl" class="item-user" />
              {{patinentDetail.name}}
            </span>
            <span class="title-right " @click="toEdit"><span class="down-row">编辑</span></span>
          </div>
          <div class="user-des">
            <div class="user-item">
              <span class="user-item-name">姓名：</span>
              <span class="user-item-des">{{patinentDetail.name}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">性别：</span>
              <span class="user-item-des">{{patinentDetail.sex}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">生日：</span>
              <span class="user-item-des">{{patinentDetail.birthday}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">民族：</span>
              <span class="user-item-des">{{patinentDetail.nationstate}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">婚姻：</span>
              <span class="user-item-des">{{patinentDetail.marriage}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">职业：</span>
              <span class="user-item-des">{{patinentDetail.job}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">分组：</span>
              <span class="user-item-des">{{patinentDetail.groupName}}</span>
            </div>
            <div class="user-item">
              <span class="user-item-name">居住地：</span>
              <span class="user-item-des">{{patinentDetail.currentProvince}} - {{patinentDetail.currentCity}} - {{patinentDetail.currentArea}} - {{patinentDetail.currentAddress}}</span>
            </div>
          </div>
        </div>
        <!-- left-item -->
        <div class="left-item">
          <div class="item-title">
            <span class="title-con">
              既往病史
            </span>
            <div class="title-right">
              <!-- <span >编辑</span> -->
              <span class="down-row" @click="changeHideOne" v-if="!hideOne"> <img src="../../assets/images/blue_dwon.png" /> 收起</span>
              <span class="down-row" @click="changeHideOne" v-else> <img class="hideone-icon" src="../../assets/images/blue_dwon.png" /> 展开</span>
            </div>
          </div>
          <div class="user-des" :class="{'user-des-hide':hideOne}">
            <div class="table-item">
              <span class="table-left">疾病史</span>
              <el-table                                              
                :data="jibing"
                class="user-table">
                <el-table-column
                  prop="disease"
                  label="疾病">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="确诊时间">
                </el-table-column>
                <el-table-column
                  prop="yearlimit"
                  label="年限">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-item">
              <span class="table-left">手术史</span>
              <el-table                                              
                :data="shoushu"
                class="user-table">
                <el-table-column
                  prop="reason"
                  label="原因">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="时间">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-item">
              <span class="table-left">外伤史</span>
              <el-table                                              
                :data="waishang"
                class="user-table">
                <el-table-column
                  prop="reason"
                  label="原因">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="时间">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-item">
              <span class="table-left">输血史</span>
              <el-table                                              
                :data="shuxue"
                class="user-table">
                <el-table-column
                  prop="reason"
                  label="原因">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 用药史 -->
        <div class="left-item">
          <div class="item-title">
            <span class="title-con">
              用药史
            </span>
            <div class="title-right">
              <!-- <span >编辑</span> -->
              <span class="down-row" @click="changeHideTwo" v-if="!hideTwo"> <img src="../../assets/images/blue_dwon.png" /> 收起</span>
              <span class="down-row" @click="changeHideTwo" v-else> <img class="hideone-icon" src="../../assets/images/blue_dwon.png" /> 展开</span>
            </div>
          </div>
          <div class="user-des" :class="{'user-des-hide':hideTwo}">
            <div class="table-item">
              <el-table                                              
                :data="yongyao"
                class="user-table">
                <el-table-column
                  prop="medicineType"
                  label="类型">
                </el-table-column>
                <el-table-column
                  prop="medicinal"
                  label="药名">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="服药时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 家族史 -->
        <div class="left-item">
          <div class="item-title">
            <span class="title-con">
              家族史
            </span>
            <div class="title-right">
              <!-- <span >编辑</span> -->
              <span class="down-row" @click="changeHideThree" v-if="!hideThree"> <img src="../../assets/images/blue_dwon.png" /> 收起</span>
              <span class="down-row" @click="changeHideThree" v-else> <img class="hideone-icon" src="../../assets/images/blue_dwon.png" /> 展开</span>
            </div>
          </div>
          <div class="user-des" :class="{'user-des-hide':hideThree}">
            <div class="table-item">
              <el-table                                              
                :data="jiazhu"
                class="user-table">
                <el-table-column
                  prop="ref"
                  label="关系">
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="疾病类型">
                </el-table-column>
                <el-table-column
                  prop="disease"
                  label="疾病">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 遗传史 -->
        <div class="left-item">
          <div class="item-title">
            <span class="title-con">
              遗传史
            </span>
            <div class="title-right">
              <!-- <span >编辑</span> -->
              <span class="down-row" @click="changeHideFour" v-if="!hideFour"> <img src="../../assets/images/blue_dwon.png" /> 收起</span>
              <span class="down-row" @click="changeHideFour" v-else> <img class="hideone-icon" src="../../assets/images/blue_dwon.png" /> 展开</span>
            </div>
          </div>
          <div class="user-des" :class="{'user-des-hide':hideFour}">
            <div class="table-item">
              <el-table                                              
                :data="yichuan"
                class="user-table">
                <el-table-column
                  prop="ref"
                  label="关系">
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="疾病类型">
                </el-table-column>
                <el-table-column
                  prop="disease"
                  label="疾病">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-right">
        <div class="detail-nodata" v-if="replyList.length === 0 && visitList.length === 0">
          <img src="../../assets/images/no_data.png" class="no-data-img"/>
          <p>暂无数据</p>
        </div>
        <!-- right-item -->
        <div class="right-item"  v-if="replyList.length">
          <div class="right-item-top">
            <div class="right-item-main">
              <span class="right-user-img right-user-icon">
                <img :src="icons.iconExamGray" class="right-top-icon">
                </span>测评
            </div>
            <div class="right-top-right">
              <span class="right-top-link" @click="toAccess" v-has="'mgr.exam.assignExam'">+分配测评</span>
              <span v-if="rightShowTwo" class="right-top-link" @click="changeArrow(2)">收起
                <img  src="../../assets/images/arrow.png" class="right-arrow-img right-arrow-rotota" />
              </span>
              <span v-else class="right-top-link" @click="changeArrow(2)">展开
                <img src="../../assets/images/arrow.png" class="right-arrow-img" />
              </span>
            </div>
          </div>
          <div class="right-main" :class="{'right-user-sq':!rightShowTwo}" v-has="'mgr.examReport.query'">
              <div class="right-table">
                <div class="right-table-item right-table-title">
                  <span class="right-tab-one">项目</span>
                  <span class="right-tab-two">测评时间</span>
                  <span class="right-tab-two">预警状态</span>
                  <span class="right-tab-two">部门</span>
                  <span class="right-tab-three">操作</span>
                </div>
                <div class="right-table-item" v-for="(item,index) in replyList" :key="index">
                  <span class="right-tab-one">{{item.examName}}</span>
                  <span class="right-tab-two">{{item.examTime}}</span>
                  <span class="right-tab-two">{{item.warnStatusDesc}}</span>
                  <span class="right-tab-two">{{item.deptName}}</span>
                  <span class="right-tab-three link-color">查看</span>
                </div>
                
              </div>
          </div>
        </div>
        <!-- right-item -->
        <div class="right-item" v-if="visitList.length">
          <div class="right-item-top">
            <div class="right-item-main">
              <span class="right-user-img right-user-icon">
                <img :src="icons.iconVisitGray" v-if="icons" class="right-top-icon">
              </span>随访
            </div>
            <div class="right-top-right">
              <span class="right-top-link" @click="toPlan">+分配随访</span>
              <span v-if="rightShowThree" class="right-top-link" @click="changeArrow(3)">收起
                <img  src="../../assets/images/arrow.png" class="right-arrow-img right-arrow-rotota" />
              </span>
              <span v-else class="right-top-link" @click="changeArrow(3)">展开
                <img src="../../assets/images/arrow.png" class="right-arrow-img" />
              </span>
            </div>
          </div>
          <div class="right-main right-main-nopadding" v-has="'mgr.visitReport.query'" :class="{'right-user-sq':!rightShowThree}" >
            <div class="right-table">
              <div class="right-table-item right-table-title">
                <span class="right-tab-one">随访计划</span>
                <span class="right-tab-two">随访开始时间</span>
                <span class="right-tab-two">阶段</span>
                <span class="right-tab-two">状态</span>
                <span class="right-tab-two">分组</span>
                <span class="right-tab-three">操作</span>
              </div>
              <div class="right-table-item" v-for="(item,index) in visitList" :key="index">
                <span class="right-tab-one">{{item.name}}</span>
                <span class="right-tab-two">{{item.startWay}}</span>
                <span class="right-tab-two">{{item.warnStatusDesc}}</span>
                <span class="right-tab-two">{{item.startWay}}</span>
                <span class="right-tab-two">{{item.groupName}}</span>
                <span class="right-tab-three link-color">查看</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分配测评 -->
    <access-assign 
      v-if="assignVisable" 
      :assignUser="patinentDetail"
      @closeAssign="closeAssign"
    ></access-assign>
    <!-- 分配随访 -->
    <plan-assign
      v-if="assignPlanVisable" 
      :assignUser="patinentDetail"
      @closePlanAssign="closePlanAssign"
    ></plan-assign>
  </div>
</template>

<script>
import {
  regPatientDetail,
  regReplyList,
  reqVisitReport
} from '../../api/index'
import { powerRight } from '../../utils/index'
import AccessAssign from '@/components/UserManage/UserCompontent/AccessAssign'
import PlanAssign from '@/components/UserManage/UserCompontent/PlanAssign'
export default {
  data () {
    return {
      hideOne: false,
      hideTwo: false,
      hideThree: false,
      hideFour: false,
      patientId: null,
      patinentDetail: {},
      jiazhu: [],
      jibing: [],
      shoushu: [],
      shuxue: [],
      waishang: [],
      yichuan: [],
      yongyao: [],
      replyList: [], // 测评报告
      rightShowTwo: true,
      rightShowThree: true,
      visitList: [],
      icons: null,
      assignVisable: false, // 分配测评
      assignPlanVisable:false,// 随访
    }
  },
  components:{
    AccessAssign,
    PlanAssign
  },
  mounted() {
    if(this.$route.params.patientId === undefined){
      this.$message({
        message: '非法路径',
        type: 'error'
      }); 
      this.$router.push({ path: '/homePage'})
    }
    this.patientId = this.$route.params.patientId
    this.getPatientDetail()
    if(powerRight('mgr.examReport.query')){
      this.getReplylist()
    }
    this.icons = JSON.parse(sessionStorage.getItem('icons'))
  },
  methods:{
    handleReturn(){
      this.$router.go(-1);
    },
    changeHideOne(){
      this.hideOne = !this.hideOne
    },
    changeHideTwo(){
      this.hideTwo = !this.hideTwo
    },
    changeHideThree(){
      this.hideThree = !this.hideThree
    },
    changeHideFour(){
      this.hideFour = !this.hideFour
    },
    // 获取患者详情
    getPatientDetail(){
      regPatientDetail({
        id: this.patientId
      }).then(res => {
        console.log('获取患者详情',res)
        if(res && res.code === 0){
          this.patinentDetail = res.data
          // 获取随访报告
          this.getVisitReport(res.data.userId)
          this.jiazhu = res.data.jiazhu ? JSON.parse(res.data.jiazhu) : []
          this.jibing = res.data.jibing ? JSON.parse(res.data.jibing) : []
          this.shoushu = res.data.shoushu ? JSON.parse(res.data.shoushu) : []
          this.shuxue =  res.data.shuxue ? JSON.parse(res.data.shuxue) : []
          this.waishang = res.data.waishang ? JSON.parse(res.data.waishang) : []
          this.yichuan = res.data.yichuan ? JSON.parse(res.data.yichuan) : []
          this.yongyao = res.data.yongyao ? JSON.parse(res.data.yongyao) : []
          
        }else{
          this.$message({
            message: '获取患者详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 随访报告
    getVisitReport(id){
      console.log("随访报告")
      reqVisitReport({
        pageModel: {
          pageNo: 1,
          pageSize: 1000
        },
        userId: id
      }).then(res => {
        if(res && res.code === 0){
          this.visitList= res.data.list
          console.log('visitList',this.visitList)
        }else{
          this.$message({
            message: '获取随访报告失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 当前的测试报告 
    getReplylist(){
      const params = {
        pageNo: 1,
        pageSize: 1000,
        //auditStatus: this.replyStatus, // 1 已审核 / 0 未审核 / 2 审核不通过
        keyword: '',
        patientId: this.patientId
      }
      regReplyList(params).then(res => {
        if(res && res.code === 0){
          this.replyList= res.data.list
          console.log('replyList',this.replyList)
        }else{
          this.$message({
            message: '获取审核列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    toEdit(){
      this.$router.push('/EditUser/'+this.patientId);
    },
    // 右侧time收起展开
    changeArrow(num){
      if(!this.currentUser){
        return false
      }
      if(num === 2){
        this.rightShowTwo = !this.rightShowTwo
      }else if(num === 3){
        this.rightShowThree = !this.rightShowThree
      }
      console.log('this.rightShowThree',this.rightShowThree)
    },
    // 分配测评
    toAccess(){
      this.assignVisable = true
    },
    closeAssign(){
      this.assignVisable =  false
      this.getReplylist()
    },
    // 随访
    toPlan(){
      this.assignPlanVisable = true
    },
    closePlanAssign(){
      this.assignPlanVisable =  false
      this.getVisitReport(this.patientId)
    },
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.detail
  padding 10px 0
  .detail-top
    display flex
    justify-content space-between
    align-items center
    border-bottom 1px solid #D8D8D8
    padding 0 20px 10px
    .detail-top-left
      display flex
      align-items center
      cursor pointer
      .return-icon
        transform rotate(180deg)
        margin-right 13px
    .detail-top-right
      .top-link-item
        display inline-block
        padding 0 8px
        border-right 1px solid #D8D8D8 
        align-items center
        height 35px
        line-height 35px
        .top-link-icon
          display inline-block
          width 35px
          height 35px
          background #D8D8D8
          margin-right 8px
        .top-link-text
          display inline-block
          height 35px
          line-height 35px
          position relative
          top -10px
  .user-detail
    display flex
    .detail-left
      display inline-block
      margin 0 25px 0 20px
      padding-right 50px
      border-right 1px solid #D8D8D8
      padding-top 18px
      overflow auto
      .left-item
        width 454px
        border-radius 5px
        border 1px solid #CED4DA
        margin-bottom 30px
        .item-title
          font-size 12px
          padding 12px 14px
          border-bottom 1px solid #CED4DA
          background #f0f0f5
          position relative
          .title-con
            display flex
            align-items center
            .item-user
              display inline-block
              width 40px
              height 40px
              border-radius 50%
              margin-right 20px
          .title-right
            position absolute
            right 25px
            top 50%
            margin-top -10px
            cursor pointer
            .down-row
              display inline-block
              margin-left 19px
              color #009375
              img 
                position relative
                top 4px
              .hideone-icon
                transform rotate(180deg)
        .user-des
          transition all 0.3s
          padding 10px 27px
          line-height 24px
          .user-item
            padding 6px 0
            display flex
            .user-item-name
              display inline-block
              min-width 56px
              text-align right
              color #CED4DA
              margin-right 15px
          .table-item
            display flex
            margin-bottom 25px
            .table-left
              display inline-block
              width 60px
              color #9E9E9E
              font-size 14px
              line-height 38px
            .user-table
              >>>td
                padding 3px 0
                height 25px
                line-height 25px
                border none
              >>>th
                padding 3px 0
                height 25px
                line-height 25px
                background #F0F0F5
                border none
        .user-des-hide
          height 0
          padding 0 27px
          overflow hidden
          transition all 0.3s
    .detail-right 
      flex 1
      margin-right 25px
      .detail-nodata
        margin-top 90px
        text-align center
        line-height 40px
        font-size 16px
        color #9E9E9E
      .right-item
        margin 15px 5px 0 
        width 100%
        border-radius 5px
        overflow hidden
        .right-item-top
          font-size 12px
          height 58px
          line-height 58px
          padding 0 20px 0 14px
          background #f0f0f0
          border 1px solid #CED4DA
          display flex
          justify-content space-between
          .right-item-main
            padding 9px 0
            display flex
            align-items center
            color #4A4A4A
            .right-user-img
              width 40px
              height 40px
              border-radius 50%
              margin-right 20px
            .right-user-icon
              border-radius 5px
              background #D8D8D8
              .right-top-icon
                width 26px
                margin 7px
          .right-top-right
            .right-top-link
              margin 0 10px
              display inline-block
              cursor pointer
              .right-arrow-img
                position relative
                top 4px
                transition all .5s ease
              .right-arrow-rotota
                transform rotate(180deg) 
                transition all .5s ease
        .right-main
          padding 10px 25px 20px 
          font-size 14px
          max-height auto
          .user-item
            padding 5px
            line-height 24px
            .user-item-title
              display inline-block
              width 58px
              text-align right
              color #9E9E9E
              margin-right 15px
              transition all 1s ease
          .right-table
            .right-table-item
              line-height 22px
              margin 7px 0
              display flex
              .right-tab-one
                display inline-block
                width 225px
              .right-tab-two
                display inline-block
                flex 1
              .right-tab-three
                display inline-block
                width 60px
            .right-table-title
              margin-bottom 20px
              font-weight bold!important
          .fllow-top
            border-bottom 1px dashed #ccc
            padding 10px 13px 0 
            .fllow-top-item
              display flex
              line-height 20px
              margin 10px 0
              .fllow-name
                display inline-block
                width 44px
                margin-right 30px
                text-align right
                font-weight bold
            .fllow-top-two
              display flex
              .fllow-top-item
                width 50%
                display flex
                flex-wrap wrap
                .fllow-main-flex
                  display inline-block
                  width 210px
                  margin-top 13px
                .fllow-main-clear
                  clear both
                  height 20px
          .fllow-main
            padding 13px 13px 30px
            clear both
            .fllow-main-item
              .fllow-main-flex
                display inline-block
                float left
                width 50%
                margin 10px 0
                .fllow-item-icon
                  position relative 
                  top 4px
                  margin-right 5px
              .fllow-main-bold
                font-weight bold
                margin-bottom 10px
        .right-main-nopadding
          padding 0
          overflow hidden 
          min-height 10px
        .right-user-sq
          transition all 1s ease
          max-height 0
          padding-bottom 0
          clear both
</style> 
