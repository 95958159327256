<template>
  <div class="assess">
    <follow-nav routeLink="questionFrom"></follow-nav>
    <div class="qadetail-return">
      <img src="../../assets/images/return_arrow.png" class="return-icon" @click="returnQues" />
      <span class="qaReturn" @click="returnQues">返回</span>
    </div>
    <div class="question">
      <div class="quest-left">
        <div class="quest-title">问卷基本信息</div>
        <div class="quest-left-item">
          <div class="quest-item-title">问卷名称</div>
          <div class="qeust-item-con">
            <el-input  placeholder="" v-model="questionnaireName" class="quest-item-from" />
          </div>
        </div>
        <div class="quest-left-item">
          <div class="quest-item-title">问卷类型</div>
          <div class="qeust-item-con">
            <el-select placeholder="全部分组" v-model="questionnaireType" class="quest-item-from">
              <el-option value="1" label="调查">调查</el-option>
              <el-option value="2"  label="评价">评价</el-option>
            </el-select>
          </div>
        </div>
        <div class="quest-left-item" v-for="(item,i) in addItem" :key="i">
          <div class="quest-item-title">{{item.newProjectName}}</div>
          <div class="qeust-item-con">
            <el-radio-group class="quest-item-radi"  v-if="item.projectType === '1'">
              <el-radio :label="rad" v-for="(rad,radidx) in item.userInputContent" :key="radidx">{{rad}}</el-radio>
              <!-- <el-radio :label="6">备选项</el-radio>
              <el-radio :label="9">备选项</el-radio> -->
            </el-radio-group>
            <div class="quest-item-radi" v-if="item.projectType === '2'">
              <span class="quest-checkbox " v-for="(rad,radidx) in item.userInputContent"  :key="radidx"><span class="quest-checkbox-b"></span>{{rad}}</span>
            </div>
            <el-input v-if="item.projectType === '3'" disabled  placeholder="" class="quest-item-from" />
            <textarea  v-if="item.projectType === '4'" disabled placeholder="" class="quest-item-from quest-item-area" />
            <img src="../../assets/images/edit_icon.png" class="edit-icon" @click="editItem(i)" />
            <img src="../../assets/images/delete_red.png" class="edit-icon" @click="educeItem(i)" /> 
          </div>
        </div>
        
        
        <!-- <div class="quest-left-item" v-for="(item,i) in addItem" :key="i">
          <div class="quest-item-title">{{item.newProjectName}}</div>
          <div class="qeust-item-con">
            <el-input v-if="item.projectType === '3'"  placeholder="" class="quest-item-from" />
            <textarea v-if="item.projectType === '4'"  placeholder="" class="quest-item-from" />
            <img src="../../assets/images/edit_icon.png" class="edit-icon" />
            <img src="../../assets/images/delete_red.png" class="edit-icon" /> 
          </div>
        </div> -->
        <span class="satge-add" @click="handleToAddItem">
          <img src="../../assets/images/add.png"  class="add-img"  > 新增录入项目
        </span>
        <!-- 新增项目 -->
        <div class="add-items" v-if="itemVisable">
          <div class="add-title">新增项目</div>
          <el-input class="add-input" placeholder="项目名" v-model="newProjectName"></el-input>
          <el-select  v-model="projectType" @change="changeItemType($event)" placeholder="项目类型" class="add-input">
            <el-option value="1" label="单选">单选</el-option>
            <el-option value="2" label="多选">多选</el-option>
            <el-option value="3" label="单行输入">单行输入</el-option>
            <el-option value="4" label="多行输入">多行输入</el-option>
          </el-select>
          <div class="add-select">
            <template v-if="projectType === '1'">
              <div div class="add-sel-item" v-for="(item,i) in userInputContent" :key="i">
                <span class="sel-icon"></span>
                <input class="sel-text" type="text" :value="item" @change="handleItemText($event,i)" />
                <span class="sel-handl-icon" @click="addItemVal(i)">+</span>
                <span class="sel-handl-icon"  v-if="i !== 0" @click="plusItemVal(i)">-</span>
              </div>
            </template>
            <template v-if="projectType === '2'">
              <div div class="add-sel-item" v-for="(item,i) in userInputContent" :key="i">
                <span class="sel-icon sel-icon-check"></span>
                <input class="sel-text" type="text" :value="item" @change="handleItemText($event,i)" />
                <span class="sel-handl-icon" @click="addItemVal(i)">+</span>
                <span class="sel-handl-icon"  v-if="i !== 0" @click="plusItemVal(i)">-</span>
              </div>
            </template>
            <template v-if="projectType === '3'">
              <div div class="add-sel-item">
                <input class="sel-text" disabled type="text"  />
              </div>
            </template>
            <template v-if="projectType === '4'">
              <div div class="add-sel-item">
                <textarea class="sel-textarea" disabled type="text"  ></textarea>
              </div>
            </template>
          </div>
          <div class="dialog-btns">
            <span class="dialog-btn dialog-cancle-btn"  @click="handleCancleItem" >取消</span>
            <span class="dialog-btn" @click="handleAddItem" >确定</span>
          </div>
        </div>
        <!-- 新增项目 结束 -->
        <div class="dialog-btns dialog-btns-ss">
          <span class="dialog-btn dialog-cancle-btn" @click="returnQues">取消</span>
          <span class="dialog-btn" @click="addQuestionForm">确定</span>
        </div>
      </div>
      <div class="quest-right">
        <div class="right-types">
          <span class="type-item" :class="{'type-item-active': currentdetailedOptionsType == '1'}" @click="addQuestion('1')">单选</span>
          <span class="type-item" :class="{'type-item-active': currentdetailedOptionsType == '2'}" @click="addQuestion('2')">多选</span>
          <span class="type-item" :class="{'type-item-active': currentdetailedOptionsType == '3'}" @click="addQuestion('3')">单文本输入</span>
          <span class="type-item" :class="{'type-item-active': currentdetailedOptionsType == '4'}" @click="addQuestion('4')">多文本输入</span>
        </div>
        <div class="right-items">
          <div class="right-item">
            <p class="right-item-par">描    述：</p>
            <textarea class="right-item-area" v-model="questionnaireDescription"></textarea>
          </div>
          <div class="right-item">
            <p class="right-item-par">指导语：</p>
            <textarea class="right-item-area" v-model="questionnaireInstruction"></textarea>
          </div>
          <div class="right-item">
            <p class="right-item-par">问卷题目：</p>
            <!-- question-item -->
            <div 
              class="question-item" 
              :class="{'question-item-active': quidx == currentQuestionIdx}" 
              v-for="(question,quidx) in titleDtos" 
              @click="changeQuestion(quidx)"
              :key="quidx"
            >
              <img src="../../assets/images/delete_red.png" class="quest-del" @click="delQuest(quidx)" />
              <div class="question-con">
                <span class="question-num">#{{quidx+1}}</span>
                <span class="quest-type" v-if="question.detailedOptionsType == '1'">单选</span>
                <span class="quest-type" v-if="question.detailedOptionsType == '2'">多选</span>
                <span class="quest-type" v-if="question.detailedOptionsType == '3'">单文本</span>
                <span class="quest-type" v-if="question.detailedOptionsType == '4'">多文本</span>
                <div class="question-main">
                  <el-input class="quest-input" @blur="changeQuestTitle($event)" v-model="question.title"  placeholder="标题2" />
                  <div v-for="(send,senIdx) in question.options" :key="senIdx">
                    <div v-if=" question.detailedOptionsType === '1' || question.detailedOptionsType === '2'">
                      <div class="answer-item" >
                        <el-input  class="quest-input answer-input" v-model.trim="send.optionContent"></el-input>
                        <el-input-number v-model.number="send.optionScore" placeholder="分值" class="answer-btn" />
                        <el-input-number v-model.number="send.skipTheQuestionTo" placeholder="跳题" class="answer-btn" />
                        <el-popover
                          placement="top-start"
                          width="85"
                          trigger="hover"
                          >
                          <div class="company-navs">
                            <p class="company-nav" @click="addOption(quidx,senIdx,1)">一级选项</p>
                            <p class="company-nav" @click="addOption(quidx,senIdx,2)">二级选项</p>
                          </div>
                          <span class="answer-add" slot="reference">+</span>
                        </el-popover>
                        <span class="answer-add" @click="reduceOne(quidx,senIdx)">-</span>
                      </div>
                      <div class="answer-item answer-item-second" v-for="(sub,subIdx) in send.subOptions" :key="subIdx" >
                        <el-input  class="quest-input answer-input" v-model.trim="sub.optionContent"></el-input>
                        <el-input-number  placeholder="分值" v-model.number="sub.optionScore" class="answer-btn" v-if="subIdx != 0" />
                        <span  class="answer-btn-zw" v-if="subIdx != 0"></span>
                        <!-- <span class="answer-add" slot="reference">+</span> -->
                        <span class="answer-add" @click="reduceTwo(quidx,senIdx,subIdx)" v-if="subIdx != 0">-</span>
                      </div>
                    </div>
                    <div class="answer-item" v-if=" question.detailedOptionsType === '3'">
                      <el-input  class="quest-input answer-input" disabled v-model.trim="question.options[0].optionContent"></el-input>
                      <el-input-number v-model.number="send.optionScore"  placeholder="分值" class="answer-btn" />
                      <el-input-number v-model.number="send.skipTheQuestionTo" placeholder="跳题" class="answer-btn" />
                    </div>
                    <div class="answer-item" v-if=" question.detailedOptionsType === '4'">
                      <textarea  class="quest-input answer-textarea" disabled v-model.trim="question.options[0].optionContent"></textarea>
                      <el-input-number v-model.number="send.optionScore" placeholder="分值" class="answer-btn" />
                      <el-input-number v-model.number="send.skipTheQuestionTo" placeholder="跳题" class="answer-btn" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- question-item end -->
            <!-- 单文本输入 -->
            <!-- <div class="question-item">
              <div class="question-con">
                <span class="question-num">#1</span>
                <div class="question-main">
                  <el-input class="quest-input"  placeholder="标题"></el-input>
                  <div class="answer-item">
                    <el-input  class="quest-input answer-input"></el-input>
                    <el-input-number  placeholder="分值" class="answer-btn" />
                    <el-input-number  placeholder="跳题" class="answer-btn" />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- 多文本输入 -->
            <!-- <div class="question-item">
              <div class="question-con">
                <span class="question-num">#1</span>
                <div class="question-main">
                  <el-input class="quest-input"  placeholder="标题"></el-input>
                  <div class="answer-item">
                    <textarea  class="quest-input answer-textarea"></textarea>
                    <el-input-number  placeholder="分值" class="answer-btn" />
                    <el-input-number  placeholder="跳题" class="answer-btn" />
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import {
  reqAddQuestion,
  reqQuestionDetail
} from '../../api/index'
import FollowNav from '@/components/FollowUp/FollowNav'
export default {
  components: {FollowNav },
  data () {
    return {
      id: null,
      questionnaireName: '',
      questionnaireType: '1',
      questionnaireInstruction: '',
      questionnaireDescription: '',
      newProjectName:'', // 新增项的名
      projectType: '1',
      addItem: [],
      // ---------右侧 
      currentdetailedOptionsType: null , // 当前题目的类型 // 1：单选 2：多选 3：单行文本4：多行文本,默认单选
      titleDtos: [], // 题目
      addQuestItem:{
        detailedOptionsType: null, // 1：单选 2：多选 3：单行文本4：多行文本,默认单选
        title: null,
        options:[
          {
            optionContent: null, // 选项内容
            optionScore: null, // 选项分值
            skipTheQuestionTo: null, // 跳题至,
            subOptions: []
          }
        ],
        skipTheQuestionTo: null, // 跳题
      },
      answerObj:{ // 一级
        optionContent: '', // 选项内容
        optionScore:'', // 选项分值
        skipTheQuestionTo: null, // 跳题至,只有一级跳题
      },
      currentQuestion:{},
      currentQuestionIdx: 0, // 当前是第几题
      itemIdx: null,
      itemVisable: false,
      inputsType: '2', //1：纯数字2：字符串,默认是纯数字
      userInputContent: [
        ''
      ]
    }
    
  },
  mounted(){
    if(this.$route.params.questionId !== undefined){
      this.id = this.$route.params.questionId
      this.getDetail()
    }
  },
  methods:{
    // 详情
    getDetail(){
      reqQuestionDetail(this.id).then(res => {
        if(res && res.code === 0){
          if(res.data){
            this.questionnaireName = res.data.questionnaireName
            this.questionnaireType = res.data.questionnaireType
            this.questionnaireInstruction = res.data.questionnaireInstruction
            this.questionnaireDescription = res.data.questionnaireDescription
            this.titleDtos = res.data.titleDtos
            let addItem = res.data.item
            for(let i=0 ;i<addItem.length; i++){
              addItem[i].userInputContent = JSON.parse( addItem[i].userInputContent)
            }
            this.addItem = addItem
          }
        }else{
          this.$message({
            message: '获取详情失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    
    // 更改选项类型
    changeItemType(val){
      console.log('changeItemType',val)
      if(val === '1' || val === '2'){
        this.userInputContent = [""]
      }
      // if(val === '3' || val === '4'){

      // }
    },
    // 修改选项值 
    handleItemText(e,idx){
      this.$set(this.userInputContent,idx,(e.target.value).trim())
    },
    addItemVal(idx){
      if(this.userInputContent[idx].trim() === ''){
        this.$message({
          message: '请先填写完当前项',
          type: 'wanring'
        }); 
        return false
      }
      this.userInputContent.push('')
    },
    // 去新增选项
    handleToAddItem(){
      this.newProjectName = ''
      this.projectType = '1'
      this.userInputContent = ['']
      this.itemVisable = true
    },
    // 新增选项
    handleAddItem(){
      if(this.newProjectName.length <2 || this.newProjectName.length >12){
        this.$message({
          message: '请输入2~12个字符的项目名',
          type: 'wanring'
        }); 
        return false
      }
      if((this.projectType === '1' || this.projectType === '2')  && !this.userInputContent[0] ){
        this.$message({
          message: '请先新增选项',
          type: 'wanring'
        }); 
        return false
      }
      if(this.itemIdx != null){
        this.$set(this.addItem,this.itemIdx,
        {
          inputsType: this.inputsType ,
          newProjectName: this.newProjectName,
          projectType:this.projectType,
          userInputContent: this.userInputContent
        })
      }else{
        this.addItem.push({
          inputsType: this.inputsType ,
          newProjectName: this.newProjectName,
          projectType:this.projectType,
          userInputContent: this.userInputContent
        })
      }
      this.itemVisable = false
      console.log('this.addItem',this.addItem,this.userInputContent[0])
      this.inputsType = '2'
      this.newProjectName = ''
      this.projectType = '1'
      this.userInputContent = []
    },
    // 取消新增/修改项目
    handleCancleItem(){
      this.itemVisable = false
      console.log('this.addItem',this.addItem,this.userInputContent[0])
      this.inputsType = '2'
      this.newProjectName = ''
      this.projectType = '1'
      this.userInputContent = []
    },
    //, 修改问卷已经加的项目
    editItem(idx){
      this.itemVisable = true
      this.itemIdx = idx
      let currentData = this.addItem[idx]
      console.log('currentData',currentData)
      this.newProjectName = currentData.newProjectName
      this.projectType = currentData.projectType
      this.userInputContent = currentData.userInputContent
    },
    // 删除问卷已经加的项目
    educeItem(idx){
      this.addItem.splice(idx,1)
    },
    plusItemVal(idx){
      this.userInputContent.splice(idx,1)
    },
    returnQues(){
      this.$router.push({path: '/questionFromList'})
    },
     // 新增题目 
    addQuestion(type){
      let addObj = _.cloneDeep(this.addQuestItem)
      addObj.detailedOptionsType = type
      this.titleDtos.push(addObj)
      this.currentQuestionIdx = this.titleDtos.length -1
      this.currentQuestion = this.titleDtos[this.titleDtos.length -1]
    },
    changeQuestTitle(val){
      console.log('ala',val.target.value)
    },
    // 删除题目 
    delQuest(idx){
      this.titleDtos.splice(idx,1)
      this.currentQuestionIdx = 0
    },
    // 添加一级子
    addOption(idx,senIdx,cj){
      console.log('idx,senIdx,cj',idx,senIdx,cj,this.titleDtos[idx].options)
      let currentOption = this.titleDtos[idx].options
      let tData = this.titleDtos[idx].options[senIdx]
      console.log('tData',tData)
      if(!tData.optionContent){
        this.$message({
          message: '请先填写上一选项内容',
          type: 'wanring'
        }); 
        return false
      }
      let curData = _.cloneDeep(this.titleDtos[idx])
      if(cj === 1){
        curData.options.push({
          optionContent: null, // 选项内容
          optionScore: null, // 选项分值
          skipTheQuestionTo: null, // 跳题至,
          subOptions: []
        })
        
      }else if(cj === 2){
        if(curData.options[senIdx].subOptions.length ==0){ // 第一次加二级
          curData.options[senIdx].subOptions.push({
            type: 1,
            optionContent: null, // 选项内容
            optionScore: null, // 选项分值
          },{
            optionContent: null, // 选项内容
            optionScore: null, // 选项分值
          })
        }else{
          curData.options[senIdx].subOptions.push({
            optionContent: null, // 选项内容
            optionScore: null, // 选项分值
          })
        }
      }
      this.$set(this.titleDtos,idx,curData)
      console.log('curData',this.titleDtos)
    },
    // 减去
    reduceOne(idx,senIdx){
      let tData = this.titleDtos[idx]
      if(tData.options.length === 1){
        return false
      }
      tData.options.splice(senIdx,1)
      this.$set(this.titleDtos,idx,tData)
    },
    reduceTwo(idx,senIdx,threeIdx){
      let tData = this.titleDtos[idx]
      tData.options[senIdx].subOptions.splice(threeIdx,1)
      if(tData.options[senIdx].subOptions.length == 1){
        tData.options[senIdx].subOptions = []
      }
      this.$set(this.titleDtos,idx,tData)
    },
    // 点击题切换题
    changeQuestion(idx){
      this.currentQuestionIdx  = idx
    },
    // 最终新增
    addQuestionForm(){
      let addItem = _.cloneDeep(this.addItem)
      for(let i=0 ;i<addItem.length; i++){
        addItem[i].userInputContent = JSON.stringify( addItem[i].userInputContent)
      }
      let params = {
        questionnaireName: this.questionnaireName,
        questionnaireType: this.questionnaireType,
        questionnaireInstruction: this.questionnaireInstruction,
        questionnaireDescription: this.questionnaireDescription,
        titleDtos: this.titleDtos,
        item: addItem
      }
      if(this.id){
        params.id = this.id
      }
      console.log('params',params)
        reqAddQuestion(params).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '操作成功',
              type: 'success'
            });    
            this.returnQues()
          }else{
            this.$message({
              message: '新增失败,'+res.msg,
              type: 'error'
            }); 
          }
        })
      
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  height calc(100% - 50px)
  .qadetail-return
    width 100%
    display block
    height 30px
    display flex
    align-items center
    line-height 30px
    font-size 14px
    margin 10px 0 30px
    cursor pointer
    .return-icon
      width 30px
      margin 0 13px 0 0
  .question
    display flex
    height calc(100% - 50px)
    .quest-left
      width 400px
      .quest-title
        width 320px
        font-size 18px
        padding-bottom 15px
        border-bottom 1px solid #d8d8d8
        font-weight bold
        margin-bottom 10px
      .quest-left-item
        margin-bottom 20px
        position relative
        .quest-item-title
          margin-bottom 8px
          color #009375
        .qeust-item-con
          position relative
          display flex
          .quest-item-from
            width 320px
            height 30px
            line-height 30px
            >>>.el-input
              height 34px
              line-height 30px
            >>>input
              height 34px
              line-height 30px
          .quest-item-radi
            width 320px
            padding-top 8px
            min-height 30px
            .quest-checkbox
              margin-right 30px
              color #666
              cursor pointer
              .quest-checkbox-b
                display inline-block
                width 15px
                height 15px
                border-radius 3px
                border 1px solid #cecece
                margin-right 10px
          .quest-item-area
            height 49px
            border 1px solid #DCDFE6
            resize none
          .edit-icon
            width 23px
            height 23px
            margin-left 10px
            display none
          &:hover
            .edit-icon
              display inline-block
              cursor pointer
      .satge-add
        display inline-block
        width 140px
        height 40px
        line-height 40px
        text-align center
        border-radius 5px
        border 1px solid #D8D8D8
        margin 0 90px
        cursor pointer
        img
          position relative
          margin-right 10px
      .add-items
        width 320px
        border-radius 5px
        border 1px solid #CED4DA
        margin 30px 0
        padding 13px 7px
        .add-title
          color #009375
          margin-bottom 20px
        .add-select
          margin-bottom 20px
          .add-sel-item
            display flex
            align-items center
            .sel-icon
              display inline-block
              width 18px
              height 18px
              border-radius 50%
              box-sizing border-box
              border 1px solid #9E9E9E
              margin-right 10px
            .sel-icon-check
              border-radius 3px
            .sel-text
              flex 1
              height 30px
              line-height 30px
              border-bottom 1px solid #CED4DD
              margin-bottom 8px
            .sel-handl-icon
              width 17px
              height 17px
              line-height 14px
              text-align center
              border 1px solid  #8995AC
              color #8995AC
              border-radius 50%
              margin-left 5px
            .sel-textarea
              height 60px
              width 100%
              border 1px solid #CED4DD
              resize none
        .add-input
          width 303px
          height 30px
          line-height 30px
          margin-bottom 20px
          >>>.el-input
            height 30px
            line-height 30px
          >>>input
            height 30px
            line-height 30px
      .dialog-btns
        margin-top 20px
        text-align center
        .dialog-btn
          display inline-block
          width 100px
          height 34px
          line-height 34px
          border-radius 5px
          background #009375 
          color #fff
          text-align center
          cursor pointer
        .dialog-cancle-btn
          background #F0F3F6
          color #4A4A4A
          margin-right 54px
      .dialog-btns-right
        text-align right
      .dialog-btns-ss
        padding-right 120px
    .quest-right
      flex 1
      margin-right 80px
      .right-types  
        padding-bottom 12px
        border-bottom 1px solid #d8d8d8
        .type-item
          display inline-block
          padding 0 20px
          height 30px
          line-height 30px
          text-align center
          border-radius 5px
          border 1px solid #009375
          font-size 14px
          color #009375
          margin-right 22px
          cursor pointer
        .type-item-active
          background #009375
          color #fff
      .right-items
        height calc(100% - 100px)
        overflow auto
        padding-left 40px
        .right-item
          margin-top 10px
          padding-right 30px
          .right-item-par
            margin-bottom 15px
          .right-item-area
            border 1px solid #dadada
            height 86px
            padding 5px
            width 100%
            border-radius 5px
          .question-item
            border-radius 5px
            background #F1F1F1
            padding 12px 20px
            margin-bottom 15px
            position relative
            .quest-del
              display none
              position absolute
              left -30px
              top 10px
            .question-con
              display flex
              position relative
              .question-num
                width 60px
              .quest-type
                position absolute
                left -3px
                height 18px
                font-size 12px
                line-height 18px
                border-radius 10px
                background #D8D8D8
                padding 0 10px
                top 30px
              .question-main
                flex 1
                .quest-input
                  height 30px
                  border-radius 5px
                  border 1px solid #B3DFD6
                  width 100%
                  >>>.el-input
                    height 28px
                    line-height 28px
                    border none
                    outline none
                  >>>input
                    height 28px
                    line-height 28px
                    border none
                    outline none
                    background #fff
                .answer-textarea
                  height 60px
                  background #fff
                  resize none
                .answer-item
                  display flex
                  margin-top 9px
                  align-items flex-end
                  .answer-btn
                    width 60px
                    height 30px
                    line-height 30px
                    border-radius 5px
                    border 1px solid #DADADA
                    background #ffffff
                    text-align center
                    margin-left 5px
                    >>>input
                      width 58px
                      height 28px
                      line-height 28px
                      border none
                      outline none
                      padding 0
                    >>>.el-input-number__decrease
                      display none!important
                    >>>.el-input-number__increase
                      display none!important
                  .answer-btn-zw
                    background transparent
                    border none
                  .answer-add
                    display inline-block
                    margin-left 10px
                    font-size 28px
                    color #009375
                    line-height 30px
                .answer-item-second
                  padding-left 60px

          .question-item-active
            background rgba(224, 242, 238, 0.6)
            border 1px solid #99D1C6
            .quest-del
              display inline-block
            .question-con
              .quest-type
                background #009375
                color #fff
.el-popover
  width 85px
  .company-navs
    padding 6px 4px 0
    width 85px
    .company-nav
      display inline-block
      width 77px
      height 20px
      line-height 20px 
      margin-bottom 6px
      padding 0 5px
      cursor pointer
      font-size 12px
      &:hover
        background #D0E6E6
        border-radius 3px
        color #009375
</style>