<template>
  <!-- 批量导入 table -->
  <el-dialog
    title="批量导入"
    class="batch-upload"
    :visible=true
    @close="closeAddUser"
    width="780px">
    <div class="current-excel">
      <img src="../../../assets/images/excel.png" class="excel-icon" />
      <div class="excel-name">{{excelName}}</div>
      <img src="../../../assets/images/delete_blue.png" class="excel-icon" @click="closeExcel" />
    </div>
    <div class="batch-table">
      <el-table                                              
        :data="batchDatas"
        size="small"
        class="user-table">     
        <el-table-column
          type="index"
          label="编号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="电话">
        </el-table-column>
        <el-table-column
          prop="diagnose"
          label="诊断">
        </el-table-column>
        <el-table-column
          prop="diagnoseTime"
          label="诊断时间">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope"> <!-- slot-scope="scope" -->
             <!-- @click.native.prevent="deleteRow(scope.$index, scope)" -->
            <el-button
              type="text"
              @click.native.prevent="deleteRow(scope)"
              class="table-green-btn"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddUser">取消</span>
      <span class="dialog-btn" @click="confirmAdd">确定</span>
    </div>
  </el-dialog>
  <!-- 导入文件table end -->
</template>

<script>
import {
  regBatchAddPatient
} from '../../../api/index'
export default {
  props:{
    menuData: Array,
    excelName: String
  },
  data () {
    return {
      batchDatas: this.menuData
    }
  },
  methods:{
    closeAddUser(){
      this.$emit('closeAddUser')
    },
    // 删除整个excel
    closeExcel(){
      this.$emit('closeExcel')
    },
    // 删除单个
    deleteRow(data){
      const index = data.$index
      this.batchDatas.splice(index,1)
    },
    // 确定新增
    confirmAdd(){
      let data =  this.batchDatas
      console.log('data',data)
      for(let i=0; i<data.length; i++){
        if(data[i].mobilePhone.length  != 11){
          this.$message({
            message: data[i].name + '手机号长度不对',
            type: 'error'
          }); 
          return false
        }
        if(!data[i].name){
          this.$message({
            message: '名字不能为空',
            type: 'error'
          }); 
          return false
        }
      }
      const bathdata = JSON.stringify(data)
      regBatchAddPatient({
        data:bathdata
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '新增成功',
            type: 'success'
          }); 
          this.$emit('batchAddSuccess')
        }else{
          this.$message({
            message: '批量新增失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.batch-upload
  .current-excel
    width 100%
    height 65px
    opacity 1
    background #F0F3F6
    border-radius 5px
    display flex
    align-items center
    padding 0 32px 0 21px
    .excel-name
      flex 1
      margin-left 25px
      color #999
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 70px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
      margin-top 32px
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .batch-table
    width 100%
    font-size 14px
    overflow auto
    margin-top 20px
    /deep/.has-gutter
      th
        background #F8F9FB
    >>>tr
      line-height 18px
      margin 4px 0
      >>>.el-button--text
        color #4a4a4a!important
      &:hover>td 
        background #E0F2EE
        color #00A389
        font-weight  bolder
        .table-green-btn
          color #00A389!important
          font-weight 700!important
    >>>.el-table__row
      td
        padding 0
    >>>.table-handles
      white-space nowrap
    .table-green-btn
      font-size 14px
      color #4a4a4a!important
      font-weight bold
    .popover-more
      margin-left 10px!important
      position relative
      top -4px
</style>