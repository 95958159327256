<template>
  <el-dialog
    :title="diaTitle"
    class="dialog-main"
    :visible=true
    @close="closeAddDept"
    width="600px">
    <div class="dialog-con">
      <div class="dialog-dept-item">
        <p class="dialog-dept-name"><span class="red-text">*</span>部门名称</p>
        <input type="text" v-model="deptName" class="dialog-dept-text" />
      </div>
      <!-- <div class="dialog-dept-item">
        <p  class="dialog-dept-name">部门ID</p >
        <input type="text" class="dialog-dept-text" />
      </div> -->
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name">上级部门</p >
        <!-- <el-select v-model="deptParentId" placeholder="请选择" @click="handleDeptParentId">
          <el-option
            v-for="item in classifyOption"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-select 
          ref="selectTree" 
          v-model="detpParentId" 
          placeholder="请选择" 
          popper-class="popperTreeSelect"
        >
          <el-option :value="detpParentId" :label="parentName">
            <el-tree 
              ref="tree" 
              :data="parentOrgArr" 
              default-expand-all
              :props="defaultProps" 
              node-key="id"
              :expand-on-click-node=false
              style="min-height:100px;"
              @node-click="handleDeptParentId"
            >
              <span class="custom-tree-node" slot-scope="{ node }">
                  <span >{{ node.label }}</span>
              </span>
            </el-tree>
          </el-option>
        </el-select>
      </div>
      <!-- <div class="dialog-dept-item" v-if="currentOrgName">
        <p  class="dialog-dept-name">上级机构</p >
        <span>{{currentOrgName}}</span>
      </div>  -->
      <div class="dialog-dept-item">
        <p  class="dialog-dept-name">部门负责人</p >
        <el-select v-model="leaderId"  placeholder="请选择" @click="changeLeaderId">
          <el-option
            v-for="item in userDatas"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="dialog-btns">
      <span class="dialog-btn dialog-cancle-btn" @click="closeAddDept">取消</span>
      <span class="dialog-btn" @click="handleAddDept">确定</span>
    </div>
  </el-dialog>
  <!-- 新建部门 end --> 
</template>
<script>
import _ from "lodash";
import {
  regAddDept,
  regDetailDept,
  regEditDeptt
} from '../../../api/index'
export default {
   props:{
    userDatas:Array,
    currentOrgId: Number,
    childOrg: Array,
    currentDeptId: Number,
    mainOrgDeptTree: Object,
    orgName: String
  },
  data(){
    return{
      diaTitle: '新建部门',
      theChildOrg: this.childOrg,
      parentOrgArr: [], // 机构和部门组成的树
      deptName: '', // 部门名称
      detpParentId: null, // 选择器中的父级id
      parentId: null, // 上级部门id
      orgId: null, // 上级组织id
      parentName : '', // 机构部门树选中name
      deptParentId:'', // 新建部门,上级部门id
      leaderId: '', // 部门领导Id
      defaultProps: {
        children: 'children',
        label: 'name'
      },  
      classifyOption:[
        {
          name:'公司组织名',
          id: 1,
          children:[
          ]
        }        
      ],
    }
  },
 
  mounted(){
    console.log('新建部门1',this.mainOrgDeptTree,this.theChildOrg)
    this.getParentOrgArr()
    if(this.currentDeptId){
      this.getDetpDetail()
    }
    if(this.currentDeptId){
      this.diaTitle = '编辑部门'
    }else{
      this.diaTitle = '新建部门'
    }
  },
  methods:{
    // 获取机构和部门组成的树
    getParentOrgArr(){
      let parentOrgArr =  _.cloneDeep(this.theChildOrg) 
      for(let i=0; i< parentOrgArr.length; i++){
        if(parentOrgArr[i].orgName != undefined){
          this.theChildOrg[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      console.log('parentOrgArr',parentOrgArr,this.mainOrgDeptTree)
      parentOrgArr.unshift(this.mainOrgDeptTree)
      for(let i=0; i< parentOrgArr.length; i++){
        if(parentOrgArr[i].orgName != undefined){
          parentOrgArr[i].name = parentOrgArr[i].orgName
        }
        //console.log('this.theChildOrg[i].name',this.theChildOrg[i].name,parentOrgArr[i].orgName)
      }
      this.parentOrgArr =  parentOrgArr
      console.log('this.parentOrgArr',this.parentOrgArr)
    },
    // 获取部门详情
    getDetpDetail(){
      regDetailDept({
        id: this.currentDeptId
      }).then(res => {
        if(res.code === 0){
          console.log('获取部门详情',res.data)
          this.deptName = res.data.name
          this.detpParentId = res.data.parentId
          this.parentName = res.data.parentName
          if(this.detpParentId == 0){
            this.parentName = this.orgName
          }
          this.leaderId = res.data.leaderId
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取部门详情失败！'
          });
        }
      })
    },
    // 更改上级部门id
    handleDeptParentId(data) {
      console.log('handleNodeClick',data)
      if(data.orgName){ // 为组织
        this.orgId = data.id
        this.parentId = null
      }else{
        this.orgId = null
        this.parentId = data.id
      }
      this.value = data.label;
      this.$refs.selectTree.blur();
      this.detpParentId = data.id
      this.parentName = data.name
      //this.deptParentId = Number(data.id) 
    },
    // 部门负责人ID
    changeLeaderId(data){
      console.log('部六',data)
      this.leaderId = data.id
    }, 
    handleAddDept(){
      let params = {
        leaderId: this.leaderId,
        name: this.deptName,
        parentId: this.parentId, // 上级部门id
        orgId: this.orgId
        // parentId: this.deptParentId
      }
      if(this.currentDeptId){
        params.id = this.currentDeptId
        regEditDeptt(params).then(res => {
          if(res.code === 0){
            this.$message({
              message: '编辑部门成功',
              type: 'success'
            });
            this.$emit('addDeptSuccess')
            this.closeAddDept()
          }else{
            this.$notify.error({
              title: '错误',
              message: '编辑部门失败！'+res.msg
            });
            
          }
        })
      }else {
        regAddDept(params).then(res => {
          if(res.code === 0){
            this.$message({
              message: '新增部门成功',
              type: 'success'
            });
            this.$emit('addDeptSuccess')
            this.closeAddDept()
          }else{
            this.$notify.error({
              title: '错误',
              message: '新建部门失败！'+ res.msg
            });
            
          }
        })
      }
      
    },
    // 关闭
    closeAddDept(){
      console.log('closeAddDept')
      this.$emit('closeAddDept')
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-main
  .dialog-con
    padding 30px
    .dialog-dept-item
      width 462px
      margin-bottom 22px
      display flex
      align-items center
      .dialog-dept-name
        width 120px
        margin-bottom 11px
      .el-select
        width 460px
      .dialog-dept-text
        width 100%
        height 38px
        line-height 38px
        border 1px solid #DADADA
        border-radius 5px
        padding 0 10px
      .dialog-dept-select
        width 100%
        border none
      .upload-demo
        >>>.el-upload-dragger
          width 200px
          height 89px
          color #009375
          border 1px dashed #009375
          display flex
          flex-direction column
        .upload-img
          width 20px
          margin 15px auto 12px
      .qualif-img 
        position relative
        margin-right 10px
        .qualif-image
          max-width 200px
          max-height 100px
        .close-qualif
          position absolute 
          width 20px
          top -8px
          right -8px
          background #fcfcfc
    .modify-bars
      margin-bottom 50px
      display flex
      align-items center
      .modify-bar
        display inline-block
        width 20px
        height 20px
        line-height 20px
        border-radius 25px
        background #D8D8D8
        color #fff
        margin 0 20px
        text-align center
      .modify-bar-ac
        width 55px
        background #E0F2EE
        color #009375
      .bar-link
        flex 1
        height 1px 
        background #ccc
    .modify-success
      text-align center
      .modify-par
        color #9e9e9e
        margin 15px 0 22px
      .modify-sencend
        font-size 12px
        color #009375
        margin-bottom 60px
    .dialog-member
      display flex
      .dialog-member-half
        flex 1
        margin-right 18px
        .dialog-dept-item
          width 300px
  .dialog-btns
    text-align center
    .dialog-btn
      display inline-block
      width 100px
      height 34px
      line-height 34px
      border-radius 5px
      background #009375 
      color #fff
      text-align center
      cursor pointer
    .dialog-cancle-btn
      background #F0F3F6
      color #4A4A4A
      margin-right 54px
  .dialog-btns-right
    text-align right
  >>>.el-dialog__header
    border-bottom 1px solid #d8d8d8
    font-size 16px
    font-weight bolder
/deep/.el-select-dropdown__item
  padding 0
  height auto
/deep/.el-select-dropdown__item.selected
  background red
/deep/.el-select-dropdown__item.hover, .el-select-dropdown__item:hover
  background #fff
</style>