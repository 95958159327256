<template>
  <div class="follow-drawer">
    <div class="qadetail-return"  @click="returnList">
      <img src="../../assets/images/return_arrow.png" class="return-icon" />
      <span >返回报告列表</span>
    </div>
    <div class="tqo">
      <span class="tqo-title">随访内容</span>
      <span>{{name}}</span>
    </div>
    <div class="examdr-main examdr-main-quest">
      <div class="examdr-quest"> 
        <div class="question-item" v-for="(item,index) in questions" :key="index">
          <div class="question-title">
            <span style="color:red;">{{index+1}}、</span>{{item.examName}}
            <template v-if="item.examType == 'choice_single'">(单选)</template>
            <template v-if="item.examType == 'choice_mut'">(多选)</template>
          </div>
          <!-- <div class="question-option" >
            <input type="text" class="quest-input"  />
          </div>
          <div class="question-option" >
            <textarea class="quest-input quest-area"></textarea> question-option-select
          </div> -->
          <div class="question-options"   v-if="item.examType == 'choice_single'">
            <div class="question-option " :class="isSelectQuest(index,queIdx)"  v-for="(ques,queIdx) in item.examAnswer.options" :key="queIdx"  @click="selectAns(index,item,ques,queIdx)">
            {{ques}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="batch-btns">
      <el-button class="batch-btn" size="small" type="" @click="returnList">取消</el-button>
      <el-button class="batch-btn success-btn" size="small" @click="handleAnswer"  type="">确定</el-button>
    </div>
  </div>
</template>

<script>

import {
  regSubmitReply
} from '../../api/index'
export default {
  props:{
    startAccessDetail:Object,
    replyId: Number
  },
  mounted(){
    console.log('startAccessDetail',this.startAccessDetail)
    this.setData()
  },
  data () {
    return {
      name: '',
      questions: [],
      replyDataJson: []
    }
    
  },
  methods:{
    setData(){
      this.name = this.startAccessDetail.examForm.name
      this.questions = this.startAccessDetail.examForm.questions
    },
    returnList(){
      this.$emit('returnFoll')
    },
    // 选择题
    selectAns(index,item,ques,queIdx){
      if(index > this.replyDataJson.length ){
        this.$message({
          message: '请先填做上一题',
          type: 'wanring'
        }); 
        return false
      }
      console.log('index,item,ques,queIdx',index,item,ques,queIdx)
      if(item.examType === 'choice_single'){ // 单选题
        let quest = {
          examType:item.examType,
          examName:item.examName,
          replyAnswer:[{
            idx: queIdx,
            content: item.examAnswer.options[queIdx]
          }]
        }
        if(index + 1 <=  this.replyDataJson.length  ){  // 
          this.replyDataJson.splice(index,1,quest)
        }else{
           this.replyDataJson.push(quest)
        }
      }
      if(item.examType === 'choice_mut'){ //  多选题
        let quest = {
          examType:item.examType,
          examName:item.examName,
          replyAnswer:[{
            idx: queIdx,
            content: item.examAnswer.options[queIdx]
          }]
        }
        if(index + 1 <=  this.replyDataJson.length  ){  
          let  hasThis = this.replyDataJson[index].replyAnswer.findIndex(item => item.idx === queIdx)
          console.log('hasThis',hasThis)
          if(hasThis > -1){ // 多选已经有这选项了了
            if(this.replyDataJson[index].replyAnswer.length >1){
              this.replyDataJson[index].replyAnswer.splice(hasThis,1)
            }else{
              this.$message({
                message: '至少选择一个选项',
                type: 'wanring'
              }); 
            }
            
          }else{
            this.replyDataJson[index].replyAnswer.push({
              idx: queIdx,
              content: item.examAnswer.options[queIdx]
            })
          }
        }else{
          this.replyDataJson.push(quest)
        }
      }
    },
    isSelectQuest(index,idx){
      return {
        'question-option-select': this.replyDataJson[index] && this.replyDataJson[index].replyAnswer && (this.replyDataJson[index].replyAnswer.filter(item => item.idx === idx).length > 0)
      }
    },
    // 做题
    handleAnswer(){
      if(this.replyDataJson.length<this.questions.length){
        this.$message({
          message: '请先做完题',
          type: 'wanring'
        });
        return false
      }
      regSubmitReply({
        replyId: this.replyId,
        replyDataJson: JSON.stringify(this.replyDataJson),
        replyVariableJson: ''
      }).then(res => {
        if(res && res.code === 0){   
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.$emit('accessSuccess')
        }else{
          this.$message({
            message: '提交失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    }
  },
  computed:{
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.follow-drawer
  width 614px
  .qadetail-return
    width 100%
    display block
    height 70px
    display flex
    align-items center
    line-height 70px
    font-size 14px
    cursor pointer
    .return-icon
      width 30px
      margin 0 13px 0 40px
  .tqo
    font-size 18px
    padding 20px 35px 30px
    display flex
    border-bottom 1px solid #D8D8D8
    .tqo-title
      font-size 16px
      width 80px
  .examdr-main
    margin-bottom 14px
    .examdr-title
      font-size 14px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 30px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 15px
          .question-num
            color #FF3243
        .question-option
          width 380px
          padding 5px 0 5px 10px
          margin 10px 30px 0
          border-radius 5px
          cursor pointer
          .quest-input
            height 30px
            border 1px solid #ececec
          .quest-area
            height 60px
        .question-option-select
          background red
          color #009375
          background #e0f2ee
  .batch-btns
    padding 20px 0
    display fle
    justify-content space-around
    .batch-btn
      width 70px
      height 34px
      border-radius 5px
      background #F0F3F6
      border none
      margin 0 100px
    .success-btn
      background #009375
      color #fff
</style>