<template>
  <div class="follow-drawer">
    <div class="drawer-top">
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">计划名称</span>
          <span class="drawer-text">{{name}}</span>
        </div>
      </div>
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">随访类型</span>
          <span class="drawer-text" v-if="type === 'stage_visit'">阶段随访</span>
          <span class="drawer-text" v-if="type === 'time_visit'">时间线随访</span>
        </div>
      </div>
      <div class="drawer-item">
        <div class="drawer-it">
          <span class="drawer-title">开始时间</span>
          <!-- <div v-for="(time,i) in startTimes" :key="i">
            <span class="drawer-text" v-if="time.code === visitStartDate">{{time.name}}</span>
          </div> -->
          <span class="drawer-text">{{visitStartDate}}</span>
        </div>
        <div class="drawer-it">
          <span class="drawer-title">预计结束时间</span>
          <span class="drawer-text">{{visitEndDate}}</span>
        </div>
      </div>
    </div>
    <div class="follow-detail">
      <div class="follow-detail-tab">
        <span class="follow-detail-t " :class="{'follow-detail-tact':tseeType ===1}" @click="changeSeeType(1)">随访计划</span>
        <span class="follow-detail-t" :class="{'follow-detail-tact':tseeType ===0}" @click="changeSeeType(0)">随访记录</span>
      </div>
      <div class="follow-det-con">
        <div class=follow-eds v-if="tseeType == 1">
          <img src="../../assets/images/follow_line.png" class="follow-line-img" />
          <!-- follow-eds-item -->
          <div class="follow-eds-item" v-for="(item,index) in planTypeDtoList" :key="index">
            <div class="follow-eds-left">
              <div class="follow-eds-name" v-if="type === 'stage_visit'">阶段{{item.sequence}}</div>
              <div class="follow-eds-name" v-if="type === 'time_visit'">第{{item.sequence}}天</div>
              <div class="follow-eds-cir">
                <span class="follow-eds-cricle"></span>
                <span class="follow-des-line"></span>
              </div>
            </div>
            <div class="follow-eds-right">
              <div class="eds-right-con"  v-if="type === 'stage_visit'">
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">开始时间：</span>
                    <!-- <div class="eds-text">{{item.startWay}}周</div> -->
                    <div v-for="(time,i) in startTimes" :key="i">
                      <div class="eds-text" v-if="item.startWay === time.code">{{time.name}}</div>
                    </div>
                  </div>
                  <div class="eds-flex">
                    <span class="eds-name">阶段周期：</span>
                    <div class="eds-text">{{item.stageCycle}}周</div>
                    
                  </div>
                </div>
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">频   率：</span>
                    <!-- <div class="eds-text">{{item.frequency}}周</div> -->
                    <div v-for="(time,i) in frequencyList" :key="i">
                      <div class="eds-text" v-if="item.frequency === time.code">{{time.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">阶段描述：</span>
                    <div class="eds-text">{{item.comment}}</div>
                  </div>
                </div>
              </div>
              <div class="eds-right-blue">
                <div class="eds-blue-title">随访内容</div>
                <div class="eds-blue-con">
                  <div class="eds-blue-item" v-for="(plan,j) in item.planContentDtoList" :key="j">
                    <span class="blue-item-one">{{j+1}}</span>
                    <span class="blue-item-one" v-if="plan.type ==='remind'">提醒</span>
                    <span class="blue-item-one" v-if="plan.type ==='scale'">量表</span>
                    <span class="blue-item-one" v-if="plan.type ==='questionnaire'">问卷</span>
                    <!-- <span class="blue-item-one">{{plan.type}}</span> -->
                    <span class="blue-item-one blue-item-more" v-if="plan.type ==='remind'">{{plan.planRemindDto.content}}</span>
                    <span class="blue-item-one blue-item-more" v-else >{{plan.contentName}}</span>
                    <span class="blue-item-one">{{plan.times}}次</span>
                  </div>
                  <!-- <div class="eds-blue-item">
                    <span class="blue-item-one">1</span>
                    <span class="blue-item-one">量表</span>
                    <span class="blue-item-one blue-item-more">心理健康测评量表</span>
                    <span class="blue-item-one">1次</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=follow-eds v-if="tseeType == 0">
          <img src="../../assets/images/follow_line.png" class="follow-line-img" />
          <!-- follow-eds-item -->
          <div class="follow-eds-item" v-for="(item,index) in planTypeRecords" :key="index">
            <div class="follow-eds-left">
              <div class="follow-eds-name" v-if="type === 'stage_visit'">阶段{{item.sequence}}</div>
              <div class="follow-eds-name" v-if="type === 'time_visit'">第{{item.sequence}}天</div>
              <div class="follow-eds-cir">
                <span class="follow-eds-cricle"></span>
                <span class="follow-des-line"></span>
              </div>
            </div>
            <div class="follow-eds-right">
              <div class="eds-right-con"  v-if="type === 'stage_visit'">
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">开始时间：</span>
                    <div v-for="(time,i) in startTimes" :key="i">
                      <div class="eds-text" v-if="item.startWay === time.code">{{time.name}}</div>
                    </div>
                    
                  </div>
                  <div class="eds-flex">
                    <span class="eds-name">阶段周期：</span>
                    <div class="eds-text">{{item.stageCycle}}周</div>
                  </div>
                </div>
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">频   率：</span>
                    <!-- <div class="eds-text">{{item.frequency}}</div> -->
                    <div v-for="(time,i) in frequencyList" :key="i">
                      <div class="eds-text" v-if="item.frequency === time.code">{{time.name}}</div>
                    </div>
                    <!-- <div v-for="(time,i) in frequencyList" :key="i">
                      <div class="eds-text" v-if="item.frequency === time.code">{{time.name}}</div>
                    </div> -->
                  </div>
                </div>
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">阶段描述：</span>
                    <div class="eds-text">{{item.comment}}</div>
                  </div>
                </div>
              </div>
              <div class="eds-right-blue" v-for="(cycle,cycleIdx) in item.planTypeCycleRecordVos" :key="cycleIdx">
                <div class="eds-blue-title">随访内容{{cycleIdx+1}}</div>
                <div class="eds-blue-con">
                  <div class="eds-blue-item" v-for="(plan,j) in cycle.planContentRecordVos" :key="j">
                    <span class="blue-item-one">{{j+1}}</span>
                    <span class="blue-item-one" v-if="plan.contentType ==='remind'">提醒</span>
                    <span class="blue-item-one" v-if="plan.contentType ==='scale'">量表</span>
                    <span class="blue-item-one" v-if="plan.contentType ==='questionnaire'">问卷</span>
                    <span class="blue-item-one blue-item-more" v-if="plan.contentType ==='remind'">{{plan.planRemindVo.content}}</span>
                    <span class="blue-item-one blue-item-more" v-else >{{plan.contentName}}</span>
                    <span class="blue-item-one">{{plan.stageStartTime}}</span>
                    <span class="blue-item-one" v-if="plan.operation == 1"><img src="../../assets/images/lisiting.png" class="table-icon" /></span>
                    <!-- <span class="blue-item-one"><img src="../../assets/images/click.png" class="table-icon" /></span> -->
                    <span class="blue-item-one" v-if="plan.status == 1"><img src="../../assets/images/success_icon.png" class="table-icon" /></span>
                    <span class="blue-item-one"  v-if="plan.status == 2 && plan.contentType ==='scale' && hasReply" @click="handleToQuestion(plan)" ><img src="../../assets/images/edit_icon.png" class="table-icon" /></span>
                    <span class="blue-item-one"  v-if="plan.status == 2 && plan.contentType ==='questionnaire' && hasQuery" @click="handleToQuestion(plan)" ><img src="../../assets/images/edit_icon.png" class="table-icon" /></span>
                    
                    <span class="blue-item-one" v-if="plan.status == 3"  ><img src="../../assets/images/click.png" class="table-icon" /></span>
                    <!-- @click="handleToQuestion(plan,item)" -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- <div class="follow-eds-item">
            <div class="follow-eds-left">
              <div class="follow-eds-name">阶段一</div>
              <div class="follow-eds-cir">
                <span class="follow-eds-cricle"></span>
                <span class="follow-des-line"></span>
              </div>
            </div>
            <div class="follow-eds-right">
              <div class="eds-right-con">
                <div class="eds-item">
                  <div class="eds-flex">
                    <span class="eds-name">开始时间：</span>
                    <div class="eds-text">分配即开始</div>
                  </div>
                  <div class="eds-flex">
                    <span class="eds-name">阶段周期：</span>
                    <div class="eds-text">2周</div>
                  </div>
                </div>
              </div>
              <div class="eds-right-blue">
                <div class="eds-blue-title">随访内容</div>
                <div class="eds-blue-con">
                  <div class="eds-blue-item">
                    <span class="blue-item-one">1</span>
                    <span class="blue-item-one">量表</span>
                    <span class="blue-item-one blue-item-more">心理健康测评量表</span>
                    <span class="blue-item-one">1次</span>
                  </div>
                  <div class="eds-blue-item">
                    <span class="blue-item-one">1</span>
                    <span class="blue-item-one">量表</span>
                    <span class="blue-item-one blue-item-more">心理健康测评量表</span>
                    <span class="blue-item-one">1次</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  reqConstants,
  regSubmitReply
} from '../../api/index'
import { powerRight } from '../../utils/index'
export default {
  props:{
    recordDetail: Object,
    planDetail: Object,
    seeType: Number // 人工随访还是查看，0人工随访 1查看
  },
  data () {
    return {
      tseeType: this.seeType,
      startTimes: [], // 开始时间列表
      frequencyList: [], // 随访阶段频率
      contentList: [], // 随访内容类型
      name: '',
      type: '',
      visitStartDate: '',
      visitEndDate: '',
      planTypeDtoList: null, // 随访计划
      planTypeRecords: null, // 随访记录
      curentStart: null,
      planTypeCycleRecordVos: []
    }
  },
  mounted(){
    console.log('tseeType',this.tseeType)
    this.toInit()
    
  },
  computed:{
    hasQuery(){
      return powerRight('mgr.visitQuestionnaire.query')
    },
    hasReply(){
      return powerRight('mgr.visitExam.reply')
    }
  },
  methods:{
    toInit(){
      reqConstants('StageStartWayEnum').then(res => {
        console.log('res',res)
        if(res && res.code === 0){
          console.log('获取分组列表',res)
          this.startTimes = res.data
          this.toInit2()
        }else{
          this.$message({
            message: '获取分组列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    toInit2(){
      // 阶段频率
      reqConstants('StageFrequencyEnum').then(res => {
        console.log('StageFrequencyEnum',res)
        if(res && res.code === 0){
          this.frequencyList = res.data
          this.toInit3()
        }else{
          this.$message({
            message: '获取随访阶段频率失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    toInit3(){
      // 阶段频率
      reqConstants('ContentTypeEnum').then(res => {
        console.log('ContentTypeEnum',res)
        if(res && res.code === 0){
          this.contentList = res.data
          this.setData()
        }else{
          this.$message({
            message: '获取随访内容类型失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    setData(){
      console.log('this.recordDetail',this.recordDetail,this.tseeType)
      console.log('this.planDetail',this.planDetail)
      this.visitStartDate = this.recordDetail.visitStartDate
      this.visitEndDate = this.recordDetail.visitEndDate
      this.name = this.recordDetail.name
      this.type = this.recordDetail.type
      this.planTypeRecords = this.recordDetail.planTypeRecords
      this.planTypeCycleRecordVos = this.recordDetail.planTypeCycleRecordVos
      if(this.tseeType == 0){
        this.planTypeDtoList =  this.recordDetail.planTypeRecords
      }else{
        this.planTypeDtoList = this.planDetail.planTypeDtoList
      }
    },
    changeSeeType(type){
      this.tseeType = type
      this.setData()
    },
    // 去做题
    handleToQuestion(plan,item = null){
      this.$emit('toDoExam',plan,item)
    }
  },
  watch:{
    seeType(val){
      this.tseeType = val
    },
    planDetail(){
      this.setData()
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.follow-drawer
  width 614px
  .drawer-top
    padding 35px
    font-size 16px
    .drawer-item
      display flex
      .drawer-it
        flex 1
        display flex
        margin-bottom 25px
        .drawer-title
          width 110px
  .follow-detail
    .follow-detail-tab
      border-bottom 1px solid #D8D8D8
      padding-left 43px
      .follow-detail-t
        display inline-block
        width 104px
        height 40px
        line-height 40px
        border-radius 5px 5px 0px 0px
        font-size 16px
        text-align center
        cursor pointer
      .follow-detail-tact
        background #E0F2EE
        color #009375
        font-weight bold
    .follow-det-con
      padding 10px 35px 10px 25px
      .follow-eds
        position relative
        .follow-line-img
          position absolute
          top 0
          width 1px
          left 10px
          height 100%
        .follow-eds-item
          display flex
          margin-bottom 33px
          .follow-eds-left
            width 82px
            .follow-eds-name
              font-size 14px
              margin-bottom 14px
            .follow-eds-cir
              display flex 
              align-items center
              .follow-eds-cricle
                display inline-block
                width 20px
                height 20px
                opacity 1
                border-radius 50%
                background #fff
                box-sizing border-box
                border 6px solid #009375
                box-shadow 0px 4px 6px 0px rgba(23, 39, 117, 0.2)
                position relative
                z-index 2
              .follow-des-line
                width 50px
                height 4px
                border-radius 0 4px 4px 0
                background #009375
          .follow-eds-right
            flex 1
            border-radius 5px
            border 1px solid #D0E6E6
            padding-top 10px
            .eds-right-con
              padding-top 10px
              font-size 14px
              .eds-item
                display flex
                margin-bottom 11px
                line-height 18px
                .eds-flex
                  display flex
                  flex 1
                  .eds-name
                    width 100px!important
                    margin-right 20px
                    text-align right
                    color #050919
                  .eds-text
                    color #1F1F1F
                    word-wrap normal
            .eds-right-blue
              border-radius 5px
              background #E0F2EE
              padding 10px 16px
              margin-top 5px
              .eds-blue-title
                font-size 16px
                color #050919
                line-height 24px
              .eds-blue-con
                background #E0F2EE
                .eds-blue-item
                  padding 10px 5px
                  text-align center
                  line-height 18px
                  font-size 14px
                  color #1F1F1F
                  display flex
                  background #E0F2EE
                  border-radius 5px
                  .blue-item-one
                    width 50px
                  .blue-item-more
                    flex 1
                  &:nth-of-type(even)
                    background #fff
</style>