<template>
  <div class="assess">
    <follow-nav routeLink="followPlan"></follow-nav>
    <div class="manage-search">
      <div class="search-left">
        <div class="search-input-out">
          <el-input class="search-input" placeholder="搜索患者姓名" v-model="name" />
          <img src="../../assets/images/search.png" class="search-icon" @click="getPlanLists" />
        </div>
        <el-date-picker
          v-model="startDate"
          type="date"
          class="search-date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="开始日期">
        </el-date-picker>
        <el-date-picker
          v-model="endDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          class="search-date"
          placeholder="结束日期">
        </el-date-picker>
        <!-- <el-select class="search-select" placeholder="状态">
          <el-option
            v-for="item in statusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
       <div class="search-right">
        <span class="search-btn" @click="handleGroup" v-has="'mgr.visitGroup.query'"><img src="../../assets/images/container.png" class="search-btn-icon"> 分组管理</span>
        <span class="search-btn" @click="addPlan" v-has="'mgr.visitPlan.createPlan'" ><img src="../../assets/images/add.png" class="search-btn-icon"> 新增计划</span>
      </div>
    </div>
    <div class="access-con">
      <el-table
        v-has="'mgr.visitPlan.query'"
        :data="ReportListData"
        class="table-div"
        style="width: 100%"
        height="calc(100vh -300px)"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="name"
          label="随访计划名称">
        </el-table-column>
        <el-table-column
          prop="groupName"
          label="分组">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template slot-scope="scope">
            <el-switch
              class="applic-switch"
              v-model="scope.row.status"
              @change="changeApplic($event,scope.row)"
              active-color="#009375"
              :active-value="1"
              :inactive-value="0"
              inactive-color="#ccc">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="population"
          label="参与人数">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发布时间">
        </el-table-column>
        <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button class="group-link" v-has="'mgr.visitPlan.updatePlan'" @click="editExam(scope.row)" style="color:#4a4a4a" type="text" size="small">编辑</el-button>
              <el-button class="group-link " @click="seeExam(scope.row)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">查看</el-button>
              <el-popover
              v-has="'mgr.visitPlan.bindPlanPerson'"
              popper-class="table-popover"
              placement="bottom"
              width="90"
              style="padding:0;min-width:90px!important;"
              trigger="click">
              <div class="handle-con" style="line-height:28px;padding:5px 0;">
                <div class="handle-popver-item" @click="toDistribute(scope.row)" :style="scope.row.status == '0'? 'opacity:0.5': ''" style="font-size:12px;padding:0 10px;cursor:pointer;">添加人员</div>
                <div class="handle-popver-item" :style="scope.row.status == '0'? 'opacity:0.5': ''" style="font-size:12px;padding:0 10px;cursor:pointer;" @click="getCode(scope.row)" v-has="'mgr.visitQrcode.add'">二维码添加</div>
              </div>
              <el-button class="group-link" :style="scope.row.status == '0'? 'opacity:0.5': ''"  slot="reference" style="color:#4a4a4a;margin-right:10px;" type="text" size="small" >分配</el-button>
              </el-popover>
            </template>
        </el-table-column>
      </el-table>
      <div class="pager" v-has="'mgr.visitPlan.query'">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
        <el-pagination
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
        </div>
      <!-- 分组 -->
    </div>
    <group-dailog  
      @closeGropuList="closeGropuList"
      v-if="showFollowGroup"
    ></group-dailog>
    <distribute
      @closeDistribute="closeDistribute"
      :distriData="distriData"
      v-if="showDistribute"
    ></distribute>
    <allocation-plan-dia
      :distriData="distriData"
      @closePlanDialog="closePlanDialog"
      v-if="showPlanDailog"
    ></allocation-plan-dia>
    <el-drawer
      title="计划详情"
      class="exam-drawer"
      size="614"
      @close="closeDetail"
      :visible="detailDrawerVisable">
        <plan-detail
        :planDetail="planDetail"
        ></plan-detail>
    </el-drawer>
  </div>
</template>

<script>
import FollowNav from '@/components/FollowUp/FollowNav'
import GroupDailog from '@/components/FollowUp/GroupDailog'
import Distribute from '@/components/FollowUp/Distribute'
import AllocationPlanDia from '@/components/FollowUp/AllocationPlanDia'
import PlanDetail from '@/components/FollowUp/PlanDetail'
import { powerRight } from '../../utils/index'
import {
  reqVisitPlan,
  reqSwitchPlan,
  reqPlanDetail
} from '../../api/index'
export default {
  components: {FollowNav, GroupDailog, Distribute, AllocationPlanDia, PlanDetail },
  data () {
    return {
      startDate: null,
      endDate: null,
      name: '',
      showFollowGroup:false,
      ReportListData: [],
      pageNo: 1,
      pageSize:  20,
      total: 0,
      showDistribute: false, // 分配计划
      showPlanDailog: false, // 邀请二维码
      userName: '',
      detailDrawerVisable: false, // 详情抽屉
      planDetail: null, // 详情
      distriData: null, // 分配的当前的详情
    }
    
  },
  mounted(){
    if(powerRight('mgr.visitPlan.query')){
      this.getPlanLists()
    }
  },
  methods:{
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getPlanLists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getPlanLists()
    },
    // 列表
    getPlanLists(){
      const params = {
        endTime: this.endDate,
        groupId: "",
        pageModel: {
          pageNo: this.pageNo,
          pageSize: this.pageSize
        },
        startTime: this.startDate,
        name: this.name
       }
      reqVisitPlan(params).then(res => {
        if(res && res.code === 0){
          this.total =  res.data.totalCount    
          this.ReportListData = res.data.list     
        }else{
          this.$message({
            message: '获取列表失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 更改状态
    changeApplic(val,data){
      console.log('ee',val,data)
      reqSwitchPlan({
        id: data.id,
        status: val
      }).then(res => {
        if(res && res.code === 0){
          this.getPlanLists()  
        }else{
          this.$message({
            message: '更改失败,'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // table选择框修改
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分组管理 
    handleGroup(){
      console.log('handleGroup')
      this.showFollowGroup = true
    },
    // 新增计划
    addPlan(){
      this.$router.push({path: '/addFollow'})
    },
    // 关闭新增分组
    closeGropuList(){
      this.showFollowGroup = false
    },
    // 查看
    seeExam(data){
      reqPlanDetail(data.id).then(res => {
        if(res && res.code === 0){
          this.planDetail = res.data
          this.detailDrawerVisable = true
        }else{
          this.$message({
            message: '获取详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 编辑
    editExam(data){
      this.$router.push({ path: `/addFollow/${data.id}` })
    },
    // 分配
    toDistribute(data){
      if(data.status == 0){
        return false
      }
      this.showDistribute = true
      this.distriData = data
      console.log('分配人员',data)
    },
    closeDistribute(){
      this.showDistribute = false
    },
    // 生成二维码
    getCode(data){
      if(data.status == 0){
        return false
      }
      console.log('生成二维码',data)
      this.distriData = data
      this.showPlanDailog = true
    },
    closePlanDialog(){
      this.showPlanDailog  = false
    },
    // 关详情
    closeDetail(){
      this.detailDrawerVisable = false
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-date
      width 136px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .access-con
    .table-div
      height calc(100vh - 250px)
      overflow auto
      .applic-switch
        margin-right 70px
        /deep/.el-switch__label
          color transparent
        /deep/.el-switch__label--left.is-active
          color #9e9e9e
        /deep/.el-switch__label--right.is-active
          color #009375
      >>>tr
        line-height 18px
        margin 4px 0
        >>>.el-button--text
          color #4a4a4a!important
        &:hover>td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
          .table-green-btn
            color #00A389!important
            font-weight 700!important
      >>>.table-handles
        white-space nowrap
      .table-green-btn
        font-size 14px
        color #4a4a4a!important
        font-weight bold
      .popover-more
        margin-left 10px!important
        position relative
        top -4px
  .exam-drawer
    /deep/.el-drawer__header 
      color #009375
      font-size 16px
      font-weight bold
      padding 20px 0 20px 20px
      position relative
      margin-bottom 5px
      &:after
        content ""
        position absolute
        display inline-block
        width 100%
        height 1px
        bottom 0
        right 0
        background #d8d8d8
      .el-icon-close
        color #4a4a4a
        font-size 16px
</style>