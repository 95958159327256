<template>
  <div class="appliction">
    <div class="applic-item">
      <div class="applic-title">已开通<span class="applic-title-line"></span></div>
      <div class="applic-con">
        <div class="appliction-item" v-for="(item,index) in opendList" :key="index">
          <div class="applic-top">
            <span class="applic-top-left">
              <img v-if="item.icon" :src="item.icon" class="applic-image" />
              <img v-else src="../../assets/images/reset_success.png" class="applic-image" />
              <span class="applic-text">{{item.appName}}</span>
            </span>
            <el-switch
              class="applic-switch"
              v-model="item.status"
              @change="changeApplic($event,item.id)"
              active-color="#009375"
              inactive-color="#ccc">
            </el-switch>
          </div>
          <div class="applic-par">
            {{item.digest}}
          </div>
          <div class="applic-bottom">
            <div class="applic-bottom-item" @click="toDetail(item.id)">应用介绍</div>
            <div class="applic-bottom-item applic-bottom-item-active">应用配置</div>
          </div>
        </div>
      </div>
    </div>
    <div class="applic-item">
      <div class="applic-title">未开通<span class="applic-title-line"></span></div>
      <div class="applic-con">
        <div class="appliction-item" v-for="(item,index) in notOpendList" :key="index">
          <div class="applic-top">
            <span class="applic-top-left">
              <img v-if="item.icon" :src="item.icon" class="applic-image" />
              <img v-else src="../../assets/images/reset_success.png" class="applic-image" />
              <span class="applic-text">{{item.appName}}</span>
            </span>
            <el-switch
              class="applic-switch"
              active-color="#009375"
              v-model="item.status"
              @change="changeApplic($event,item.id)"
              inactive-color="#ccc">
            </el-switch>
          </div>
          <div class="applic-par">
            {{item.digest}}
          </div>
          <div class="applic-bottom">
            <div class="applic-bottom-item" @click="toDetail(item.id)">应用介绍</div>
            <div class="applic-bottom-item applic-bottom-item-active">应用配置</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      class="app-dialog"
      :visible="showDetail"
      @close="closeDetail"
      width="700px"
    >
      <div class="app-con">
        <div class="app-top">
          <img src="../../assets/images/reset_success.png" class="applic-top-img" />
          <div class="app-top-detail">
            <p class="app-name">{{detailInfo.appName}}</p>
            <p class="app-time">上线时间：{{detailInfo.onlineTime}}</p>
          </div>
          <span class="app-link"><img src="../../assets/images/app.png" class="app-pz-icon" />应用配制</span>
        </div>
        <!-- <el-carousel>
          <el-carousel-item
              class="carousel flex"
              v-for="(item1, index1) in returnData"
              :key="index1"
          >
              <div class="item-img flex" v-for="(item, i) in item1" :key="i">
              <img class="imgUrl" :src="item.url" alt="" />
              <div class="item-img-right">
                  <div class="name">{{ item.name }}</div>
                  <div class="item-title">{{ item.declaration }}</div>
              </div>
              </div>
          </el-carousel-item>
      </el-carousel> -->
        <div class="app-info">
          <div class="app-title">应用介绍
            <span class="app-item-line"></span>
          </div>
          <div class="app-info-con" v-html="detailInfo.intro"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  regAllNotOpened,
  regAllOpened,
  regOpenApp,
  regCloseApp,
  regAppDetail
} from '../../api/index'
export default {
  name: 'ApplicConfiger',
  data() {
    return {
      switchValue: 1,
      notOpendList:[],
      opendList: [],
      showDetail: false,
      detailInfo: {}
    }
  },
  mounted(){
    this.getNotOpend()
    this.getOpend()
  },
  methods:{
    getNotOpend(){
      regAllNotOpened().then(res => {
        if(res && res.code === 0){
          let list = res.data
          if(list.length){
            for(let i=0; i<list.length; i++){
              if(list[i].status === 'Y'){
                list[i].status = true
              }else{
                list[i].status = false
              }
            }
          }
          this.notOpendList = list
        }else{
          this.$message({
            message: '获取未开通系统失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    getOpend(){
      regAllOpened().then(res => {
        if(res && res.code === 0){
          let list = res.data
          if(list.length){
            for(let i=0; i<list.length; i++){
              if(list[i].status === 'Y'){
                list[i].status = true
              }else{
                list[i].status = false
              }
            }
          }
          this.opendList = list
        }else{
          this.$message({
            message: '获取开已开通系统失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    changeApplic(val,id){
      if(val){
        regOpenApp({id}).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '操作成功',
              type: 'success'
            }); 
            this.getNotOpend()
            this.getOpend()
          }else{
            this.$message({
              message: '操作失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }else{
        regCloseApp({id}).then(res => {
          if(res && res.code === 0){
            this.$message({
              message: '操作成功',
              type: 'success'
            }); 
            this.getNotOpend()
            this.getOpend()
          }else{
            this.$message({
              message: '操作失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
      }
    },
    toDetail(id){
      regAppDetail({id}).then(res => {
          if(res && res.code === 0){
            this.detailInfo = res.data
            this.showDetail = true
          }else{
            this.$message({
              message: '获取详情失败，'+res.msg,
              type: 'error'
            }); 
          }
        })
    },
    closeDetail(){
      this.showDetail = false
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.appliction
  padding 34px 29px 15px
  .applic-item
    .applic-title
      display flex
      align-items center
      margin-bottom 30px
      .applic-title-line
        flex 1
        height 1px 
        background #d8d8d8
        margin-left 17px
    .applic-con
      .appliction-item
        display inline-block
        width 270px
        height 161px
        border-radius 5px
        box-sizing border-box
        border 1px solid #DADADA
        position relative
        margin 0 45px 30px 0
        .applic-top
          display flex
          padding  12px 17px
          align-items center
          justify-content space-between
          .applic-top-left
            align-items center
            display flex
            .applic-image
              width 46px
              height 46px
              border-radius 5px
              margin-right 25px
          .applic-switch
            float right
        .applic-par
          padding 0 12px
          height 42px
          line-height 21px
          overflow hidden
        .applic-bottom
          position absolute
          width 100%
          border-top 1px solid #DADADA
          bottom 0
          display flex
          height 36px
          line-height 36px
          .applic-bottom-item
            flex 1
            text-align center            
          .applic-bottom-item-active
            border-left 1px solid #DADADA
            color #009375
  .app-dialog
    .app-con
      padding 0 30px
      .app-top
        border-bottom  1px solid #D8D8D8
        display flex
        position relative
        height 105px
        padding-left 108px
        justify-content space-between
        .applic-top-img
          width 80px
          height 80px
          position absolute
          left 0 
          top 0
        .app-top-detail
          .app-name
            font-size 18px
            font-weight bold
            margin 15px 0 10px
          .app-time
            font-size 14px
        .app-link
          display inline-block
          width 104px
          height 40px
          line-height 40px
          border-radius 5px
          border 1px solid #D8D8D8
          text-align center
          margin-top 20px
          .app-pz-icon
            position relative
            top 3px
            margin-right 5px
      .app-info
        margin-top 50px    
        .app-title
          position relative
          display flex
          .app-item-line
            height 1px 
            background #D8D8D8
            flex 1
            margin 5px 0 0 5px
        .app-info-con
          font-size 14px
          color #9E9E9E
          line-height 40px
          height 120px
          overflow auto
</style>