import {
  reqRegPhoneCode
} from "../api"

export default {
  async getPhoneAct({commit},data) {
    console.log('commit',commit)
    //const timer = Date.now()
    //const {systemCode,bill_name,systemFlag,key} = getUrlParam(location.search)
    const {mobilePhone} = data
    const result = await reqRegPhoneCode({mobilePhone})
    console.log('result',result)
  },
}