<template>
  <div>
    <div class="user-item is-online" v-for="user in unreadList"  @click="toChat(user)">
      <img :src="user.headImgUrl" class="user-img" />
      <div class="user-des">
        <p class="user-name">{{user.name}}</p>
        <p class="user-time">{{user.lastMsgTime}}</p>
      </div>
      <span class="is-new" v-if="user.unreadMsgCount > 0"></span>
    </div>
    <!-- <div class="user-item is-online">
      <img src="../../assets/images/user.png" class="user-img" />
      <div class="user-des">
        <p class="user-name">张三</p>
        <p class="user-time">2023-11-02</p>
      </div>
      <span class="is-new"></span>
    </div> -->
  </div>
</template>

<script>
import {
  regChatList
} from '../../api/index'
export default {
  data () {
    return {
      unreadList: [], // 聊天列表
    }
    
  },
  mounted(){
    this.getUnreadList()
  },
  methods:{
    getUnreadList(){
      regChatList().then(res=>{
        if(res.code === 0){
          this.unreadList = res.data
          this.$emit('chartData',res.data)
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取未读列表失败！' + res.msg
          });
        }
      })
    },
    // 点击用户进行聊天
    toChat(userData){
      this.$emit('toChat',userData)
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.user-item
  display flex
  padding 14px
  position relative
  cursor pointer
  font-size 12px
  color #9E9E9E
  .user-img
    width 40px
    height 40px
    border-radius 50%
    margin-right 12px
  .user-des
    height 40px
    flex 1
    text-align left
    .user-name 
      font-size 16px
      color #000
      font-weight  bold
      height 30px
      line-height 30px
  .is-new
    display inline-block
    width 10px
    height 10px
    border-radius 50%
    background #009375
    margin 12px 10px 0 0
  &:hover
    background #F7F8FA
    border-radius 10px
.is-online
  &::after
    content ""
    display inline-block
    width 8px
    height 8px
    border-radius 50%
    background url(../../assets/images/green_c.png) no-repeat
    position absolute
    left 45px
    bottom 15px
</style>