<template>
  <div >
    <div class="examdr-detail">
      <div class="examdr-con">
        <div class="examdr-item">
          <span class="examdr-name">问卷标题</span>
          <!-- <input type="text"  v-model="questionnaireName"  class="examdr-text" /> -->
          <span class="examdr-des">{{questionnaireName}}</span>
        </div>
        <div class="examdr-main examdr-main-quest">
          <div class="examdr-title">问卷详情</div>
          <div class="examdr-quest"> 
            <div class="question-item" v-for="(item,index) in titleDtos" :key="index">
              <div class="question-title">
              <!-- <span class="question-num">1、</span>你是如何看待朋友关系的？ -->
               {{item.title}}
              </div>
              <div class="question-option" v-if="item.detailedOptionsType == '3'">
                <input type="text" class="quest-input" disabled />
              </div>
              <div class="question-option" v-if="item.detailedOptionsType == '4'">
                <textarea disabled  class="quest-input quest-area"></textarea>
              </div>
              <div class="question-options"  v-if="item.detailedOptionsType == '1' || item.detailedOptionsType == '2'">
                <div class="question-option" v-for="(opt,optIdx) in item.options" :key="optIdx">
                 {{opt.optionContent}}
                <div class="question-options" v-if="opt.subOptions" >
                  <div class="question-option" v-for="(sub,subIdx) in opt.subOptions"  :key="subIdx">
                      {{sub.optionContent}}
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   regEditExaml
// } from '../../../api/index'
export default {
  props:{
    questionDetail: Object
  },
  data () {
    return {
      questionnaireName: '',
      questionnaireType: '',
      titleDtos: [],
      addItem: [],
    }
  },
  mounted(){
    this.setData()
  },
  methods:{
    setData(){
      this.questionnaireName = this.questionDetail.questionnaireName
      this.questionnaireType = this.questionDetail.questionnaireType
      this.titleDtos = this.questionDetail.titleDtos
      let addItem = this.questionDetail.item
      for(let i=0 ;i<addItem.length; i++){
        addItem[i].userInputContent = JSON.parse( addItem[i].userInputContent)
      }
      this.addItem = addItem
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.examdr-con
  padding 22px 24px
  .examdr-item
    display  flex
    margin-bottom 20px
    line-height 25px
    .examdr-name
      min-width 80px
      margin-right 10px
      font-weight bold
    .examdr-text
      width 300px
      padding 0 10px
      height 30px
      line-height 30px
      border 1px solid #ccc
      border-radius 3px
  .examdr-main
    margin-bottom 14px
    .examdr-title
      font-size 14px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 30px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 15px
          .question-num
            color #FF3243
        .question-option
          padding-left 30px
          margin-top 20px
          .quest-input
            width 100%
            height 30px
            border 1px solid #ececec
          .quest-area
            height 60px
            resize  none
</style>