<template>
  <div class="dialog-con">
    <div class="modify-bars">
      <span class="modify-bar " :class="{'modify-bar-ac':modifyNum >= 1}">1</span>
      <span class="bar-link" ></span>
      <span class="modify-bar" :class="{'modify-bar-ac':modifyNum >= 2}">2</span>
      <span class="bar-link" ></span>
      <span class="modify-bar" :class="{'modify-bar-ac':modifyNum >= 3}">3</span>
    </div>
    <template v-if="modifyNum ===1">
      <div class="dialog-from-item">
        <span class="dialog-from-name">原手机号</span>
        {{mobilePhone}}
      </div>
      <div class="dialog-from-item">
        <span class="dialog-from-name">验证码</span>
        <div class="code-con">
          <input type="text" class="phone-text phone-code" v-model="verfiyCode" placeholder="输入验证码" />
          <span class="phone-link" @click="toSendOldMobileVerifyCode">{{waitText1}}</span>
        </div>
      </div>
      <div class="dialog-btns dialog-btns-right">
        <span class="dialog-btn" @click="toCheckOldMobileVerifyCode">下一步</span>
      </div>
    </template>
    <template v-if="modifyNum === 2">
      <div class="dialog-from-item">
        <span class="dialog-from-name">密码</span>
        <!-- <span class="regfrom-name">密码</span> -->
        <el-input class="reg-input" show-password  
          @focus="handleShowPassnote" 
          v-model="password" 
          placeholder="输入密码" 
          @input="checkPass($event)"
          @blur="isShowPassNote = false"
        />
        <!-- <i class="reg-wanning el-icon-warning-outline" ></i> -->
        <div class="pass-note" v-show="isShowPassNote">
          <p aria-colcount="note-par note-success">
            <img src="../../../assets/images/pass-notesu.png" v-if="passCheckArr[0]" class="pass-note-icon"/>
            <img src="../../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
            最小字符数：8 
          </p>
          <p aria-colcount="note-par note-wanning">
            <img src="../../../assets/images/pass-notesu.png" v-if="passCheckArr[1]" class="pass-note-icon"/>
            <img src="../../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
            包含一个大写字母
          </p>
          <p aria-colcount="note-par">
            <img src="../../../assets/images/pass-notesu.png" v-if="passCheckArr[2]" class="pass-note-icon"/>
            <img src="../../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
            包含一个小写字母
          </p>
          <p aria-colcount="note-par">
            <img src="../../../assets/images/pass-notesu.png" v-if="passCheckArr[3]" class="pass-note-icon"/>
            <img src="../../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
            包含一个数字
          </p>
          <p aria-colcount="note-par">
            <img src="../../../assets/images/pass-notesu.png" v-if="passCheckArr[4]" class="pass-note-icon"/>
            <img src="../../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
            <!-- <img src="../../assets/images/pass-note.png" class="pass-note-icon"/> -->
            包含特殊字符
          </p>
        </div>
      </div>
      <div class="dialog-from-item">
        <span class="dialog-from-name">重复密码</span>
        <el-input class="reg-input" show-password  v-model="notPassword" placeholder="再次输入密码" />
        <!-- <i class="reg-wanning el-icon-warning-outline"></i> -->
      </div>
      <div class="dialog-btns">
        <span class="dialog-btn dialog-btns-right" @click="ToEitMobile">下一步</span>
      </div>
    </template>
    <div class="modify-success" v-if="modifyNum === 3">
      <img src="../../../assets/images/modify_success.png" class="modify-img"/>
      <p class="modify-par">恭喜您以成功修改手机号码</p>
      <p class="modify-sencend">{{successSencend}}s 后自动关闭</p>
      <div class="dialog-btns" @click="FnCloseRestPass">
        <span class="dialog-btn">完成</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  sendBaseResetPwdVerifyCode,
  checkResetPwdVerifyCode,
  resetBasePwdSubmit
} from '../../../api/index'
export default {
  props:{
    mobilePhone: String
  },
  data () {
    return {
      modifyNum: 1,
      verfiyCode: '',
      waitText1: '获取验证码', // 验证码倒计时
      password: '', // 新密码
      notPassword: '', // 重复密码
      successSencend: 3,
      isShowPassNote: false,
      passCheckArr: [false,false,false,false,false],
      tempToken: ''
    }
    
  },
  methods:{
    // 显示密码提示
    handleShowPassnote(){
      this.isShowPassNote = true
    },
    // 判断是否符合规范
    checkPass(val){
      console.log('checkPass(val)',val)
      if(val){
        if(val.length >=8) {
          this.passCheckArr[0] = true
        }else{
          this.passCheckArr[0] = false
        }
        console.log('checkPass',this.passCheckArr)
        if (/^(?=.*[A-Z])/.test(val)) {
          this.passCheckArr[1] = true
        }else{
          this.passCheckArr[1] = false
        }
        if (/^(?=.*[a-z])/.test(val)) {
          this.passCheckArr[2] = true
        }else{
          this.passCheckArr[2] = false
        }
        if (/^(?=.*\d)/.test(val)) {
          this.passCheckArr[3] = true
        }else{
          this.passCheckArr[3] = false
        }
        if (/^(?=.*[!@#$%^&*])/.test(val)) {
          this.passCheckArr[4] = true
        }else{
          this.passCheckArr[4] = false
        }
      }else{
        this.passCheckArr = [false,false,false,false,false]
      }
      
    },
    //发送验证码到旧手机号
    toSendOldMobileVerifyCode(){
      this.verfiyCode = ''
      if(this.waiting1){
        return false
      }
      sendBaseResetPwdVerifyCode().then(res => {
        if(res && res.code === 0){
          // 验证码倒计时
          let timeLeft1 = 60
          this.waiting1 = true;
          // 更新按钮上的文字
          this.waitText1 = timeLeft1 + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft1--;
            // 更新按钮上的文字
            this.waitText1 = timeLeft1 + 's后再次获取';
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft1 === 0) {
              clearInterval(timer);
              this.waiting1 = false;
              this.waitText1 = '获取验证码';
            }
          }, 1000);
        }else{
          this.$message({
            message: '获取验证码失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 验证旧手机号的验证码
    toCheckOldMobileVerifyCode(){
      if(!this.verfiyCode){
        this.$message({
          message: '请先输入验证码',
          type: 'wanring'
        }); 
        return false
      }
      checkResetPwdVerifyCode({
        verfiyCode: this.verfiyCode
      }).then(res => {
        if(res && res.code === 0){
          this.modifyNum = 2
          this.tempToken = res.data.tempToken
        }else{
          this.$message({
            message: '验证码错误，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 提交新手机号
    ToEitMobile(){
      if(!this.password){
        this.$message({
          message: '请先输入密码',
          type: 'wanring'
        }); 
        return false
      }
      if(this.passCheckArr.filter(item => item === true).length < 5){
        this.$message({
          message: '密码格式不正确',
          type: 'warning'
        });
        return false; 
      }
      if(this.password != this.notPassword){
        this.$message({
          message: '密码与密码密码不一致',
          type: 'warning'
        });
        return false; 
      }
      resetBasePwdSubmit({
        password: this.$md5(this.password).toUpperCase(),
        tempToken: this.tempToken
      }).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '修改成功！',
            type: 'success'
          });
          this.modifyNum = 3
          this.countdown()
          // sessionStorage.setItem('token',res.data.token)
          // sessionStorage.setItem('name',res.data.name)
        }else{
          this.$message({
            message: '验证码错误，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    countdown() {
      var timer = setInterval(() => {
        this.successSencend--;
        if (this.successSencend < 0) {
          clearInterval(timer);
          this.modifyNum = 1
          this.$emit('closeRestPass')
        }
      }, 1000);
    },
    FnCloseRestPass(){
      this.modifyNum = 1
      this.$emit('closeRestPass')
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.dialog-con
  padding 30px
  .dialog-from-item
    display flex
    align-items center
    margin-bottom 25px
    position relative
    .dialog-from-name
      width 100px
    .phone-text
      width 302px
      height 38px
      line-height 38px
      border 1px solid #DADADA
      border-radius 5px
      padding 0 10px
      &:focus
        border-color #DADADA
    .code-con
      display flex
      .phone-code
        width 194px
        margin-right 13px
      .phone-link
        display inline-block
        width 95px
        height 38px
        line-height 38px
        text-align center
        border-radius 5px
        border 1px solid #DADADA
        font-size 14px
        cursor pointer
      .glay-link
        color #7e7e7e
    .reg-wanning
      position absolute
      right 10px
      top 16px
      color #ccc
      z-index 1
      cursor pointer
    .pass-note
      display inline-block
      min-width  150px
      height 130px
      line-height 22px
      border 1px solid #CED4DA
      border-radius 5px
      padding 10px
      position absolute
      right -150px
      top -120px
      background #fff
      color #9e9e9e
      font-size 12px
      .note-par
        .pass-note-icon
          margin-right 7px
      .note-success
        color #009375
      .note-wanning
        color #FF630F
    .reset-code
      width 300px
      margin-right 25px
    .get-resetcode
      cursor pointer
    .reg-tell
      position relative
      &::before
        content: "+86"
        display inline-block
        width 80px
        height 40px
        text-align center
        border-right 1px solid #ccc
        position absolute
      >>>.el-input__inner
        text-indent 100px
  .modify-bars
    margin-bottom 50px
    display flex
    align-items center
    .modify-bar
      display inline-block
      width 20px
      height 20px
      line-height 20px
      border-radius 25px
      background #D8D8D8
      color #fff
      margin 0 20px
      text-align center
    .modify-bar-ac
      width 55px
      background #E0F2EE
      color #009375
    .bar-link
      flex 1
      height 1px 
      background #ccc
  .modify-success
    text-align center
    .modify-par
      color #9e9e9e
      margin 15px 0 22px
    .modify-sencend
      font-size 12px
      color #009375
      margin-bottom 60px
.dialog-btns
  text-align center
  .dialog-btn
    display inline-block
    width 100px
    height 34px
    line-height 34px
    border-radius 5px
    background #009375 
    color #fff
    text-align center
    cursor pointer
.dialog-btns-right
  text-align right
>>>.el-dialog__header
  border-bottom 1px solid #d8d8d8
  font-size 16px
  font-weight bolder
</style>