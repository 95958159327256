<template>
  <div class="drawer">
    <div class="drawer-title">
      <p class="drawer-title-text green-text">报告详情</p>
      <img src="../../assets/images/close.png" class="close-drawer-icon" @click="closeDrawer" />
    </div>
    <div class="report" id="print" ref="export">
      <div class="report-top">
        <p class="report-main-title" v-if="orgName">{{orgName}}</p>
        <p class="report-sentit">{{reportName}}</p>
      </div>
      <div class="report-titles">
        <span class="report-title">一、基础信息</span>
        <span class="report-tit-right">测评日期：{{createTime}}</span>
      </div>
      <div class="report-base">
        <span class="base-item">
          <span class="base-item-name">姓        名：</span>
          <span class="base-item-val">{{name}}</span>
        </span>
        <span class="base-item">
          <span class="base-item-name">性       别：</span>
          <span class="base-item-val">{{sex}}</span>
        </span>
        <span class="base-item">
          <span class="base-item-name">生        日：</span>
          <span class="base-item-val">{{birthday}}</span>
        </span>
        <span class="base-item">
          <span class="base-item-name">学        历：</span>
          <span class="base-item-val">{{education}}</span>
        </span>
        <span class="base-item">
          <span class="base-item-name">手机号码：</span>
          <span class="base-item-val">{{mobilePhone}}</span>
        </span>
        <span class="base-item">
          <span class="base-item-name">科        室：</span>
          <span class="base-item-val">{{deptName}}</span>
        </span>
      </div>
      <div class="report-titles">
        <span class="report-title">二、测评分析</span>
      </div>
      <div class="report-froms">
        <div class="report-from-top">
          <span class="from-item textindnt">名称</span>
          <span class="from-item">得分</span>
          <span class="from-item">参考范围</span>
          <span class="from-item">提示</span>
        </div>
        <div class="from-sans" v-for="(item,index) in reportItemList" :key="index">
          <div class="from-san textindnt">{{item.title}}</div>
          <div class="from-san">{{item.scoreFact}}</div>
          <!-- <div class="from-san from-san-left">
            <p class="from-san-item">0-4：无</p>
            <p class="from-san-item">5-7：轻度</p>
            <p class="from-san-item">8-15：中度</p>
            <p class="from-san-item">16-39：中度</p>
          </div> -->
          <div class="from-san from-san-left"><p class="from-san-item">{{item.reference}}</p></div>
          <div class="from-san">-</div>
        </div>
      </div>
      <div class="report-titles">
        <span class="report-title">三、指导建议</span>
      </div>
      <div class="report-jy" v-html="advice">
      </div>
      <div class="report-bottom">
        <div class="report-qimin" v-if="eleSign">
          <span>评估人员签字：</span>
          <img  :src="eleSign" class="sigo-img"/>
          <!-- <img v-else src="../../assets/images/singo.png" class="sigo-img"/> -->
        </div>
      </div>
      <div class="report-bot">
        <span class="report-time">打印时间：{{currentDateTime}}</span>
        <span>* 本报告数据均来源于本次测评结果，不能代替医生诊断，仅供参考。</span>
      </div>
      <div class="report-btns">
        <div class="report-btn-left">
          <span class="report-btn"><img src="../../assets/images/import.png" class="search-btn-icon"> 导出数据</span>
          <span class="report-btn" @click="printRpeort"><img src="../../assets/images/print.png"  class="search-btn-icon"> 打印报告</span>
        </div>
        <span class="report-btn" v-has="'mgr.examReport.editReport'"  @click="toEdit"><img src="../../assets/images/import.png" class="search-btn-icon"> 编辑报告</span>
      </div>
    </div>
  </div>
</template>

<script>
import print from '../../utils/print'
export default {
  props:{
    detailObj: Object,
    replyId: Number
  },
  data () {
    return {
      currentDateTime: '',
      reportName: '',
      orgName: '',
      createTime: '',
      name: '',
      sex: '',
      birthday: '',
      education: '',
      mobilePhone: '',
      eleSign: '',
      deptName: '',
      advice: '',
      reportItemList: []
    }
    
  },
  mounted(){
    console.log('eee',this.detailObj)
    var currentDate = new Date();
    this.currentDateTime = currentDate.toLocaleString();
    this.setData()
  },
  methods:{
    // 详情
    setData(){
      if(this.detailObj){
        this.reportName = this.detailObj.reportName
        this.orgName = this.detailObj.orgName || ''
        this.createTime = this.detailObj.createTime
        this.advice = this.detailObj.advice
        this.reportItemList = this.detailObj.reportItemList
        this.name = this.detailObj.userInfo.name
        this.sex = this.detailObj.userInfo.sex
        this.birthday = this.detailObj.userInfo.birthday
        this.education = this.detailObj.userInfo.education
        this.mobilePhone = this.detailObj.userInfo.mobilePhone
        this.deptName = this.detailObj.userInfo.deptName
        this.eleSign = this.detailObj.userInfo.eleSign
      }
    },
    closeDrawer(){
      this.$emit('closeDrawer')
    },
    // 打印报告
    printRpeort(){
      this.$print(this.$refs.export)
    },
    toEdit(){
      this.$router.push({ path: `/accessDetail/${this.replyId}`})
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.drawer
  padding 0 45px 
  .drawer-title
    height 66px
    line-height 66px
    position relative
    font-size 16px
    border-bottom 1px solid #D8D8D8
    .drawer-title-text
      font-weight bolder
    .close-drawer-icon
      position absolute
      right 25px
      top 20px
      cursor pointer
  .report
    .report-top
      padding 36px 0 50px
      text-align center
      font-weight bold
      .report-main-title
        font-size 20px
        line-height 28px
        margin-bottom 10px
      .report-sentit
        font-size 18px
    .report-titles
      height 32px
      line-height 32px
      border-bottom 2px solid #000
      font-size 14px
      font-weight bold
      display flex
      justify-content space-between
      .report-title
        font-weight bold
      .report-tit-right
        font-size 12px
        font-weight normal
    .report-base
      padding 15px 25px
      display flex
      flex-wrap wrap
      .base-item
        display inline-block
        width 33.3%
        margin-bottom 8px
    .report-froms
      border-bottom 1px solid #000
      margin-bottom 25px
      .textindnt
        text-indent 25px
      .report-from-top
        height 24px
        line-height 24px
        border-bottom 1px solid  #000
        font-size 12px
        display flex
        margin 5px 0
        .from-item
          flex 1
          text-align left
      .from-sans
        display flex
        padding 5px 0
        .from-san
          flex 1
          text-align left
        .from-san-left
          text-align left
        .from-san-item
          line-height 22px
          margin-bottom 8px
    .report-jy
      min-height 80px
      padding 25px
    .report-bottom
      padding-bottom 8px
      border-bottom 1px solid #000
      text-align right
      .sigo-img
        width 136px
    .report-bot
      display flex
      font-size 10px
      padding 10px 0 50px
      justify-content space-between
    .report-btns
      display flex
      justify-content space-between
      margin-bottom 25px
      .report-btn-left
        display inline-block
        .report-btn
          display inline-block
          width 104px
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          margin-right 15px
          cursor pointer
      .report-btn
        width 104px
        height 40px
        line-height 40px
        text-align center
        border-radius 5px
        border 1px solid #D8D8D8
        cursor pointer
@media print
  .report
    .report-top
      padding 36px 0 50px
      text-align center
      font-weight bold
      .report-main-title
        font-size 20px
        line-height 28px
        margin-bottom 10px
      .report-sentit
        font-size 18px
    .report-titles
      height 32px
      line-height 32px
      border-bottom 2px solid #000
      font-size 14px
      font-weight bold
      display flex
      justify-content space-between
      .report-title
        font-weight bold
      .report-tit-right
        font-size 12px
        font-weight normal
    .report-base
      padding 15px 25px
      display flex
      flex-wrap wrap
      .base-item
        display inline-block
        width 33.3%
        margin-bottom 8px
    .report-froms
      border-bottom 1px solid #000
      margin-bottom 25px
      .textindnt
        text-indent 25px
      .report-from-top
        height 24px
        line-height 24px
        border-bottom 1px solid  #000
        font-size 12px
        display flex
        margin 5px 0
        .from-item
          flex 1
          text-align left
      .from-sans
        display flex
        padding 5px 0
        .from-san
          flex 1
          text-align left
        .from-san-left
          text-align left
        .from-san-item
          margin-bottom 8px
          line-height 22px
    .report-jy
      min-height 80px
      padding 25px
    .report-bottom
      padding-bottom 8px
      border-bottom 1px solid #000
      text-align right
      .sigo-img
        width 136px
    .report-bot
      display flex
      font-size 10px
      padding 10px 0 50px
      justify-content space-between
    .report-btns
      display none
      justify-content space-between
      margin-bottom 25px
      .report-btn-left
        display inline-block
        .report-btn
          display inline-block
          width 104px
          height 40px
          line-height 40px
          text-align center
          border-radius 5px
          border 1px solid #D8D8D8
          margin-right 15px
      .report-btn
        width 104px
        height 40px
        line-height 40px
        text-align center
        border-radius 5px
        border 1px solid #D8D8D8
        
</style>