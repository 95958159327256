<template>
  <div class="group-zj">
    <el-collapse class="group-con">
      <div class="groups-item " :class="{'groups-item-act': openArr.includes(item.id)}"  v-for="(item,index) in chartGroup" :key="index">
        <div class="groups-top" @click="openClose(item.id)">
          <!-- <span class="edit-name">{{item.groupName}}</span> -->
          <div class="groups-left">
            <template v-if="editGroupId===item.id">
              <input type="text" v-model="editGroupName"  class="edit-name-text" />
              <img src="../../assets/images/confirm.png" class="edit-group-icon" @click="toEidtGroup" />
              <img src="../../assets/images/close.png" class="edit-group-icon" @click="editGroupId = null" />
            </template>
            <span class="edit-name" v-else>{{item.groupName}}</span>
          </div>
          <el-popover
            class="company-popover"
            placement="bottom"
            width="87"
            trigger="hover">
            <div class="company-navs">
              <p class="company-nav" @click="toEdit(item)">编辑</p>
              <p class="company-nav" @click="toDelete(item.id)" >删除</p>
              <p class="company-nav" @click="getChartCode(item.id)">二维码</p>
            </div>
            <img src="../../assets/images/more.png" slot="reference" class="more-icon" />
            
          </el-popover>
          <img src="../../assets/images/right-arrow.png" class="group-arrow" />
        </div>
        <div class="user-item is-online"
            v-for="(user,j) in item.userList" 
            :class="user.userId == currentUserId?'user-item-active' : ''"
            :key="j" @click="toChat(user)" 
            v-show="openArr.includes(item.id)">
          <img :src="user.headImgUrl"  class="user-img" />
          <div class="user-des">
            <p class="user-name">{{user.name}}</p>
            <p class="user-time">{{user.lastMsgTime}}</p>
          </div>
          <span class="is-new" v-if="user.unreadMsgCount > 0"></span>
        </div>
      </div>
      <div class="toadd-con" v-if="showAdd">
        <input type="text" class="toadd-text" v-model.trim="newGroupName" placeholder="请输入分组名" />
        <span class="toadd-confirm" @click="addGroup">确认</span>
        <span class="toadd-confirm toadd-cancle" @click="showAdd = false">取消</span>
      </div>
      <!-- <el-collapse-item :title="item.groupName" :name="index" v-for="(item,index) in chartGroup" :key="index">
        <div class="user-item is-online" v-for="(user,j) in item.userList" :key="j" @click="toChat(user)">
          <img src="../../assets/images/user.png" class="user-img" />
          <div class="user-des">
            <p class="user-name">{{user.name}}</p>
            <p class="user-time">{{user.name}}</p>
          </div>
          <span class="is-new" v-if="user.unreadMsgCount > 0"></span>
        </div>
      </el-collapse-item>
      <el-collapse-item title="反馈 Feedback" name="2">
        <div class="user-item is-online">
          <img src="../../assets/images/user.png" class="user-img" />
          <div class="user-des">
            <p class="user-name">张三</p>
            <p class="user-time">2023-11-02</p>
          </div>
          <span class="is-new"></span>
        </div>
        <div class="user-item">
          <img src="../../assets/images/user.png" class="user-img" />
          <div class="user-des">
            <p class="user-name">李四</p>
            <p class="user-time">2023-11-02</p>
          </div>
          <span class="is-new"></span>
        </div>
      </el-collapse-item> -->
    </el-collapse>
    <div class="add-group" v-if="!showAdd" @click="showAdd = true">
      <img src="../../assets/images/group.png" class="add-group-img" />
      新建分组
    </div>
  </div>
</template>

<script>
import {
  regChatGroupList,
  regChatAddGrout,
  regChatEditGroup,
  regChatRemoveGroup
} from '../../api/index'
export default {
  props:{
    userId: String
  },
  data () {
    return {
      chartGroup: [], // 分组列表
      openArr: [],
      showAdd: false,
      newGroupName: '', // 新增分组name
      editGroupId: null, // 当前正在编辑的分组
      editGroupName: '', // 编辑后的分组名
      isLoad: false,
      allUserList: [],
      currentUserId: null
    }
    
  },
  mounted(){
    this.getGroupList()
    this.currentUserId = this.userId
  },
  methods:{
    getGroupList(){
      console.log('getGroupList')
      
      regChatGroupList().then(res=>{
        if(res.code === 0){
          this.allUserList = []
          this.chartGroup = res.data
          for(let i=0; i<this.chartGroup.length; i++){
            this.openArr.push(this.chartGroup[i].id)
            for(let j=0; j< this.chartGroup[i].userList.length; j++){
              this.allUserList.push(this.chartGroup[i].userList[j])
            }
          }
          if(this.userId){
            this.getPageUser()
            //this.isLoad = false
          }
        }else{
          this.$notify.error({
            title: '错误',
            message: '拉取分组列表失败！' + res.msg
          });
        }
      })
    },
    // 从其它页面跳转过来获取过来用户信息
    getPageUser(){
      if(this.isLoad ){
        return false
      }
      this.isLoad = true
      console.log('this.userId',this.userId,this.chartGroup)
      let chartGroup = this.chartGroup
      for(let i=0; i< chartGroup.length; i++){
        console.log('chartGroup[i].userList',chartGroup[i].userList)
        for(let j=0; j<chartGroup[i].userList.length; j++){
          console.log('--',chartGroup[i].userList[j].userId)
          if(chartGroup[i].userList[j].userId == this.userId){
            console.log('找到了',chartGroup[i].userList[j])
            //this.toChat(chartGroup[i].userList[j])
            this.$emit('toChat',chartGroup[i].userList[j])
          }
        }
      }
    },
    // 点击用户进行聊天
    toChat(userData){
      this.currentUserId = userData.userId
      this.$emit('toChat',userData)
    },
    openClose(val){
      this.openArr = this.setGroupOpen(this.openArr,val)
    },
    setGroupOpen(arr,val){
      if (arr && arr.includes(val)) {
          let index = arr.indexOf(val);
          arr.splice(index, 1); // 从数组中删除该元素
      } else {
          arr.push(val); // 向数组尾部添加该元素
      }
      return arr
    },
    addGroup(){
      if(this.newGroupName == ''){
        this.$notify.error({
          title: '错误',
          message: '请先输入分组名'
        });
      }
      regChatAddGrout({
        groupName: this.newGroupName
      }).then(res=>{
        if(res.code === 0){
          this.showAdd = false
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.getGroupList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '新增分组失败' + res.msg
          });
        }
      })
    },
    // 编辑分组
    toEdit(data){
      this.editGroupId = data.id
      this.editGroupName = data.groupName
    },
    // 删除分组
    toDelete(id){
      regChatRemoveGroup({
        id
      }).then(res=>{
        if(res.code === 0){
          this.showAdd = false
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getGroupList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '删除分组失败' + res.msg
          });
        }
      })
    },
    // 二维码生成
    getChartCode(id){
      this.$emit('getChartCode',id)
    },
    // 发生了更改
    groupChange(e){
      this.editGroupName = e.target.value
    },
    toEidtGroup(){
      if(!this.editGroupName){
        this.$message.warning('分组名不能为空')
        return false
      }
      regChatEditGroup({
        id: this.editGroupId,
        groupName: this.editGroupName
      }).then(res=>{
        if(res.code === 0){
          this.showAdd = false
          this.$message({
            message: '更改成功',
            type: 'success'
          });
          this.editGroupId = null
          this.getGroupList()
        }else{
          this.$notify.error({
            title: '错误',
            message: '更改分组失败' + res.msg
          });
        }
      })
    }
  },
  watch:{
    userId(val){
      console.log('userId change',val)
      this.currentUserId = val
    }
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.group-zj
  display flex
  flex-direction column
  justify-content space-between
  height 100%
  .group-con
    margin-bottom 10px
    overflow-y auto
    .groups-item
      padding 0 0 0 0
      .groups-top
        width 100%
        text-align left 
        font-size 16px 
        height 34px
        line-height 34px
        padding 0 60px 0 15px
        position relative
        .more-icon
          display none
          position absolute
          right 30px
          top 6px
        .group-arrow
          position absolute
          right 12px
          top 10px
          width 6px
        .edit-name,.edit-name-text
          width 100%
          height  30px
          line-height 30px
        .groups-left
          width 100% 
          display flex
          align-items center
          .edit-name-text
            flex 1
            border none
            border-radius 5px
            padding  0 5px
            margin-top 2px
          .edit-group-icon
            width 20px
            margin-left 5px
            cursor pointer
      &:hover 
        .more-icon
          display inline-block
      .user-item
        display flex
        padding 14px
        position relative
        cursor pointer
        font-size 12px
        color #9E9E9E
        .user-img
          width 40px
          height 40px
          border-radius 50%
          margin-right 12px
        .user-des
          height 40px
          flex 1
          text-align left
          .user-name 
            font-size 16px
            color #000
            font-weight  bold
            min-height 30px
            line-height 30px
        .is-new
          display inline-block
          width 10px
          height 10px
          border-radius 50%
          background #009375
          margin 12px 10px 0 0
        &:hover
          background #E0F2EE
          border-radius 10px
      .user-item-active
        background #E0F2EE
        border-radius 10px
      .is-online
        &::after
          content ""
          display inline-block
          width 8px
          height 8px
          border-radius 50%
          background url(../../assets/images/green_c.png) no-repeat
          position absolute
          left 45px
          bottom 15px
    .groups-item-act
      .groups-top
        color #009375
        background #f7f8fa
        .group-arrow
          transform rotate(90deg)
    .toadd-con
      padding 0 15px
      margin 10px 0
      display flex
      .toadd-text
        flex 1
        height 30px
        line-height 30px
        border 1px solid  #cccccc
        border-radius 5px
        padding 0 5px
      .toadd-confirm
        display inline-block
        width 80px
        height 30px
        line-height 30px
        text-align center
        background #009375
        color #fff
        font-size 12px
        border-radius 5px
        margin-left 10px
        cursor pointer
      .toadd-cancle
        background #fff
        color #666
        border 1px solid #ccc
  .add-group
    width 90%
    margin 0 5%
    text-align center
    background #fff
    height 40px
    line-height 40px
    border-top 1px solid #d9d9d9
    cursor pointer
    .add-group-img
      position relative
      top 3px
      margin-right 5px
.company-navs
  padding 6px 4px 0
  .company-nav
    display inline-block
    width 77px
    height 20px
    line-height 20px 
    margin-bottom 6px
    padding 0 5px
    cursor pointer
    font-size 12px
    &:hover
      background #D0E6E6
      border-radius 3px
      color #009375
</style>