<template>
  <div class="system">
    <!-- system-top -->
    <div class="system-top">
      <p class="system-top-title">系统设置</p>
      <div class="system-top-links">
        <span class="system-top-link" @click="changeSystem(1)" :class="{'system-top-link-active': systemNum === 1 }">基础信息</span>
        <span class="system-top-link" v-if="userType != 1"  @click="changeSystem(2)" :class="{'system-top-link-active': systemNum === 2 }">组织架构</span>
        <span class="system-top-link" v-if="userType != 1"  @click="changeSystem(3)" :class="{'system-top-link-active': systemNum === 3 }">角色管理</span>
        <span class="system-top-link" v-if="userType != 1"  @click="changeSystem(4)" :class="{'system-top-link-active': systemNum === 4 }">表单配置</span>
        <span class="system-top-link" v-has="'mgr.appConfig.query'"   @click="changeSystem(5)" :class="{'system-top-link-active': systemNum === 5 }">应用配置</span>
        <span class="system-top-link"  @click="changeSystem(6)" :class="{'system-top-link-active': systemNum === 6 }">规则设置</span>
      </div>
    </div>
    <div class="system-main">
      <base-info v-if="systemNum === 1"></base-info>
      <structure v-if="systemNum === 2"></structure>
      <role-manage v-if="systemNum ===3"></role-manage>
      <from-configer v-if="systemNum === 4 " @toFromDetail="toFromDetail" @toEditFromDetail="toEditFromDetail"></from-configer>
      <from-detail v-if="systemNum === 7"></from-detail>
      <applic-configer  v-if="systemNum === 5"></applic-configer>
      <rule-setting  configModule="base" v-if="systemNum === 6 && hasgz"></rule-setting>
    </div>
  </div>
</template>

<script>
import {
  regUserInfo
} from '../../api/index'
import { powerRight } from '../../utils/index'
import BaseInfo from '@/components/SystemSetting/BaseInfo'
import Structure from '@/components/SystemSetting/Structure'
import FromConfiger from '@/components/SystemSetting/FromConfiger'
import FromDetail from '@/components/SystemSetting/FromDetail'
import ApplicConfiger from '@/components/SystemSetting/ApplicConfiger'
import RoleManage from '@/components/SystemSetting/RoleManage'
import RuleSetting from '@/components/SystemSetting/RuleSetting'
export default {
  name: 'System',
  components: {
    BaseInfo,
    Structure,
    FromConfiger,
    FromDetail,
    ApplicConfiger,
    RoleManage,
    RuleSetting
  },
  data() {
    return {
      systemNum: 1,
      detailId: null,
      userType: undefined
    }
  },
  computed:{
    hasgz(){
      return powerRight('mgr.moduleConfig.query')
    }
  },
  mounted(){
    if(this.$route.params.sysType === undefined){
      this.$message({
        message: '非法路径',
        type: 'error'
      }); 
      this.$router.push({ path: '/homePage'})
    }
    console.log('this.systemNum',this.systemNum)
    this.systemNum = Number(this.$route.params.sysType)
    this.getUserInfo()
  },
  methods:{
    getUserInfo(){
      regUserInfo({}).then(res => {
        if(res.code === 0){
          this.userType = res.data.userType
        }
      })
    },
    changeSystem(theType){
      this.systemNum = theType
      this.$router.push({
        params: {...this.$route.params, sysType:theType}  
      })
    },
    toFromDetail(){
      this.systemNum = 7
      this.$router.push({ path: `/system/7` })
    },
    toEditFromDetail(data){
      this.systemNum = 7
      this.$router.push({
        path: `/system/7`,
        query: { detailId: data.id}  
      })
    },
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.system
  .system-top
    padding 34px 29px 15px
    .system-top-title
      font-size 18px
      font-weight bold
      margin-bottom 30px
    .system-top-links
      border 1px solid #DADADA
      border-radius 5px
      height 46px
      .system-top-link
        display inline-block
        width 95px
        height 38px
        line-height 38px
        margin 4px 0
        border-radius 5px
        margin-left 15px
        text-align center
        font-size 14px
        cursor pointer
        &:hover
          background #E0F2EE
          color #009375
      .system-top-link-active
        background #E0F2EE
        color #009375
</style>