<template>
  <div class="regiter">
    <div class="reg-left">
      <img src="../../assets/images/logo.png" class="reg-logo" />
      <div class="reg-copy">版权所有 © 2023 legu Health  - 保留所有权利</div>
    </div>
    <div class="reg-main">
      <div class="reg-con" v-if="resetType === 'edit'">
        <div class="reg-title">保证账号安全，首次登陆时需重置密码</div>
        <div class="reg-par">请及时更改初始密码，以免为您账号造成不必安全要风险。</div>
        <div class="reg-from">
          <div class="reg-from-item">
            <span class="regfrom-name">注册号码</span>
            <el-input class="reg-input reg-tell" :disabled="hasPhone" v-model="mobilePhone" placeholder="输入手机号码" />
          </div>
          <div class="reg-from-item">
            <span class="regfrom-name">验证码</span>
            <el-input class="reg-input reset-code" v-model="verfiyCode" placeholder="验证码" />
            <span type="primary" class="get-resetcode link-color" @click="getPhoneCode">{{waitText}}</span>
          </div>
          <div class="reg-from-item">
              <span class="regfrom-name">密码</span>
              <el-input class="reg-input" show-password  
                @focus="handleShowPassnote" 
                v-model="password" 
                placeholder="输入密码" 
                @change="checkPass($event)"
                @blur="isShowPassNote = false"
              />
              <!-- <i class="reg-wanning el-icon-warning-outline" ></i> -->
              <div class="pass-note" v-show="isShowPassNote">
                <p aria-colcount="note-par note-success">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[0]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  最小字符数：8 
                </p>
                <p aria-colcount="note-par note-wanning">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[1]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个大写字母
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[2]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个小写字母
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[3]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  包含一个数字
                </p>
                <p aria-colcount="note-par">
                  <img src="../../assets/images/pass-notesu.png" v-if="passCheckArr[4]" class="pass-note-icon"/>
                  <img src="../../assets/images/pass-noteing.png" v-else class="pass-note-icon"/>
                  <!-- <img src="../../assets/images/pass-note.png" class="pass-note-icon"/> -->
                  包含特殊字符
                </p>
              </div>
            </div>
            <div class="reg-from-item">
              <span class="regfrom-name">重复密码</span>
              <el-input class="reg-input" show-password  v-model="notPassword" placeholder="再次输入密码" />
              <!-- <i class="reg-wanning el-icon-warning-outline"></i> -->
            </div>
        </div>
        <div class="reset-btns">
          <span class="base-btn color-btn" @click="handleSuccess">确定</span>
        </div>
      </div>
      <div class="reg-con" v-else>
        <div class="reg-title">重置密码成功</div>
        <div class="reg-par">您已完成密码重置。</div>
        <div class="reg-from">
          <div class="reset-success">
            <img src="../../assets/images/reset_success.png" class="reset-sucicon" />
            <p class="success-text">您已完成密码重置！我们将致力于为您提供安全高效的服务。 平台建议您平均每一至三个月更改一次密码。</p>
            <p><span class="link-color">{{seconed}}s</span> 后自动进入系统</p>
          </div>
        </div>
        <div class="reset-btns" v-if="showLink">
          <span class="base-btn color-btn" @click="toIndex">进入系统</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  regCodeResetPass,
  regResetPass
} from '../../api/index'
export default {
   data () {
    return {
      resetType: 'edit', 
      seconed:3, //倒计时
      timer: null,
      isShowPassNote: false,
      passCheckArr: [false,false,false,false,false],
      mobilePhone: '',
      verfiyCode: '',
      password: '',
      notPassword: '',
      showLink: false,
      waiting: false, // 等待验证码中
      hasPhone: false, // 是否有电话号码
      waitText: '获取验证码', // 验证码倒计时
    }
  },
  mounted() {
    console.log('this.$route',this.$route)
     if(this.$route.query && this.$route.query.mobilePhone ){
      //this.toChat({userId: this.$route.query.userId})
      this.mobilePhone = this.$route.query.mobilePhone
      this.hasPhone = true
    }else{
      this.hasPhone = false
    }
  },
  methods:{
    handleSuccess(){
      if(!this.password){
        this.$message({
          message: '请先输入密码',
          type: 'wanring'
        }); 
        return false
      }
      if(!this.verfiyCode){
        this.$message({
          message: '请先输入验证码',
          type: 'wanring'
        }); 
        return false
      }
      if(this.password != this.notPassword){
        this.$message({
          message: '密码与确认密码不一致',
          type: 'warning'
        });
        return false; 
      }
      console.log('this.passCheckArr',this.passCheckArr)
      if(this.passCheckArr.filter(item => item === true).length < 5){
        this.$message({
          message: '密码格式不正确',
          type: 'warning'
        });
        return false; 
      }
      regResetPass({
        password: this.$md5(this.password).toUpperCase(),
        verfiyCode: this.verfiyCode
      }).then(res => {
        if(res.code === 0){
          this.resetType = ''
          this.countDown()
          this.showLink = true
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取验证码失败，请联调管理员'
          });
        }
      })
    },
    countDown(){
      this.timer = setInterval(() => {
        if (this.seconed > 0) {
            this.seconed--;
          } else {
            clearInterval(this.timer);
            // 进入
            this.toIndex()
          }
      }, 1000);
    },
    toIndex(){
      this.$router.push({ path: '/homePage'})
    },
    // 获取手机验证码
    getPhoneCode(){
      if(this.waiting){
        return false
      }
      if(!this.mobilePhone){
        this.$message({
          message: '请先输入手机号',
          type: 'wanring'
        }); 
        return false
      }
      regCodeResetPass({
        mobilePhone: this.mobilePhone
      }).then(res => {
        if(res.code === 0){
          // 验证码倒计时
          let timeLeft = 60
          this.waiting = true;
          // 更新按钮上的文字
          this.waitText = timeLeft + 's后再次获取';
          // 创建计时器变量
          let timer = setInterval(() => {
            timeLeft--;
        
            // 更新按钮上的文字
            this.waitText = timeLeft + 's后再次获取';
        
            // 当倒计时为0时，启用按钮并清除计时器
            if (timeLeft === 0) {
              clearInterval(timer);
              this.waiting = false;
              this.waitText = '获取验证码';
            }
          }, 1000);
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取验证码失败，请联调管理员'
          });
        }
      })
    },
    // 显示密码提示
    handleShowPassnote(){
      this.isShowPassNote = true
    },
    // 判断是否符合规范
    checkPass(val){
      if(val){
        if(val.length >=8) {
          this.passCheckArr[0] = true
        }else{
          this.passCheckArr[0] = false
        }
        if (/^(?=.*[A-Z])/.test(val)) {
          this.passCheckArr[1] = true
        }else{
          this.passCheckArr[1] = false
        }
        if (/^(?=.*[a-z])/.test(val)) {
          this.passCheckArr[2] = true
        }else{
          this.passCheckArr[2] = false
        }
        if (/^(?=.*\d)/.test(val)) {
          this.passCheckArr[3] = true
        }else{
          this.passCheckArr[3] = false
        }
        if (/^(?=.*[!@#$%^&*])/.test(val)) {
          this.passCheckArr[4] = true
        }else{
          this.passCheckArr[4] = false
        }
      }else{
        this.passCheckArr = [false,false,false,false,false]
      }
      
    },
  },
  watch:{
    password(val){
      this.checkPass(val)
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.regiter
  display flex
  height 100%
  .reg-left
    width 40%
    height 100%
    background #EDF0F5
    position relative
    .reg-logo
      display inline-block
      margin 40% 0 0 30%
      color #009375
      font-size 32px
      font-weight bold
    .reg-copy
      width 100%
      position absolute
      bottom 100px
      text-align center
  .reg-main
    flex 1
    overflow-y auto
    .reg-con
      margin 50px 100px
      .reg-title
        font-size 26px
        font-weight 400
        margin-bottom 15px
      .reg-par
        color #9E9E9E
        margin-bottom 25px
      .reg-from
        margin 80px 0 120px
        .reg-from-item
          dispay flex 
          margin-bottom 25px
          position relative
          .regfrom-name
            display inline-block
            width 92px
          .reg-input
            width 433px
            height 40px
            line-height 40px
          .reg-wanning
            position absolute
            left 530px
            top 16px
            color #ccc
            z-index 1
            cursor pointer
          .pass-note
            display inline-block
            min-width  150px
            height 130px
            line-height 22px
            border 1px solid #CED4DA
            border-radius 5px
            padding 10px
            position absolute
            left 420px
            top -120px
            background #fff
            color #9e9e9e
            font-size 12px
            .note-par
              .pass-note-icon
                margin-right 7px
            .note-success
              color #009375
            .note-wanning
              color #FF630F
          .reset-code
            width 300px
            margin-right 25px
          .get-resetcode
            cursor pointer
          .reg-tell
            position relative
            &::before
              content: "+86"
              display inline-block
              width 80px
              height 40px
              text-align center
              border-right 1px solid #ccc
              position absolute
            >>>.el-input__inner
              text-indent 100px
          .reg-wanning
            position absolute
            left 530px
            top 16px
            color #ccc
            z-index 1
            cursor pointer
        .reset-success
          text-align center
          .reset-sucicon
            margin-bottom 50px
          .success-text
            font-size 16px
            width 442px
            line-height 40px
            margin 0 auto 60px auto
            text-align left 
      .reset-btns
        text-align center
        
      
</style>