<template>
  <div class="assess">
    <access-links :routeLink="'accesScale'"></access-links>
    <div class="manage-search">
      <div class="search-left">
        <div class="scale-tabs">
          <span class="scale-tab scale-tab-active">量表</span>
          <span class="scale-tab" @click="toLink('accessGroupManage')">分组</span>
        </div>
        <div class="search-input-out">
          <el-input class="search-input" v-model="name" placeholder="搜索名称" />
          <img src="../../assets/images/search.png" class="search-icon" @click="getExamlists" />
        </div>
        
        <el-select v-model="categoryId" class="search-select" placeholder="量表分类">
          <el-option
            v-for="item in allCategory"
            :key="item.categoryId"
            :label="item.categoryName"
            :value="item.categoryId">
          </el-option>
        </el-select>
        <el-select v-model="payType" class="search-select" placeholder="类型">
          <el-option
            v-for="item in payTypeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="search-select" v-model="groupId"  placeholder="分组" v-has="'mgr.examGroup.query'">
          <el-option
            v-for="item in examGroupData"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="search-right">
        <span class="search-btn" @click="getCode(true)" v-has="'mgr.exam.addQrcode'"><img src="../../assets/images/import.png" class="search-btn-icon"> 生成二维码</span>
      </div>
    </div>
    <div class="access-con">
      <el-table
        v-has="'mgr.exam.query'"
        :data="examData"
        height="calc(100vh -300px)"
        style="width: 100%"
        class="table-div"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="name"
          label="量表名称"
          width="280">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80">
          <template slot-scope="scope">
            <el-switch
              class="applic-switch"
              v-model="scope.row.status"
              @change="changeApplic($event,scope.row)"
              active-color="#009375"
              active-value="Y"
              inactive-value="N"
              inactive-color="#ccc">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="categoryName"
          label="量表类型">
        </el-table-column>
        <el-table-column
          prop="numofQuestion"
          label="题数">
        </el-table-column>
        <el-table-column
          v-has="'mgr.exam.addQrcode'"
          prop="id"
          label="二维码">
          <template slot-scope="scope">
            <img @click="getCode(false,scope.row)" style="cursor:pointer;" src="../../assets/images/code_small.png" />
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格">
        </el-table-column>
        <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button class="group-link " v-has="'mgr.exam.query'" @click="seeExam(scope.row)" style="color:#4a4a4a;margin-right:10px;" type="text" size="small">查看</el-button>  | 
              <el-button class="group-link"  v-has="'mgr.exam.editExam'" @click="editExam(scope.row)" style="color:#4a4a4a" type="text" size="small">编辑</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="pager" v-has="'mgr.exam.query'">
        <!-- @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage4" -->
        <el-pagination
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 50, 200, 1000, 2000]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
        </div>
      <!-- 分组 -->
      <!-- <el-table
        :data="examData"
        style="width: 100%">
        <el-table-column
          prop="name"
          label="分组名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="180">
        </el-table-column>
        <el-table-column
          prop="address"
          label="量表数">
        </el-table-column>
        <el-table-column
          prop="address"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="address"
          label="二维码">
        </el-table-column>
        <el-table-column
          prop="address"
          label="分组价格">
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作">
        </el-table-column>
      </el-table> -->
    </div>
    <el-drawer
    :title="examDrawerTitle"
    class="exam-drawer"
    @close="closeDrawer"
    :visible="examDrawerVisable">
      <exam-drawer
      :isEdit="isEdit"
      v-if="examDrawerVisable"
      :examDetail="examDetail"
      :allCategory="allCategory"
      @toDetail="toExamDetail"
      @returnDetail="toExam"
      @modifySuccess="modifySuccess"
      @changeEidt="changeEidt"
      @handleGetCode="getCode"
      ></exam-drawer>
    </el-drawer>
    <invite-dialog
    :allCategory="allCategory"
    v-if="showInviteDialog"
    isGroup="0"
    :examDetailId="examDetail"
    :selectIds="selectIds"
    @closeInviteDialog="closeInviteDialog"
    ></invite-dialog>
  </div>
</template>
<script>
import {
  regExamlist,
  regAllCategory,
  regExamlDetail,
  regExamlQrcode,
  regExamGroupList,
  regExamStatus
} from '../../api/index'
import { powerRight } from '../../utils/index'
import ExamDrawer from '@/views/Access/ExamComponent/ExamDrawer'
import InviteDialog from '@/views/Access/ExamComponent/InviteDialog'
import AccessLinks from '@/components/Access/AccessLinks'
export default {
  components:{
    ExamDrawer,
    InviteDialog,
    AccessLinks
  },
  data () {
    return {
      examDrawerVisable: false,
      showInviteDialog: false,
      examDrawerTitle: '查看量表',
      allCategory: [], // 所有量表分类
      categoryId: '', // 当前所选量表id
      examGroupData: [], // 分组列表
      payTypeArr: [
        {
          label: '免费',
          value: 'free'
        },
        {
          label: '付费',
          value: 'paid'
        }
      ],
      payType: '', // 搜索类型 free 免费/paid 付费
      isEdit: false, // 是否为编辑详情
      statusArr:[
        {
          label: '正常',
          value: 1
        },
        {
          label: '已完成',
          value: 2
        }
      ],
      examData: [],
      name: '',
      groupId: '',
      pageNo: 1,
      pageSize:  20,
      total: 0,
      examDetail: null, // 量表详情
      selectIds: '', // 列表选择的Ids
    }
  },
   mounted(){
    if(powerRight('mgr.exam.query')){
      this.getAllCategory()
      this.getExamlists()
    }
    if(powerRight('mgr.examGroup.query')){
      this.getExamGroup()
    }
  },
  methods:{
    toLink(pageUrl){
      this.$router.push({ path: '/'+ pageUrl })
    },
    // 量表分组列表
    getExamGroup(){
      regExamGroupList({
        pageNo: 1,
        pageSize: 1000,
      }).then(res => {
        if(res && res.code === 0){
          this.examGroupData = res.data.list
          console.log('examGroupData',this.examGroupData)
        }else{
          this.$message({
            message: '获取量表分组列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 查询所有量表类型分类
    getAllCategory(){
      regAllCategory().then(res => {
        if(res && res.code === 0){
          this.allCategory = res.data
        }else{
          this.$message({
            message: '获取量表类型分类失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // pagesize更改
    handleSizeChange (val) {
      this.pageSize = val
      this.getExamlists()
    },
    handlePageChange(val){
      this.pageNo = val
      this.getExamlists()
    },
    // 量表列表
    getExamlists(){
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name,
        categoryId: this.categoryId,
        groupId: this.groupId,
        payType: this.payType
      }
      regExamlist(params).then(res => {
        console.log('列表',res)
        if(res && res.code === 0){
          this.examData = res.data.list
          this.total = res.data.totalCount
        }else{
          this.$message({
            message: '获取量表列表失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // table选择框修改
    handleSelectionChange(data) {
      this.selectIds =  data.map(obj => obj.id).join(', ');
      console.log('选择枉',this.selectIds)
    },
    // 更改状态
    changeApplic(val,data){
      let params ={
        status: val,
        id: data.id,
      }
      regExamStatus(params).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.getExamlists()
        }else{
          this.$message({
            message: '操作失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 关闭量表详情
    closeDrawer(){
      this.examDrawerVisable = false
      this.isEdit = false
    },
    // 查看量表详情
    seeExam(data){
      this.isEdit = false
      //this.examId = data.is
      this.getExam(data.id)
    },
    // 编辑量表
    editExam(data){
      this.isEdit = true
      this.getExam(data.id)
    },
    getExam(id){
      regExamlDetail({
        id
      }).then(res => {
        if(res && res.code === 0){
          this.examDetail = res.data
          this.examDrawerVisable = true
        }else{
          this.$message({
            message: '获取量表详情失败，'+res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 查看量表详情
    toExamDetail(){
      this.examDrawerTitle = '量表详情'
    },
    toExam(){
      this.examDrawerTitle = '查看量表'
    },
    // 修改成功
    modifySuccess(){
      this.getExamlists()
      this.examDrawerVisable = false
      this.isEdit = false
    },
    // 更改为编辑
    changeEidt(data){
      this.isEdit = data
    },
    // 生成二维码
    getCode(isMu,data = null){
      console.log('data',data,isMu)
      if(!isMu){
        this.examDetail = data
      }else { // 批量
        console.log('selectIds',this.selectIds)
        if(this.selectIds === ''){
          this.$message({
            message: '请先选择量表',
            type: 'wanring'
          }); 
          return false
        }
      }
      this.showInviteDialog = true
    },
    // 闭关生成二维码
    closeInviteDialog(){
      this.showInviteDialog =false
    }
  }
}
</script>
<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.assess
  padding 30px
  .assess-manages
    width 367px
    height 46px
    line-height 46px
    box-sizing border-box
    border 1px solid #E8E8E8
    display flex
    justify-content space-between
    border-radius 25px
    margin 0 auto 25px
    .assess-item
      display inline-block
      width 100px
      height 40px
      line-height 40px
      text-align center
      margin  3px
      font-size 14px
      cursor pointer
    .assess-item-active
      border-radius 25px
      background #E0F2EE
      color #009375
      font-weight  bold
  .manage-search
    margin-bottom 14px
    display flex
    justify-content space-between
    .search-left
      display flex
      .scale-tabs
        display  inline-block
        height 40px
        padding-right 19px
        margin-right 30px
        border-right 1px solid #ccc
        .scale-tab
          display inline-block
          width 70px
          height 40px
          line-height 40px
          margin-left 15px
          text-align center
          cursor pointer
        .scale-tab-active
          border-radius 25px
          background #D0E6E6
          color #009375
      .search-input-out
        display inline-block
        width 320px
        height 40px
        line-height 40px
        margin-right 17px
        position relative
        .search-icon
          position absolute
          right 10px
          top 10px
          cursor pointer
    .search-select
      width 104px
      height 40px
      line-height 40px
      margin-right 17px
    .search-right
      .search-btn
        display inline-block
        height 40px
        line-height 40px
        padding 0 10px
        border 1px solid #D8D8D8
        border-radius 5px
        margin-left 25px
        align-items center
        cursor pointer
      .search-btn-icon
        margin-right 10px
        position relative
        top 3px
  .access-con
    .table-div
      height calc(100vh - 248px)
      >>>tr
        line-height 18px
        margin 4px 0
        >>>.el-button--text
          color #4a4a4a!important
        &:hover>td 
          background  #E0F2EE
          color #00A389
          font-weight  bolder
          .table-green-btn
            color #00A389!important
            font-weight 700!important
      >>>.table-handles
        white-space nowrap
      .table-green-btn
        font-size 14px
        color #4a4a4a!important
        font-weight bold
      .popover-more
        margin-left 10px!important
        position relative
        top -4px
  .exam-drawer
    /deep/.el-drawer__header 
      color #009375
      font-size 16px
      font-weight bold
      padding 20px 0 20px 20px
      position relative
      margin-bottom 5px
      &:after
        content ""
        position absolute
        display inline-block
        width 440px
        height 1px
        bottom 0
        right 0
        background #d8d8d8
      .el-icon-close
        color #4a4a4a
        font-size 16px
</style>