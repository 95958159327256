import ajax from './ajax'
import {baseApi} from "../utils/variate"

// 首页信息
export const reqStatPatient = () => ajax(`${baseApi}/mgrapi/console/statPatient`,{},'POST',true)

// 新增日程
export const addSchedule = (data) => ajax(`${baseApi}/mgrapi/schedule/addSchedule`,data,'POST',true)

//查询某天的日程列表
export const reqListByDay = (data) => ajax(`${baseApi}/mgrapi/schedule/listByDay`,data,'POST',true)

// 删除日程
export const reqRemoveSchedule = (data) => ajax(`${baseApi}/mgrapi/schedule/remove`,data,'POST',true)

// 稍后再提示
export const reqRemindLater = (data) => ajax(`${baseApi}/mgrapi/schedule/remindLater`,data,'POST',true)

// 获取系统的图标
export const reqIcon = (data) => ajax(`${baseApi}/mgrapi/icon/getIcon`,data,'POST',true)


// 查询某个月份中含有日程的日期
export const reqDaysByMonth = (data) => ajax(`${baseApi}/mgrapi/schedule/listDaysByMonth`,data,'POST',true)

// 问答列表
export const reqFaqList = (data) => ajax(`${baseApi}/mgrapi/faq/list`,data,'POST',true)

//拉取所有faq列表(按分类分组)
export const reqGroupList = (data) => ajax(`${baseApi}/mgrapi/faq/groupList`,data,'POST',true)

//faq详情
export const reqFaqDetail = (data) => ajax(`${baseApi}/mgrapi/faq/detail`,data,'POST',true)

//  根据手机号获取注册验证码
export const reqRegPhoneCode = (data) => ajax(`${baseApi}/mgrapi/sendRegVerifyCode`,data)

//  验证注册验证码
export const reqRegCode = (data) => ajax(`${baseApi}/mgrapi/checkRegVerifyCode`,data)

// 注册提交
export const regSubmit = (data) => ajax(`${baseApi}/mgrapi/regSubmit`,data)

// 登录
export const regLogin = (data) => ajax(`${baseApi}/mgrapi/login`,data)

// 检测用户密码是否正确
export const regCheckPwd = (data) => ajax(`${baseApi}/mgrapi/session/checkPwd`,data,'POST',true)

// (首次重置密码)提交
export const regResetPass = (data) => ajax(`${baseApi}/mgrapi/resetPwdSubmit`,data,'POST',true)

// (首次重置密码)获取验证码
export const regCodeResetPass = (data) => ajax(`${baseApi}/mgrapi/sendResetPwdVerifyCode`,data,'POST',true)

//

// 密码登录
export const regPassLogin = (data) => ajax(`${baseApi}/mgrapi/login`,data)

// 获取登录验证码
export const regPhoneCodeLogin = (data) => ajax(`${baseApi}/mgrapi/sendLoginVerifyCode`,data)

// 获取登录验证码
export const regExitLogin = (data) => ajax(`${baseApi}/mgrapi/exit`,data)

// 验证码登录
export const regCodeLogin = (data) => ajax(`${baseApi}/mgrapi/loginByVerifyCode`,data)

//获取当前用户信息
export const regUserInfo = (data) => ajax(`${baseApi}/mgrapi/session/info`,data,'POST',true)

// 表单上传
export const regFromUpload = (data) => ajax(`${baseApi}/mgrapi/file/upload`,data,'POST',false,true)

// 用户列表
export const regUserLists = (data) => ajax(`${baseApi}/mgrapi/patient/list`,data,'POST',true)

// 用户新增
export const regAddPatient = (data) => ajax(`${baseApi}/mgrapi/patient/addPatient`,data,'POST',true)

// 批量新增
export const regBatchAddPatient = (data) => ajax(`${baseApi}/mgrapi/patient/batchAddPatient`,data,'POST',true)


// 编辑用户
export const regEditPatient = (data) => ajax(`${baseApi}/mgrapi/patient/editPatient`,data,'POST',true)

// 删除患者用户
export const regRemovePatient = (data) => ajax(`${baseApi}/mgrapi/patient/remove`,data,'POST',true)

// 用户统计列表
export const regPatientData = (data) => ajax(`${baseApi}/mgrapi/patient/statPatient`,data,'POST',true)


// 获取批量患者导入模板
export const reqUploadTemplate = (data) => ajax(`${baseApi}/mgrapi/patient/getUploadTemplate`,data,'POST',true)

//提交上传文件(返回批次号) 
export const reqUploadPatient = (data) => ajax(`${baseApi}/mgrapi/patient/upload`,data,'POST',true)

//提交上传文件(返回批次号) 
// export const reqUploadPatient = (data) => ajax(`${baseApi}/mgrapi/patient/upload`,data,'POST',true)

//修改账户信息（组织）
export const reqEditOrgAccountInfo = (data) => ajax(`${baseApi}/mgrapi/session/editOrgAccountInfo`,data,'POST',true)

//修改运营信息（组织）
export const reqEditOrgBusinessInfo = (data) => ajax(`${baseApi}/mgrapi/session/editOrgBusinessInfo`,data,'POST',true)

// 修改认证信息（组织）：发送验证码
export const reqSendMobileVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editOrgAdminInfo/sendMobileVerifyCode`,data,'POST',true)

// // 修改认证信息（组织）：验证验证码
// export const reqCheckVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editOrgAuthInfo/checkVerifyCode`,data,'POST',true)

// 修改认证信息（组织）
export const reqEditOrgAuthInfo = (data) => ajax(`${baseApi}/mgrapi/session/editOrgAuthInfo`,data,'POST',true)

// 修改账户信息（个人
export const reqEditPersonalAccountInfo = (data) => ajax(`${baseApi}/mgrapi/session/editPersonalAccountInfo`,data,'POST',true)

//修改认证信息（个人）
export const reqEditPersonalAuthInfo = (data) => ajax(`${baseApi}/mgrapi/session/editPersonalAuthInfo`,data,'POST',true)

// 修改运营信息(个人)：发送验证码运营手机号
export const reqSendPerMobileVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editPersonalAdminInfo/sendMobileVerifyCode`,data,'POST',true)

// 修改运营信息(个人)：验证验证码
export const reqCheckPerVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editPersonalAdminInfo/checkVerifyCode`,data,'POST',true)

// 修改认证或运营信息: 发送验证码   不区分那么多
export const reqSendVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/sendVerifyCode`,data,'POST',true)

// 修改认证或运营信息: 发送验证码   不区分那么多
export const reqCheckVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/checkVerifyCode`,data,'POST',true)

// 修改组织运营信息
export const reqEditOrgAdminInfo = (data) => ajax(`${baseApi}/mgrapi/session/editOrgAdminInfo`,data,'POST',true)

// 修改运营信息(个人)
export const reqEditPersonalAdminInfo = (data) => ajax(`${baseApi}/mgrapi/session/editPersonalAdminInfo`,data,'POST',true)



// 用户分组列表
export const regUserGroupList = (data) => ajax(`${baseApi}/mgrapi/group/list`,data,'POST',true)

// 所有分组列表
export const regAllUserGroup = (data) => ajax(`${baseApi}/mgrapi/group/listAll`,data,'POST',true)

// 编辑分组
export const regModifyGroup = (data) => ajax(`${baseApi}/mgrapi/group/editGroup`,data,'POST',true)

// 新增分组
export const regAddrGroup = (data) => ajax(`${baseApi}/mgrapi/group/addGroup`,data,'POST',true)

// 分组人员转移
export const regMoveGroup = (data) => ajax(`${baseApi}/mgrapi/group/transfer`,data,'POST',true)

// 删除分组
export const regDeleteGroup = (data) => ajax(`${baseApi}/mgrapi/group/removeGroup`,data,'POST',true)

// 患者详情
export const regPatientDetail = (data) => ajax(`${baseApi}/mgrapi/patient/detail`,data,'POST',true)

// 添加下级组织
export const regAddOrg = (data) => ajax(`${baseApi}/mgrapi/org/addOrg`,data,'POST',true)

// 编辑下级组织
export const regEditOrg = (data) => ajax(`${baseApi}/mgrapi/org/editOrg`,data,'POST',true)

// 组织树
export const regOrgTree = (data) => ajax(`${baseApi}/mgrapi/org/tree`,data,'POST',true)

// 部门新增
export const regAddDept = (data) => ajax(`${baseApi}/mgrapi/dept/addDept`,data,'POST',true)

// 部门编辑
export const regEditDeptt = (data) => ajax(`${baseApi}/mgrapi/dept/editDept`,data,'POST',true)

// 部门删除
export const regRemoveDept = (data) => ajax(`${baseApi}/mgrapi/dept/removeDept`,data,'POST',true)

// 部门详情
export const regDetailDept = (data) => ajax(`${baseApi}/mgrapi/dept/detail`,data,'POST',true) 

// 当前人员所在组织的部门列表
export const regCurDeptList = (data) => ajax(`${baseApi}/mgrapi/dept/listAllCurOrg`,data,'POST',true) 

// 成员新增
export const regAddMember = (data) => ajax(`${baseApi}/mgrapi/member/add`,data,'POST',true)

// 成员编辑
export const regEditMember = (data) => ajax(`${baseApi}/mgrapi/member/edit`,data,'POST',true)

// 成员详情
export const regDetailMember = (data) => ajax(`${baseApi}/mgrapi/member/detail`,data,'POST',true)

// 成员列表
export const reglistMember = (data) => ajax(`${baseApi}/mgrapi/member/list`,data,'POST',true)

// 成员变更部门  
export const regEditDept = (data) => ajax(`${baseApi}/mgrapi/member/editDept`,data,'POST',true)

// 成员停启用
export const regEditMemberStatus = (data) => ajax(`${baseApi}/mgrapi/member/editStatus`,data,'POST',true)

// 成员重置密码
export const regMemberResetPassword = (data) => ajax(`${baseApi}/mgrapi/member/resetPassword`,data,'POST',true)

// 成员的私有权限
export const regAuthTree = (data) => ajax(`${baseApi}/mgrapi/member/authTree`,data,'POST',true)

// 成员的私有权限授予
export const regAuthToQx = (data) => ajax(`${baseApi}/mgrapi/member/auth`,data,'POST',true)

// 基础信息 修改密码  获取验证码
export const sendBaseResetPwdVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/sendResetPwdVerifyCode`,data,'POST',true)

// 基础信息 修改密码  校验验证码
export const checkResetPwdVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/checkResetPwdVerifyCode`,data,'POST',true)

// 基础信息 修改密码  校验验证码
export const resetBasePwdSubmit = (data) => ajax(`${baseApi}/mgrapi/session/resetPwdSubmit`,data,'POST',true)

// [导入]：获取导入模板
export const reqMeUploadTemplate = (data) => ajax(`${baseApi}/mgrapi/member/getUploadTemplate`,data,'POST',true)

// 批量添加成员
export const regBatchAddMember = (data) => ajax(`${baseApi}/mgrapi/member/batchAddMember`,data,'POST',true)



// 某个组织的部门和角色混合树
export const regDeptAndRoleTree = (data) => ajax(`${baseApi}/mgrapi/org/deptAndRoleTree`,data,'POST',true)

 // 下级组织列表
export const regChildList = (data) => ajax(`${baseApi}/mgrapi/org/childList`,data,'POST',true)

// 新增角色
export const regAddRole = (data) => ajax(`${baseApi}/mgrapi/role/add`,data,'POST',true)

// 角色详情
export const regDetailRole = (data) => ajax(`${baseApi}/mgrapi/role/detail`,data,'POST',true)

//编辑角色
export const regModifyRole = (data) => ajax(`${baseApi}/mgrapi/role/edit`,data,'POST',true)

//角色列表
export const regRoleList = (data) => ajax(`${baseApi}/mgrapi/role/list`,data,'POST',true)

// 删除角色 
export const regDeleteRole = (data) => ajax(`${baseApi}/mgrapi/role/remove`,data,'POST',true)

// 角色进行赋权
export const regRoleAuth = (data) => ajax(`${baseApi}/mgrapi/role/auth`,data,'POST',true)

// 获取角色的授权情况
export const regRoleaAuthTree = (data) => ajax(`${baseApi}/mgrapi/role/authTree`,data,'POST',true)


// 某个组织的所有部门列表
export const regDeptList = (data) => ajax(`${baseApi}/mgrapi/dept/listAll`,data,'POST',true)

// 某个组织的部门树
export const regDeptTree = (data) => ajax(`${baseApi}/mgrapi/dept/tree`,data,'POST',true) 

// 查询多个部门的部门角色混合树
export const regDeptAndRoleTreeByDept = (data) => ajax(`${baseApi}/mgrapi/dept/deptAndRoleTreeByDept`,data,'POST',true) 

// 移除成员某个角色权限
export const regRemoveAuthByRole = (data) => ajax(`${baseApi}/mgrapi/member/removeAuthByRole`,data,'POST',true) 

// 拉取权限
export const regRightTree = (data) => ajax(`${baseApi}/mgrapi/right/tree`,data,'POST',true)

// 所有的疾病名称
export const regListAllName = (data) => ajax(`${baseApi}/mgrapi/disease/listAllName`,data,'POST',true)

// 修改当前用户信息，发送验证码到旧手机号
export const sendOldMobileVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editAdminMobile/sendOldMobileVerifyCode`,data,'POST',true)

// 修改当前用户信息，验证旧手机号的验证码
export const checkOldMobileVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editAdminMobile/checkOldMobileVerifyCode`,data,'POST',true)

// 修改当前用户信息，发送验证码给新手机号
export const sendNewMobileVerifyCode = (data) => ajax(`${baseApi}/mgrapi/session/editAdminMobile/sendNewMobileVerifyCode`,data,'POST',true)

// 修改当前用户信息，提交新手机号
export const editMobile = (data) => ajax(`${baseApi}/mgrapi/session/editAdminMobile/editMobile`,data,'POST',true)

// 表单配制-获取全部表单
export const RegFormdynList = (data) => ajax(`${baseApi}/mgrapi/formdyn/listAll`,data,'POST',true)

// 表单配制-获取表单分页
export const RegFormdynListPage = (data) => ajax(`${baseApi}/mgrapi/formdyn/list`,data,'POST',true)

// 表单配制-表单详情
export const regFormdynDetail = (data) => ajax(`${baseApi}/mgrapi/formdyn/detail`,data,'POST',true)

// 表单配制-新增表单
export const regAddform = (data) => ajax(`${baseApi}/mgrapi/formdyn/addForm`,data,'POST',true)

// 表单配制-编辑表单
export const regEditForm = (data) => ajax(`${baseApi}/mgrapi/formdyn/editForm`,data,'POST',true)

// 获取所有[未开通]的应用
export const regAllNotOpened = (data) => ajax(`${baseApi}/mgrapi/appConfig/listAllNotOpened`,data,'POST',true)

// 获取所有[已开通]的应用
export const regAllOpened = (data) => ajax(`${baseApi}/mgrapi/appConfig/listAllOpened`,data,'POST',true)

// 开通应用
export const regOpenApp = (data) => ajax(`${baseApi}/mgrapi/appConfig/open`,data,'POST',true)

// 停用应用
export const regCloseApp = (data) => ajax(`${baseApi}/mgrapi/appConfig/close`,data,'POST',true)

// 获取应用详情
export const regAppDetail = (data) => ajax(`${baseApi}/mgrapi/appConfig/detail`,data,'POST',true)

// 获取某个模块的所有配置
export const regListByModule = (data) => ajax(`${baseApi}/mgrapi/moduleConfig/listByModule`,data,'POST',true)

// 获取某个配置项
export const regModuleConfig = (data) => ajax(`${baseApi}/mgrapi/moduleConfig/getConfig`,data,'POST',true)

// 保存某个模块的所有配置
export const regSaveConfig = (data) => ajax(`${baseApi}/mgrapi/moduleConfig/saveConfig`,data,'POST',true)


// -----------------------量表--------------------
// 量表列表
export const regExamlist = (data) => ajax(`${baseApi}/mgrapi/exam/list`,data,'POST',true)

// 查询所有量表类型分类
export const regAllCategory = (data) => ajax(`${baseApi}/mgrapi/exam/listAllCategory`,data,'POST',true)

// 查询量表详情(会返回题库)
export const regExamlDetail = (data) => ajax(`${baseApi}/mgrapi/exam/detail`,data,'POST',true)

// 编辑量表
export const regEditExaml = (data) => ajax(`${baseApi}/mgrapi/exam/editExam`,data,'POST',true)

// 评测量表: (第1步)开始评测量表
export const regStartReply = (data) => ajax(`${baseApi}/mgrapi/visit/exam/startReply`,data,'POST',true)

// 评测量表: (第2步)提交测评答案
export const regSubmitReply = (data) => ajax(`${baseApi}/mgrapi/visit/exam/submitReply`,data,'POST',true)

//添加量表二维码
export const regExamlQrcode = (data) => ajax(`${baseApi}/mgrapi/exam/addQrcode`,data,'POST',true)

//添加量表二维码
export const regExamlGroupQrcode = (data) => ajax(`${baseApi}/mgrapi/examGroup/addQrcode`,data,'POST',true)

//给用户分配量表
export const regAssignExam = (data) => ajax(`${baseApi}/mgrapi/exam/assignExam`,data,'POST',true)

// 量表状态变更
export const regExamStatus = (data) => ajax(`${baseApi}/mgrapi/exam/editStatus`,data,'POST',true)

//分组分页
export const regExamGroupList = (data) => ajax(`${baseApi}/mgrapi/examGroup/list`,data,'POST',true)

//查询所有量表类型分类
export const regAllCategoryList = (data) => ajax(`${baseApi}/mgrapi/exam/listAllCategory`,data,'POST',true)

// 查询所有的量表(根据分类进行分组)
export const regExamTree = (data) => ajax(`${baseApi}/mgrapi/exam/examTree`,data,'POST',true)

// 新增分组
export const regAddGroup = (data) => ajax(`${baseApi}/mgrapi/examGroup/addGroup`,data,'POST',true)

// 编辑组
export const regEditGroup = (data) => ajax(`${baseApi}/mgrapi/examGroup/editGroup`,data,'POST',true)


// 查询量表分组详情
export const regExamlGroupDetail = (data) => ajax(`${baseApi}/mgrapi/examGroup/detail`,data,'POST',true)



// -------------聊天
// 获取聊天分组[分组Tab]
export const regChatGroupList = (data) => ajax(`${baseApi}/mgrapi/chat/groupList`,data,'POST',true)

// 添加聊天分组
export const regChatAddGrout = (data) => ajax(`${baseApi}/mgrapi/chat/addGroup`,data,'POST',true)

// 获取聊天用户[聊天Tab]
export const regChatList = (data) => ajax(`${baseApi}/mgrapi/chat/chatList`,data,'POST',true)

// 修改聊天分组
export const regChatEditGroup = (data) => ajax(`${baseApi}/mgrapi/chat/editGroup`,data,'POST',true)

// 获取聊天服务器地址
export const regChatServer = (data) => ajax(`${baseApi}/mgrapi/chat/getChatServer`,data,'POST',true)

// 获取与某个用户的消息(用于聊天框的消息增量拉取,每次拉取10条)
export const regChatMessageList = (data) => ajax(`${baseApi}/mgrapi/chat/messageList`,data,'POST',true)

// 删除聊天分组
export const regChatRemoveGroup = (data) => ajax(`${baseApi}/mgrapi/chat/removeGroup`,data,'POST',true)

// 将某个用户的消息全部设置已读
export const regChatSetAllMessageRead = (data) => ajax(`${baseApi}/mgrapi/chat/setAllMessageRead`,data,'POST',true)

// 获取未读的聊天用户[未读Tab]
export const regChatUnreadList = (data) => ajax(`${baseApi}/mgrapi/chat/unreadList`,data,'POST',true)

// 生成某个分组的二维码
export const regAddChartQrcode = (data) => ajax(`${baseApi}/mgrapi/chat/addQrcode`,data,'POST',true)

// 搜索聊天用户列表
export const regChartUserList = (data) => ajax(`${baseApi}/mgrapi/chat/userList`,data,'POST',true)

// -----测评报告-----
// 列表
export const regExamReportList = (data) => ajax(`${baseApi}/mgrapi/examReport/list`,data,'POST',true)

// 获取报告详情
export const regExamReport = (data) => ajax(`${baseApi}/mgrapi/examReport/getReport`,data,'POST',true)

// 量表评测审核
export const regReportaAudit = (data) => ajax(`${baseApi}/mgrapi/examReport/audit`,data,'POST',true)

// 编辑评测报告
export const regEditReport = (data) => ajax(`${baseApi}/mgrapi/examReport/editReport`,data,'POST',true)


// 量表的评测列表 -----------------
export const regReplyList = (data) => ajax(`${baseApi}/mgrapi/examReport/replyList`,data,'POST',true)


//------ 随访计划-----
//  随访报告列表
export const reqVisitPlanList = (data) => ajax(`${baseApi}/mgrapi/visit/plan/getVisitPlanReport`,data,'POST',true,false,true)
 
//  随访计划列表
export const reqVisitPlan = (data) => ajax(`${baseApi}/mgrapi/visit/plan/getVisitPlan`,data,'POST',true,false,true)

//  随访计划详情
//export const reqPlanDetail = (data) => ajax(`${baseApi}/mgrapi/visit/plan/getPlanDetails`,data,'POST',true,false,true)

//  创建随访计划
export const reqCreatePlan = (data) => ajax(`${baseApi}/mgrapi/visit/plan/createPlan`,data,'POST',true,false,true)

// 修改随访计划
export const reqUpdatePlan = (data) => ajax(`${baseApi}/mgrapi/visit/plan/updatePlan`,data,'PUT',true,false,true,true)


//  启用禁用随访计划
export const reqSwitchPlan = (data) => ajax(`${baseApi}/mgrapi/visit/plan/switchPlan`,data,'PUT',true,false,true)

//  随访计划详情
export const reqPlanDetail = (data) => ajax(`${baseApi}/mgrapi/visit/plan/getVisitPlanDetail`,data,'GET',true,false,true)

//  创建随访分组
export const reqCreateGroup = (data) => ajax(`${baseApi}/mgrapi/visit/group/createGroup`,data,'POST',true,false,true)

//  删除随访分组
export const reqDelGroup = (data) => ajax(`${baseApi}/mgrapi/visit/group/delGroup`,data,'DELETE',true,false,true)

//  修改随访分组
export const reqUpdateGroup = (data) => ajax(`${baseApi}/mgrapi/visit/group/updateGroup`,data,'POST',true,false,true)

//  分页查询随访分组
export const reqPageGroup = (data) => ajax(`${baseApi}/mgrapi/visit/group/getGroup`,data,'POST',true,false,true)

//  随访计划绑定人员
export const bindPlanPerson = (data) => ajax(`${baseApi}/mgrapi/visit/plan/bindPlanPerson`,data,'POST',true,false,true)

//  载-生成二维码,同一个问卷或者随访计划只有一个二维码
export const reqInsertQrcode = (data) => ajax(`${baseApi}/mgrapi/visit/qrcode/insertQrcode`,data,'POST',true,false,true)

//  随访帮做问卷
export const reqAnswerQuestion = (data) => ajax(`${baseApi}/mgrapi/visit/generation/planAnswerQuestion`,data,'POST',true,false,true)

// 医生帮做问卷
export const reqAnswerQuestionByPlan = (data) => ajax(`${baseApi}/mgrapi/visit/generation/answerQuestionByPlan`,data,'POST',true,false,true)

// 问卷输入框类型/mgrapi/visit/dict/inputType

// 获取常量数据
export const reqConstants = (data) => ajax(`${baseApi}/mgrapi/visit/dict/getConstants`,data,'GET',true,false,true)

// ----随访报告——---
//  获取随访报告-分页
export const reqVisitReport = (data) => ajax(`${baseApi}/mgrapi/visit/report/getVisitReport`,data,'POST',true,false,true)

// 随访报告,人工随访(随访记录)
export const reqVisitRecordDetail = (data) => ajax(`${baseApi}/mgrapi/visit/report/getVisitRecordDetail`,data,'GET',true,false,true)

// 随访报告,查看(随访计划)
export const reqVisitPlanDetail = (data) => ajax(`${baseApi}/mgrapi/visit/report/getVisitPlanDetail`,data,'GET',true,false,true)

// ====问卷
// 新增问卷
export const reqAddQuestion = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/insertQuestionnaireWithItem`,data,'POST',true,false,true)

// 问卷详情
export const reqQuestionDetail = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/getVisitQuestionnaireVo`,data,'GET',true,false,true)

// 问卷列表
export const reqAQuestionList = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/findQuByPage`,data,'POST',true,false,true)


// 删除问卷
export const reqDeleteQuestion = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/deleteQuestionnaire`,data,'POST',true,false,true)

// 判断这个问卷是否已经绑定到随访或者其他系统,并且没有完成,删除问卷模板的时候需要提醒一下
export const reqIsBindingOtherSy = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/findQuIsBindingOtherSys`,data,'GET',true,false,true)

// 启用或者禁用问卷模板
export const reqEnableQuestion = (data) => ajax(`${baseApi}/mgrapi/visit/questionnaire/enableOrDisableQuestionnaire`,data,'POST',true,false,true)



// 问卷下的 生列表
export const reqQuPageByCondition = (data) => ajax(`${baseApi}/mgrapi/visit/generation/findQuPageByCondition`,data,'POST',true,false,true)

// 答题的问卷详情
export const reqGenQuestDetail = (data) => ajax(`${baseApi}/mgrapi/visit/generation/getFinishedAnswerByGneId`,data,'POST',true,false,true)


// 删除用户做的问卷
export const reqDeleteGenQuestion = (data) => ajax(`${baseApi}/mgrapi/visit/generation/deleteGenQuestion`,data,'POST',true,false,true)

