<template>
  <div v-if="!showDetail">
    <div class="examdr-detail">
      <div class="examdr-con">
        <div class="examdr-item">
          <span class="examdr-name">量表名</span>
          <input type="text" v-if="edit" v-model="name"  class="examdr-text" />
          <span class="examdr-des" v-else>{{name}}</span>
        </div>
        <div class="examdr-item">
          <span class="examdr-name">分类</span>
          <el-select v-if="edit" v-model="categoryName" class="search-select" placeholder="量表分类" @change="changeCateogry">
            <el-option
              v-for="item in allCategory"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item">
            </el-option>
          </el-select>
          <span class="examdr-des" v-else>{{categoryName}}</span>
        </div>
        <div class="examdr-item">
          <div class="examdr-name">量表介绍</div>
          <textarea v-if="edit"  class="examdr-textarea" v-model="content"></textarea>
          <div class="examdr-des" v-else>{{content}}</div>
        </div>
        <div class="examdr-item">
          <div class="examdr-name">指导语</div>
          <textarea v-if="edit"  class="examdr-textarea" v-model="guideIntro"></textarea>
          <div class="examdr-des" v-else>{{guideIntro}}</div>
        </div>
        <div class="examdr-item" v-if="showPrice">
          <span class="examdr-name">价格</span>
          <input type="text" v-if="edit" v-model="price"  class="examdr-text" />
          <span class="examdr-des" v-else>￥{{price}}</span>
        </div>
        <div class="examdr-item">
          <span class="examdr-name">状态</span>
          <el-switch
            v-if="edit"
            v-model="status"
            active-color="#13ce66"
            inactive-color="#ccc">
          </el-switch>
          <span v-else class="examdr-des">
            <template v-if="status">启用</template>
            <template v-else>禁用</template>
          </span>
        </div>
        <div class="examdr-item"  v-if="!edit" v-has="'mgr.exam.addQrcode'">
          <span class="examdr-name" v-has="'mgr.exam.addQrcode'">二维码</span>
          <span class="examdr-des" v-has="'mgr.exam.addQrcode'">
            <span class="get-ewm" @click="handleGetCode">
              <img src="../../../assets/images/code.png" class="ewm-icon" />生成二维码
            </span>
          </span>
        </div>
      </div>
      <div class="exmdr-bottom" v-if="edit">
        <span class="exmdr-btn" @click="cancleEdit" >取消</span>
        <span class="exmdr-btn exmdr-btn-blue" @click="confirmEidt" >确定</span>
      </div>
      <div class="exmdr-bottom" v-else>
        <span class="exmdr-border-btn" @click="toDetail"><img src="../../../assets/images/see.png" class="btn-icon" /> 量表详情</span>
        <span class="exmdr-border-btn" v-has="'mgr.exam.editExam'" @click="toEdit"><img src="../../../assets/images/edit1.png" class="btn-icon" />编辑</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="examdr-con">
      <div class="examdr-item">
        <span class="examdr-name">量表名</span>
        <span class="examdr-des">{{name}}</span>
      </div>
      <div class="examdr-item">
        <span class="examdr-name">分类</span>
        <span class="examdr-des">{{categoryName}}</span>
      </div>
      <div class="examdr-main">
        <div class="examdr-title">量表介绍</div>
        <div class="examdr-des">{{content}}</div>
      </div>
      <div class="examdr-main">
        <div class="examdr-title">指导语</div>
        <div class="examdr-des"> {{guideIntro}}</div>
      </div>
      <div class="examdr-main examdr-main-quest">
        <div class="examdr-title">测评项</div>
        <div class="examdr-quest"> 
          <div class="question-item" v-for="(item,index) in questions" :key="index">
            <div class="question-title">
              <!-- <span class="question-num">1、</span>你是如何看待朋友关系的？ -->
              {{item.examName}}
            </div>
            <div class="question-options" v-if="item.examAnswer && item.examAnswer.options">
              <div class="question-option" v-for="(question,j) in item.examAnswer.options" :key="j">
               {{question}}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="exmdr-bottom">
      <span class="exmdr-btn" @click="returnDetail">返回</span>
    </div>
  </div> 
</template>

<script>
import {
  regEditExaml,
  regListByModule
} from '../../../api/index'
export default {
  props:{
    isEdit: Boolean,
    examDetail: Object,
    allCategory: Array
  },
  data () {
    return {
      edit: this.isEdit, 
      examId: null,
      showDetail:false, // 是否显示详情
      name:'', // 量表名称
      categoryName: '', // 分类
      categoryId: null, // 分类 id
      content: '', // 介绍
      guideIntro: '', // 指导语
      payType: '', // 付费类型
      price: '', // 价格
      status: '', // 状态
      questions:[], // 题目
      showPrice: false, // 是否显示价格
    }
    
  },
  mounted(){
    console.log('examDetail',this.examDetail)
    if(this.examDetail){
      this.setData()
    }
    this.getAllModule()
  },
  methods:{
    setData(){
      this.examId = this.examDetail.id
      this.name = this.examDetail.name
      this.categoryName = this.examDetail.categoryName
      this.categoryId = this.examDetail.categoryId
      this.content = this.examDetail.content
      this.guideIntro = this.examDetail.guideIntro
      this.payType = this.examDetail.payType
      this.price = this.examDetail.price
      this.status = this.examDetail.status === 'Y' ? true : false
      this.questions = this.examDetail.questions 
      let questions = this.examDetail.questions 
      if(questions.length){
        for(let i=0; i<questions.length; i++){
          if(questions[i].examAnswer && questions[i].examAnswer.options){

          }
        }
      }
    },
    // 获取是否显示价格
    getAllModule(){
      regListByModule({configModule: 'exam'}).then(res => {
        if(res.code === 0){
         let priceModule = res.data.filter(item => item.configCode === "exam.price.open")
         console.log('priceModule',priceModule)
         if(priceModule.length){
           this.showPrice = priceModule[0].configValue === 'Y' ? true : false
         }
        }else{
          this.$notify.error({
            title: '错误',
            message: '获取规则列表失败！' + res.msg
          });
          
        }
      })
    },
    // 修改分类
    changeCateogry(val){
      console.log('val',val)
      this.categoryName = val.categoryName
      this.categoryId = val.categoryId
    },
    //
    toEdit(){
      this.edit = true
      this.$emit('changeEidt',true)
    },
    toDetail(){
      this.showDetail = true
      this.$emit('toDetail')
    },
    returnDetail(){
      this.showDetail = false
      this.$emit('returnDetail')
    },
    // 取消编辑
    cancleEdit(){
      this.edit = false 
      // 重置最初状态
      this.setData()
    },
    // 修改量表
    confirmEidt(){
      let params = {
        id: this.examId,
        name: this.name,
        categoryName: this.categoryName,
        categoryId: this.categoryId,
        content: this.content,
        guideIntro: this.guideIntro,
        payType: this.payType,
        status: this.status === true ? 'Y' : 'N'
      }
      if(this.payType === 'paid'){
        params.price = this.price
      }
      console.log('params',params)
      regEditExaml(params).then(res => {
        if(res && res.code === 0){
          this.$message({
            message: '修改成功',
            type: 'success'
          }); 
          this.$emit('modifySuccess')
        }else{
          this.$message({
            message: '修改失败，' + res.msg,
            type: 'error'
          }); 
        }
      })
    },
    // 生成二维码
    handleGetCode(){
      this.$emit('handleGetCode',false, this.examDetail)
    }
    
  },
  watch:{
    isEdit(val){
      this.showDetail = false
      this.edit = val
    },
    examDetail(val){
      this.showDetail = false
      this.setData()
    }
  }
  
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.examdr-con
  padding 22px 24px
  .examdr-item
    display  flex
    margin-bottom 20px
    line-height 25px
    .examdr-name
      min-width 80px
      margin-right 10px
    .examdr-text
      width 300px
      padding 0 10px
      height 30px
      line-height 30px
      border 1px solid #ccc
      border-radius 3px
    .examdr-textarea
      width 300px
      padding 2px 10px
      height 120px
      line-height 22px
      border 1px solid #ccc
      border-radius 3px
      resize none
    .get-ewm
      display flex
      width 116px
      height 36px
      line-height 36px
      border-radius 3px
      padding 0 9px
      font-size 12px
      color #009375
      border 1px solid #CED4DA
      align-items center
      cursor pointer
      .ewm-icon
        width 25px
        margin-right 10px
  .examdr-main
    margin-bottom 14px
    .examdr-title
      font-size 16px
      color #1F1F1F
      margin-bottom 10px
      font-weight bold
    .examdr-des
      border-radius 6px
      background rgba(179, 223, 214, 0.1)
      padding 14px
      line-height 24px
      text-indent 36px
      .examdr-code
        width 120px
    .examdr-quest
      padding 15px 15px 0 
      .question-item
        margin-bottom 15px
        .question-title
          font-size 16px
          line-height 22px
          margin-bottom 10px
          .question-num
            color #FF3243
        .question-option
          padding-left 30px
          margin-top 15px
  .examdr-main-quest
    margin-top 30px
.exmdr-bottom
  display flex
  justify-content space-around
  margin-bottom 40px
  padding 0 50px
  .exmdr-btn
    display inline-block
    padding 0 20px
    height 34px
    line-height 34px
    text-align center
    border-radius 5px
    background #F0F3F6
    cursor pointer
  .exmdr-btn-blue
    background #009375
    color #fff
  .exmdr-border-btn
    height 40px
    line-height 40px
    border-radius 5px
    background #FFFFFF
    border 1px solid #D8D8D8
    padding 0 15px 
    display flex
    align-items center
    cursor pointer
    .btn-icon
      margin-right 7px
</style>